<template>
  <div :class="className" @dblclick="select">
    <div class="host-media__inner">
      <video
        autoplay
        loop
        v-if="isMaxHeadRoomVideo && getFeauredHostVideo"
        :src="getFeauredHostVideo"
        class="host-media__media-placeholder"
        :class="{ rounded, flipped: isFeaturedVideoFlipped }"
      />
      <UserMedia
        v-else-if="user && user.id"
        :identity="user.id"
        :imageUrl="user.image"
        :userRole="user.role"
        class="host-media__media"
      />
      <!-- TODO(Andrew): `aria-live` for a11y -->
      <div class="host-media__badge">
        <template v-if="isTalkingTo">
          <span v-if="user" class="rtb-color-primary">
            <UserName :firstname="user.firstname" :lastname="user.lastname" />
          </span>
          <span class="rtb-color-primary" v-else> Offline </span>
          <span class="rtb-color-primary">
            is talking to {{ globalTeamName }}</span
          >
        </template>
        <template v-else>
          <span class="rtb-color-white">Host: </span>
          <span v-if="user" class="rtb-color-primary">
            <UserName :firstname="user.firstname" />
          </span>
          <span v-else class="rtb-color-primary"> Offline </span>
        </template>
      </div>
    </div>
    <!-- TODO(Andrew): a11y button -->
    <v-icon v-if="isHost" class="host-media__mute" @click="toggleAudio">
      {{ isMuted ? "volume_off" : "volume_up" }}
    </v-icon>
  </div>
</template>

<script>
import Vue from "vue"
import { mapActions, mapGetters, mapState } from "vuex"
import UserName from "@/components/GroupTeams/Common/User/UserName"
import UserMedia from "@/components/GroupTeams/Common/User/UserMedia"

export default Vue.extend({
  name: "HostMedia",
  components: {
    UserName,
    UserMedia
  },
  props: {
    rounded: {
      type: Boolean,
      default: false
    },
    hideBadge: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions(["updateUser"]),
    ...mapActions("recording", ["getAnnouncementVideos"]),
    ...mapActions("Games", ["updateGameAny"]),
    select() {
      if (this.isHost) {
        this.updateUser({
          userID: this.user.id,
          obj: { selected: !this.user.selected }
        })
      }
    },
    toggleAudio() {
      return this.updateUser({
        userID: this.user.id,
        obj: { muted: !this.user.muted }
      })
    }
  },
  computed: {
    ...mapState("group", { globalTeamID: "currentGlobalTeam" }),
    ...mapGetters({ user: "gameHost", gameID: "gameID" }),
    ...mapGetters("auth", ["isHost", "clientID"]),
    ...mapGetters({ teams: "chats", game: "game" }),
    isMuted() {
      const muted = this.user?.muted ?? false
      if (this.isHost) {
        return muted
      } else {
        return muted || this.isMutedGlobally
      }
    },
    isTalkingTo() {
      return !this.isHost && this.isMutedGlobally && !this.isMuted
    },
    globalTeamName() {
      return this.globalTeam ? this.globalTeam.name : "another team"
    },
    globalTeam() {
      if (!this.teams) return null
      if (!this.globalTeamID) return null
      return this.teams[this.globalTeamID]
    },
    isMutedGlobally() {
      return this.user ? this.user.mutedGlobally : false
    },
    className() {
      return [
        "host-media",
        {
          "host-media--rounded": this.rounded,
          "host-media--muted": this.isMuted
        }
      ]
    },
    isMaxHeadRoomVideo() {
      return this.game?.isMaxHeadRoomVideo || false
    },
    getFeauredHostVideo() {
      return this.game?.featuredRecording?.url
    },
    isFeaturedVideoFlipped() {
      return !!this.game?.featuredRecording?.flipped
    }
  }
})
</script>

<style lang="scss">
.host-media {
  $block: &;
  height: 100%;
  border: 3px solid $primary_accent_color;
  border-radius: 8px;
  background-color: $primary_accent_color;
  transition: border-radius 0.75s 0.25s;

  &__inner {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: inherit;
  }

  &__media-placeholder {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
    transform: translateZ(0);
    &.flipped {
      transform: translateZ(0) scaleX(1);
    }
  }

  &__media {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    overflow: hidden;
    // GPU acceleration, please don't remove it
    transform: translateZ(0);
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__badge {
    @include absolute($bottom: 0, $left: 0);
    padding: 4px 12px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: inherit;
    background-color: $color-secondary-dark;
    font-size: 12px;
    transition: opacity 0.5s 1s ease-in-out;
  }

  &__mute {
    @include absolute($top: 4px, $left: 4px);
    font-size: 14px;
    color: $color-white !important;
    cursor: pointer;
  }

  &--rounded {
    border-radius: 50%;

    #{$block}__badge {
      opacity: 0;
      transition: none;
    }
  }

  &--muted {
    border-color: $color-tertiary-dark;
    background-color: $color-tertiary-dark;
  }
}
</style>
