<template>
  <div class="game-over-team-minor-specifics-wrap">
    <div class="game-over-team-minor-specifics-wrap-wrap">
      <transition name="team-carrot-specifics-transition">
        <div class="game-over-team-minor-specifics" v-if="canShow">
          <SvgTriangle class="team-minor-specifics-shape-left"></SvgTriangle>
          <div class="team-minor-specifics-text">
            <span
              >{{ name }} <span class="highlighted">{{ pts }}</span></span
            >
          </div>
          <SvgTriangle class="team-minor-specifics-shape-right"></SvgTriangle>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import SvgTriangle from "@/components/Shared/SvgTriangle.vue"

export default {
  name: "GameOverTeamTag",
  components: {
    SvgTriangle
  },
  props: {
    canShow: {
      type: Boolean,
      default: false
    },
    total: {
      required: true,
      type: Number
    },
    name: {
      required: true,
      type: String
    }
  },
  computed: {
    pts() {
      return this.total + "pts"
    }
  }
}
</script>

<style lang="scss">
.game-over-team-minor-specifics-wrap {
  pointer-events: none;
  height: 0px;
  text-align: center;
  .team-carrot-specifics-transition-enter-active {
    transition: all ease 0.4s;
  }
  .team-carrot-specifics-transition-leave-active {
    transition: all ease 0.4s;
  }
  .team-carrot-specifics-transition-enter,
  .team-carrot-specifics-transition-leave-to {
    transform: scale(0);
  }
  .game-over-team-minor-specifics-wrap-wrap {
    transform: translateY(-50%);
    position: relative;
  }
  .game-over-team-minor-specifics {
    position: relative;
    display: inline-block;
    color: $color-white;
    background-color: $primary_accent_color;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
  }
  .highlighted {
    color: $color-white;
  }
  .team-minor-specifics-text {
    padding: 0 1rem;
    font-size: 18px;
    font-weight: bold;
  }
  .team-minor-specifics-shape-left,
  .team-minor-specifics-shape-right {
    width: auto;
    top: 0;
    height: 100%;
    position: absolute;
  }
  .team-minor-specifics-shape-left {
    right: calc(100% - 1px);
  }
  .team-minor-specifics-shape-right {
    left: calc(100% - 1px);
    transform: rotate(180deg);
  }
}
</style>
