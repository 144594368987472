<template>
  <RtbCard class="meeting-announcement-card">
    <v-layout column justify-center>
      <v-flex class="text-md-center">
        {{ game.meetingAnnouncement }}
      </v-flex>
    </v-layout>
  </RtbCard>
</template>

<script>
import { mapGetters } from "vuex"
const RtbCard = () => import("@/components/GroupTeams/Common/Games/RtbCard.vue")

export default {
  name: "MeetingAnnouncement",
  components: {
    RtbCard
  },
  computed: {
    ...mapGetters(["game"])
  }
}
</script>

<style lang="scss">
.meeting-announcement-card {
  position: relative;
  margin: auto;
  width: fit-content;
  height: auto;
  overflow: scroll;
  max-height: 120px;
  max-width: 50%;
  min-width: 30%;
  padding: 5px !important;
  font-size: 21px;
  font-weight: bolder;
  margin-top: 4px;
  overflow-wrap: break-word;
  overflow-y: auto !important;
}
</style>
