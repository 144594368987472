export class Firebase {
  /**
   *
   * @param {object} value
   * @param {string} key
   */
  static normalizeSnapshotToArray(value, key = "id") {
    return Object.keys(value).map(k => ({ [key]: k, ...value[k] }))
  }

  /**
   * @param {string} value
   * @returns {boolean}
   */
  static isFirebaseKey(value) {
    return value.startsWith("-")
  }
}
