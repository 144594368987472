import { render, staticRenderFns } from "./WaitingForTeam.vue?vue&type=template&id=0c82c075&scoped=true&"
import script from "./WaitingForTeam.vue?vue&type=script&lang=js&"
export * from "./WaitingForTeam.vue?vue&type=script&lang=js&"
import style0 from "./WaitingForTeam.vue?vue&type=style&index=0&id=0c82c075&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c82c075",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VBtn,VContainer,VFlex,VLayout,VProgressCircular})


/* hot reload */
if (module.hot) {
  var api = require("/Users/finnkelly/Code/Go Remote/remote-chat-finn/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0c82c075')) {
      api.createRecord('0c82c075', component.options)
    } else {
      api.reload('0c82c075', component.options)
    }
    module.hot.accept("./WaitingForTeam.vue?vue&type=template&id=0c82c075&scoped=true&", function () {
      api.rerender('0c82c075', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/GroupTeams/Common/WaitingForTeam.vue"
export default component.exports