var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-layout", { attrs: { column: "", "justify-center": "" } }, [
    _c(
      "div",
      { staticClass: "social-player" },
      [
        _c(
          "v-flex",
          { ref: "root", staticClass: "fact-match-player-container" },
          [
            _c("RatioContainer", { staticClass: "ratio-container" }, [
              _c(
                "div",
                { staticClass: "outline-border" },
                [
                  _vm.imageOnly
                    ? _c("UserImage", { attrs: { imageUrl: _vm.imageURL } })
                    : _c("UserMedia", {
                        attrs: { identity: _vm.userID, imageUrl: _vm.imageURL }
                      }),
                  !_vm.imageOnly
                    ? _c(
                        "v-layout",
                        {
                          staticClass: "fact-match-player-bottom-info",
                          attrs: { row: "" }
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "fact-match-player-name",
                              attrs: { column: "", "justify-center": "" }
                            },
                            [
                              _c(
                                "v-flex",
                                [
                                  _c("UserName", {
                                    attrs: {
                                      firstname: _vm.firstname,
                                      lastname: _vm.lastname
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "fact-match-player-team" }, [
                    _c(
                      "div",
                      { staticClass: "fact-match-player-team-bg" },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "fact-match-player-team-icon" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.teamIcon || "radio_button_unchecked"
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }