var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    {
      staticClass: "team-fact-match-card",
      attrs: { "d-flex": "", "text-xs-center": "" }
    },
    [
      _c(
        "transition",
        { attrs: { name: "card-flip-transition", mode: "out-in" } },
        [
          _vm.state === "results"
            ? _c(
                "v-flex",
                {
                  key: _vm.state,
                  staticClass: "card-front-side card",
                  attrs: { "d-flex": "" }
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "", "justify-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c("v-icon", { staticClass: "team-icon" }, [
                            _vm._v(" " + _vm._s(_vm.teamIcon) + " ")
                          ])
                        ],
                        1
                      ),
                      _c("v-flex", { attrs: { shrink: "" } }, [
                        _c("span", { staticClass: "team-name" }, [
                          _vm._v(_vm._s(_vm.teamName))
                        ])
                      ]),
                      _c(
                        "v-flex",
                        { staticClass: "team-score", attrs: { shrink: "" } },
                        [
                          _c("span", { staticClass: "team-score-number" }, [
                            _vm._v(_vm._s(_vm.pts))
                          ]),
                          _c("span", { staticClass: "team-score-pts" }, [
                            _vm._v("pts")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-flex",
                {
                  key: _vm.state,
                  staticClass: "card-back-side card",
                  attrs: { "d-flex": "" }
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "", "justify-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c("v-icon", { staticClass: "team-icon" }, [
                            _vm._v(" " + _vm._s(_vm.teamIcon) + " ")
                          ])
                        ],
                        1
                      ),
                      _c("v-flex", { attrs: { shrink: "" } }, [
                        _c("span", { staticClass: "team-name" }, [
                          _vm._v(_vm._s(_vm.teamName))
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }