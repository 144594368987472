<template>
  <div class="mode-mapper">
    <Swapper :component="component" />

    <ModeMapperHostContainer :mission="currentMission" :mode="mode">
      <template #default="{ rounded, badgeHidden }">
        <HostMedia
          v-show="isHostMediaVisible"
          :rounded="rounded"
          :hide-badge="badgeHidden"
        />
      </template>
    </ModeMapperHostContainer>

    <ModeMapperTips :game="game" :mission="currentMission" :mode="mode" />
  </div>
</template>

<script>
import Vue from "vue"

import { GameMission } from "@/entities/GameMission"

import Swapper from "@/components/GroupTeams/Common/Swapper.vue"
import HostMedia from "@/components/GroupTeams/Common/HostMedia.vue"
import ModeMapperHostContainer from "./ModeMapperHostContainer.vue"
import ModeMapperTips from "./ModeMapperTips.vue"
import { GameMode } from "@/entities/GameMode"

export default Vue.extend({
  name: "ModeMapper",
  components: {
    Swapper,
    HostMedia,
    ModeMapperHostContainer,
    ModeMapperTips
  },
  props: {
    mode: {
      /** @type {import('vue').PropType<import('@/entities/GameMode).GameMode>}*/
      type: String,
      required: true
    }
  },
  data() {
    return {
      component: ""
    }
  },

  computed: {
    game() {
      return this.$store.getters.game
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    missionBehavior() {
      return this.currentMission.behavior
    },
    isFactMatchHuddle() {
      return (
        this.missionBehavior === GameMission.FactMatch &&
        this.mode === GameMode.Huddle
      )
    },
    isFactMatchResults() {
      return (
        this.missionBehavior === GameMission.FactMatch &&
        this.mode === GameMode.Results
      )
    },
    isHostMediaVisible() {
      return this.mode !== GameMode.Meeting
    }
  },
  watch: {
    mode(newValue) {
      this.$nextTick(() => {
        this.component = this.mapCurrentMode(newValue)
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.component = this.mapCurrentMode(this.mode)
    })
  },
  methods: {
    mapCurrentMode(currentMode) {
      // an exclusive rule for a fact match mission
      if (this.isFactMatchHuddle) return "SocialMode"
      // if (this.isFactMatchSocial) return "SocialMode"
      if (this.isFactMatchResults) return null
      switch (currentMode) {
        case "welcome":
          return null
        case "explain":
        case "play":
        case "huddle":
        case "results":
          return "PlayMode"
        case "social":
          return "SocialMode"
        case "voting":
          return "VotingMode"
        case "over":
          return null
        case "meeting":
          return "MeetingMode"
        default:
          return this.component
      }
    }
  }
})
</script>

<style lang="scss">
.mode-mapper {
  $block: &;
  position: relative;
  display: flex;
  width: 100%;
  min-height: 200px;
  max-width: $max_width;
  margin: {
    right: auto;
    left: auto;
  }
}
</style>
