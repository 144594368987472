<template>
  <v-app>
    <template v-if="authorized && isHost">
      <LobbyToolbar v-if="isLobby" />
      <DirectGroup v-else-if="isGame" />
      <v-toolbar v-else app flat dark class="black grey--text" height="33px">
        <v-toolbar-title style="font-size: 15px" width="400">
          <span id="theOrg" v-if="!!org" @click="pushToGames">
            {{ org.name }}
          </span>
          <span id="game" v-if="!!game"> > {{ game.name }}</span>
        </v-toolbar-title>
        <v-flex shrink>
          <v-btn icon @click="pushToGameSettings">
            <v-icon>edit</v-icon>
          </v-btn>
        </v-flex>
        <v-flex shrink>
          <v-btn icon :to="`/gamelogin/${urlID}?auth=0`" target="_blank">
            <v-icon>flash_on</v-icon>
          </v-btn>
        </v-flex>
        <v-toolbar-items v-for="item in menuItems" :key="item.title">
          <v-btn class="grey--text" :to="item.route" flat>
            <v-icon class="grey--text" left>{{ item.icon }}</v-icon>
            <div class="hidden-xs-only">{{ item.title }}</div>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </template>

    <v-content>
      <router-view />
    </v-content>

    <Popup
      :isOpen="!!popupText"
      @onClose="onPopupClose"
      @onResponse="onPopupClose"
      :mode="'okay'"
    >
      {{ popupText }}
    </Popup>
    <div v-if="!!localVersion" class="app-version-label">
      <div>
        {{ localVersion }}
      </div>
    </div>
    <video style="display: none" id="screenshotRootVidEl" muted />

    <GameStartSound v-if="isPreGame" />
    <!-- <Speech v-if="canEnableSpeechToText" /> -->
  </v-app>
</template>

<script>
import firebase from "firebase/app"
import "firebase/database"
import * as moment from "moment"
import _ from "lodash"
import { mapGetters, mapActions } from "vuex"
import { TweenMax } from "gsap/TweenMax"
import { getCurrentUser } from "./services/auth.service"
import { removeItemsByPatterns } from "@/services/localstorage.service"

import { ActionTypes as OrgModuleActionTypes } from "@/store/OrgModule"
import {
  initialize as initializeChat,
  show as showChat,
  hide as hideChat,
  identify as identifyChatUser
} from "@/services/purechat.service"
import { GetterTypes } from "@/store"
import { Role, Navigation, GameHistoryStorage } from "@/helpers"

import LobbyToolbar from "@/components/GroupTeams/Common/LobbyToolbar"
import DirectGroup from "@/components/GroupTeams/Common/DirectGroup2"
import Popup from "@/components/Popups/Popup"
import GameStartSound from "@/components/GameStartSound"

import { fetchGame } from "@/services/game.service"
import { fetchClient } from "@/services/client.service"
import {
  identifyLogRocketUser,
  initializeLogRocket
} from "@/services/logrocket.service"
import { onAuthStateChanged, onIdTokenChanged } from "@/services/auth.service"
import { fetchTwilioToken } from "@/services/api.service"

import { LATECOMER_TIME } from "@/config"

// a global value for any TweenMax animation
const DEFAULT_FRAMES_PER_SECOND = 60
const USER_CHECK_FORGET_DELAY = 1000 * 60 * 60 * 8
// 15 second to accept auto assign
const DASHBOARD_PAGES = [
  "games",
  "clients",
  "hosts",
  "events",
  "gameSettings",
  "twitch"
]

const SPEECH_TO_TEXT_ENABLED_PAGES = ["lobby", "pregame"]

const USER_ASSIGN_MESSAGE_TEMPLATE = game =>
  `Your amazing host has put you on ${game}. Cheers 🍸`

const AUTO_ASSIGN_MESSAGE_TEMPLATE = game =>
  `You have been added to ${game}. Cheers 🍸`

const VERSION_UPDATE_MESSAGE_TEMPLATE = version =>
  `NEW VERSION ${version} IS AVAILABLE. REFRESH NOW?`

const SCREENSHARE_ENABLED_PAGES = ["lobby", "game"]

export default {
  name: "App",
  data() {
    return {
      response: null,
      localVersion: null,
      remoteVersion: null,
      isLockedToGame: false,
      now: null,
      isGameTime: false,
      purechat: false
    }
  },
  components: {
    LobbyToolbar,
    DirectGroup,
    GameStartSound,
    Popup
    // Speech
  },
  async created() {
    // local version
    this.localVersion = this.$app.version
    // Remove items from localStorage (it covers cases: first login or refresh)
    removeItemsByPatterns([/viewedKeynote/])
    // subscribe to remote version
    if (window.location.hostname !== "localhost") {
      firebase
        .database()
        .ref("app/version")
        .on("value", snapshot => {
          this.remoteVersion = snapshot.val()
        })
    }
    // watch for auth status change
    onAuthStateChanged(data => {
      if (data) {
        console.group("AUTH")
        console.log("USER AUTHORIZED")
        console.groupEnd()
      } else {
        this.$store.dispatch("auth/signUserOut")
        console.group("AUTH")
        console.log("USER UNAUTHORIZED")
        console.groupEnd()
      }
    })
    // Update refreshed firebase token
    onIdTokenChanged(async user => {
      if (user) {
        const token = await user.getIdToken()
        this.$store.dispatch("auth/updateFirebaseToken", token)
      }
    })
    // cache to not trigger recalcutaion every time `globalTime` changes
    this.isGameTime = this.time > this.gameStartTime && this.gameStartTime
    TweenMax.ticker.fps(DEFAULT_FRAMES_PER_SECOND)
    document.title = "RTB"
    const { clientID, gameID } = Navigation.parseUrlID(this.$route.params.id)

    let canStartChat = false

    if (clientID) {
      const client = await fetchClient({ clientID })
      if (client && client.onboardChat) {
        canStartChat = true
      } else if (client && gameID) {
        const game = await fetchGame({ orgID: client.orgID, gameID })
        if (game.onboardChat) {
          canStartChat = true
        }
      }
    }

    if (canStartChat) {
      const chat = initializeChat()
      chat.on("chatbox:ready", () => {
        hideChat()
        this.purechat = true
      })
    }

    if (
      this.$route.query.auth !== "0" &&
      this.lastKnownUrl &&
      clientID &&
      this.lastKnownUrl.indexOf(clientID) > -1
    ) {
      await this.reauthenticate()
    }
    await this.updateTime(Date.now())
    this.now = this.time
    window.addEventListener("beforeunload", e => {
      this.disconnect()
      // the absence of a returnValue property on the event will guarantee the browser unload happens
      delete e["returnValue"]
    })
  },
  computed: {
    ...mapGetters(["missions", "time", "onlineUsersArray"]),
    ...mapGetters("auth", [
      "lastKnownCheck",
      "user",
      "role",
      "lastKnownUrl",
      "hasPreGame",
      "initialized",
      "initializing",
      "authorized",
      "clientID",
      "client",
      "isSuper",
      "isHost",
      "isAudit",
      "isSpectator"
    ]),
    ...mapGetters(["drawer", "orgID", "urlID", "isExpoTourCompleted"]),
    ...mapGetters({
      org: "theOrg",
      game: "actualGame",
      gameID: "actualGameID"
    }),
    ...mapGetters("pregame", [
      "usersToMap",
      "ignoreLocalAssign",
      "userPlayedGames"
    ]),
    ...mapGetters("pregame", { popupText: "message" }),
    ...mapGetters("twilio", {
      isTwilioBusy: "isBusy",
      twilioError: "error",
      isTwilioConnected: "isConnected"
    }),
    ...mapGetters("screenshot", [
      "getIsBroadcastingDataToTwitch",
      "getScreenshotStream",
      "getLocalScreenshotStatus"
    ]),
    isClientGamePlayed() {
      return !!Object.keys(this.userPlayedGames || {}).length
    },
    isUserChecked() {
      return this.now
        ? this.lastKnownCheck + USER_CHECK_FORGET_DELAY > this.now
        : false
    },
    canTurnOnboardingOff() {
      return (
        this.authorized &&
        this.isUserChecked &&
        (this.isLobby || this.isPreGame || this.isGame) &&
        this.isUserOnboarding
      )
    },
    isUserOnboarding() {
      return this.user ? !!this.user.onboarding : false
    },
    isPureChatOn() {
      console.log("PURE CHAT", this.route)
      return (
        this.purechat &&
        (this.route === "login" ||
          this.route === "welcome" ||
          this.route === "pregame")
      )
    },
    route() {
      return this.$route.name
    },
    isLobby() {
      return this.route === "lobby"
    },
    isPreGame() {
      return this.route === "pregame"
    },
    isGame() {
      return this.route === "game"
    },
    isGameSettings() {
      return this.route === "gameSettings"
    },
    gameStarted() {
      return this.game && this.user.gameID ? !!this.game.started : false
    },
    gameStartTime() {
      return this.game && this.user.gameID ? this.game.startTimestamp : 0
    },
    isGameRunning() {
      return this.gameStarted && this.isGameTime
    },
    isGameLocked() {
      return this.game ? !!this.game.locked : true
    },
    isLoggerOnInClinet() {
      return !!this.client && !!this.client.logrocket
    },
    isLoggerOnInGame() {
      return !!this.game && !!this.game.logrocket
    },
    isLoggerOn() {
      return this.isLoggerOnInClinet || this.isLoggerOnInGame
    },
    identifyToLogger() {
      return this.isLoggerOn && this.authorized
    },
    menuItems() {
      let items = [{ icon: "lock_open", title: "Login", route: "/login" }]
      if (this.authorized) {
        items = []
        items.push({
          icon: "transit_enterexit",
          title: "Logout",
          route: "/logout"
        })
        if (this.isSuper) {
          items.push({
            icon: "admin_panel_settings",
            title: "Admin",
            route: "/admin"
          })
        }
        items.push({
          icon: "golf_course",
          title: "Games",
          route: "/games"
        })
        items.push({
          icon: "accessibility",
          title: "Clients",
          route: "/clients"
        })
      }
      return items
    },
    isTwilioRoute() {
      return this.isGame || this.isLobby
    },
    canTurnTwilioConnectionOn() {
      return this.isTwilioRoute && !this.isTwilioBusy && this.initialized
    },
    canTurnTwilioConnectionOff() {
      return (
        (!this.isTwilioRoute || this.initializing) &&
        this.isTwilioConnected &&
        !this.isPreGame
      )
    },
    twilioConnectionPreferredStatus() {
      if (this.canTurnTwilioConnectionOn) {
        return "on"
      } else if (this.canTurnTwilioConnectionOff) {
        return "off"
      } else {
        return null
      }
    },
    isLocked() {
      return this.isGameLocked && !this.isHost
    },
    canEnterGame() {
      return !this.isLocked && this.initialized
    },
    isClientLocked() {
      return this.client ? !!this.client.locked : false
    },
    isGameEnded() {
      return this.game
        ? !!this.game.endTimestamp || !!this.game.deletedTimestamp
        : false
    },
    preferredRoute() {
      if (!this.authorized) {
        return null
      } else if (
        !this.isUserChecked ||
        (this.client?.showChooseGameCard &&
          !this.isHost &&
          !this.isAudit &&
          !this.isClientGamePlayed &&
          (!this.user?.gameID || !this.user?.teamID))
      ) {
        return "welcome"
      } else if (this.hasPreGame && !this.initializing) {
        if (this.isClientLocked && !this.isHost) {
          return "welcome"
        } else if (this.isGameEnded && !this.isHost) {
          return "pregame"
        } else if (this.isGameRunning && this.initialized) {
          return "game"
        } else if (this.isGameTime && this.initialized) {
          return "lobby"
        } else {
          return "pregame"
        }
      } else if (!this.initializing) {
        if (
          (this.gameStarted && this.canEnterGame) ||
          (this.isLockedToGame && this.canEnterGame)
        ) {
          if (!this.isHost) this.isLockedToGame = true
          return "game"
        } else if (this.canEnterGame) {
          return "lobby"
        } else if (this.initialized) {
          return "welcome"
        } else {
          return null
        }
      } else {
        return null
      }
    },
    nOfPlayersOnline() {
      return this.onlineUsersArray.length
    },
    isGameSigned() {
      return this.game ? !!this.game.startTime : false
    },
    canUpdateGameStatus() {
      return (
        this.initialized &&
        this.nOfPlayersOnline > 3 &&
        !this.isGameSigned &&
        this.isHost
      )
    },
    canAutoAssignTeam() {
      return (
        !this.isGameLocked &&
        !this.isHost &&
        !this.isAudit &&
        !this.isSpectator &&
        this.gameStarted &&
        this.user.gameID &&
        this.user.teamID === 0 &&
        this.initialized &&
        this.isGame
      )
    },
    canSubscribeToGodsHand() {
      return this.authorized && this.clientID && this.user
    },
    isDashboardPage() {
      return this.route
        ? DASHBOARD_PAGES.includes(this.route.toLowerCase())
        : false
    },
    canSubscribeToDashboardData() {
      return !!this.authorized && !!this.isDashboardPage && !!this.isHost
    },
    isScreenSharingPage() {
      return this.route
        ? SCREENSHARE_ENABLED_PAGES.includes(this.route.toLowerCase())
        : false
    },
    shouldReactToScreenSharingStatus() {
      return this.isScreenSharingPage && this.getLocalScreenshotStatus
    },
    isSpeechToTextEnabled() {
      return this.game.speechToText || false
    },
    isSpeechEnabledPage() {
      return this.route
        ? SPEECH_TO_TEXT_ENABLED_PAGES.includes(this.route.toLowerCase())
        : false
    },
    canEnableSpeechToText() {
      return (
        this.isSpeechEnabledPage &&
        this.isSpeechToTextEnabled &&
        (this.user.speechToText || this.isHost)
      )
    }
  },
  watch: {
    isPureChatOn: {
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          showChat()
        } else if (!newValue && newValue !== oldValue) {
          hideChat()
        }
      },
      immediate: true
    },
    remoteVersion: {
      async handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          console.group("APP VERSION")
          console.log(`REMOTE VERSION: ${newValue}`)
          console.log(`LOCAL VERSION: ${this.localVersion}`)
          console.groupEnd()
          if (this.$app.isSmallerThan(newValue)) {
            if (window.confirm(VERSION_UPDATE_MESSAGE_TEMPLATE(newValue))) {
              const settings = await this.getLocalClientSetting({
                clientID: "0"
              })
              const { regular } = settings || {}
              const { timestamp } = regular || {}
              this.$app.purge()
              if (timestamp)
                await this.updateLocalClientSettingLoginTimestamp(timestamp)
              window.location.reload(true)
            }
          }
        }
      },
      immediate: true
    },
    identifyToLogger: {
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          const { id: userID, username, firstname, lastname } = this.user
          const { clientID, gameID } = this
          const email = getCurrentUser().email
          initializeLogRocket(this.$store)
          identifyLogRocketUser({
            userID,
            username,
            firstname,
            lastname,
            email,
            gameID,
            clientID
          })
        }
      },
      immediate: true
    },
    canTurnOnboardingOff: {
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.updateUser({ userID: this.user.id, obj: { onboarding: false } })
        }
      },
      immediate: true
    },
    async orgID(value) {
      if (this.canSubscribeToDashboardData && value)
        await this.subscribeToDashboardData()
    },
    async canSubscribeToDashboardData(newValue, oldValue) {
      if (newValue && newValue === oldValue) return
      if (newValue) {
        await this.$store.dispatch(OrgModuleActionTypes.FETCH_ORGS)
        await this.subscribeToDashboardData()
      } else {
        await this.unsubscribeFromDashboardData()
      }
    },
    canSubscribeToGodsHand(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.$store.dispatch("pregame/subscribeToGodsHand")
        console.log(`this.clientID in App.vue ${this.clientID}`)
      }
    },
    gameStartTime(value) {
      this.isGameTime = this.time > value && value
    },
    time(value) {
      const isGameTime = value > this.gameStartTime && this.gameStartTime
      if (this.isGameTime != isGameTime) this.isGameTime = isGameTime
    },
    usersToMap: {
      async handler(value) {
        const { gameID, force } = value || {}
        if ((!this.user.gameID || force) && !this.initializing) {
          try {
            if (gameID === 0) {
              await this.onPopupClose()
              await this.updateLocalAssignState(true)
              await this.$store.dispatch("auth/deinitializeGame")
            } else if (gameID && this.user.gameID !== gameID) {
              if (this.ignoreLocalAssign)
                return this.updateLocalAssignState(false)
              await this.onPopupClose()
              await this.initializeToGame({ gameID, clientID: this.clientID })
              const name = this.game.externalName || this.game.name
              const message =
                force && !this.isHost
                  ? USER_ASSIGN_MESSAGE_TEMPLATE(name)
                  : AUTO_ASSIGN_MESSAGE_TEMPLATE(name)
              await this.updateMessage(message)
            }
          } catch (e) {
            console.log(e)
            console.warn(`Could not auto assing ${gameID} game`)
          }
        }
      },
      immediate: false
    },
    canUpdateGameStatus: {
      async handler(value) {
        if (value) await this.updateGameStatus()
      },
      immediate: true
    },
    isGameLocked: {
      async handler(value) {
        if (value === true && this.initialized && this.isHost) {
          await this.updateGameAny({
            theKey: this.gameID,
            started: false,
            endTimestamp: 0
          })
        }
      },
      immediate: true
    },
    twilioError(error) {
      console.group("TWILIO")
      if (error) {
        console.error(error)
        alert(error)
        if (this.gameID) {
          window.location.href = `/login/${this.clientID}${this.gameID}?auth=0`
        } else {
          window.location.href = `/login/${this.clientID}?auth=0`
        }
      } else {
        console.warn("You've been disconnected from Twilio")
      }
      console.groupEnd()
    },
    twilioConnectionPreferredStatus: {
      handler(value) {
        if (value) this.onTwilioConnectionPreferredStatusChange(value)
      },
      immediate: true
    },
    authorized: {
      async handler(value) {
        if (!value) return
        document.title = `RTB - ${this.user.firstname}`
        if (this.purechat) {
          const email = getCurrentUser()?.email
          const firstname = this?.user?.firstname
          const lastname = this?.user?.lastname
          identifyChatUser({ email, firstname, lastname })
        }
      },
      immediate: true
    },
    preferredRoute: {
      handler(value) {
        this.onPreferredRouteChange(value)
      },
      immediate: true
    },
    canAutoAssignTeam: {
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue)
          this.autoAssignTeam({ userID: this.user.id })
      },
      immediate: true
    },
    shouldReactToScreenSharingStatus: {
      handler(value) {
        if (Role.Host !== this.role) return
        this.onScreenSharingStatusChange(value)
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions("auth", [
      "initializeToGame",
      "updateLastKnownCheck",
      "getLocalClientSetting",
      "updateLocalClientSettingLoginTimestamp"
    ]),
    ...mapActions(["setLowPerformance", "updateTime", "updateUser"]),
    ...mapActions("Games", ["updateGameAny"]),
    ...mapActions("auth", ["reauthenticate"]),
    ...mapActions("group", ["autoAssignTeam"]),
    ...mapActions("pregame", ["updateMessage", "updateLocalAssignState"]),
    ...mapActions("screenshot", [
      "startScreenshotStream",
      "stopScreenshotStream",
      "stopTwitchStreaming"
    ]),
    onPopupClose() {
      return this.updateMessage(null)
    },
    onPreferredRouteChange: _.debounce(async function (value) {
      if (this.isDashboardPage) return
      // await this.$store.dispatch("fetchMissions", this.gameID)
      await this.$store.dispatch("bringBackActualGame")
      // var arr = Object.values(this.$store.getters.missions)
      // await this.$store.dispatch("setLocalCurrentMission", arr[0])
      if (value === "welcome" && this.route !== "welcome") {
        await this.pushToWelcome()
      } else if (
        value === "pregame" &&
        this.route !== "pregame" &&
        this.route !== "games"
      ) {
        await this.pushToPreGame()
      } else if (value === "game" && this.route !== "game") {
        const {
          getters: { game, user }
        } = this.$store
        if (
          game &&
          user &&
          this.$store.getters[GetterTypes.LATECOMER_VIDEO_CODE]
        ) {
          const { theKey: gameID, startTimestamp } = game

          if (startTimestamp) {
            if (
              startTimestamp + LATECOMER_TIME < Date.now() &&
              GameHistoryStorage.has(gameID) === false &&
              user.role !== Role.Host
            ) {
              console.log("⌛️ Seems like you are latecomer...")
              GameHistoryStorage.add(gameID)
              await this.$router.push({
                name: "GameLatecomer",
                params: { id: `${this.clientID}${gameID}` }
              })
              return
            } else {
              await this.pushToGame()
              return
            }
          }
        }

        await this.pushToGame()
      } else if (
        value === "lobby" &&
        this.route !== "lobby" &&
        this.route !== "games"
      ) {
        await this.pushToLobby()
      }
    }, 333),
    onTwilioConnectionPreferredStatusChange: _.debounce(function (value) {
      console.group("TWILIO")
      if (value === "on") {
        console.log("connecting to Twilio in game")
        this.connect()
      } else if (value === "off") {
        console.log("disconnecting from Twilio in game")
        this.disconnect()
      }
      console.groupEnd()
    }, 1000),
    async subscribeToDashboardData() {
      await Promise.all([
        this.$store.dispatch("screenshot/subscribeToTwitchAccounts")
      ])
    },
    async unsubscribeFromDashboardData() {
      await Promise.all([
        this.$store.dispatch("screenshot/unsubscribeToTwitchAccounts")
      ])
    },
    async updateGameStatus() {
      const now = new Date()
      const startTime = moment(now).unix()
      const endTime = parseInt(startTime) + 6000

      const game = {
        ...this.game,
        startTime,
        theKey: this.gameID
      }

      const obj = {
        gameID: this.gameID,
        numOfPlayers: this.nOfPlayersOnline,
        user: this.user,
        startTime,
        endTime,
        game
      }

      try {
        await Promise.all([
          this.$store.dispatch("addEvent", obj),
          this.$store.dispatch("Games/updateGame", game)
        ])
      } catch (e) {
        console.error(e)
        console.warn("Cannot sign a game")
      }
    },
    async pushToPreGame() {
      await this.$navigator.navigateTo(`/reception/${this.clientID}`)
    },
    async pushToGames() {
      await this.$navigator.navigateTo(`/games`)
    },
    async pushToWelcome() {
      await this.$navigator.navigateTo(`/welcome/${this.urlID}`)
    },
    async pushToGame() {
      await this.$navigator.navigateTo(`/game/${this.urlID}`)
    },
    async pushToLobby() {
      await this.$navigator.navigateTo(`/lobby/${this.urlID}`)
    },
    async pushToGameSettings() {
      await this.$store.dispatch("auth/initializeToGame", {
        gameID: this.gameID,
        clientID: this.clientID
      })
      await this.$navigator.navigateTo(`/game/${this.urlID}/settings`)
    },
    async disconnect() {
      await this.$store.dispatch("twilio/disconnect")
    },
    // this is a Twilio connection handler function that gathers up all
    // required data to pass it further
    async connect() {
      const { user, gameID } = this
      if (!user) throw new Error("User info is not available")
      const { role, id: userID, potato } = user
      const token = this.$store.state.auth.token
      const isHost = role === Role.Host
      const priority = isHost ? "high" : "standard"
      const join = role !== Role.Audit && role !== Role.Spectator

      let max = 25
      if (isHost) max = 25
      if (potato) max = 16

      const twilioToken = await fetchTwilioToken({ token, userID })

      const config = {
        roomID: gameID,
        max,
        userID,
        token: twilioToken,
        potato,
        join,
        priority,
        role
      }

      await this.$store.dispatch("twilio/connect", config)
    },
    test() {
      var obj = {}
      obj.theKey = this.gameID
      obj.locked = this.locked
      this.updateGameAny(obj)
    },
    addMission(mission) {
      const obj = { ...mission }
      obj.gameID = this.gameID
      obj.orgID = this.orgID
      obj.pos = 300
      this.$store.dispatch("addToMissions", {
        array: [obj],
        gameID: this.gameID,
        orgID: this.orgID
      })
    },
    async startScreenShareStream() {
      try {
        await this.startScreenshotStream()
        const video = document.getElementById("screenshotRootVidEl")
        video.srcObject = this.getScreenshotStream
        video.play()
      } catch (e) {
        console.warn(e.message)
      }
    },
    async stopScreenSharingStream() {
      try {
        await this.stopScreenshotStream()
        const video = document.getElementById("screenshotRootVidEl")
        video.srcObject = null
      } catch (e) {
        console.warn(e.message)
      }
    },
    async onScreenSharingStatusChange(status) {
      console.log("onScreenSharingStatusChange", status)
      try {
        if (status) {
          // enable screen sharing if it has been turned
          // on before (from the customizer page)
          // and make sure we are not enabling it more than once
          if (this.getLocalScreenshotStatus && !this.getScreenshotStream) {
            await this.startScreenShareStream()
          }
        } else {
          await this.stopScreenSharingStream()
          // stop broadcast if broadcasting and toggle-off
          // screenShare
          this.stopTwitchStreaming()
        }
      } catch (e) {
        console.warn(e.message)
      }
    }
  }
}
</script>

<style lang="scss">
html {
  overflow-y: auto;
}

.application {
  font-family: "Avenir Next";
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #7f7f7f;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
  background-color: #fff;
}

#theOrg #game {
  font-size: 13px !important;
  color: $color-grey-light1;
}

.audio-player {
  position: relative;
  z-index: 1000;
  margin-top: -4px;
}

.v-toolbar__content .v-btn--icon.stop-btn {
  margin: 14px 0 0 0;
}

.v-select__selections {
  position: relative;
}

.v-select__selections .v-select__selection {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  width: 100%;
  max-width: 100%;
  left: 0;
  display: block;
  margin: 2px 0 0 0;
}

.app-version-label {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 20px;
  z-index: 999;

  & > div {
    opacity: 0.2;
    &:hover {
      opacity: 1;
    }
    display: inline-block;
    height: 20px;
    font-weight: bold;
    padding-left: 5px;
    padding-right: 5px;
    background-color: rgba(255, 255, 255, 0.3);
    line-height: 18px;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.5);
  }
}
</style>
