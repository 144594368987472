<template>
  <v-layout class="rtb-header">
    <v-layout class="rtb-header-wrap">
      <v-layout row shrink>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-layout row class="logo">
              <v-flex shrink d-flex align-center style="position: relative">
                <img
                  v-if="customLogo"
                  :src="customLogo"
                  class="rtb-header__img-logo"
                  :style="customLogoStyle"
                />
                <logo-small v-else class="rtb-header__svg-logo" />
              </v-flex>
              <v-flex v-if="!customLogo" class="remote-text"> REMOTE </v-flex>
              <v-flex class="page-headline" v-if="!!headline">
                {{ headline }}
              </v-flex>
              <GameEndCountdown
                v-if="isHost && hasPreGame && !!expectedEndTime"
                :endTime="expectedEndTime"
                endTimeLabel="Round Has Ended"
                endInTimeLabel="Round ends in"
              />
              <GameEndCountdown
                v-else-if="isHost && !!endTime"
                :endTime="endTime"
              />
            </v-layout>
          </template>
          <span>version: </span>
        </v-tooltip>
        <slot name="headline"></slot>
        <slot name="potato"></slot>
        <slot name="speechToText"></slot>
      </v-layout>
      <slot name="wrap"></slot>
    </v-layout>
    <slot></slot>
  </v-layout>
</template>
<script>
import { mapGetters } from "vuex"
import LogoSmall from "@/components/LogoSmall.vue"
import GameEndCountdown from "@/components/GameEndCountdown.vue"

export default {
  name: "Header",
  components: {
    LogoSmall,
    GameEndCountdown
  },
  props: {
    headline: String
  },
  computed: {
    ...mapGetters(["game"]),
    ...mapGetters("auth", ["client", "isHost", "hasPreGame"]),
    endTime() {
      return this.client ? this.client.endTimestamp : null
    },
    expectedEndTime() {
      return this.game ? this.game.expectedEndTime : null
    },
    customLogo() {
      return this.$theme.getImage("expoLogo")
    },
    customLogoStyle() {
      if (this.client)
        return this.client.customLogoStyle ? this.client.customLogoStyle : null
      if (this.game)
        return this.game.customLogoStyle ? this.game.customLogoStyle : null
      return null
    },
    isSvgLogoColored() {
      return this.$route.name !== "pregame"
    }
  }
}
</script>

<style lang="scss">
.rtb-header {
  min-height: 28px;
  max-height: 28px;
  position: relative;
  background-color: $color-primary-dark;
  color: $color-yellow;

  .rtb-header-wrap {
    position: absolute;
    width: 100%;
    height: 40px;
    overflow: hidden;
    z-index: 1;
  }

  .rtb-logo-image {
    position: relative;
    padding-bottom: 2px;
    margin-right: 4px;
  }

  .logo {
    height: 28px;
    padding-left: 10px;
    z-index: 2;
    .remote-text {
      color: $logo_color;
      text-transform: uppercase;
      font-weight: 400;
      line-height: 26px;
      margin-left: 2px;
      font-size: 17px;
    }
  }

  .rtb-header__img-logo {
    position: relative;
    max-width: 200px;
    max-height: 50px;
    height: auto;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 2px;
  }

  .page-headline {
    font-weight: bold;
    font-size: 17px;
    line-height: 26px;
    white-space: nowrap;
    padding-left: 3px;
    color: $color-white;
    text-transform: uppercase;
    font-weight: 600;
  }

  &__svg-logo {
    height: 20px;
    margin: {
      right: 4px;
    }
  }
}
</style>
