var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "margin-top": "20px" } }, [
    _c("h2", [_vm._v("Screenshot Settings")]),
    _c(
      "div",
      [
        _c("v-switch", {
          attrs: { label: "Enable Screenshot" },
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function() {
                return [
                  _c("span", { staticClass: "v-label-white" }, [
                    _vm._v(
                      _vm._s(
                        !_vm.isScreenSharingEnabled
                          ? "Enable Screenshot"
                          : "Disable Screenshot"
                      )
                    )
                  ])
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.isScreenSharingEnabled,
            callback: function($$v) {
              _vm.isScreenSharingEnabled = $$v
            },
            expression: "isScreenSharingEnabled"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "v-btn",
          {
            staticClass: "rtb-button",
            on: {
              click: function($event) {
                return _vm.downloadScreenshots()
              }
            }
          },
          [
            _vm._v(
              _vm._s(
                _vm.processingDownload
                  ? "processing..."
                  : "Download Screenshots"
              )
            )
          ]
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _vm.isScreenSharingEnabled
          ? _c("v-select", {
              staticClass: "rtb-select",
              attrs: {
                items: _vm.getFreeTwitchAccounts,
                label: "Twitch Accounts",
                "item-text": "name",
                "item-value": "key",
                solo: "",
                dark: ""
              },
              model: {
                value: _vm.twitchKey,
                callback: function($$v) {
                  _vm.twitchKey = $$v
                },
                expression: "twitchKey"
              }
            })
          : _vm._e()
      ],
      1
    ),
    _vm.getIsBroadcastingDataToTwitch
      ? _c("div", { staticClass: "bradcast-link" }, [
          _vm._v(" Broadcasting to: "),
          _c(
            "a",
            {
              attrs: { href: _vm.getInuseTwitchAccount.url, target: "_blank" }
            },
            [_vm._v(_vm._s(_vm.getInuseTwitchAccount.url) + " ")]
          )
        ])
      : _vm._e(),
    _vm.isScreenSharingEnabled &&
    (_vm.userHasTwitchAccount || _vm.getFreeTwitchAccounts.length)
      ? _c(
          "div",
          [
            _c(
              "v-btn",
              {
                staticClass: "rtb-button",
                attrs: {
                  disabled: _vm.getIsProcessingBroadcastingDataToTwitch
                },
                on: { click: _vm.handleStreamButtonClicked }
              },
              [
                _vm._v(
                  _vm._s(
                    !_vm.getIsBroadcastingDataToTwitch
                      ? "Broadcast Game"
                      : "Stop Broadcast..."
                  )
                )
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _vm.isScreenSharingEnabled && !_vm.getFreeTwitchAccounts.length
      ? _c("div", [_vm._v(" All twitch accounts are in use ")])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }