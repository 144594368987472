<template>
  <v-flex align-self-end shrink>
    <div
      class="media-container"
      @dblclick="onVideoDblClick"
      :style="{ margin: `0.5em ${videoSpace}` }"
    >
      <v-layout class="media-responsive-wrap" row>
        <v-flex
          d-flex
          class="media-responsive"
          :class="{ 'grey-border': isMuted }"
          :style="{
            width: `${getVideoWidth}px`,
            height: `${getVideoHeight}px`
          }"
        >
          <UserMedia :identity="player.id" :imageUrl="player.image" />
          <div class="player-name">
            <div class="player-name-bg" v-if="!!player">
              <span>
                <UserName
                  :firstname="player.firstname"
                  :lastname="player.lastname"
                />
              </span>
            </div>
          </div>
          <v-icon class="meeting-player-mute-icon" @click="toggleAudio(player)">
            {{ isMuted ? "volume_off" : "volume_up" }}
          </v-icon>
        </v-flex>
      </v-layout>
    </div>
  </v-flex>
</template>

<script>
import UserName from "@/components/GroupTeams/Common/User/UserName.vue"
import UserMedia from "@/components/GroupTeams/Common/User/UserMedia"
import { mapGetters, mapActions } from "vuex"

export default {
  name: "MeetingPlayer",
  components: {
    UserName,
    UserMedia
  },
  props: {
    player: {
      required: true,
      type: Object
    },
    videoWidth: {
      type: Number,
      default: 300
    },
    videoHeight: {
      type: Number,
      default: 300
    },
    colCount: {
      type: Number,
      default: 1
    }
  },
  computed: {
    ...mapGetters("auth", ["role", "user", "isHost"]),
    ...mapGetters(["getMeetingModeActiveUser"]),
    videoSpace() {
      return 3 / this.colCount + "em"
    },
    getVideoHeight() {
      return this.videoHeight + this.getIncreasedSize
    },
    getVideoWidth() {
      return this.videoWidth + this.getIncreasedSize
    },
    getIncreasedSize() {
      return this.getMeetingModeActiveUser === this.player.id ? 60 : 0
    },
    isMuted() {
      return this.player ? this.player.muted : false
    }
  },
  methods: {
    ...mapActions(["updateUser"]),
    ...mapActions([
      "updateMeetingModeActiveUser",
      "localUpdateMeetingModeActiveUser"
    ]),
    onVideoDblClick() {
      if (this.isHost) {
        this.updateMeetingModeActiveUser(this.player.id)
      }
    },
    async toggleAudio(player) {
      if (player.id === this.user.id || this.isHost) {
        await this.updateUser({
          userID: player.id,
          obj: { muted: !player.muted }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.media-container {
  min-height: 90px;
  min-width: 90px;
  margin-top: 0.5em;
  cursor: pointer;

  .player-name {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: -8px;
    margin-bottom: -8px;
    color: $color-white;
    font-size: 0.9rem;
  }
  .player-name-bg {
    background-color: $color-secondary-dark;
    padding-bottom: 12px;
    padding-top: 4px;
    padding-left: 20px;
    padding-right: 12px;
    border-radius: 8px;
  }

  .media-responsive-wrap {
    position: relative;
    display: inline-flex;
    max-height: 100%;
    max-width: 100%;
    height: 100%;
  }

  .media-responsive {
    @extend .rtb-border, .rtb-border-radius, .rtb-box-shadow;
    transform: translateZ(0);
    justify-content: center;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    background-color: $primary_accent_color;
    &.grey-border {
      border-color: $color-tertiary-dark !important;
      background-color: $color-tertiary-dark !important;
    }
  }

  .media-responsive video {
    position: relative;
    display: block;
    min-height: 101%;
    max-height: 101%;
    flex-shrink: 0;
    margin-bottom: -1%;
  }

  .media-container > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    font-size: 20px;
    display: flex;
    justify-content: center;
  }
  .vid-img {
    border-style: none;
    position: relative;
    display: block;
    min-height: 101%;
    max-height: 101%;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
  }
}
.meeting-player-mute-icon {
  position: absolute;
  bottom: 6px;
  right: 6px;
  font-size: 14px;
  cursor: pointer;
  color: $color-white !important;
}
</style>
