var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.className, on: { dblclick: _vm.select } },
    [
      _c(
        "div",
        { staticClass: "host-media__inner" },
        [
          _vm.isMaxHeadRoomVideo && _vm.getFeauredHostVideo
            ? _c("video", {
                staticClass: "host-media__media-placeholder",
                class: {
                  rounded: _vm.rounded,
                  flipped: _vm.isFeaturedVideoFlipped
                },
                attrs: { autoplay: "", loop: "", src: _vm.getFeauredHostVideo }
              })
            : _vm.user && _vm.user.id
            ? _c("UserMedia", {
                staticClass: "host-media__media",
                attrs: {
                  identity: _vm.user.id,
                  imageUrl: _vm.user.image,
                  userRole: _vm.user.role
                }
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "host-media__badge" },
            [
              _vm.isTalkingTo
                ? [
                    _vm.user
                      ? _c(
                          "span",
                          { staticClass: "rtb-color-primary" },
                          [
                            _c("UserName", {
                              attrs: {
                                firstname: _vm.user.firstname,
                                lastname: _vm.user.lastname
                              }
                            })
                          ],
                          1
                        )
                      : _c("span", { staticClass: "rtb-color-primary" }, [
                          _vm._v(" Offline ")
                        ]),
                    _c("span", { staticClass: "rtb-color-primary" }, [
                      _vm._v(" is talking to " + _vm._s(_vm.globalTeamName))
                    ])
                  ]
                : [
                    _c("span", { staticClass: "rtb-color-white" }, [
                      _vm._v("Host: ")
                    ]),
                    _vm.user
                      ? _c(
                          "span",
                          { staticClass: "rtb-color-primary" },
                          [
                            _c("UserName", {
                              attrs: { firstname: _vm.user.firstname }
                            })
                          ],
                          1
                        )
                      : _c("span", { staticClass: "rtb-color-primary" }, [
                          _vm._v(" Offline ")
                        ])
                  ]
            ],
            2
          )
        ],
        1
      ),
      _vm.isHost
        ? _c(
            "v-icon",
            { staticClass: "host-media__mute", on: { click: _vm.toggleAudio } },
            [
              _vm._v(
                " " + _vm._s(_vm.isMuted ? "volume_off" : "volume_up") + " "
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }