import { render, staticRenderFns } from "./Result.vue?vue&type=template&id=345f8007&"
import script from "./Result.vue?vue&type=script&lang=js&"
export * from "./Result.vue?vue&type=script&lang=js&"
import style0 from "./Result.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex,VIcon,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/Users/finnkelly/Code/Go Remote/remote-chat-finn/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('345f8007')) {
      api.createRecord('345f8007', component.options)
    } else {
      api.reload('345f8007', component.options)
    }
    module.hot.accept("./Result.vue?vue&type=template&id=345f8007&", function () {
      api.rerender('345f8007', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/GroupTeams/Common/Games/Result.vue"
export default component.exports