import firebase from "firebase/app"
import "firebase/database"

/** @typedef {import('../types').Theme} Theme */

export class ThemeService {
  /**
   * @param {string} orgID
   */
  static getNewThemeID(orgID) {
    return firebase.database().ref(`orgs/${orgID}/themes`).push().key
  }

  /**
   * @param {string} orgID
   * @param {string} themeID
   * @param {Theme} theme
   */
  static updateOrgTheme(orgID, themeID, theme) {
    return firebase.database().ref(`orgs/${orgID}/themes/${themeID}`).set(theme)
  }

  // TODO: Handle if theme is active
  /**
   * @param {string} orgID
   * @param {string} themeID
   */
  static deleteOrgTheme(orgID, themeID) {
    return firebase.database().ref(`orgs/${orgID}/themes/${themeID}`).set(null)
  }

  /**
   * @param {string} orgID
   * @param {string} themeID
   */
  static setOrgTheme(orgID, themeID) {
    return firebase.database().ref(`orgs/${orgID}/theme`).set(themeID)
  }

  /**
   *
   * @param {string} orgID
   * @param {string} clientID
   */
  static async purgeGames(orgID, clientID) {
    const ref = firebase.database().ref(`org/${orgID}/games`)

    const snapshot = await ref
      .orderByChild("clientID")
      .equalTo(clientID)
      .once("value")

    const value = snapshot.val()

    /** @type {Record<string, null>} */
    const update = {}

    for (const gameID in value) {
      update[`${gameID}/themeID`] = null
      update[`${gameID}/styles`] = null
    }

    return ref.update(update)
  }
}
