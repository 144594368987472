var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "rtb-game-team",
      attrs: { "pa-0": "", fluid: "", "fill-height": "" }
    },
    [
      _c("v-layout", { attrs: { column: "", "justify-center": "" } }, [
        _c(
          "div",
          [
            _c(
              "v-layout",
              {
                staticClass: "rtb-game-team__generic-player-row",
                attrs: { row: "", "no-wrap": "", "justify-center": "" }
              },
              _vm._l(_vm.players, function(player) {
                return _c(
                  "GenericPlayer",
                  {
                    key: "generic-player-" + player.id + "-" + player.teamID,
                    attrs: {
                      isMutable: player.id === _vm.user.id || _vm.isHost,
                      isMutedGlobally: player.mutedGlobally,
                      isMuted: player.muted,
                      skip: player.skip,
                      isHost: _vm.isHost,
                      selected: player.selected,
                      bus: _vm.bus,
                      mode: _vm.mode,
                      isTop: _vm.isTop,
                      isPotato: _vm.isPotato,
                      isTeamMision: !_vm.isIndividualMission,
                      firstname: player.firstname,
                      lastname: player.lastname,
                      maxWidth: _vm.maxWidth,
                      userID: player.id,
                      location: player.location,
                      playStatus: _vm.playStatus(player.id),
                      teamPosition: _vm.teamPosition,
                      imageURL: player.image,
                      teamID: _vm.teamID
                    },
                    on: {
                      onSelected: function($event) {
                        return _vm.selectTheUser(player.id, _vm.teamID)
                      },
                      onSkip: function($event) {
                        return _vm.skipTheUser(player.id, _vm.teamID)
                      },
                      onMute: function($event) {
                        return _vm.toggleAudio(player)
                      }
                    }
                  },
                  [
                    _vm.showOnlyScribe
                      ? _c("TeamIconTag", {
                          staticClass: "rtb-game-team__player-team-icon",
                          attrs: { teamID: _vm.teamID }
                        })
                      : _vm._e()
                  ],
                  1
                )
              }),
              1
            )
          ],
          1
        )
      ]),
      _vm.teamID == _vm.user.teamID
        ? _c("audio", { ref: "audio" }, [
            _c("source", {
              attrs: { src: _vm.scribeSound, type: "audio/mpeg" }
            })
          ])
        : _vm._e(),
      _vm.isHost && _vm.isTop
        ? _c(
            "v-tooltip",
            {
              attrs: { right: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-switch",
                          _vm._g(
                            {
                              staticClass:
                                "rtb-game-team__show-onboarding-switch",
                              attrs: { dense: "" },
                              model: {
                                value: _vm.showOnboardingPlayers,
                                callback: function($$v) {
                                  _vm.showOnboardingPlayers = $$v
                                },
                                expression: "showOnboardingPlayers"
                              }
                            },
                            on
                          )
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3331314371
              )
            },
            [_c("span", [_vm._v("Show Onboarding Players")])]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }