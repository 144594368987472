<template>
  <v-flex
    :style="{ visibility: isOpen ? 'visible' : 'hidden' }"
    d-flex
    justify-center
    class="popup-overlay"
    align-center
  >
    <transition name="popup">
      <v-flex v-if="isOpen" d-flex pa-4 class="popup-content">
        <v-layout column justify-center>
          <v-flex
            v-if="!!header"
            d-flex
            shrink
            justify-center
            class="popup-header"
          >
            {{ header }}
          </v-flex>
          <v-flex shrink mt-2 class="text-xs-center popup-text">
            <slot />
          </v-flex>
          <template v-if="mode === 'yes-no'">
            <v-flex shrink mt-4 d-flex>
              <v-layout justify-center>
                <v-flex>
                  <button
                    @click.stop="onResponse({ status: 'yes' })"
                    class="popup-button"
                  >
                    YES
                  </button>
                </v-flex>
                <v-flex>
                  <button
                    @click.stop="onResponse({ status: 'no' })"
                    class="popup-button"
                  >
                    NO
                  </button>
                </v-flex>
              </v-layout>
            </v-flex>
          </template>
          <template v-else-if="mode == 'okay'">
            <v-flex shrink mt-4 justify-center>
              <button
                @click.stop="onResponse({ status: 'okay' })"
                class="popup-button"
              >
                OK
              </button>
            </v-flex>
          </template>
        </v-layout>
        <div class="close-symbol" @click.stop="onClose">×</div>
      </v-flex>
    </transition>
  </v-flex>
</template>

<script>
export default {
  name: "Popup",
  props: {
    isOpen: Boolean,
    header: String,
    mode: String
  },
  methods: {
    onResponse({ status }) {
      this.$emit("onResponse", { status })
    },
    onClose() {
      this.$emit("onClose")
    }
  }
}
</script>
