<template>
  <v-flex d-flex text-xs-center class="team-fact-match-card">
    <transition name="card-flip-transition" mode="out-in">
      <v-flex
        :key="state"
        d-flex
        class="card-front-side card"
        v-if="state === 'results'"
      >
        <v-layout column justify-center>
          <v-flex shrink>
            <v-icon class="team-icon">
              {{ teamIcon }}
            </v-icon>
          </v-flex>
          <v-flex shrink>
            <span class="team-name">{{ teamName }}</span>
          </v-flex>
          <v-flex shrink class="team-score">
            <span class="team-score-number">{{ pts }}</span
            ><span class="team-score-pts">pts</span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex :key="state" d-flex class="card-back-side card" v-else>
        <v-layout column justify-center>
          <v-flex shrink>
            <v-icon class="team-icon">
              {{ teamIcon }}
            </v-icon>
          </v-flex>
          <v-flex shrink>
            <span class="team-name">{{ teamName }}</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </transition>
  </v-flex>
</template>

<script>
export default {
  name: "TeamFactMatchCard",
  props: {
    teamName: {
      type: String,
      default: "Error"
    },
    state: {
      type: String,
      default: "hidden"
    },
    teamIcon: {
      type: String,
      default: "radio_button_unchecked"
    },
    pts: {
      type: Number,
      default: 0
    }
  }
}
</script>
<style lang="scss">
.team-fact-match-card {
  line-height: 1;
  perspective: 2000px;

  .card-flip-transition-enter-active,
  .card-flip-transition-leave-active {
    transition: transform ease 0.3s;
    transform: rotateX(0deg);
  }
  .card-flip-transition-enter {
    transform: rotateX(90deg);
  }
  .card-flip-transition-leave-to {
    transform: rotateX(-90deg);
  }
  .card {
    @extend .rtb-border, .rtb-border-radius, .rtb-box-shadow;
  }
  .card-front-side {
    background-color: $color-white;
    position: relative;
    .team-icon,
    .team-name {
      color: $color-black;
    }
  }
  .card-back-side {
    background-color: $primary_accent_color;
    position: relative;
    .team-icon,
    .team-name {
      color: $color-white;
    }
  }
  .team-icon {
    font-size: 2em;
  }
  .team-name {
    font-size: 1.5em;
  }
  .team-score {
    font-size: 2em;
  }
  .team-score-number {
    display: inline-block;
    vertical-align: middle;
    font-size: 3em;
    color: $primary_accent_color;
    text-shadow: 0px 0px 2px $color-black;
    font-weight: bold;
  }
  .team-score-pts {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.5em;
    color: $primary_accent_color;
  }
}
</style>
