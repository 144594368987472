import firebase from "firebase/app"
import "firebase/database"
import * as moment from "moment"

import { fetchGameObject } from "@/services/game.service"

const NO_ORIGIN_GAME_ERROR_MESSAGE = "There is no original game to copy from"

// TODO: can we move this functions to the helper?
/**
 *
 * @param rawMissionList
 * @return {{id: string}[]}
 */
function getMissionList(rawMissionList) {
  return Object.entries(rawMissionList)
    .map(([id, mission]) => ({
      ...mission,
      id,
      theKey: id
    }))
    .sort((a, b) => (a.pos > b.pos ? 1 : -1))
}

/**
 *
 * @param teams
 * @return {*}
 */
function resetTeams(teams) {
  let i = 1
  // team object is mutable, so changes will be in the teams object
  Object.values(teams).forEach(team => {
    if (!team.show) return
    team.totalScore = 0
    team.otherTeamFacts = []
    team.flippedState = "hidden"
    team.name = `Team ${i}`
    i++
  })
  return teams
}

const GamesModule = {
  namespaced: true,
  state: {
    gameTypes: [
      "Standard",
      "YouTube",
      "Twitch",
      "Flash Cards",
      "Broadcast",
      "Green Room"
    ]
  },
  getters: {
    gameTypes(state) {
      console.log("GETTERS")
      return state.gameTypes
    }
  },
  actions: {
    async updateGameCapacity({ rootState }, { capacity, gameID }) {
      const { orgID } = rootState
      await firebase
        .database()
        .ref(`org/${orgID}/games/${gameID}/players`)
        .set(capacity)
    },
    async updateGame({ rootState }, payload) {
      const { theKey: gameID } = payload
      const { orgID } = rootState

      if (!orgID) throw new Error("Invalid input org ID")
      if (!gameID) throw new Error("Invalid input game ID")

      const game = { ...payload }
      // delete game.theKey

      const serialize = obj => JSON.parse(JSON.stringify(obj))

      await firebase
        .database()
        .ref(`org/${orgID}/games/${gameID}`)
        .set(serialize(game))
    },
    async updateGameAny({ rootState }, payload) {
      const id = payload.theKey
      // delete payload.theKey
      await firebase
        .database()
        .ref("org/" + rootState.orgID + "/games/" + id)
        .update(payload)
    },
    async addGame({ rootState }, payload) {
      var thePayload = JSON.parse(JSON.stringify(payload))
      thePayload.user = rootState.auth.user

      const ref = await firebase
        .database()
        .ref("org/" + rootState.orgID + "/games")
        .push(thePayload)

      const clientObj = {}
      clientObj.gameID = ref.key
      clientObj.games = {}
      clientObj.games[ref.key] = true
      clientObj.orgID = rootState.orgID
      clientObj.user = rootState.auth.user
      clientObj.name = payload.name

      const client = await firebase.database().ref("clients").push(clientObj)

      await firebase
        .database()
        .ref(`org/${rootState.orgID}/games/${ref.key}`)
        .update({ clientID: client.key })

      await firebase
        .database()
        .ref(`org/${rootState.orgID}/game/${ref.key}`)
        .update({
          clientID: client.key,
          gameStatus: {
            active: true,
            buzzer: false,
            current_mission: "-LtzoCESdSn15NVpjVdY",
            endTime: 1574110360,
            media: "facilitator",
            startTime: 1574110360,
            teamPoints: true,
            twoteam: true,
            ...thePayload
          },
          teams: {
            "-LhCzJQ9Dmjy6oqg1234": {
              action: "local_activity",
              active: true,
              color: "#6d9c9f",
              icon: "favorite",
              can_hear_facilitator: true,
              id: "-LhCzJQ9Dmjy6oqg1234",
              muted: true,
              name: "Team 1",
              players: 0,
              rank: 1,
              show: true,
              slogan: "Manual",
              totalScore: 0,
              groupID: "LhCzJQ9Dmjy6oqg1234"
            },
            "-LhCzJQ9Dmjy6oqg3452": {
              action: "local_activity",
              active: true,
              can_hear_facilitator: true,
              id: "-LhCzJQ9Dmjy6oqg3452",
              muted: true,
              name: "Team 2",
              players: 0,
              rank: 1,
              color: "#6d9ccc",
              icon: "brightness_1",
              show: true,
              slogan: "Manual",
              totalScore: 0,
              groupID: "LhCzJQ9Dmjy6oqg1234"
            },
            "-LhCzJQ9Dmjy6oqgfYgN": {
              action: "local_activity",
              active: true,
              can_hear_facilitator: true,
              id: "-LhCzJQ9Dmjy6oqgfYgN",
              muted: true,
              name: "Team 3",
              players: 0,
              rank: 1,
              color: "#ff9c9f",
              icon: "grade",
              show: true,
              slogan: "Manual",
              totalScore: 0,
              groupID: "LhCzJQ9Dmjy6oqg1234"
            }
          }
        })

      const defaultMissionPayload = {
        "-LtzoCESdSn15NVpjVdY": {
          answer: "",
          behavior: "Team Name",
          explain: false,
          gameID: "-LtznczxIYK5FdJXtBPA",
          huddle: false,
          id: "-LtzoCESdSn15NVpjVdY",
          instructions: "Introduce yourself to your teammates, and choos...",
          multiCorrect: 1,
          name: "Welcome",
          numOfPlayers: "",
          numOfTries: "Unlimited",
          photo: "",
          play: true,
          points: 0,
          pos: 1,
          results: false,
          social: false,
          time: 0,
          title: "Welcome",
          video: "",
          voting: false,
          welcome: true
        }
      }

      await firebase
        .database()
        .ref(`org/${rootState.orgID}/game/${ref.key}/missions`)
        .update(defaultMissionPayload)

      const updates = {
        "groups/LhCzJQ9Dmjy6oqg1234/teams": {
          "-LhCzJQ9Dmjy6oqg1234": 1,
          "-LhCzJQ9Dmjy6oqg3452": 1,
          "-LhCzJQ9Dmjy6oqgfYgN": 1
        }
      }

      await firebase
        .database()
        .ref(`org/${rootState.orgID}/game/${ref.key}`)
        .update(updates)
    },
    async copyGame({ rootState }, { game, orgID, startIn }) {
      // assumingly the same for some two /game and /games docs
      // console.log("GAME ", game)
      // console.log("ORGID", orgID)
      const oldGameID = game.theKey

      if (!oldGameID) throw new Error("No game ID is given")

      const srcOrgID = rootState.orgID
      const destOrgID = orgID || rootState.orgID
      // const srcGamesRef = firebase.database().ref(`org/${srcOrgID}/games`)
      const srcGameRef = firebase.database().ref(`org/${srcOrgID}/game`)
      const destGamesRef = firebase.database().ref(`org/${destOrgID}/games`)
      const destGameRef = firebase.database().ref(`org/${destOrgID}/game`)

      // create new doc in /games
      const newGamesRef = await destGamesRef.push()

      // get new /games doc ID
      const newGamesID = newGamesRef.key
      let clientID = null

      if (!game.sameClientID) {
        // by default crate a new client doc
        const client = {}
        client.gameID = newGamesID
        client.games = {}
        client.dateAdded = new Date()
        client.games[newGamesID] = true
        client.orgID = destOrgID
        client.user = rootState.auth.user
        client.name = game.name
        const clientSnapshot = await firebase
          .database()
          .ref("clients")
          .push(client)

        clientID = clientSnapshot.key
      } else {
        console.log(`adding game ${newGamesID} to client ${game.clientID}`)
        // or update an existing client with a new game
        await firebase
          .database()
          .ref(`clients/${game.clientID}/games/${newGamesID}`)
          .set(true)

        clientID = game.clientID
      }

      if (!clientID) throw new Error("Something went wrong")

      const newGame = { ...game }

      delete newGame.sameClientID

      await destGamesRef.child(newGamesID).update({
        ...newGame,
        clientID,
        theKey: newGamesID,
        endTimestamp: null,
        startTimestamp: startIn || newGame.startTimestamp,
        deletedTimestamp: null,
        locked: false,
        started: false
      })

      // get a doc by a payload ID from /game collection
      const oldGameSnapshot = await srcGameRef.child(oldGameID).once("value")
      // get val
      const oldGameVal = oldGameSnapshot.val()
      // update/create a new doc in /game with an ID we got from the new /games doc
      await destGameRef.child(newGamesID).update({
        ...oldGameVal,
        clientID,
        play: null,
        giphies: null,
        teams: null
      })
      const detailedGameObject = await fetchGameObject({
        orgID: srcOrgID,
        gameID: oldGameID
      })
      const teamArr = Object.values(detailedGameObject.teams)
      var numOfTeams = teamArr.filter(item => item.show).length
      if (numOfTeams == 0) numOfteams = 3
      console.log("Num Of Teams", numOfTeams)
      console.log("OLD GAME VALUES", oldGameVal)
      for (var i = 1; i <= numOfTeams; i++) {
        var obj = {}
        obj.name = "Team " + i
        obj.active = true
        if (i == 1) obj.icon = "favorite"
        if (i == 2) obj.icon = "brightness_1"
        if (i == 3) obj.icon = "flash_on"
        if (i == 4) obj.icon = "eco"
        if (i == 5) obj.icon = "fitness_center"
        obj.can_hear_facilitator = true
        obj.show = true
        obj.totalScore = 0
        const promise = await firebase
          .database()
          .ref(`org/${destOrgID}/game/${newGamesID}/teams`)
          .push()

        const teamID = promise.key
        await firebase
          .database()
          .ref(`org/${destOrgID}/game/${newGamesID}/teams/${teamID}`)
          .set(obj)
      }
      return newGamesID
    },
    /**
     *
     * @param dispatch
     * @param rootState
     *
     * @param originalGameID: original game id (source of copy)
     * @param orgID:  organization ID
     * @param clientID: client ID
     * @param startIn: start in minutes
     * @param numOfTeams: num of teams
     * @param tournamentID
     * @param startAt
     * @param hostID
     * @param teams
     * @param gameType
     * @param runStatus
     * @return {Promise<string>}
     */
    async copyFromOriginalGame(
      { dispatch, rootState },
      {
        originalGameID,
        orgID,
        clientID,
        startIn,
        startAt,
        hostID,
        tournamentID,
        teams,
        runStatus,
        gameType,
        name,
        assignable,
        round,
        deactivate,
        ondeck,
        expectedEndTime
      }
    ) {
      // Get refs
      const gameRef = firebase.database().ref(`org/${orgID}/game`)
      const gamesRef = firebase.database().ref(`org/${orgID}/games`)
      // Get snapshots of Original Game and Games objects
      let [originalGameSnap, originalGamesSnap] = await Promise.all([
        gameRef.child(originalGameID).once("value"),
        gamesRef.child(originalGameID).once("value")
      ])
      // Get value of original objects
      const originalGame = originalGameSnap.val()
      const originalGames = originalGamesSnap.val()

      console.log("originalGame", originalGame)

      if (!originalGame) throw new Error(NO_ORIGIN_GAME_ERROR_MESSAGE)
      if (!originalGames) throw new Error(NO_ORIGIN_GAME_ERROR_MESSAGE)

      //  Get Mission with pos == 1
      const firstMission = getMissionList(originalGame.missions)[0]
      // Copy /games and get key of new object
      const newGames = {
        ...originalGames,
        originalGameID,
        round: !isNaN(parseInt(round)) ? round : null,
        assignable: assignable || null,
        externalName: name
          ? name
          : originalGames.externalName || originalGames.name,
        clientID: clientID,
        endTimestamp: 0,
        startTimestamp: startIn
          ? moment().add(startIn, "minutes").toDate().getTime()
          : startAt,
        deletedTimestamp: null,
        deactivate: !!deactivate,
        ondeck: !!ondeck,
        locked: false,
        started: false,
        runStatus: runStatus || originalGames.runStatus || null,
        gameType: gameType || originalGames.gameType || null,
        hostUserID: hostID || originalGames.hostUserID || null,
        tournamentID: tournamentID || null,
        expectedEndTime:
          expectedEndTime || originalGames.expectedEndTime || null
      }
      const newGamesSnap = gamesRef.push(newGames)
      const newGameID = newGamesSnap.key
      // Save new /games object
      await gamesRef
        .child(newGameID)
        .update({ theKey: newGameID, id: newGameID })

      if (!teams) {
        const gameTeamsRef = firebase
          .database()
          .ref(`org/${orgID}/game/${newGameID}/teams`)
        let i = 1
        teams = Object.entries(originalGame.teams).reduce((acc, [key, val]) => {
          if (["undefined", "0"].includes(key)) return acc
          if (!val.show) {
            acc[key] = val
            return acc
          }
          val.totalScore = 0
          val.otherTeamFacts = []
          val.flippedState = "hidden"
          val.name = `Team ${i}`
          i++
          acc[gameTeamsRef.push().key] = val
          return acc
        }, {})
      }

      // Copy /game object
      const newGame = {
        ...originalGame,
        clientID: clientID,
        play: null,
        giphies: null,
        buzz: null,
        votes: null,
        gameStatus: {
          ...originalGame.gameStatus,
          current_mission: firstMission,
          active: true,
          startTime: null,
          endTime: null,
          buzzer: false,
          currentMode: "welcome" // TODO: move "welcome" to constant
        },
        teams: teams
      }
      // Create new /game object (with same key as /games)
      await gameRef.child(newGameID).update(newGame)

      await firebase
        .database()
        .ref(`clients/${clientID}/games/${newGameID}`)
        .set(true)
      return newGameID
    },
    async removeGame({ rootState }, payload) {
      const { theKey: gameID, clientID } = payload
      const promises = []

      if (gameID) {
        promises.push(
          firebase
            .database()
            .ref("org/" + rootState.orgID + "/games/" + gameID)
            .remove()
        )
        promises.push(
          firebase
            .database()
            .ref("org/" + rootState.orgID + "/game/" + gameID)
            .remove()
        )
      } else {
        console.error("No game ID")
      }

      if (clientID && gameID) {
        promises.push(
          firebase
            .database()
            .ref(`clients/${clientID}/games/${gameID}`)
            .set(null)
        )
      } else {
        console.log("No client ID")
      }

      await Promise.all(promises)
    },
    async fetchGames({ commit }, { orgID }) {
      const snapshot = await firebase
        .database()
        .ref("org/" + orgID + "/games")
        .once("value")

      const obj = {}
      snapshot.forEach(item => {
        obj[item.key] = item.val()
      })
      return obj
    }
  }
}

export default GamesModule
