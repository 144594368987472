<template>
  <v-flex class="player-icon" :class="getIconClasses" ref="root">
    <StarExplosion
      v-if="canPlayStars"
      :radius="radius"
      :nOfStar1="4"
      :nOfStar2="8"
      :maxStar1Size="10"
      :maxStar2Size="13"
      :speed="0.5"
      :shape="shape"
      ref="stars"
      class="star-explosion"
    />
    <!-- 1:1 aspect ratio container -->
    <RatioContainer
      @mouseover="active = true"
      @mouseleave="active = false"
      @dblclick="onSelected"
      @click.shift="onSkip"
    >
      <slot></slot>
      <!-- Points -->
      <div class="player-points" v-if="skip && isHost">SKIP</div>
      <v-flex d-flex class="player-outline" :class="clickable" ref="circle1">
        <!-- Player video -->
        <UserMedia :identity="userID" :imageUrl="imageURL" :disable="covered" />
        <!-- Controls -->
        <div
          v-if="isMutable"
          class="mute-btn"
          :class="{ active: showMuteButton }"
        >
          <div>
            <v-icon class="icon" @click="onMute">
              {{ isMuted ? "volume_off" : "volume_up" }}
            </v-icon>
          </div>
        </div>
        <!-- The player name -->
        <div class="player-name">
          <template v-if="isHost && location">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <UserName :firstname="firstname" :lastname="lastname" />
                </div>
              </template>
              <span>{{ location }}</span>
            </v-tooltip>
          </template>
          <template v-else>
            <div>
              <UserName :firstname="firstname" :lastname="lastname" />
            </div>
          </template>
        </div>
        <!-- Scribe indication -->
        <div class="scribe-indicator">
          <div>
            <div class="scribe-indicator-spans" ref="scribe">
              <span>S</span><span>C</span><span>R</span><span>I</span
              ><span>B</span><span>E</span>
            </div>
          </div>
        </div>
      </v-flex>
      <div class="winner-outline" ref="circle2"></div>
    </RatioContainer>
    <div class="ribbon-wrap" v-if="!!teamPosition">
      <img
        v-if="teamPosition === 1"
        width="65"
        class="colored-ribbon-image"
        :src="require('../../../../assets/ribbon-blue.png')"
      />
      <img
        v-else-if="teamPosition === 2"
        width="65"
        class="colored-ribbon-image"
        :src="require('../../../../assets/ribbon-red.png')"
      />
      <img
        v-else
        width="65"
        class="colored-ribbon-image"
        :src="require('../../../../assets/ribbon-white.png')"
      />
      <div class="ribbon-team-position">
        <span>{{ teamPosition }}</span
        ><!--
     --><span class="nth">{{ positionSuffix }}</span>
      </div>
    </div>
  </v-flex>
</template>

<script>
import UserMedia from "@/components/GroupTeams/Common/User/UserMedia"
import RatioContainer from "@/components/GroupTeams/Common/RatioContaier.vue"
import UserName from "@/components/GroupTeams/Common/User/UserName"
import StarExplosion from "@/components/GroupTeams/Common/StarExplosion.vue"
import { Back, TimelineLite, TweenMax, Power2 } from "gsap/TweenMax"
import { mapGetters } from "vuex"

export default {
  name: "GenericPlayer",
  props: {
    location: String,
    isMutedGlobally: Boolean,
    isMuted: Boolean,
    isMutable: Boolean,
    selected: Boolean,
    skip: Boolean,
    isHost: Boolean,
    maxWidth: Number,
    mode: String,
    imageURL: String,
    bus: Object,
    firstname: String,
    lastname: String,
    teamID: String,
    src: MediaStream,
    visible: Boolean,
    userID: String,
    playStatus: Object,
    teamPosition: {
      type: Number,
      default: 0
    },
    canPlayStars: {
      type: Boolean,
      default: true
    },
    isTop: {
      type: Boolean,
      default: false
    },
    isTeamMision: {
      typle: Boolean,
      default: false
    },
    isPotato: {
      typle: Boolean,
      default: false
    }
  },
  data() {
    return {
      introTl: null,
      huddleTl: null,
      deactivateTl: null,
      active: false,
      radius: 0,
      shape: "circle",
      showPlayerPoints: false
    }
  },
  watch: {
    winner(newValue) {
      if (newValue) this.playWinningAnimation()
    },
    waiting: {
      handler(newValue) {
        if (newValue) this.playScribeText()
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({ actualGameMode: "getCurrentMode" }),
    ...mapGetters(["styles"]),
    waiting() {
      return this.playStatus.waiting
    },
    winner() {
      return this.playStatus.winner
    },
    canPassScribe() {
      try {
        return this.$store.getters.getCurrentMission.passScribe
      } catch (e) {
        return false
      }
    },
    clickable() {
      return (
        this.user.selected &&
        this.canPassScribe &&
        this.currentTeamID === this.teamID &&
        this.userID !== this.user.id
      )
    },
    user() {
      return this.$store.getters.user
    },
    currentTeamID() {
      return this.$store.getters.teamID
    },
    positionSuffix() {
      if (this.teamPosition === 1) return "ST"
      else if (this.teamPosition === 2) return "ND"
      else if (this.teamPosition === 3) return "RD"
      else return "TH"
    },
    showMuteButton() {
      return this.isMuted || this.active
    },
    covered() {
      if (this.mode === "social" && this.selected) {
        return true
      } else if (!this.isTop && this.isPotato) {
        return this.isTeamMision ? !this.selected : true
      } else {
        return false
      }
    },
    isGameOver() {
      return this.actualGameMode === "over"
    },
    getIconClasses() {
      return {
        cursor: this.isHost || this.clickable,
        muted: this.isMutedGlobally,
        active: this.selected,
        covered: this.covered,
        selfmuted: this.isMuted,
        starred: this.isGameOver && this.selected,
        submitted: this.playStatus.submitted,
        boarding:
          this.isHost && this.location && this.location !== this.$route.path
      }
    }
  },
  methods: {
    playWinningAnimation() {
      this.$nextTick(() => {
        //console.log("WINNING ANIMATION")
        const root = this.$refs.root
        if (!root) return console.log("The root node is undefined")

        root.classList.add("on-top")
        setTimeout(() => {
          root.classList.remove("on-top")
        }, 1500)
        // define animation size with the respect to the
        // current size and shape
        const n = this.shape === "circle" ? 1.5 : 1.33
        // set star animation radius
        this.radius = (root.clientWidth / 2) * n

        if (this.$refs.stars) {
          this.$refs.stars.animate()
        }

        if (this.$refs.circle2) {
          TweenMax.fromTo(
            this.$refs.circle2,
            0.25,
            { opacity: 0 },
            { opacity: 1, overwrite: false }
          )
          TweenMax.fromTo(
            this.$refs.circle2,
            1,
            { scale: 1 },
            { scale: 1.33, overwrite: false, ease: Power2.easeInOut }
          ).delay(0.15)
          TweenMax.to(this.$refs.circle2, 0.3, {
            opacity: 0,
            overwrite: false
          }).delay(0.75)
        }
      })
    },
    onHuddleComplete() {
      this.shape = "square"
    },
    onHuddleReverseComplete() {
      TweenMax.set([this.$refs.circle1, this.$refs.circle2, this.$refs.root], {
        clearProps: "all"
      })
      this.shape = "circle"
    },
    getHuddleTl() {
      if (this.huddleTl) return this.huddleTl
      this.huddleTl = new TimelineLite({
        onReverseComplete: this.onHuddleReverseComplete,
        onComplete: this.onHuddleComplete,
        paused: true
      })
      this.huddleTl
        .addLabel("root", "+=0.1")
        .to(
          this.$refs.root,
          0.6,
          {
            // this animation should rather be
            // dynamic so the huddle icon size
            // can adapt as the screen resizes
            maxWidth: this.maxWidth,
            marginLeft: 3,
            marginRight: 3,
            ease: Power2.easeInOut
          },
          "root"
        )
        .to(
          this.$refs.circle1,
          0.6,
          {
            borderRadius: 10,
            ease: Power2.easeInOut
          },
          "root"
        )
        .to(
          this.$refs.circle2,
          0.6,
          {
            borderRadius: 8,
            ease: Power2.easeInOut
          },
          "root"
        )

      return this.huddleTl
    },
    huddle() {
      try {
        this.getHuddleTl().play()
      } catch (e) {
        console.log(e.message)
      }
    },
    unhuddle() {
      if (this.huddleTl) this.huddleTl.reverse()
    },
    onMute() {
      this.$emit("onMute")
    },
    onSelected() {
      this.$emit("onSelected")
    },
    onSkip() {
      this.$emit("onSkip")
    },
    onMouseLeave() {
      if (!this.isMuted) this.active = false
    },
    playScribeText() {
      this.$nextTick(() => {
        const elems = this.$refs.scribe.getElementsByTagName("span")
        const clearProps = () => TweenMax.set(elems, { clearProps: "color" })
        const loop = () => {
          if (!elems) return
          if (!this.waiting) return clearProps()
          TweenMax.staggerFromTo(
            elems,
            0.1,
            { color: this.$theme.get("--primary-accent-color") },
            { color: "$color-white", yoyo: true, repeat: 1 },
            0.2,
            () => {
              clearProps()
              loop()
            }
          )
        }
        loop()
      })
    }
  },
  components: {
    UserMedia,
    RatioContainer,
    UserName,
    StarExplosion
  },
  created() {
    this.introTl = new TimelineLite({ paused: true })
    // register listeners
    this.bus.$on("huddle", this.huddle)
    this.bus.$on("unhuddle", this.unhuddle)
  },
  beforeDestroy() {
    if (this.huddleTl) this.huddleTl.kill()
    if (this.introTl) this.introTl.kill()

    this.huddleTl = null
    this.introTl = null

    if (this.$refs.circle1) TweenMax.killTweensOf(this.$refs.circle1)
    if (this.$refs.circle2) TweenMax.killTweensOf(this.$refs.circle2)
    if (this.$refs.root) TweenMax.killTweensOf(this.$refs.root)

    // unregister listeners
    this.bus.$off("huddle", this.huddle)
    this.bus.$off("unhuddle", this.unhuddle)
  },
  mounted() {
    // intro animation is supposed to be played once
    this.introTl
      .addLabel("root", "+=0.1")
      .from(
        this.$refs.root,
        0.2,
        {
          minWidth: 0,
          maxWidth: 0
        },
        "root"
      )
      .from(
        this.$refs.root,
        0.5,
        {
          alpha: 0,
          scale: 0.6,
          ease: Back.easeOut.config(2),
          clearProps: "all"
        },
        "root+=0.2"
      )

    try {
      if (this.mode === "huddle") {
        this.introTl.timeScale(1.6).play()
        this.getHuddleTl().delay(0.1).play()
      } else {
        if (this.covered) {
          this.introTl.timeScale(1.6).play()
          this.disable()
        } else {
          this.introTl.play()
        }
      }
    } catch (e) {
      console.log(e.message)
    }

    // we need to get the initial star animation size
    // after the intro animation is already played
    setTimeout(() => {
      if (!this.$refs.root) return
      if (this.shape === "circle") {
        this.radius = (this.$refs.root.clientWidth / 2) * 1.5
      } else {
        this.radius = (this.$refs.root.clientWidth / 2) * 1.33
      }
    }, 1000)
  }
}
</script>

<style lang="scss">
.player-icon {
  position: relative;
  user-select: none;
  flex-direction: column;

  .spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: rgba(255, 255, 255, 0.4) !important;
    z-index: 44;
  }

  .video-stream-wrap {
    max-height: 100%;
    width: 100%;
  }

  &.on-top {
    z-index: 1;
  }

  .clickable {
    cursor: pointer;
  }

  .player-name {
    position: absolute;
    width: 100%;
    height: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 11.5px;
    padding-top: 3px;
    color: $color-white;
    white-space: nowrap;
    background-color: rgba($color-black, 0.5);
    text-shadow: 1px 1px 2px rgba($color-black, 0.7);
    transition: all 0.5s ease;
  }

  .player-points {
    position: absolute;
    width: 30%;
    color: $color-white;
    font-size: 10px;
    border-radius: 20% 0 20% 0%;
    background-color: rgba($color-black, 0.7);
    margin-left: -88%;
    margin-top: 17%;
    z-index: 1000000;
  }

  .scribe-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    pointer-events: none;
    transition: all 0.3s;
    & > div {
      display: block;
      position: absolute;
      width: 100%;
      height: 22px;
      text-shadow: 1px 1px 0px rgba($color-black, 0.25);
      background-color: rgba($color-black, 0.7);
      bottom: -22px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-white;
      font-size: 11.5px;
      font-weight: bold;
      transition: all 0.3s;
      pointer-events: auto;
      padding-bottom: 1px;
    }
  }

  .mute-btn {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.3s;
    top: 0;
    .icon {
      position: relative;
      font-size: 15px;
      color: $color-white;
      font-weight: normal;
      text-shadow: 1px 1px 2px rgba($color-black, 0.7);
      cursor: pointer;
    }
    & > div {
      z-index: 99;
      display: block;
      position: absolute;
      width: 100%;
      background-color: rgba($color-black, 0.7);
      height: 22px;
      bottom: -22px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s;
      padding-bottom: 0px;
    }
  }

  .player-outline {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    transform: translateZ(0);
    border: 4px solid $color-white;
    background-color: $color-white;
    box-shadow: 0px 0px 4px rgba($color-black, 0.33);
  }

  .video {
    position: relative;
    min-height: 100%;
    max-height: 100%;
  }

  &.muted {
    .player-outline {
      border-color: $color-tertiary-dark !important;
      background-color: $color-tertiary-dark !important;
    }
  }

  &.covered {
    .player-name {
      padding-top: 0px;
      height: 100%;
      font-size: 20px;
    }
  }

  &.cursor {
    cursor: pointer;
  }

  &:not(.starred).active {
    .scribe-indicator > div {
      bottom: 0px;
    }
    .player-outline {
      border-color: $primary_accent_color;
      background-color: $primary_accent_color;
    }
  }

  &:not(.active) {
    .mute-btn.active > div {
      bottom: 0px;
    }
  }

  &.submitted {
    .scribe-indicator-spans span {
      color: $primary_accent_color !important;
    }
  }

  .star-explosion {
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: transparent;
  }

  .winner-outline {
    pointer-events: none;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid $secondary_accent_color;
  }

  .ribbon-wrap {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-33%) translateY(5%);
  }

  .ribbon-team-position {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 23px;
    line-height: 62px;
    font-weight: bold;
    color: $color-black;
    padding-left: 3px;
    padding-right: 3px;
    .nth {
      position: relative;
      font-size: 60%;
      top: -0.55em;
    }
  }

  &.starred {
    .player-outline {
      border-color: $secondary_accent_color;
      background-color: $secondary_accent_color;
    }
    .winner-outline::before {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 8px;
      background-image: url("../../../../assets/star-text.png");
      background-position: center bottom;
      background-size: 80px;
      background-repeat: no-repeat;
    }
    .winner-outline {
      opacity: 1;
      border-color: transparent;
    }
  }

  &.boarding {
    cursor: initial !important;
    filter: contrast(50%) brightness(50%);
    .mute-btn > div {
      bottom: -22px !important;
    }
  }
}
</style>
