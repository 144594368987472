export const mission = {
  type: Object,
  required: true
}

export const mode = {
  /** @type {import('vue').PropType<GameMode>}*/
  type: String,
  required: true
}
