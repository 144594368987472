import firebase from "firebase/app"
import "firebase/database"
import { Role } from "@/helpers"
import _ from "lodash"

let missionsSubscriptionRef = null
let missionsSubscription = null

let audioSubscriptionRef = null
let audioSubscription = null

let gameStatusSubscriptionRef = null
let gameStatusSubscription = null

let audioPlayerSubscriptionRef = null

const MODES = [
  "welcome",
  "explain",
  "play",
  "huddle",
  "social",
  "voting",
  "results",
  "over",
  "meeting"
]

const DEFAULT_GAME_STATUS = {
  media: Role.Host,
  active: true,
  currentMode: MODES[0]
}

const MissionModule = {
  state: {
    modes: MODES,
    currentMission: null,
    currentMode: MODES[0],
    missions: [],
    gameStatus: {},
    playMedia: {},
    videoPlayer: null,
    audioPlayer: null,
    gameAudio: null,
    allMissions: [],
    blocks: []
  },
  mutations: {
    SET_CURRENT_MISSION(state, payload) {
      state.currentMission = payload
    },
    removeMission(state, payload) {
      console.log("REMOVE MISSION")
    },
    setMissions(state, payload) {
      state.missions = payload
    },
    setPlayMedia(state, payload) {
      state.playMedia = payload
    },
    SET_GAME_STATUS(state, payload) {
      state.gameStatus = payload
    },
    setVideoPlayer(state, payload) {
      state.videoPlayer = payload
    },
    setAudioPlayer(state, payload) {
      state.audioPlayer = payload
    },
    setGameAudio(state, payload) {
      state.gameAudio = payload
    },
    updateAllMissions(state, payload) {
      state.allMissions = payload
    },
    UPDATE_MODE(state, mode) {
      state.currentMode = mode
    },
    UPDATE_GAMESTATUS_MODE(state, mode) {
      state.gameStatus.currentMode = mode
    },
    UPDATE_BLOCKS(state, blocks) {
      console.log("BLOCKS ")
      if (!blocks) {
        state.blocks = []
      } else {
        const array = Object.entries(blocks).map(([id, block]) => ({
          id,
          name: block.name,
          version: parseInt(block.version) || null
        }))
        state.blocks = _.chain(array)
          .sortBy("version")
          .sortBy(({ version }) => !version)
          .value()
      }
    },
    LOCAL_UPDATE_MEETING_MODE_ACTIVE_USER(state, userID) {
      state.gameStatus = {
        ...state.gameStatus,
        meetingActiveUser:
          state.gameStatus.meetingActiveUser === userID ? false : userID
      }
    }
  },
  getters: {
    missions(state) {
      return state.missions
    },
    blocks(state) {
      return state.blocks
    },
    playMedia(state) {
      return state.playMedia
    },
    getCurrentMission(state) {
      return state.currentMission
    },
    currentMission(state) {
      return state.currentMission ? state.currentMission.theKey : null
    },
    gameStatus(state) {
      return state.gameStatus
    },
    videoPlayer(state) {
      return state.videoPlayer
    },
    audioPlayer(state) {
      return state.audioPlayer
    },
    gameAudio(state) {
      return state.gameAudio
    },
    allMissions(state) {
      return state.allMissions
    },
    getCurrentMode(state, rootState) {
      return state.gameStatus.currentMode || state.modes[0]
    },
    getEveryoneAudioStatus(state) {
      return state.gameStatus.everyoneAudioOn || false
    },
    getEveryoneMuted(state) {
      return state.gameStatus.everyoneMuted || false
    },
    getMeetingModeActiveUser(state) {
      return state.gameStatus.meetingActiveUser || false
    }
  },
  actions: {
    async updatePreGameMode({ commit }, payload) {
      console.log("UPDATE PRE GAME MODE", payload)
      commit("UPDATE_GAMESTATUS_MODE", payload)
    },
    async fetchBlocks({ rootState: { orgID }, commit }) {
      const snapshot = await firebase
        .database()
        .ref(`org/${orgID}/games/`)
        .orderByChild("runStatus")
        .equalTo("Blocks")
        .once("value")

      commit("UPDATE_BLOCKS", snapshot.val())
    },
    updateCurrentMode({ rootState, state, commit }, payload) {
      return new Promise((resolve, reject) => {
        const { orgID, gameID } = rootState
        const gamePath = `org/${orgID}/game/${gameID}`
        const { currentMode } = payload

        const updateMode = currentMode || state.modes[0]

        firebase
          .database()
          .ref(`${gamePath}/gameStatus`)
          .update({ currentMode: updateMode }, err => {
            if (err) return reject(err)
            commit("UPDATE_MODE", currentMode)
            resolve(currentMode)
          })
      })
    },
    setLocalCurrentMission({ commit }, payload) {
      commit("SET_CURRENT_MISSION", payload)
    },
    updateGameMedia({ rootState: { orgID, gameID } }, { media }) {
      return firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/gameStatus/media`)
        .set(media)
    },
    updateGameStatus2(
      { rootState: { orgID, gameID } },
      { mission, mode, endTime }
    ) {
      if (!mode && !mission) return
      const update = {}
      if (mode) update["currentMode"] = mode
      if (mission) {
        update["current_mission"] = mission
        update["endTime"] = endTime
      }
      update["media"] = null
      return firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/gameStatus`)
        .update(update)
    },
    async setCurrentMission({ commit, rootState }, payload) {
      console.log("SETTING CURRENT MISSION", payload)
      const thePath = "org/" + rootState.orgID + "/game/" + rootState.gameID
      await firebase
        .database()
        .ref(thePath + "/gameStatus")
        .update(payload)
    },
    setEndTime({ commit, rootState }, payload) {
      const thePath = "org/" + rootState.orgID + "/game/" + rootState.gameID
      firebase
        .database()
        .ref(thePath + "/gameStatus")
        .update(payload)
    },
    subscribeToGameAudio({ rootState, commit }) {
      const path = "org/" + rootState.orgID + "/game/" + rootState.gameID
      if (audioSubscriptionRef) audioSubscriptionRef.off()
      audioSubscriptionRef = firebase.database().ref(path + "/audio")
      audioSubscriptionRef.on("value", snapshot => {
        commit("setGameAudio", snapshot.val())
      })
    },
    endMission({ rootState }) {
      const thePath = "org/" + rootState.orgID + "/game/" + rootState.gameID
      firebase
        .database()
        .ref(thePath + "/gameStatus")
        .update({ active: false })
    },
    updateMission({ rootState }, payload) {
      var id = payload.theKey
      delete payload.theKey
      var thePayload = JSON.parse(JSON.stringify(payload))
      const thePath = "org/" + rootState.orgID + "/game/" + rootState.gameID
      firebase
        .database()
        .ref(thePath + "/missions/" + id)
        .update(thePayload)
    },
    async updateSocialMissionStatus({ rootState }, { hidden, missionID }) {
      await firebase
        .database()
        .ref(
          `org/${rootState.orgID}/game/${rootState.gameID}/missions/${missionID}`
        )
        .update({ hidden })
    },
    async copyBlock({ rootState, dispatch }, { gameID, index }) {
      const { orgID } = rootState
      const path = `org/${orgID}/game/${gameID}/missions`

      console.log("copyBlock index", index)

      const snapshot = await firebase
        .database()
        .ref(path)
        .orderByChild("pos")
        .once("value")
      const missions = snapshot.val()
      const array = Object.values(missions)
      array.sort((a, b) => a.pos - b.pos)
      await dispatch("addToMissions", { array, index })
    },
    async addToMissions({ rootState }, payload) {
      const error = new Error("Invalid input data")

      console.log("addToMissions payload", payload)

      if (typeof payload !== "object") throw error

      const { array, index } = payload
      // override local values with payload
      const orgID = payload.orgID || rootState.orgID
      const gameID = payload.gameID || rootState.gameID

      if (!orgID) throw error
      if (!gameID) throw error
      if (!Array.isArray(array)) throw error
      if (!array.length) throw error

      console.log("addToMissions index", index)

      const isIndexNil = index === null || index === undefined

      if (!isIndexNil && index < 0) throw error

      // const serialize = obj => JSON.parse(JSON.stringify(obj))
      const path = `org/${orgID}/game/${gameID}/missions`
      const ref = firebase.database().ref(path)

      if (array.length === 1 && isIndexNil) {
        console.log(`pushing just one mission`)
        return await ref.push(array[0])
      }

      const snapshot = await ref.orderByChild("pos").once("value")
      const value = snapshot.val()
      const missions = Object.entries(value).map(([id, mission]) => ({
        ...mission,
        id
      }))

      // looks like firebase orderByChild is not "ordering" anyting
      missions.sort((a, b) => a.pos - b.pos)

      const keyed = array.map(mission => ({ ...mission, id: ref.push().key }))
      const update = {}

      console.log("old set", missions)

      if (isIndexNil) {
        console.log(`pushing with null index`)
        let i = 1
        missions.forEach(mission => {
          update[mission.id] = { ...mission, pos: i }
          i++
        })
        keyed.forEach(mission => {
          update[mission.id] = { ...mission, pos: i }
          i++
        })
      } else {
        let i = 1
        console.log(`pushing to the middle`)
        missions.slice(0, index).forEach(mission => {
          update[mission.id] = { ...mission, pos: i }
          i++
        })
        keyed.forEach(mission => {
          update[mission.id] = { ...mission, pos: i }
          i++
        })
        missions.slice(index).forEach(mission => {
          update[mission.id] = { ...mission, pos: i }
          i++
        })
      }

      console.log("new set", Object.values(update))

      await ref.set(update)
    },
    async addMission({ rootState, dispatch }, { mission, noReorder }) {
      let orgID = rootState.orgID
      const obj = JSON.parse(JSON.stringify(mission))
      console.log("ADDMISSION ", mission)
      if (obj.orgID) orgID = obj.orgID

      const path = `org/${orgID}/game/${obj.gameID}`

      const { key } = await firebase
        .database()
        .ref(path + "/missions")
        .push(obj)

      if (!noReorder) await dispatch("reorderMissions")

      return { ...obj, id: key }
    },
    async reorderMissions({ rootState: { orgID, gameID }, dispatch }) {
      const path = `org/${orgID}/game/${gameID}`
      const snapshot = await firebase
        .database()
        .ref(path + "/missions")
        .orderByChild("pos")
        .once("value")

      const update = {}
      let i = 1

      snapshot.forEach(child => {
        update[`${child.key}`] = {
          ...child.val(),
          pos: i,
          theKey: child.key
        }
        i++
      })

      console.log("REORDER UPDATE", update)

      await firebase
        .database()
        .ref(path + "/missions")
        .update(update)
    },
    updatePosition({ rootState }, payload) {
      var id = payload.theKey
      const thePath = "org/" + rootState.orgID + "/game/" + rootState.gameID
      return firebase
        .database()
        .ref(thePath + "/missions/" + id)
        .update({ pos: payload.pos })
    },
    updateGameStatus({ rootState }, payload) {
      const thePath = "org/" + rootState.orgID + "/game/" + rootState.gameID
      return firebase
        .database()
        .ref(thePath + "/gameStatus")
        .update({ media: payload })
    },
    updateGameStatusAny({ rootState }, payload) {
      const gameID = payload.gameID || rootState.gameID
      const path = "org/" + rootState.orgID + "/game/" + gameID
      return firebase
        .database()
        .ref(path + "/gameStatus")
        .update(payload)
    },
    updateVideoPlayer({ rootState: { orgID, gameID } }, payload) {
      return firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/videoPlayer`)
        .update(payload)
    },
    updateAudioPlayer({ rootState: { orgID, gameID } }, payload) {
      return firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/audioPlayer`)
        .update(payload)
    },
    updateSoundEffect({ dispatch, rootState }, payload) {
      const thePath = "org/" + rootState.orgID + "/game/" + rootState.gameID
      if (rootState.theGame.audio) {
        if (payload == "GotItRight") {
          var obj = {}
          obj.name = payload
          obj.source =
            "https://assets.remoteteambuilding.com/audio%2F-LroXpzuSTyvLT2mA87K%2FRiseKeepGoing.mp3Fri%20Oct%2025%202019%2009%3A53%3A18%20GMT-0400%20(Eastern%20Daylight%20Time)?alt=media&token=7408abd3-d104-4bc2-8789-d196f4cb07a9"
          obj.playing = true
          obj.volume = 75
          obj.currentTime = new Date()
          dispatch("updateAudioPlayer", obj)
        }
        if (payload == "GotItWrong") {
          var obj = {}
          obj.name = payload
          obj.source =
            "https://assets.remoteteambuilding.com/audio%2F-Lsho9jTXUkTVWjEOqoF%2FSFX%20HitnFall.mp3Sun%20Nov%2003%202019%2018%3A18%3A59%20GMT-0800%20(Pacific%20Standard%20Time)?alt=media&token=a752b580-0cbd-4547-8f2a-e83eb731063b"
          obj.playing = true
          obj.volume = 80
          obj.currentTime = new Date()
          dispatch("updateAudioPlayer", obj)
        }
        if (payload == "BuzzIn") {
          var obj = {}
          obj.name = payload
          obj.source =
            "https://assets.remoteteambuilding.com/audio%2F-LroXpzuSTyvLT2mA87K%2FBendTarragon.mp3Fri%20Oct%2025%202019%2013%3A19%3A23%20GMT-0400%20(Eastern%20Daylight%20Time)?alt=media&token=4cf20a8c-7f47-4744-a4f7-03a33ed7b6e9"
          obj.playing = true
          obj.volume = 80
          obj.currentTime = new Date()
          dispatch("updateAudioPlayer", obj)
        }

        if (payload == "Swoosh") {
          console.log("play swoosh")
          var obj = {}
          obj.name = payload
          obj.source =
            "https://assets.remoteteambuilding.com/audio%2F-LroXpzuSTyvLT2mA87K%2Fswoosh.mp3Fri%20Oct%2025%202019%2010%3A24%3A09%20GMT-0400%20(Eastern%20Daylight%20Time)?alt=media&token=b733bd22-9f9b-4551-aec7-b5a8f5737bed"
          obj.playing = true
          obj.volume = 80
          obj.currentTime = new Date()
          dispatch("updateAudioPlayer", obj)
        }
      }
    },
    updateTeamAudio({ rootState }, payload) {
      const thePath = "org/" + rootState.orgID + "/game/" + rootState.gameID
      if (rootState.theGame.audio) {
        if (payload.name == "GotItRight") {
          var obj = {}
          obj.name = payload.name
          obj.source =
            "https://assets.remoteteambuilding.com/audio%2F-LroXpzuSTyvLT2mA87K%2FRiseKeepGoing.mp3Fri%20Oct%2025%202019%2009%3A53%3A18%20GMT-0400%20(Eastern%20Daylight%20Time)?alt=media&token=7408abd3-d104-4bc2-8789-d196f4cb07a9"
          obj.playing = true
          obj.volume = 80
          obj.currentTime = new Date()
          firebase
            .database()
            .ref(thePath)
            .child("teams/" + payload.teamID + "/audioPlayerData")
            .update(obj)
        }
        if (payload.name == "GotItWrong") {
          var obj = {}
          obj.name = payload.name
          obj.source =
            "https://assets.remoteteambuilding.com/audio%2F-LroXpzuSTyvLT2mA87K%2Fbuzzer--sound.mp3Fri%20Oct%2025%202019%2013%3A15%3A33%20GMT-0400%20(Eastern%20Daylight%20Time)?alt=media&token=ff06caaf-bd9a-4ba2-9fa4-8dec380cccc7"
          obj.playing = true
          obj.volume = 100
          obj.currentTime = new Date()
          firebase
            .database()
            .ref(thePath)
            .child("teams/" + payload.teamID + "/audioPlayerData")
            .update(obj)
        }
      }
    },
    subscribeToGameStatus({ commit, state, rootState }) {
      if (gameStatusSubscriptionRef)
        gameStatusSubscriptionRef.off("value", gameStatusSubscription)

      gameStatusSubscriptionRef = firebase
        .database()
        .ref(`org/${rootState.orgID}/game/${rootState.gameID}/gameStatus`)

      return new Promise(resolve => {
        gameStatusSubscription = gameStatusSubscriptionRef.on(
          "value",
          snapshot => {
            const value = snapshot.val()
            if (value) {
              commit("SET_GAME_STATUS", value)
              if (!_.isEqual(state.currentMission, value.current_mission)) {
                commit("SET_CURRENT_MISSION", value.current_mission)
              }
              resolve(value)
            } else {
              gameStatusSubscriptionRef.update(DEFAULT_GAME_STATUS)
              commit("SET_GAME_STATUS", DEFAULT_GAME_STATUS)
              resolve(DEFAULT_GAME_STATUS)
            }
          }
        )
      })
    },
    subscribeToAudioPlayer({ commit, state, rootState: { gameID, orgID } }) {
      if (audioPlayerSubscriptionRef) audioPlayerSubscriptionRef.off("value")

      audioPlayerSubscriptionRef = firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/audioPlayer`)

      audioPlayerSubscriptionRef.on("value", snapshot =>
        commit("setAudioPlayer", snapshot.val())
      )
    },
    async removeMission({ rootState, commit, dispatch }, payload) {
      const id = payload.theKey
      const thePath = "org/" + rootState.orgID + "/game/" + rootState.gameID
      await firebase
        .database()
        .ref(thePath + "/missions/" + id)
        .remove()
      commit("removeMission", payload)
      await dispatch("reorderMissions")
    },
    setupMissions({ commit }, payload) {
      commit("setMissions", payload)
    },
    unsubscribeFromMissions() {
      if (missionsSubscriptionRef)
        missionsSubscriptionRef.off("value", missionsSubscription)
    },
    subscribeToMissions({ commit, state, rootState }) {
      if (missionsSubscriptionRef)
        missionsSubscriptionRef.off("value", missionsSubscription)

      missionsSubscriptionRef = firebase
        .database()
        .ref(`org/${rootState.orgID}/game/${rootState.gameID}/missions`)
        .orderByChild("pos")

      return new Promise(resolve => {
        missionsSubscription = missionsSubscriptionRef.on("value", snapshot => {
          const obj = {}
          snapshot.forEach(item => {
            obj[item.key] = item.val()
          })
          commit("setMissions", obj)
          resolve()
        })
      })
    },
    async fetchMissions({ commit, state, rootState }, gameID) {
      console.log("fetchMissions is fired")
      if (!gameID) gameID = rootState.gameID
      const path = "org/" + rootState.orgID + "/game/" + gameID
      console.log("PAHT", path)
      const ref = firebase
        .database()
        .ref(path + "/missions")
        .orderByChild("pos")

      const onSnapshot = snapshot => {
        const obj = {}
        snapshot.forEach(item => {
          obj[item.key] = item.val()
        })
        return obj
      }

      const snapshot = await ref.once("value")
      console.log("SNAPSHOT", snapshot.val())
      commit("setMissions", onSnapshot(snapshot))
    },
    async fetchAllMissions({ commit, rootGetters, rootState, dispatch }) {
      const games = await dispatch("Games/subscribeToGames")
      var gArr = Object.keys(games)
      var missionsPromise = []
      gArr = gArr.reverse()
      async function getMissions(gameID) {
        var thePath = "org/" + rootState.orgID + "/game/" + gameID
        return new Promise(resolve => {
          firebase
            .database()
            .ref(thePath + "/missions")
            .once("value", snapshot => {
              resolve(snapshot.val())
            })
        })
      }

      for (var i in gArr) {
        missionsPromise.push(getMissions(gArr[i]))
      }
      let allMissions = await Promise.all(missionsPromise)
      if (allMissions) {
        allMissions = [].concat(
          ...allMissions.map(mission => Object.values(mission || {}))
        )
      }
      commit("updateAllMissions", allMissions)

      return allMissions
    },
    updateMeetingModeActiveUser({ rootState, getters }, userID) {
      const thePath = "org/" + rootState.orgID + "/game/" + rootState.gameID
      firebase
        .database()
        .ref(thePath + "/gameStatus")
        .update({
          meetingActiveUser:
            getters.getMeetingModeActiveUser === userID ? false : userID
        })
    },
    localUpdateMeetingModeActiveUser({ commit }, userID) {
      commit("LOCAL_UPDATE_MEETING_MODE_ACTIVE_USER", userID)
    }
  }
}

export default MissionModule
