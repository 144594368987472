var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mode-mapper" },
    [
      _c("Swapper", { attrs: { component: _vm.component } }),
      _c("ModeMapperHostContainer", {
        attrs: { mission: _vm.currentMission, mode: _vm.mode },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var rounded = ref.rounded
              var badgeHidden = ref.badgeHidden
              return [
                _c("HostMedia", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isHostMediaVisible,
                      expression: "isHostMediaVisible"
                    }
                  ],
                  attrs: { rounded: rounded, "hide-badge": badgeHidden }
                })
              ]
            }
          }
        ])
      }),
      _c("ModeMapperTips", {
        attrs: { game: _vm.game, mission: _vm.currentMission, mode: _vm.mode }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }