import { ImagesToClientMap } from "../constants"

import { GetterTypes } from "./getter-types"
import { ThemeScope } from "./constants"

export const getters = {
  [GetterTypes.STYLES](state, getters, rootState) {
    const theme = getters[GetterTypes.__CURRENT_THEME__]

    if (state.scope === ThemeScope.GAME) {
      const { game } = rootState

      if (game && game.styles && !game.themeID) {
        return game.styles
      }
    }

    return theme ? theme.styles : null
  },
  [GetterTypes.IMAGES](_, getters, rootState) {
    return image => {
      const theme = getters[GetterTypes.__CURRENT_THEME__]
      if (theme && theme.images[image]) {
        return theme.images[image]
      }
      const { client } = rootState.auth
      if (client) {
        return client[ImagesToClientMap[image]]
      }
    }
  },
  [GetterTypes.META](_, getters, rootState) {
    return key => {
      const theme = getters[GetterTypes.__CURRENT_THEME__]
      if (theme && theme.meta[key]) {
        return theme.meta[key]
      }
      const { client } = rootState.auth
      if (client) {
        return client[key]
      }
    }
  },
  [GetterTypes.__CURRENT_THEME__](state, getters, rootState) {
    if (state.theme) return state.theme
    const themes = getters[GetterTypes.__THEMES__]
    if (themes === null) return null

    /** @type {string} */
    let themeID

    if (state.scope === ThemeScope.GAME) {
      const { game } = rootState

      if (game) {
        themeID = game.themeID
      }
    }

    if (themeID === undefined) {
      const { client } = rootState.auth

      if (client) {
        themeID = client.themeID
      }

      if (themeID === undefined) {
        const { theOrg: org } = rootState
        themeID = org.themeID
      }
    }

    if (themeID) {
      const theme = themes[themeID]
      if (theme) {
        return theme
      }
    }

    return null
  },
  [GetterTypes.__THEMES__](_, __, rootState) {
    const { theOrg: org } = rootState

    if (org) {
      const { themes } = org
      if (themes) return themes
    }

    return null
  }
}
