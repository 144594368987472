var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "game-over-team-minor-specifics-wrap" }, [
    _c(
      "div",
      { staticClass: "game-over-team-minor-specifics-wrap-wrap" },
      [
        _c(
          "transition",
          { attrs: { name: "team-carrot-specifics-transition" } },
          [
            _vm.canShow
              ? _c(
                  "div",
                  { staticClass: "game-over-team-minor-specifics" },
                  [
                    _c("SvgTriangle", {
                      staticClass: "team-minor-specifics-shape-left"
                    }),
                    _c("div", { staticClass: "team-minor-specifics-text" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.name) + " "),
                        _c("span", { staticClass: "highlighted" }, [
                          _vm._v(_vm._s(_vm.pts))
                        ])
                      ])
                    ]),
                    _c("SvgTriangle", {
                      staticClass: "team-minor-specifics-shape-right"
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }