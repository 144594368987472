<template>
  <div>
    <TipJar
      v-if="tipJarVisible"
      :data="gameHost"
      class="mode-mapper__tip-jar"
      @venmo="venmoModalVisible = true"
    />
    <VenmoModal
      v-if="venmoModalVisible && !!venmoCode"
      :src="venmoCode"
      @hide="hideVenmoModal"
    />
  </div>
</template>

<script>
import Vue from "vue"

import { GameMode } from "@/entities/GameMode"

import { mission, mode } from "./common/props"

export default Vue.extend({
  name: "ModeMapperTips",
  inheritAttrs: false,
  components: {
    VenmoModal: () => import("./VenmoModal.vue"),
    TipJar: () => import("./TipJar.vue")
  },
  props: {
    mission,
    mode,
    game: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      venmoModalVisible: false,
      localHostStatus: undefined,
      timeout: null
    }
  },
  computed: {
    venmoCode() {
      return this.gameHost?.venmo
    },
    gameHost() {
      return this.$store.getters.gameHost
    },
    gameHostStatus() {
      return this.gameHost?.status
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    isGameOverMission() {
      return this.currentMission.name == "Game Over"
    },
    votingOverride() {
      return this.$store.state.group.votingOverride
    },
    tipJarVisible() {
      return (
        !this.game.tipsDisabled &&
        this.isGameOverMission &&
        this.gameHost &&
        (this.localHostStatus === "offline" || this.game.showTipJar) &&
        (this.votingOverride ||
          [GameMode.Social, GameMode.Voting, GameMode.Over].includes(this.mode))
      )
    }
  },
  watch: {
    gameHostStatus: {
      handler(value) {
        if (value === "offline") {
          this.timeout = setTimeout(() => {
            this.localHostStatus = value
          }, 10000)
        } else {
          this.localHostStatus = value
          clearTimeout(this.timeout)
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  },
  methods: {
    hideVenmoModal() {
      this.venmoModalVisible = false
    }
  }
})
</script>

<style lang="scss">
.mode-mapper {
  $block: &;

  &__tip-jar {
    position: absolute;
    right: 0;
    width: 16vmin;
    height: 16vmin;
    min-width: 128px;
    min-height: 128px;
    transform: translateY(-50%) rotate(-16deg);
    z-index: 2;
  }
}
</style>
