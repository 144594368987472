<script>
import Vue from "vue"
import { ResizeObserver } from "vue-resize"
import { throttle } from "lodash"
import { TweenMax, Power3 } from "gsap/TweenMax"

import { GameMission } from "@/entities/GameMission"
import { GameMode } from "@/entities/GameMode"
import { GameMissionEntity } from "@/entities/GameMission"

import { getRectRelativeToParent } from "@/helpers/rect"

import { mission, mode } from "./common/props"

import { HostPosition } from "./types"

export default Vue.extend({
  name: "ModeMapperHostContainer",
  props: {
    tag: {
      type: String,
      default: "div"
    },
    mission,
    mode
  },
  data() {
    return {
      height: 0
    }
  },
  computed: {
    /** @returns {HostPosition} */
    position() {
      return this.getPosition(this.mode)
    },
    /** @returns {boolean} */
    rounded() {
      return this.position === HostPosition.Rouned
    },
    /** @returns {boolean} */
    badgeHidden() {
      return this.rounded
    },
    /** @returns {boolean} */
    isDrawingMission() {
      return GameMissionEntity.isDrawingMission(this.mission)
    },
    /** @returns {GameMission} */
    missionBehavior() {
      return this.mission.behavior
    }
  },
  watch: {
    mode(_, oldVal) {
      this.previousMode = oldVal
    },
    position(newValue, oldValue) {
      if (
        oldValue === HostPosition.Rouned ||
        this.previousMode === GameMode.Huddle ||
        this.mode === GameMode.Huddle
      ) {
        this.animating = true
        this.animationTimeout = setTimeout(() => {
          this.animate({ delay: 0 })
          this.animationTimeout = null
        }, 800)
      } else if (
        this.previousMode === GameMode.Welcome &&
        this.mode === GameMode.Over
      ) {
        this.animate({ delay: 0 })
      } else {
        this.animate()
      }
    }
  },
  created() {
    this.windowResizeListener = throttle(this.windowResizeListener, 400)
    window.addEventListener("resize", this.windowResizeListener)
    // not reactive
    this.animationTimeout = null
    this.animating = false
  },
  mounted() {
    this.height = this.$el.offsetHeight

    this.$nextTick(() => {
      this.animate({ duration: 0, delay: 0 })
    })
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.windowResizeListener)
    clearTimeout(this.animationTimeout)
  },
  methods: {
    /** @param {GameMode} mode */
    getPosition(mode) {
      if (mode === GameMode.Welcome) {
        return HostPosition.Default
      } else if (
        [
          GameMode.Social,
          GameMode.Voting,
          GameMode.Over,
          GameMode.Meeting
        ].includes(mode) ||
        (this.isDrawingMission && this.mode !== GameMode.Welcome) ||
        (this.missionBehavior === GameMission.FactMatch &&
          this.mode === GameMode.Huddle) ||
        (this.missionBehavior === GameMission.FactMatch &&
          this.mode === GameMode.Results)
      ) {
        return HostPosition.Rouned
      } else {
        return HostPosition.Minimized
      }
    },
    onNotify({ height }) {
      this.height = height

      if (this.animating === false) {
        this.$nextTick(() => {
          this.animate({ duration: 0, delay: 0 })
        })
      }
    },
    /** @param {{ duration?: number, delay?: number, ease?: unknown }} options */
    animate(options = {}) {
      const duration = options.duration ?? 0.75
      const delay = options.delay ?? 0.25
      const ease = options.ease ?? Power3.easeInOut
      const element = this.getHostPositionElement(this.position)
      if (element) {
        this.animating = true
        const { left: x, top: y, height } = getRectRelativeToParent(
          this.$el,
          element
        )

        this.tween = TweenMax.to(this.getRootElement(), duration, {
          x,
          y,
          height,
          width: height,
          ease,
          delay,
          onComplete: () => {
            this.animating = false
            this.tween = null
          }
        })
      }
    },
    /** @returns {HTMLElement} */
    getRootElement() {
      return this.$refs.root
    },
    /**
     * @param {HostPosition} position
     * @returns {HTMLElement}
     */
    getHostPositionElement(position) {
      return this.$refs[position]
    },
    /**
     * @param {HostPosition} position
     * @returns {string}
     */
    getPositionClass(position) {
      return `host-position host-position--${position}`
    },
    /**
     * @param {HostPosition} position
     * @returns {import('vue').VNode } }
     */
    renderHostPositionElement(position) {
      return this.$createElement("div", {
        ref: position,
        staticClass: this.getPositionClass(position)
      })
    },
    windowResizeListener() {
      if (this.animating === false) {
        this.animate({ duration: 0, delay: 0 })
      }
    }
  },
  /** @returns {import('vue').VNode } */
  render(h) {
    const content = this.$scopedSlots.default({
      rounded: this.rounded,
      badgeHidden: this.badgeHidden
    })

    return h(
      this.tag,
      {
        staticClass: "mode-mapper__host-container",
        style: `--container-height: ${this.height}px`,
        class: `--position--${this.position}`
      },
      [
        h(ResizeObserver, { on: { notify: this.onNotify } }),
        h("div", { ref: "root", staticClass: "__positioner" }, content),
        Object.values(HostPosition).map(this.renderHostPositionElement)
      ]
    )
  }
})
</script>

<style lang="scss">
@import "~vue-resize/dist/vue-resize.css";

.mode-mapper {
  &__host-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none;

    .__positioner {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: auto;
    }
  }
}

.host-position {
  position: absolute;

  &--default {
    width: var(--container-height);
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &--minimized {
    top: 50%;
    left: 75%;
    transform: translateY(-50%);
    width: 212px;
    height: 212px;
  }

  &--rounded {
    top: -20px;
    right: 0;
    width: 16vmin;
    height: 16vmin;
    min-width: 128px;
    min-height: 128px;
    transform: translateY(-50%);
  }
}
</style>
