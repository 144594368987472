import firebase from "firebase/app"
import "firebase/database"

import { ClientService } from "@/services/client.service"

export const ActionTypes = {
  APPLY_CLINET_THEME_TO_GAMES: "APPLY_CLINET_THEME_TO_GAMES"
}

const ClientModule = {
  state: {
    currentClient: null,
    events: null
  },
  mutations: {
    setCurrentClient(state, payload) {
      state.currentClient = payload
    },
    setEvents(state, payload) {
      console.log("SETTING EVENTS", payload)
      state.events = payload
    }
  },
  getters: {
    restriction(state) {
      return state.restriction
    },
    client(state) {
      return state.client
    },
    events(state) {
      return state.events
    }
  },
  actions: {
    async updateUserClientPresence({ commit }, { userID, clientID }) {
      const ref = firebase
        .database()
        .ref(`client/${clientID}/presense/${userID}`)
      const snapshot = await ref.once("value")
      if (snapshot.val()) {
        await ref.child(`/updated`).set(Date.now())
      }
    },
    async addEvent({ rootState }, payload) {
      payload.orgID = rootState.orgID
      const snapshot = await firebase
        .database()
        .ref("events")
        .orderByChild("gameID")
        .equalTo(payload.gameID)
        .once("value")

      const value = snapshot.val()

      if (value) {
        const array = Object.keys(value)
        await firebase
          .database()
          .ref("events/" + array[0])
          .update(payload)
      } else {
        await firebase.database().ref("events").push(payload)
      }
    },
    async loadEvents({ commit }) {
      const snapshot = await firebase
        .database()
        .ref("events")
        .limitToLast(3000)
        .once("value")

      commit("setEvents", snapshot.val())
    },
    /**
     * @param { { games: Record<string, object> } } payload
     */
    [ActionTypes.APPLY_CLINET_THEME_TO_GAMES]({ rootState }, payload) {
      return ClientService.applyThemeToGames(
        rootState.orgID,
        rootState.auth.client.themeID,
        payload.games
      )
    }
  }
}

export default ClientModule
