var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "main",
      attrs: { "pa-0": "", fluid: "", "fill-height": "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "justify-center": "" } },
        [
          _vm.getTeamCounts < 2 || _vm.loading
            ? _c("WaitingForTeam")
            : [_c("Main", { key: "multi-team-" + _vm.isMultiTeam })],
          _vm._l(_vm.audio, function(user) {
            return _c("UserAudio", {
              key: "user-audio-" + user.id,
              attrs: { track: user.audioTrack }
            })
          }),
          _c("SoundEffects"),
          _vm.isDebugOrigin
            ? _c(
                "div",
                {
                  staticClass: "debug-window",
                  class: _vm.debug ? "show" : null,
                  on: {
                    click: function($event) {
                      if (!$event.altKey) {
                        return null
                      }
                      $event.stopPropagation()
                      _vm.debug = !_vm.debug
                    }
                  }
                },
                [
                  _c("div", [
                    _vm.debug
                      ? _c(
                          "table",
                          [
                            _c("tr", [
                              _c("td", [_vm._v("player")]),
                              _c("td", [_vm._v("mute")]),
                              _c("td", [_vm._v("global mute")]),
                              _c("td", [_vm._v("audio")]),
                              _c("td", [_vm._v("on/off")]),
                              _c("td", [_vm._v("video")])
                            ]),
                            _vm._l(_vm.debugUsers, function(debugUser) {
                              return _c(
                                "tr",
                                {
                                  key: "debug-user-" + debugUser.id,
                                  class: {
                                    host: debugUser.role === "facilitator",
                                    current: debugUser.id === _vm.user.id
                                  }
                                },
                                [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(debugUser.firstname) +
                                        " " +
                                        _vm._s(debugUser.lastname)
                                    )
                                  ]),
                                  _c("td", [_vm._v(_vm._s(debugUser.muted))]),
                                  _c("td", [
                                    _vm._v(_vm._s(debugUser.mutedGlobally))
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(!!debugUser.audioTrack))
                                  ]),
                                  _c("td", [_vm._v(_vm._s(debugUser.audioOn))]),
                                  _c("td", [
                                    _vm._v(_vm._s(!!debugUser.videoTrack))
                                  ])
                                ]
                              )
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "info" }, [
                    _c("span", { staticClass: "current" }, [_vm._v("you ")]),
                    _c("span", { staticClass: "host" }, [_vm._v("host")])
                  ])
                ]
              )
            : _vm._e(),
          _vm.isHost
            ? _c(
                "div",
                {
                  staticClass: "tool-window",
                  class: _vm.isToolBoxOpen ? "show" : "",
                  on: {
                    click: function($event) {
                      if ($event.target !== $event.currentTarget) {
                        return null
                      }
                      return _vm.onToolClick($event)
                    },
                    mouseleave: function($event) {
                      _vm.isOverTool = false
                    },
                    mouseover: function($event) {
                      _vm.isOverTool = true
                    }
                  }
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: { dark: "" },
                          model: {
                            value: _vm.activeTab,
                            callback: function($$v) {
                              _vm.activeTab = $$v
                            },
                            expression: "activeTab"
                          }
                        },
                        [
                          _c("v-tab", { key: "game" }, [_vm._v(" Game ")]),
                          _c(
                            "v-tab",
                            { key: "personal", attrs: { ripple: "" } },
                            [_vm._v(" Personal ")]
                          ),
                          _c(
                            "v-tab",
                            { key: "broadcast", attrs: { ripple: "" } },
                            [_vm._v(" Broadcast ")]
                          ),
                          _c("v-tab", { key: "CC", attrs: { ripple: "" } }, [
                            _vm._v(" CC ")
                          ]),
                          _c("v-tab-item", { key: "game" }, [
                            _c("table", [
                              _c("tr", [
                                _c("td", { attrs: { colspan: "3" } }, [
                                  _c("h2", [
                                    _vm._v(
                                      "Add mission after '" +
                                        _vm._s(_vm.mission.name) +
                                        "'"
                                    )
                                  ])
                                ])
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Pictionary")]),
                                _c(
                                  "td",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        dark: "",
                                        label: "Pictionary Item"
                                      },
                                      on: {
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.addPictionary()
                                        }
                                      },
                                      model: {
                                        value: _vm.pictionary,
                                        callback: function($$v) {
                                          _vm.pictionary = $$v
                                        },
                                        expression: "pictionary"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "rtb-button",
                                        on: {
                                          click: function($event) {
                                            return _vm.addPictionary()
                                          }
                                        }
                                      },
                                      [_vm._v("Add")]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Link URL")]),
                                _c(
                                  "td",
                                  [
                                    _c("v-text-field", {
                                      attrs: { dark: "", label: "Link URL" },
                                      on: {
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.addLink()
                                        }
                                      },
                                      model: {
                                        value: _vm.linkURL,
                                        callback: function($$v) {
                                          _vm.linkURL = $$v
                                        },
                                        expression: "linkURL"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "rtb-button",
                                        on: {
                                          click: function($event) {
                                            return _vm.addLink()
                                          }
                                        }
                                      },
                                      [_vm._v("Add")]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Blocks:")]),
                                _c(
                                  "td",
                                  [
                                    _c("v-autocomplete", {
                                      staticClass: "rtb-select",
                                      attrs: {
                                        solo: "",
                                        items: _vm.sortedBlocks,
                                        "item-text": "name",
                                        "item-value": "id"
                                      },
                                      model: {
                                        value: _vm.block,
                                        callback: function($$v) {
                                          _vm.block = $$v
                                        },
                                        expression: "block"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "rtb-button",
                                        on: {
                                          click: function($event) {
                                            return _vm.addBlock()
                                          }
                                        }
                                      },
                                      [_vm._v("Add")]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Question Type:")]),
                                _c(
                                  "td",
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        id: "select-mission",
                                        items: _vm.behaviors,
                                        label: "Type",
                                        solo: "",
                                        dark: ""
                                      },
                                      model: {
                                        value: _vm.newMission.behavior,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.newMission,
                                            "behavior",
                                            $$v
                                          )
                                        },
                                        expression: "newMission.behavior"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _c("br"),
                            _vm.newMission.behavior == "Text"
                              ? _c("div", [
                                  _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        attrs: { dark: "", label: "Question" },
                                        model: {
                                          value: _vm.newMission.instructions,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.newMission,
                                              "instructions",
                                              $$v
                                            )
                                          },
                                          expression: "newMission.instructions"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        staticStyle: { float: "left" },
                                        attrs: { dark: "", label: "Answer" },
                                        model: {
                                          value: _vm.newMission.answer,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.newMission,
                                              "answer",
                                              $$v
                                            )
                                          },
                                          expression: "newMission.answer"
                                        }
                                      }),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "rtb-button",
                                          staticStyle: {
                                            "margin-bottom": "0",
                                            float: "left"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addMission()
                                            }
                                          }
                                        },
                                        [_vm._v("Add")]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            _vm.newMission.behavior == "Freeform"
                              ? _c("div", [
                                  _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        attrs: { dark: "", label: "Question" },
                                        on: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.addMission()
                                          }
                                        },
                                        model: {
                                          value: _vm.newMission.instructions,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.newMission,
                                              "instructions",
                                              $$v
                                            )
                                          },
                                          expression: "newMission.instructions"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "rtb-button",
                                          staticStyle: {
                                            "margin-bottom": "0",
                                            float: "left"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addMission()
                                            }
                                          }
                                        },
                                        [_vm._v("Add")]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            _vm.newMission.behavior == "Poll" ||
                            _vm.newMission.behavior == "Multiple Choice"
                              ? _c("div", [
                                  _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        attrs: { dark: "", label: "Question" },
                                        model: {
                                          value: _vm.newMission.instructions,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.newMission,
                                              "instructions",
                                              $$v
                                            )
                                          },
                                          expression: "newMission.instructions"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _vm.newMission.behavior ==
                                      "Multiple Choice"
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.newMission.multiCorrect,
                                                expression:
                                                  "newMission.multiCorrect"
                                              }
                                            ],
                                            staticStyle: {
                                              float: "left",
                                              "margin-top": "19px",
                                              "margin-right": "10px"
                                            },
                                            attrs: {
                                              type: "radio",
                                              value: "1"
                                            },
                                            domProps: {
                                              checked: _vm._q(
                                                _vm.newMission.multiCorrect,
                                                "1"
                                              )
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.$set(
                                                  _vm.newMission,
                                                  "multiCorrect",
                                                  "1"
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      _c("v-text-field", {
                                        staticStyle: { float: "left" },
                                        attrs: { dark: "", label: "Answer 1" },
                                        model: {
                                          value: _vm.newMission.answer1,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.newMission,
                                              "answer1",
                                              $$v
                                            )
                                          },
                                          expression: "newMission.answer1"
                                        }
                                      }),
                                      _vm.newMission.behavior ==
                                      "Multiple Choice"
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.newMission.multiCorrect,
                                                expression:
                                                  "newMission.multiCorrect"
                                              }
                                            ],
                                            staticStyle: {
                                              float: "left",
                                              "margin-top": "19px",
                                              "margin-right": "10px"
                                            },
                                            attrs: {
                                              type: "radio",
                                              value: "2"
                                            },
                                            domProps: {
                                              checked: _vm._q(
                                                _vm.newMission.multiCorrect,
                                                "2"
                                              )
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.$set(
                                                  _vm.newMission,
                                                  "multiCorrect",
                                                  "2"
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      _c("v-text-field", {
                                        staticStyle: {
                                          float: "left:width:20px"
                                        },
                                        attrs: {
                                          width: "50",
                                          dark: "",
                                          label: "Answer 2"
                                        },
                                        model: {
                                          value: _vm.newMission.answer2,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.newMission,
                                              "answer2",
                                              $$v
                                            )
                                          },
                                          expression: "newMission.answer2"
                                        }
                                      }),
                                      _vm.newMission.behavior ==
                                      "Multiple Choice"
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.newMission.multiCorrect,
                                                expression:
                                                  "newMission.multiCorrect"
                                              }
                                            ],
                                            staticStyle: {
                                              float: "left",
                                              "margin-top": "19px",
                                              "margin-right": "10px"
                                            },
                                            attrs: {
                                              type: "radio",
                                              value: "3"
                                            },
                                            domProps: {
                                              checked: _vm._q(
                                                _vm.newMission.multiCorrect,
                                                "3"
                                              )
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.$set(
                                                  _vm.newMission,
                                                  "multiCorrect",
                                                  "3"
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      _c("v-text-field", {
                                        staticStyle: { float: "left" },
                                        attrs: { dark: "", label: "Answer 3" },
                                        model: {
                                          value: _vm.newMission.answer3,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.newMission,
                                              "answer3",
                                              $$v
                                            )
                                          },
                                          expression: "newMission.answer3"
                                        }
                                      }),
                                      _vm.newMission.behavior ==
                                      "Multiple Choice"
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.newMission.multiCorrect,
                                                expression:
                                                  "newMission.multiCorrect"
                                              }
                                            ],
                                            staticStyle: {
                                              float: "left",
                                              "margin-top": "19px",
                                              "margin-right": "10px"
                                            },
                                            attrs: {
                                              type: "radio",
                                              value: "4"
                                            },
                                            domProps: {
                                              checked: _vm._q(
                                                _vm.newMission.multiCorrect,
                                                "4"
                                              )
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.$set(
                                                  _vm.newMission,
                                                  "multiCorrect",
                                                  "4"
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      _c("v-text-field", {
                                        staticStyle: {
                                          float: "left:width:20px"
                                        },
                                        attrs: {
                                          width: "50",
                                          dark: "",
                                          label: "Answer 4"
                                        },
                                        model: {
                                          value: _vm.newMission.answer4,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.newMission,
                                              "answer4",
                                              $$v
                                            )
                                          },
                                          expression: "newMission.answer4"
                                        }
                                      }),
                                      _vm.newMission.behavior ==
                                      "Multiple Choice"
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.newMission.multiCorrect,
                                                expression:
                                                  "newMission.multiCorrect"
                                              }
                                            ],
                                            staticStyle: {
                                              float: "left",
                                              "margin-top": "19px",
                                              "margin-right": "10px"
                                            },
                                            attrs: {
                                              type: "radio",
                                              value: "5"
                                            },
                                            domProps: {
                                              checked: _vm._q(
                                                _vm.newMission.multiCorrect,
                                                "5"
                                              )
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.$set(
                                                  _vm.newMission,
                                                  "multiCorrect",
                                                  "5"
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      _c("v-text-field", {
                                        staticStyle: {
                                          float: "left:width:20px"
                                        },
                                        attrs: {
                                          width: "50",
                                          dark: "",
                                          label: "Answer 5"
                                        },
                                        model: {
                                          value: _vm.newMission.answer5,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.newMission,
                                              "answer5",
                                              $$v
                                            )
                                          },
                                          expression: "newMission.answer5"
                                        }
                                      }),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "rtb-button",
                                          staticStyle: {
                                            "margin-bottom": "0",
                                            float: "left"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addMission()
                                            }
                                          }
                                        },
                                        [_vm._v("Add")]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              [
                                _c("h2", [_vm._v("Meeting Announcement")]),
                                _c("v-textarea", {
                                  attrs: {
                                    solo: "",
                                    label: "Enter your announcement",
                                    rows: "1",
                                    dark: ""
                                  },
                                  model: {
                                    value: _vm.game.meetingAnnouncement,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.game,
                                        "meetingAnnouncement",
                                        $$v
                                      )
                                    },
                                    expression: "game.meetingAnnouncement"
                                  }
                                }),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "rtb-button",
                                    attrs: {
                                      loading: _vm.isSAvingMeetingAnnouncement
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.saveAnnouncement()
                                      }
                                    }
                                  },
                                  [_vm._v("Update")]
                                )
                              ],
                              1
                            )
                          ]),
                          _c("v-tab-item", { key: "personal" }, [
                            _c(
                              "div",
                              [
                                _c("h2", [_vm._v("Personal Settings")]),
                                _c("v-text-field", {
                                  staticStyle: { float: "left:width:20px" },
                                  attrs: {
                                    width: "50",
                                    dark: "",
                                    label: "Venmo QR Code URL"
                                  },
                                  model: {
                                    value: _vm.user.venmo,
                                    callback: function($$v) {
                                      _vm.$set(_vm.user, "venmo", $$v)
                                    },
                                    expression: "user.venmo"
                                  }
                                }),
                                _c("v-text-field", {
                                  staticStyle: { float: "left:width:20px" },
                                  attrs: {
                                    width: "50",
                                    dark: "",
                                    label: "Paypal Username"
                                  },
                                  model: {
                                    value: _vm.user.paypal,
                                    callback: function($$v) {
                                      _vm.$set(_vm.user, "paypal", $$v)
                                    },
                                    expression: "user.paypal"
                                  }
                                }),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "rtb-button",
                                    staticStyle: {
                                      "margin-bottom": "0",
                                      float: "left"
                                    },
                                    attrs: { loading: _vm.loadingImage },
                                    on: {
                                      click: function($event) {
                                        return _vm.updateUser()
                                      }
                                    }
                                  },
                                  [_vm._v("Update")]
                                )
                              ],
                              1
                            )
                          ]),
                          _c(
                            "v-tab-item",
                            { key: "broadcast" },
                            [_c("Screenshot")],
                            1
                          ),
                          _c("v-tab-item", { key: "CC" }, [
                            _c(
                              "div",
                              [
                                _c("h2", [_vm._v("Speech To Text")]),
                                _c("v-switch", {
                                  attrs: { label: "Enable Speech To Text" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "span",
                                              { staticClass: "v-label-white" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    !_vm.speechToText
                                                      ? "Enable Speech To Text"
                                                      : "Disable Speech To Text"
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    2213063971
                                  ),
                                  model: {
                                    value: _vm.speechToText,
                                    callback: function($$v) {
                                      _vm.speechToText = $$v
                                    },
                                    expression: "speechToText"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }