var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "overlay" },
    [
      _c("v-progress-circular", {
        staticClass: "overlay__spinner",
        attrs: { color: "#f36523", indeterminate: "" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }