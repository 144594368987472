var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "play-mode-wrap", attrs: { row: "", "fill-height": "" } },
    [
      _c(
        "v-flex",
        { staticClass: "asset-column", attrs: { xs3: "", "ml-2": "" } },
        [
          _c(
            "v-layout",
            { attrs: { column: "", "justify-center": "", "fill-height": "" } },
            [
              _c(
                "div",
                { staticClass: "play-asset-wrap" },
                [
                  _c("AssetSlide", {
                    staticClass: "play-asset",
                    attrs: { mode: _vm.currentMode }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-flex",
        {
          staticClass: "play-mode",
          attrs: { xs6: "", "mr-2": "", "ml-2": "" }
        },
        [
          _vm.isDrawingMission
            ? _c(
                "RtbCard",
                { staticClass: "canvas-rtb-card" },
                [
                  _c(
                    "transition",
                    {
                      attrs: {
                        name: "mission-result-transition",
                        mode: "out-in"
                      }
                    },
                    [
                      _vm.currentMode === "results"
                        ? _c("ResultContainer", {
                            staticClass: "result-component"
                          })
                        : _c("Drawing", {
                            key: "drawing-component-" + _vm.missionID,
                            attrs: {
                              bgColor: "#efefef",
                              width: 510,
                              height: 270
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "MissionContainer",
                { attrs: { mode: _vm.currentMode } },
                [
                  _vm.missionType === "Multiple Choice"
                    ? _c("MultipleChoice", { attrs: { mode: _vm.currentMode } })
                    : _vm.missionType === "Poll"
                    ? _c("Poll", { attrs: { mode: _vm.currentMode } })
                    : _vm.missionType === "Giphy"
                    ? _c("Giphy", { attrs: { mode: _vm.currentMode } })
                    : _vm.missionType === "Lipdub"
                    ? _c("Lipdub", { attrs: { mode: _vm.currentMode } })
                    : _vm.missionType === "Take Photo"
                    ? _c("TakePhoto", { attrs: { mode: _vm.currentMode } })
                    : _vm.missionType === "Photo"
                    ? _c("Photo", { attrs: { mode: _vm.currentMode } })
                    : _vm.missionType === "URL"
                    ? _c("URL", { attrs: { mode: _vm.currentMode } })
                    : _vm.missionType === "Text" ||
                      _vm.missionType === "Freeform" ||
                      _vm.missionType === "Team Name" ||
                      _vm.missionType === "Fact Match"
                    ? _c("TextInput", { attrs: { mode: _vm.currentMode } })
                    : _vm.missionType === "Categories"
                    ? _c("Categories", { attrs: { mode: _vm.currentMode } })
                    : _vm.missionType === "Buzz In" ||
                      _vm.missionType === "Player Rating" ||
                      _vm.missionType === "Info" ||
                      _vm.missionType === "Link"
                    ? _c("TextOnly", { attrs: { mode: _vm.currentMode } })
                    : _vm._e()
                ],
                1
              )
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { "d-flex": "", "align-center": "", xs3: "", "mr-2": "" } },
        [
          _c(
            "transition",
            {
              attrs: { name: "canvas-tools-slide-transition", mode: "out-in" }
            },
            [
              _vm.hasPallette
                ? _c(
                    "RtbCard",
                    { staticClass: "canvas-tools-rtb-card" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { shrink: "" } },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "draw-tools-left-col",
                                  attrs: { column: "" }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { "d-flex": "", shrink: "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { column: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticStyle: { display: "flex" },
                                              attrs: { "align-center": "" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "draw-tool-btn",
                                                  class: {
                                                    disabled: false, //!canPlayerDraw(),
                                                    active: _vm.isPen
                                                  },
                                                  on: { click: _vm.setToPen }
                                                },
                                                [
                                                  _vm.isPen
                                                    ? _c("img", {
                                                        staticClass:
                                                          "draw-tool-icon",
                                                        attrs: {
                                                          width: "28",
                                                          src: require("../../../../assets/pen-icon-yellow.png")
                                                        }
                                                      })
                                                    : _c("img", {
                                                        staticClass:
                                                          "draw-tool-icon",
                                                        attrs: {
                                                          width: "28",
                                                          src: require("../../../../assets/pen-icon-white.png")
                                                        }
                                                      }),
                                                  _c("div", [_vm._v("Draw")])
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticStyle: { display: "flex" },
                                              attrs: { "align-center": "" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "draw-tool-btn",
                                                  class: {
                                                    disabled: false, //!canPlayerDraw(),
                                                    active: !_vm.isPen
                                                  },
                                                  on: { click: _vm.setToEraser }
                                                },
                                                [
                                                  !_vm.isPen
                                                    ? _c("img", {
                                                        staticClass:
                                                          "draw-tool-icon",
                                                        attrs: {
                                                          width: "28",
                                                          src: require("../../../../assets/eraser-icon-yellow.png")
                                                        }
                                                      })
                                                    : _c("img", {
                                                        staticClass:
                                                          "draw-tool-icon",
                                                        attrs: {
                                                          width: "28",
                                                          src: require("../../../../assets/eraser-icon-white.png")
                                                        }
                                                      }),
                                                  _c("div", [_vm._v("Erase")])
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.isHost
                                    ? _c(
                                        "v-flex",
                                        { attrs: { "d-flex": "", shrink: "" } },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { column: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "clear-board-btn",
                                                      on: {
                                                        click:
                                                          _vm.onClearOneTeam
                                                      }
                                                    },
                                                    [
                                                      _vm._v(" CLEAR"),
                                                      _c("br"),
                                                      _vm._v("THIS ")
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-flex",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "clear-all-btn",
                                                      on: {
                                                        click: _vm.onClearTeams
                                                      }
                                                    },
                                                    [
                                                      _vm._v(" CLEAR"),
                                                      _c("br"),
                                                      _vm._v("ALL ")
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              staticStyle: {
                                overflow: "hidden",
                                "background-color": "#fff"
                              }
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { column: "", "fill-height": "" } },
                                [
                                  _c("v-flex", [
                                    _c(
                                      "div",
                                      { staticClass: "pen-size-container" },
                                      _vm._l(7, function(idx) {
                                        return _c(
                                          "div",
                                          {
                                            key: "pen-size-" + idx,
                                            staticClass: "pen-size",
                                            class: {
                                              active: _vm.isCurrentSize(idx + 1)
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.onSizeClick(idx + 1)
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass: "pen-size-svg",
                                                attrs: {
                                                  height: _vm.brushSizeBase(
                                                    idx + 1
                                                  ),
                                                  width: _vm.brushSizeBase(
                                                    idx + 1
                                                  )
                                                }
                                              },
                                              [
                                                _c("circle", {
                                                  staticClass: "filled-circle",
                                                  attrs: {
                                                    cx:
                                                      _vm.brushSizeBase(
                                                        idx + 1
                                                      ) / 2,
                                                    cy:
                                                      _vm.brushSizeBase(
                                                        idx + 1
                                                      ) / 2,
                                                    r:
                                                      _vm.brushSizeBase(
                                                        idx + 1
                                                      ) /
                                                        2 -
                                                      3,
                                                    fill: "#68625f"
                                                  }
                                                }),
                                                _c("circle", {
                                                  staticClass: "outline-circle",
                                                  attrs: {
                                                    cx:
                                                      _vm.brushSizeBase(
                                                        idx + 1
                                                      ) / 2,
                                                    cy:
                                                      _vm.brushSizeBase(
                                                        idx + 1
                                                      ) / 2,
                                                    r:
                                                      _vm.brushSizeBase(
                                                        idx + 1
                                                      ) /
                                                        2 -
                                                      2,
                                                    "stroke-width": "1",
                                                    fill: "none"
                                                  }
                                                }),
                                                _vm._v(
                                                  " Sorry, your browser does not support inline SVG. "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "select-color-text",
                                      attrs: { shrink: "" }
                                    },
                                    [_vm._v(" Select Color ")]
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { "d-flex": "" } },
                                    [
                                      _c("chrome-picker", {
                                        staticClass: "color-picker",
                                        attrs: { disableFields: true },
                                        on: { input: _vm.onColorPickerUpdate },
                                        model: {
                                          value: _vm.colors,
                                          callback: function($$v) {
                                            _vm.colors = $$v
                                          },
                                          expression: "colors"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }