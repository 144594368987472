<template>
  <Result
    :data="data"
    :isHost="isHost"
    @onIncreaseTotalScore="({ team, score }) => increaseTotalScore(team, score)"
    @onDecreaseTotalScore="({ team, score }) => decreaseTotalScore(team, score)"
    @onIncreaseMissionScore="
      ({ team, score }) => increaseMissionScore(team, score)
    "
    @onDecreaseMissionScore="
      ({ team, score }) => decreaseMissionScore(team, score)
    "
  />
</template>

<script>
import Result from "./Result.vue"

// The component that connects the Result to the store
export default {
  name: "ResultContainer",
  components: {
    Result
  },
  methods: {
    getTeamUsingId(teamID) {
      const teams = this.$store.getters.chats
      if (Object.prototype.hasOwnProperty.call(teams, teamID)) {
        return teams[teamID]
      }

      return {}
    },
    increaseMissionScore(team, score = 30) {
      // up
      console.log(team, score)
    },
    decreaseMissionScore(team, score = 30) {
      // down
      console.log(team, score)
    },
    addIntoTeamPlays(score) {
      // here you can distribute a positive or
      // negative number among all plays submitted
      // by the team
      // ...
      console.log("quiet eslint", score)
    },
    increaseTotalScore(team) {
      if (this.isHost) {
        //console.log("INCREASE CONTAIN")
        const obj = {}
        const num = team.totalScore + parseInt(this.currentMission.points / 2)
        if (num > 1000000) return
        if (isNaN(num)) return
        obj.teamScore = num
        obj.teamID = team.id
        this.$store.dispatch("setTeamScore", obj)
      }
    },
    decreaseTotalScore(team) {
      if (this.isHost) {
        const obj = {}
        const num = team.totalScore - parseInt(this.currentMission.points / 2)
        if (num < 0) return
        if (isNaN(num)) return
        obj.teamScore = num
        obj.teamID = team.id
        this.$store.dispatch("setTeamScore", obj)
      }
    },
    // filter plays of the current mission by a team ID
    getPlaysByTeamID(teamID) {
      return (
        this.missionPlaysArray
          .filter(obj => obj.teamID === teamID)
          // make sure the score is an int
          .map(obj => {
            const score = parseInt(obj.score || 0)
            return { ...obj, score }
          })
      )
    }
  },
  computed: {
    isHost() {
      return this.user.role === "facilitator"
    },
    user() {
      return this.$store.getters.user
    },
    teams() {
      return Object.values(this.$store.getters.chats)
    },
    currentMissionID() {
      return this.$store.getters.currentMission
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    missionPlaysArray() {
      return this.$store.getters.missionPlaysArray
    },
    data() {
      const teams = this.teams.map(({ id: teamID }) => {
        const team = this.getTeamUsingId(teamID)
        const totalScore = parseInt(team.totalScore || 0)
        // get all people who submited for a team
        // and sum their results into a new prop `score`
        const teamPlays = this.getPlaysByTeamID(teamID)
        // sum all the numbers
        const score = teamPlays.reduce((a, b) => a + b.score, 0)
        return {
          ...team,
          totalScore,
          score
        }
      })
      // sort by total score (descending) and team name
      teams.sort((a, b) => {
        if (a.totalScore > b.totalScore) return -1
        if (a.totalScore < b.totalScore) return 1

        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
      })
      return teams
    }
  }
}
</script>
