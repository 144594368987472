var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentMode != "over"
    ? _c(
        "div",
        {
          staticClass: "score-table-root",
          staticStyle: { position: "relative", "z-index": "2" }
        },
        _vm._l(_vm.dataMutable, function(team, i) {
          return _c(
            "span",
            {
              key: "score-table-item-" + i,
              staticClass: "team-score-span",
              style:
                _vm.isHost || _vm.isAudit || _vm.isSpectator
                  ? "cursor: pointer;"
                  : ""
            },
            [
              _c(
                "span",
                {
                  on: {
                    click: function($event) {
                      return _vm.onTeamSelect(team)
                    }
                  }
                },
                [
                  _vm.showIcon
                    ? _c(
                        "span",
                        { staticClass: "team-icon" },
                        [
                          _c("v-icon", { staticClass: "result-team-icon" }, [
                            _vm._v(
                              " " +
                                _vm._s(team.icon || "radio_button_unchecked") +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("span", { staticClass: "orange-text" }, [
                    _vm.isHost && !_vm.showIcon
                      ? _c("span", [
                          _vm.totalPlays(team.id) > 0
                            ? _c("span", [_vm._v(" ** ")])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" " + _vm._s(team.name)),
                    _c(
                      "span",
                      {
                        staticClass: "colon",
                        class: { show: _vm.teamPoints && _vm.isHost }
                      },
                      [_vm._v(":")]
                    )
                  ])
                ]
              ),
              _c("transition", { attrs: { name: "fade" } }, [
                !!_vm.teamPoints && _vm.isHost
                  ? _c("span", { staticClass: "team-points" }, [
                      _c(
                        "span",
                        {
                          on: {
                            click: [
                              function($event) {
                                if (
                                  $event.ctrlKey ||
                                  $event.shiftKey ||
                                  $event.altKey ||
                                  $event.metaKey
                                ) {
                                  return null
                                }
                                return _vm.increaseScore(team)
                              },
                              function($event) {
                                if (!$event.altKey) {
                                  return null
                                }
                                return _vm.decreaseScore(team)
                              },
                              function($event) {
                                if (!$event.ctrlKey) {
                                  return null
                                }
                                return _vm.decreaseScore(team)
                              },
                              function($event) {
                                if (!$event.shiftKey) {
                                  return null
                                }
                                return _vm.toggleControls(team.id)
                              }
                            ]
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(parseInt(team.totalScore)) + "pts "
                          )
                        ]
                      ),
                      !!team.controls && _vm.isHost
                        ? _c("span", { staticClass: "plus-minus-wrap" }, [
                            _c(
                              "span",
                              {
                                staticClass: "plus-minues-controls minus",
                                on: {
                                  mousedown: function($event) {
                                    return _vm.onDown(team.id)
                                  },
                                  mouseup: function($event) {
                                    return _vm.onRelease(team.id)
                                  }
                                }
                              },
                              [_vm._v(" - ")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "plus-minues-controls plus",
                                on: {
                                  mousedown: function($event) {
                                    return _vm.onUp(team.id)
                                  },
                                  mouseup: function($event) {
                                    return _vm.onRelease(team.id)
                                  }
                                }
                              },
                              [_vm._v(" + ")]
                            )
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }