var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "team-specifics-card", attrs: { "d-flex": "" } },
    [
      _c(
        "transition",
        { attrs: { name: "card-flip-transition", mode: "out-in" } },
        [
          _vm.state === "results"
            ? _c(
                "v-flex",
                {
                  key: _vm.state,
                  staticClass: "card-front-side card",
                  attrs: { "d-flex": "" }
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "", "fill-height": "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "team-name-wrap",
                          attrs: { shrink: "" }
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              staticStyle: {
                                margin: "0 0.8em",
                                "justify-content": "space-between"
                              },
                              attrs: { row: "" }
                            },
                            [
                              _c(
                                "v-flex",
                                { staticStyle: { "flex-grow": "0" } },
                                [
                                  _c("v-icon", { staticClass: "team-icon" }, [
                                    _vm._v(" " + _vm._s(_vm.teamIcon) + " ")
                                  ]),
                                  _c("span", { staticClass: "team-name" }, [
                                    _vm._v(_vm._s(_vm.teamName) + " ")
                                  ])
                                ],
                                1
                              ),
                              !_vm.isMultiTeam
                                ? _c(
                                    "v-flex",
                                    {
                                      staticClass: "team-name-team-total",
                                      staticStyle: { "flex-grow": "0" },
                                      attrs: { "d-flex": "" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.teamTotal + "pts") +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "team-info-wrap" },
                        [
                          _c(
                            "v-flex",
                            { staticClass: "team-info-scroll" },
                            [
                              _c(
                                "v-layout",
                                { attrs: { column: "" } },
                                _vm._l(_vm.info, function(obj, idx) {
                                  return _c(
                                    "v-flex",
                                    {
                                      key: "team-spec-card-" + idx,
                                      staticClass: "team-spec-card-wrap"
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          staticClass: "team-spec-card",
                                          attrs: { row: "" }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "left-col",
                                              attrs: { grow: "" }
                                            },
                                            [
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  obj.title
                                                    ? _c(
                                                        "v-flex",
                                                        {
                                                          staticClass:
                                                            "v-align",
                                                          attrs: { shrink: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "team-spec-title"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "team-spec-title-wrap"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        obj.title
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "v-align team-total",
                                              attrs: { shrink: "" }
                                            },
                                            [
                                              _c("div", {
                                                domProps: {
                                                  innerHTML: _vm._s(obj.points)
                                                }
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.position
                    ? _c("div", { staticClass: "ribbon-wrap" }, [
                        _vm.position === 1
                          ? _c("img", {
                              staticClass: "colored-ribbon-image",
                              attrs: {
                                width: "40",
                                src: require("../../../assets/ribbon-blue.png")
                              }
                            })
                          : _vm._e(),
                        _vm.position === 2
                          ? _c("img", {
                              staticClass: "colored-ribbon-image",
                              attrs: {
                                width: "40",
                                src: require("../../../assets/ribbon-red.png")
                              }
                            })
                          : _vm._e(),
                        _vm.position >= 3
                          ? _c("img", {
                              staticClass: "colored-ribbon-image",
                              attrs: {
                                width: "40",
                                src: require("../../../assets/ribbon-white.png")
                              }
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "ribbon-team-position",
                            class: "ribbon-team-position-color-" + _vm.position
                          },
                          [
                            _c("span", [_vm._v(_vm._s(_vm.position))]),
                            _c("span", { staticClass: "nth" }, [
                              _vm._v(_vm._s(_vm.positionSuffix))
                            ])
                          ]
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            : _c(
                "v-flex",
                {
                  key: _vm.state,
                  staticClass: "card-back-side card",
                  attrs: { "d-flex": "" }
                },
                [
                  _c("div", { staticClass: "ribbon-bg" }),
                  _c(
                    "v-layout",
                    {
                      staticClass: "position-text",
                      attrs: { column: "", "justify-center": "" }
                    },
                    [_c("div", [_vm._v(_vm._s(_vm.positionText))])]
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }