// import firebase from "firebase/app"
// import "firebase/database"

const UserSettings = {
  namespaced: true,
  state: {
    user: null
  },
  mutations: {
    UPDATE_USER(state, { user }) {
      state.user = user
    }
  },
  actions: {
    updateUser({ commit }, { user }) {
      commit("UPDATE_USER", { user })
    }
  },
  getters: {
    user(state) {
      return state.user
    }
  }
}

export default UserSettings
