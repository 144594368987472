// Since different browsers have different errors,
// we need to keep all of them handy.
// The list should be extended further as we focus on
// supporting more browser and browser versions

const MIC_USER_PERMISSION_ERROR = {
  names: ["NotAllowedError"],
  messages: [
    "Permission denied",
    "The request is not allowed by the user or the platform in this current form.",
    "The request is not allowed by the user agent or the platform in the current context."
  ],
  text:
    "Oops. You've run into a permission error. Please give the mic access to your browser."
}

const MIC_GENERIC_ERROR = {
  text:
    "Yikes. You've got a microphone error. Please make sure you have one, close all applications that may use it and make sure your browser has access to the microphone."
}

const CAMERA_ALLOCATION_ERROR = {
  messages: ["Could not start video source", "Failed to allocate videosource"],
  names: ["NotReadableError", "SourceUnavailableError"],
  text:
    "Oops. Looks like your webcam is being used by another application or you don't have one plugged in at all. Check the connection and close all applications that might use it and try again."
}

// macOS Mojave specific error catch info here
const CAMERA_SYSTEM_PERMISSION_ERROR = {
  messages: ["Permission denied by system"],
  names: [],
  text: `Oops. You've run into a permission error. Please make sure your browser has permission to access the webcam. If you are on macOS Mojave, follow this <a target="_blank" href="https://blog.addpipe.com/camera-and-microphone-access-in-macos-mojave">link</a> to resolve.`
}

const CAMERA_USER_PERMISSION_ERROR = {
  names: ["NotAllowedError"],
  messages: [
    "Permission denied",
    "The request is not allowed for this user. Please update your web browser.",
    "The request is not allowed by the user agent or the platform in the current context."
  ],
  text:
    'Oops. You have run into a permission error. Please give the webcam access to your browser. Follow this <a target="_blank" href="https://www.thegogame.com/virtual-game-show-tech-support">link</a> to resolve.'
}

const CAMERA_GENERIC_ERROR = {
  text:
    "Oops. Looks like you didn't give permission to use your webcam or it is in use by another application or browser tab.  We can't tell which, which but even an inactive Zoom can steal your webcam. Find the app and try again."
}

const TestModule = {
  namespaced: true,
  // some mapping is happening here
  actions: {
    async getCameraErrorMessage(context, { error }) {
      console.log("getCameraErrorMessage", error)
      const { name, message } = error
      if (message) {
        if (CAMERA_ALLOCATION_ERROR.messages.indexOf(message) > -1) {
          return CAMERA_ALLOCATION_ERROR.text
        } else if (
          CAMERA_SYSTEM_PERMISSION_ERROR.messages.indexOf(message) > -1
        ) {
          return CAMERA_SYSTEM_PERMISSION_ERROR.text
        } else if (
          CAMERA_USER_PERMISSION_ERROR.messages.indexOf(message) > -1
        ) {
          return CAMERA_USER_PERMISSION_ERROR.text
        } else {
          return CAMERA_GENERIC_ERROR.text
        }
      } else if (name) {
        if (CAMERA_ALLOCATION_ERROR.names.indexOf(name) > -1) {
          return CAMERA_ALLOCATION_ERROR.text
        } else if (CAMERA_SYSTEM_PERMISSION_ERROR.names.indexOf(name) > -1) {
          return CAMERA_SYSTEM_PERMISSION_ERROR.text
        } else if (CAMERA_USER_PERMISSION_ERROR.names.indexOf(name) > -1) {
          return CAMERA_USER_PERMISSION_ERROR.text
        } else {
          return CAMERA_GENERIC_ERROR.text
        }
      } else {
        return CAMERA_GENERIC_ERROR.text
      }
    },
    async getMicErrorMessage(context, { error }) {
      const { name, message } = error
      if (message) {
        if (MIC_USER_PERMISSION_ERROR.messages.indexOf(message) > -1) {
          return MIC_USER_PERMISSION_ERROR.text
        } else {
          return MIC_GENERIC_ERROR.text
        }
      } else if (name) {
        if (MIC_USER_PERMISSION_ERROR.names.indexOf(name) > -1) {
          return MIC_USER_PERMISSION_ERROR.text
        } else {
          return MIC_GENERIC_ERROR.text
        }
      } else {
        return MIC_GENERIC_ERROR.text
      }
    }
  }
}

export default TestModule
