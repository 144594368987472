
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"

export default Vue.extend({
  name: "SvgIcon",
  functional: true,
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String
    },
    size: {
      type: String,
      default: "default",
      validator: val => ["default", "small", "large"].indexOf(val) !== -1
    }
  },
  render(h, { props, data }) {
    const { name, title } = props
    let icon

    try {
      icon = require(`@/assets/icons/${name}.svg`)
    } catch (e) {
      console.error(e)
    }

    if (icon !== null && icon !== undefined) {
      if (Object.prototype.hasOwnProperty.call(icon, "default")) {
        icon = icon.default
      }

      return h(
        "svg",
        mergeData(data, {
          class: [
            "svg-icon svg-icon--" + name,
            { [`svg-icon--size--${props.size}`]: props.size }
          ]
        }),
        [
          title !== undefined ? h("title", undefined, title) : null,
          h("use", {
            attrs: {
              "xlink:href": icon.url,
              "xmlns:xlink": "http://www.w3.org/1999/xlink"
            }
          })
        ]
      )
    } else {
      return null
    }
  }
})
