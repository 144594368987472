function initialize() {
  window.purechatApi = {
    l: [],
    t: [],
    on: function() {
      this.l.push(arguments)
    }
  }
  ;(function() {
    var done = false
    var script = document.createElement("script")
    script.async = true
    script.type = "text/javascript"
    script.src = "https://app.purechat.com/VisitorWidget/WidgetScript"
    script.id = "purechat-VisitorWidget-WidgetScript-scrpt"
    document
      .getElementsByTagName("HEAD")
      .item(0)
      .appendChild(script)

    /* eslint-disable */
    script.onreadystatechange = script.onload = function(e) {
      if (
        !done &&
        (!this.readyState ||
          this.readyState == "loaded" ||
          this.readyState == "complete")
      ) {
        var w = new PCWidget({
          c: "bdc2560c-00ba-44dc-beb5-c81da7507477",
          f: true
        })
        done = true
      }
    }
    //   /* eslint-enable */
  })()

  return window.purechatApi
}

function identify({ firstname, lastname, email } = {}) {
  if (email) window.purechatApi.set("visitor.email", email)
  if (firstname && lastname) window.purechatApi.set("visitor.name", `${firstname} ${lastname}`)
  if (firstname) window.purechatApi.set("visitor.firstName", firstname)
  if (lastname) window.purechatApi.set("visitor.lastName", lastname)
}

function show() {
  window.purechatApi && window.purechatApi.set('chatbox.visible', true)
}

function hide() {
  window.purechatApi && window.purechatApi.set('chatbox.visible', false)
}

export { initialize, show, hide, identify }
