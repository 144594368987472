import _ from "lodash"
import { createLocalTracks } from "twilio-video"
import { Role } from "@/helpers"
import { TwilioRoom } from "@/services/twilio.service"
import { fetchTwilioToken } from "@/services/api.service"

export const MutationTypes = {
  SET_CAMERA: "SET_CAMERA",
  SET_CAMERAS: "SET_CAMERAS",
  SET_MICROPHONE: "SET_MICROPHONE",
  SET_MICROPHONES: "SET_MICROPHONES"
}

export const ActionTypes = {
  SET_CAMERA: "SET_CAMERA",
  SET_MICROPHONE: "SET_MICROPHONE",
  CREATE_LOCAL_VIDEO_TRACK: "CREATE_LOCAL_VIDEO_TRACK",
  CREATE_LOCAL_AUDIO_TRACK: "CREATE_LOCAL_AUDIO_TRACK"
}

const _LOCAL_STORAGE_KEYS = {
  CAMERA: "camera",
  MICROPHONE: "microphone"
}

export const LIPDUB_CHANNEL_IDENTIFIER = "lipdub-channel"

const DIMENSIONS = {}

DIMENSIONS[Role.Host] = {
  width: 380,
  height: 380
}
DIMENSIONS[Role.Player] = {
  width: 256,
  height: 256
}
DIMENSIONS["default"] = {
  width: 256,
  height: 256
}

/** @type import('vuex').Module<{ cameras: MediaDeviceInfo[], camera: string | null, microphones: MediaDeviceInfo[], microphone: string | null, }, any> */
const TwilioModule = {
  namespaced: true,
  state: {
    users: {},
    error: null,
    status: "disconnected",
    room: null,
    camera: localStorage.getItem(_LOCAL_STORAGE_KEYS.CAMERA),
    cameras: [],
    microphone: localStorage.getItem(_LOCAL_STORAGE_KEYS.MICROPHONE),
    microphones: [],
    token: null
  },
  mutations: {
    UPDATE(state, { users }) {
      console.log("users", users)
      state.users = Object.keys(users).reduce((acc, val) => {
        if (users[val].audio) {
          if (!acc[val]) acc[val] = {}
          acc[val].audioTrack = users[val].audio
        }
        if (users[val].video) {
          if (!acc[val]) acc[val] = {}
          acc[val].videoTrack = users[val].video
        }
        return acc
      }, {})
    },
    UPDATE_STATUS(state, { status }) {
      state.status = status
    },
    UPDATE_TOKEN(state, { token }) {
      state.token = token
    },
    UPDATE_ROOM(state, { room }) {
      state.room = room
    },
    UPDATE_ERROR(state, { error }) {
      state.error = error
    },
    [MutationTypes.SET_CAMERA](state, id) {
      state.camera = id
      localStorage.setItem(_LOCAL_STORAGE_KEYS.CAMERA, id)
    },
    [MutationTypes.SET_CAMERAS](state, cameras) {
      state.cameras = cameras
    },
    [MutationTypes.SET_MICROPHONE](state, id) {
      state.microphone = id
      localStorage.setItem(_LOCAL_STORAGE_KEYS.MICROPHONE, id)
    },
    [MutationTypes.SET_MICROPHONES](state, microphones) {
      state.microphones = microphones
    }
  },
  actions: {
    [ActionTypes.SET_CAMERA]({ state, commit }, id) {
      const value = state.cameras.find(camera => camera.deviceId === id)
      if (value !== undefined) {
        commit(MutationTypes.SET_CAMERA, id)
      } else {
        throw new Error(`There is no camera with ${id} deviceId`)
      }
    },
    [ActionTypes.SET_MICROPHONE]({ state, commit }, id) {
      const value = state.microphones.find(
        microphone => microphone.deviceId === id
      )
      if (value !== undefined) {
        commit(MutationTypes.SET_MICROPHONE, id)
      } else {
        throw new Error(`There is no microphone with ${id} deviceId`)
      }
    },
    async [ActionTypes.CREATE_LOCAL_VIDEO_TRACK]({ state }, dimensions) {
      /** @type MediaStreamConstraints */
      const constraints = {
        video: {
          ...(dimensions || DIMENSIONS["default"])
        }
      }

      if (state.camera !== null) {
        constraints.video.deviceId = state.camera
      }

      const [track] = await createLocalTracks(constraints)

      return track
    },
    async [ActionTypes.CREATE_LOCAL_AUDIO_TRACK]({ state }) {
      /** @type MediaStreamConstraints */
      const constraints = {}

      if (state.microphone !== null) {
        constraints.audio = {
          deviceId: state.microphone
        }
      } else {
        constraints.audio = true
      }

      const [track] = await createLocalTracks(constraints)

      return track
    },
    throttledUpdate: _.throttle(({ commit }, { users }) => {
      commit("UPDATE", { users })
    }, 1000),
    update({ dispatch }, { users }) {
      dispatch("throttledUpdate", { users })
    },
    async disconnect({ commit, state }) {
      commit("UPDATE_STATUS", { status: "disconnecting" })
      if (state.room) {
        await state.room.disconnect()
        if (state.status !== "disconnected") {
          commit("UPDATE_STATUS", { status: "disconnected" })
        }
      } else {
        console.warn("Cannot disconnect from an undefined room")
        commit("UPDATE_STATUS", { status: "disconnected" })
      }
    },
    async connect(
      { state, commit, dispatch },
      {
        token,
        userID,
        roomID,
        join,
        max = 10,
        priority = "standard",
        role,
        region = "gll",
        potato = false
      }
    ) {
      if (!roomID) throw new Error("Cannot connect to an undefined room")
      commit("UPDATE_STATUS", { status: "connecting" })
      commit("UPDATE_ERROR", { error: null })
      try {
        console.log(`Connecting to room ${roomID}`)
        // initialize a class
        const room = new TwilioRoom()
        // connect to the room
        await room.connect({
          name: roomID,
          identity: userID,
          potato,
          max,
          token,
          region,
          onUpdate: async users => dispatch("throttledUpdate", { users }),
          onDisconnect: error => {
            if (error) commit("UPDATE_ERROR", { error: error.message })
            if (state.status !== "disconnected") {
              commit("UPDATE_STATUS", { status: "disconnected" })
            }
          }
        })

        // publish you camera/mic input to the room
        if (join) {
          const [videoTrack, audioTrack] = await Promise.all([
            dispatch(ActionTypes.CREATE_LOCAL_VIDEO_TRACK, DIMENSIONS[role]),
            dispatch(ActionTypes.CREATE_LOCAL_AUDIO_TRACK)
          ])
          await room.join({ priority, videoTrack, audioTrack })
        }

        commit("UPDATE_ROOM", { room })
        commit("UPDATE_STATUS", { status: "connected" })
      } catch ({ message }) {
        commit("UPDATE_ERROR", { error: message })
        commit("UPDATE", { users: {} })
        commit("UPDATE_STATUS", { status: "disconnected" })
      }
    }
  },
  getters: {
    token: state => state.token,
    error: state => state.error,
    room: state => state.room,
    users: state => state.users,
    lipdubTrack: state => state.users?.[LIPDUB_CHANNEL_IDENTIFIER]?.audioTrack,
    isBusy: state => state.status !== "disconnected",
    isConnected: state => state.status === "connected"
  }
}

export default TwilioModule
