<template>
  <v-flex class="email-login">
    <form @submit.prevent="$emit('submit', form)">
      <v-layout wrap>
        <v-flex xs6 class="px-2">
          <input
            class="signin-text-input game-login-text-input"
            v-model="form.firstname"
            type="text"
            placeholder="FIRST NAME"
            autocomplete="off"
            required
          />
        </v-flex>
        <v-flex xs6 class="px-2">
          <input
            class="signin-text-input game-login-text-input"
            v-model="form.lastname"
            type="text"
            placeholder="LAST NAME"
            autocomplete="off"
            required
          />
        </v-flex>
        <v-flex xs12 class="px-2">
          <input
            class="signin-text-input game-login-text-input"
            v-model="form.email"
            type="text"
            placeholder="EMAIL"
            autocomplete="off"
            required
          />
        </v-flex>
      </v-layout>
      <v-layout row>
        <input
          class="login-btn signin-btn anonymous-btn"
          type="submit"
          value="Join The Game"
        />
      </v-layout>
    </form>
  </v-flex>
</template>

<script>
export default {
  name: "EmailLogin",
  data: () => ({
    form: {
      firstname: null,
      lastname: null,
      email: null
    }
  })
}
</script>

<style scoped></style>
