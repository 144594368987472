import firebase from "firebase/app"
import "firebase/database"

import { fetchGames, fetchGameObjects } from "../services/game.service.js"

import * as moment from "moment"

let gameSubscriptionRef = null
let gameSubscription = null
let tournamentsSubscription = null
let tournamentsSubscriptionCallback = null

let godsHandSubscriptionRef = null
let godsHandSubscription = null

const PreGame = {
  namespaced: true,
  state: {
    usersToMap: {},
    games: {},
    tournaments: {},
    gameObjects: {},
    selectedGameID: null,
    message: null,
    userPlayedGames: {},
    ignoreLocalAssign: false
  },
  mutations: {
    UPDATE_GAMES(state, { games }) {
      console.log("GAMES ARE UPDATED")
      state.games = games
    },
    UPDATE_GAME_OBJECTS(state, { gameObjects }) {
      state.gameObjects = gameObjects
    },
    UPDATE_USERS_TO_MAP(state, { game }) {
      state.usersToMap = game
    },
    UPDATE_MESSAGE(state, { message }) {
      state.message = message
    },
    UPDATE_SELECTED_GAME_ID(state, { gameID }) {
      state.selectedGameID = gameID
    },
    UPDATE_USER_PLAYED_GAMES(state, payload) {
      state.userPlayedGames = payload || {}
    },
    UPDATE_LOCAL_ASSIGN_STATE(state, payload) {
      state.ignoreLocalAssign = payload
    },
    UPDATE_TOURNAMENTS(state, payload) {
      console.log("TOURNAMENTS ARE UPDATED", payload)
      state.tournaments = payload
    }
  },
  actions: {
    updateLocalAssignState({ commit }, payload) {
      commit("UPDATE_LOCAL_ASSIGN_STATE", payload)
    },
    updateSelectedGameID({ commit }, { gameID }) {
      commit("UPDATE_SELECTED_GAME_ID", { gameID })
    },
    updateMessage({ commit }, message) {
      commit("UPDATE_MESSAGE", { message })
    },
    subscribeToGodsHand({ commit, rootGetters }) {
      const clientID = rootGetters["auth/clientID"]
      const { id: userID } = rootGetters["auth/user"] || {}
      if (godsHandSubscriptionRef)
        godsHandSubscriptionRef.off("value", godsHandSubscription)
      commit("UPDATE_USERS_TO_MAP", { users: {} })
      godsHandSubscriptionRef = firebase
        .database()
        .ref(`client/${clientID}/playersToMap/${userID}`)
      godsHandSubscription = godsHandSubscriptionRef.on("value", snapshot => {
        commit("UPDATE_USERS_TO_MAP", { game: snapshot.val() || null })
      })
    },
    async unsubscribeFromGodsHand({ commit }) {
      if (godsHandSubscriptionRef)
        godsHandSubscriptionRef.off("value", godsHandSubscription)
      commit("UPDATE_USERS_TO_MAP", { users: {} })
    },
    unsubscribeFromClientGames() {
      if (gameSubscriptionRef) return gameSubscriptionRef.off("value")
    },
    async subscribeToClientGames({ commit, dispatch, rootGetters }) {
      const clientID = rootGetters["auth/clientID"]
      const orgID = rootGetters["orgID"]
      await dispatch("unsubscribeFromClientGames")
      gameSubscriptionRef = firebase
        .database()
        .ref(`org/${orgID}/games`)
        .orderByChild("clientID")
        .equalTo(clientID)
      return new Promise(resolve => {
        gameSubscriptionRef.on("value", snapshot => {
          commit("UPDATE_GAMES", { games: snapshot.val() })
          resolve()
        })
      })
    },
    async subscribeTournaments({ commit }, { clientID }) {
      if (tournamentsSubscription)
        tournamentsSubscription.off("value", tournamentsSubscriptionCallback)

      tournamentsSubscription = firebase
        .database()
        .ref(`/client/${clientID}/tournaments`)

      return new Promise(resolve => {
        tournamentsSubscriptionCallback = tournamentsSubscription.on(
          "value",
          snapshot => {
            commit("UPDATE_TOURNAMENTS", snapshot.val())
            resolve()
          }
        )
      })
    },
    async unsubscribeTournaments() {
      if (tournamentsSubscription)
        tournamentsSubscription.off("value", tournamentsSubscriptionCallback)
    },
    async fetchGames({ commit }, { orgID, clientID }) {
      const games = await fetchGames({ orgID, clientID })
      commit("UPDATE_GAMES", { games })
    },
    async fetchGameObjects({ commit }, { orgID, clientID }) {
      const gameObjects = await fetchGameObjects({ orgID, clientID })
      commit("UPDATE_GAME_OBJECTS", { gameObjects })
    },
    async userPlayedGame(store, { clientID, userID, gameID }) {
      await firebase
        .database()
        .ref(`client/${clientID}/usersPlayedGames/${userID}/${gameID}`)
        .set(moment().unix())
    },
    async fetchUserPlayedGames({ commit }, { clientID, userID }) {
      const snapshot = await firebase
        .database()
        .ref(`client/${clientID}/usersPlayedGames/${userID}`)
        .once("value")
      commit("UPDATE_USER_PLAYED_GAMES", snapshot.val())
    }
  },
  getters: {
    ignoreLocalAssign(state) {
      return state.ignoreLocalAssign
    },
    selectedGameID(state) {
      return state.selectedGameID
    },
    message(state) {
      return state.message
    },
    usersToMap(state) {
      return state.usersToMap
    },
    games(state) {
      return state.games
    },
    gamesArray(state) {
      const array = []
      for (let key in state.games) {
        const game = state.games[key]
        game.id = key
        array.push(game)
      }
      return array
    },
    gameObjects(state) {
      return state.gameObjects
    },
    access(state, getters, rootState, rootGetters) {
      return rootGetters["auth/access"]
    },
    userPlayedGames(state) {
      return state.userPlayedGames
    },
    tournaments(state) {
      return state.tournaments
    }
  }
}

export default PreGame
