var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "root", staticClass: "team-icon-tag" },
    [_c("v-icon", [_vm._v(" " + _vm._s(_vm.teamIcon) + " ")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }