<template>
  <transition name="social-submode-transition" mode="out-in">
    <FactMatch v-if="isFactMatch && isOtherTeamFactsOkay" />
    <v-flex
      style="color: #fff;"
      d-flex
      fill-height
      justify-center
      align-center
      v-else-if="isFactMatch && !isOtherTeamFactsOkay"
    >
      Sorry, there is not enough data to play a Fact Match mission.
    </v-flex>
    <SocialTeamUsers v-else />
  </transition>
</template>

<script>
import SocialTeamUsers from "@/components/GroupTeams/Common/GameModes/Social/TeamUsers"
import FactMatch from "@/components/GroupTeams/Common/GameModes/Social/FactMatch"

export default {
  name: "SocialMode",
  components: {
    SocialTeamUsers,
    FactMatch
  },
  computed: {
    isFactMatch() {
      return this.currentMission.behavior === "Fact Match"
    },
    isOtherTeamFactsOkay() {
      if (!this.teams) return false
      const array = Object.values(this.teams)
      if (!Array.isArray(array)) return false
      if (!array.length) return false
      const facts = array.map(({ otherTeamFacts }) => {
        if (!Array.isArray(otherTeamFacts)) return 0
        return otherTeamFacts.length
      })
      // must be at least two facts per team
      return facts.every(n => n > 1)
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    teams() {
      return this.$store.getters.chats
    }
  }
}
</script>
<style scoped>
.social-submode-transition-enter-active,
.social-submode-transition-leave-active {
  transition: all ease 0.3s;
  opacity: 1;
}
.social-submode-transition-enter-active {
  transition-delay: 0.3s;
}
.social-submode-transition-enter,
.social-submode-transition-leave-to {
  opacity: 0;
}
</style>
