var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    {
      staticClass: "popup-overlay",
      style: { visibility: _vm.isOpen ? "visible" : "hidden" },
      attrs: { "d-flex": "", "justify-center": "", "align-center": "" }
    },
    [
      _c(
        "transition",
        { attrs: { name: "popup" } },
        [
          _vm.isOpen
            ? _c(
                "v-flex",
                {
                  staticClass: "popup-content",
                  attrs: { "d-flex": "", "pa-4": "" }
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "", "justify-center": "" } },
                    [
                      !!_vm.header
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "popup-header",
                              attrs: {
                                "d-flex": "",
                                shrink: "",
                                "justify-center": ""
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.header) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "v-flex",
                        {
                          staticClass: "text-xs-center popup-text",
                          attrs: { shrink: "", "mt-2": "" }
                        },
                        [_vm._t("default")],
                        2
                      ),
                      _vm.mode === "yes-no"
                        ? [
                            _c(
                              "v-flex",
                              {
                                attrs: { shrink: "", "mt-4": "", "d-flex": "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { "justify-center": "" } },
                                  [
                                    _c("v-flex", [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "popup-button",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.onResponse({
                                                status: "yes"
                                              })
                                            }
                                          }
                                        },
                                        [_vm._v(" YES ")]
                                      )
                                    ]),
                                    _c("v-flex", [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "popup-button",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.onResponse({
                                                status: "no"
                                              })
                                            }
                                          }
                                        },
                                        [_vm._v(" NO ")]
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : _vm.mode == "okay"
                        ? [
                            _c(
                              "v-flex",
                              {
                                attrs: {
                                  shrink: "",
                                  "mt-4": "",
                                  "justify-center": ""
                                }
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "popup-button",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.onResponse({
                                          status: "okay"
                                        })
                                      }
                                    }
                                  },
                                  [_vm._v(" OK ")]
                                )
                              ]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "close-symbol",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.onClose($event)
                        }
                      }
                    },
                    [_vm._v("×")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }