import Role from "./role"

/**
 * @typedef {Object} User
 * @property {boolean} isAnonymous
 */

export class UserSorter {
  constructor() {}

  /**
   * @param { { role: "player" | "facilitator" | "audit" | "spectator" } } a
   * @param { { role: "player" | "facilitator" | "audit" | "spectator" } } b
   */
  static watchersLast(a) {
    if (a.role === Role.Audit || a.role === Role.Spectator) {
      return 1
    } else {
      return -1
    }
  }
}

export class UserFilter {
  /**
   * @param {{ teamID?: number }} user
   */
  static unassignedPredicate(user) {
    return user.gameID === undefined
  }

  /**
   * @param {{ teamID?: number }} user
   */
  static assignedPredicate(user) {
    return user.gameID !== undefined
  }

  /**
   * @param {{ role: string }} user
   */
  static playerPredicate(user) {
    return user.role === Role.Player
  }

  /**
   * @param {{ role: string }} user
   */
  static hostPredicate(user) {
    return user.role === Role.Host
  }

  /**
   * @param {{ roles: string[] }} roles
   */
  static createRolePredicate(roles) {
    /** @type {(user: { role: string }) => boolean} */
    const predicate = user => {
      return roles.includes(user.role)
    }

    return predicate
  }

  /**
   * @param {{ role: string }} user
   */
  static watchablePredicate(user) {
    return user.role === Role.Audit || user.role === Role.Spectator
  }
}

export class User {
  /**
   * @param {User} user
   * @returns {boolean}
   */
  static isAnon(user) {
    return user.isAnonymous
  }
}
