<template>
  <div @click="pause()" class="game-countdown">
    <countdown
      ref="countdown"
      :interval="1000"
      :time="endTime"
      @progress="trackProgress"
      @end="
        trackProgress({
          totalSeconds: 0,
          totalHours: 0,
          seconds: 0,
          minutes: 0
        })
      "
    />
    <div class="timer" ref="timer">
      <span>{{ time }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameCountdown",
  props: {
    endTime: {
      required: true,
      type: Number
    },
    pregame: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      time: 0,
      paused: false
    }
  },
  methods: {
    pause() {
      if (this.paused) {
        console.log("WE GONNA START", this.endTime)
        this.$refs.countdown.start()
        this.paused = false
      } else {
        this.$refs.countdown.pause()
        this.paused = true
      }
    },
    start() {
      console.log("Starting countdown from " + this.endTime)
      this.$refs.countdown.start()
    },
    reset() {
      this.$refs.timer.classList.remove("activated")
      this.$refs.timer.classList.remove("late")
    },
    trackProgress({ totalHours, minutes, seconds, totalSeconds }) {
      const node = this.$refs.timer
      let time = ""

      if (this.pregame) {
        time = `${totalHours}:${minutes}:${seconds}`
      } else {
        if (node) {
          if (totalSeconds > 0) {
            node.classList.add("activated")
            node.classList.remove("late")
          }
          if (totalSeconds <= 5) {
            if (totalSeconds == 0) {
              node.classList.remove("alert")
              if (node.classList.contains("activated")) {
                node.classList.add("late")
                node.classList.remove("activated")
              }
            } else {
              node.classList.add("alert")
            }
          } else {
            node.classList.remove("alert")
            node.classList.remove("late")
          }
        }

        if (seconds < 10) {
          time = "0" + seconds
        } else {
          time = seconds
        }

        if (minutes > 10) {
          time = minutes + ":" + time
        } else if (minutes > 0) {
          time = "0" + minutes + ":" + time
        }
      }

      this.time = time
    }
  }
}
</script>

<style lang="scss">
.game-countdown {
  .timer {
    text-align: center;
    height: 40px;
    line-height: 40px;
    font-size: 25px;
    width: 85px;
    padding: 0 2px 0 23px;
    cursor: pointer;
    color: $primary_accent_color;
    span {
      position: relative;
    }
    &::before {
      content: "";
      width: 108%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: $color-primary-dark;
      transform: skewX(40deg);
    }
    &.alert span {
      display: block;
      animation-duration: 1.25s;
      animation-iteration-count: infinite;
      animation-name: alert;
    }
    &.late span {
      color: $color-wrong;
      animation-duration: 1.2s;
      animation-name: late;
      animation-fill-mode: forwards;
    }
  }

  @keyframes alert {
    0% {
      transform: scale(1);
      color: $color-grey-light1;
    }
    50% {
      transform: scale(1.05);
      color: $color-wrong;
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes late {
    0% {
      color: $color-wrong;
    }
    7% {
      color: $color-wrong;
    }
    14% {
      color: $color-wrong;
    }
    21% {
      color: $color-wrong;
    }
    28% {
      color: $color-wrong;
    }
    35% {
      color: $color-wrong;
    }
    42% {
      color: $color-wrong;
    }
    49% {
      color: $color-white;
    }
    56% {
      color: $color-wrong;
    }
    64% {
      color: $color-wrong;
    }
    71% {
      color: $color-wrong;
    }
    78% {
      color: $color-wrong;
    }
    85% {
      color: $color-wrong;
    }
  }
}
</style>
