import firebase from "firebase/app"
import "firebase/auth"
window.firevase = firebase

const getToken = async () => firebase.auth().currentUser.getIdToken()
const getCurrentUser = () => firebase.auth().currentUser

const PERSISTENCE =
  process.env.VUE_APP_MODE && process.env.VUE_APP_MODE === "local"
    ? firebase.auth.Auth.Persistence.SESSION
    : firebase.auth.Auth.Persistence.LOCAL

const signInWithEmailAndPassword = async ({ email, password }) => {
  await firebase.auth().setPersistence(PERSISTENCE)
  let res
  try {
    res = await firebase.auth().signInWithEmailAndPassword(email, password)
  } catch (e) {
    console.log(e)
    throw new Error("The password is invalid or the user does not exist.")
  }
  return res
}

const signUpSomeoneElse = async ({ email, password }) => {
  const config = {
    apiKey:
      process.env.VUE_APP_FIREBASE_apiKey ||
      "AIzaSyC1B6ZKqESQ_Y0jzZjH44u-xGEwC4n5h4s",
    authDomain:
      process.env.VUE_APP_FIREBASE_authDomain || "chat-remote.firebaseapp.com",
    databaseURL:
      process.env.VUE_APP_FIREBASE_databaseURL ||
      "https://chat-remote.firebaseio.com",
    projectId: process.env.VUE_APP_FIREBASE_projectId || "chat-remote",
    storageBucket:
      process.env.VUE_APP_FIREBASE_storageBucket || "chat-remote.appspot.com"
  }
  const secondaryApp = firebase.initializeApp(config, "Secondary")
  const firebaseUser = await secondaryApp
    .auth()
    .createUserWithEmailAndPassword(email, password)
  console.log("firebaseUser", firebaseUser)
  console.log("User created successfully!")
  secondaryApp.auth().signOut()
  secondaryApp.delete()
  return firebaseUser
}

const signUpWithEmailAndPassword = async ({ email, password }) =>
  await firebase.auth().createUserWithEmailAndPassword(email, password)

const googleSingIn = async () => {
  await firebase.auth().setPersistence(PERSISTENCE)
  const provider = new firebase.auth.GoogleAuthProvider()
  return firebase.auth().signInWithPopup(provider)
}

const sendResetPassword = ({ email, url }) =>
  firebase.auth().sendPasswordResetEmail(email, { url })

/**
 * Verify password reset code
 * Return email or rise Invalid or expired action code error
 * @param actionCode
 * @return {Promise<string>}
 */
const verifyPasswordResetCode = actionCode =>
  firebase.auth().verifyPasswordResetCode(actionCode)

/**
 * Confirm Reset password
 * @param actionCode
 * @param newPassword
 * @return {Promise<void>}
 */
const confirmPasswordReset = (actionCode, newPassword) =>
  firebase.auth().confirmPasswordReset(actionCode, newPassword)

/**
 * Verify Email
 * @param actionCode
 * @return {Promise<void>}
 */
const applyActionCode = actionCode =>
  firebase.auth().applyActionCode(actionCode)

const sendEmailVerification = url =>
  firebase.auth().currentUser.sendEmailVerification({ url })

const reauth = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(data => {
      if (data) {
        unsubscribe()
        resolve(data)
      } else {
        unsubscribe()
        reject(new Error("Cannot reauthenticate"))
      }
    })
  })
}

const onAuthStateChanged = callback =>
  firebase.auth().onAuthStateChanged(callback)

const onIdTokenChanged = callback => firebase.auth().onIdTokenChanged(callback)

const signOut = () => firebase.auth().signOut()

const signInAnonymously = async () => {
  await firebase.auth().setPersistence(PERSISTENCE)
  return firebase.auth().signInAnonymously()
}

const userEmailInList = (emails, email) => {
  email = email || getCurrentUser().email
  emails = emails || []
  return emails.includes(email)
}

export {
  reauth,
  signOut,
  getToken,
  googleSingIn,
  sendResetPassword,
  confirmPasswordReset,
  verifyPasswordResetCode,
  applyActionCode,
  getCurrentUser,
  signInAnonymously,
  signInWithEmailAndPassword,
  signUpWithEmailAndPassword,
  sendEmailVerification,
  onAuthStateChanged,
  onIdTokenChanged,
  userEmailInList,
  signUpSomeoneElse
}
