import _ from "lodash"
import firebase from "firebase/app"
import "firebase/database"

import { getGameUsersRef } from "../services/user.service.js"

let gameUsersSubscription = null
let usersLocationSubscription = null

const GameUsers = {
  namespaced: true,
  state: {
    users: {},
    location: {}
  },
  mutations: {
    UPDATE_USERS(state, { users }) {
      state.users = users || {}
    },
    UPDATE_USERS_LOCATION(state, location) {
      state.location = location
    }
  },
  actions: {
    async pushUsersToGame({ rootGetters }, { users, gameID }) {
      const clientID = rootGetters["auth/clientID"]
      const update = {}

      users.forEach(userID => {
        update[userID] = { gameID, force: true }
      })

      await firebase
        .database()
        .ref(`/client/${clientID}/playersToMap/`)
        .transaction(() => update)

      await firebase
        .database()
        .ref(`/client/${clientID}/playersToMap/`)
        .transaction(() => null)
    },
    async unsubscribeFromGameUsers({ commit }) {
      if (gameUsersSubscription) gameUsersSubscription.off()
      commit("UPDATE_USERS", { users: {} })
    },
    async subscribeToUsersLocation({ rootGetters, dispatch }) {
      await dispatch("unsubscribeFromUsersLocation")
      const { actualGameID: gameID, orgID } = rootGetters
      if (!gameID || !orgID) return null
      usersLocationSubscription = firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/user_location`)
      usersLocationSubscription.on("value", snapshot =>
        dispatch("throttledUpdateUsersLocation", snapshot)
      )
    },
    throttledUpdateUsersLocation: _.throttle(({ commit }, snapshot) => {
      commit("UPDATE_USERS_LOCATION", snapshot.val())
    }, 1000),
    async unsubscribeFromUsersLocation() {
      if (usersLocationSubscription)
        return usersLocationSubscription.off("value")
      return null
    },
    updateUserLocation({ rootGetters }, route) {
      const { actualGameID: gameID, orgID } = rootGetters
      if (!gameID || !orgID) return null
      const { id: userID } = rootGetters["auth/user"]
      return firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/user_location/${userID}`)
        .set(route)
    },
    async subscribeToGameUsers({ commit, dispatch }, { gameID }) {
      await dispatch("unsubscribeFromGameUsers")
      gameUsersSubscription = getGameUsersRef({ gameID })
      return new Promise(resolve => {
        gameUsersSubscription.on("value", snapshot => {
          commit("UPDATE_USERS", { users: snapshot.val() })
          resolve()
        })
      })
    }
  },
  getters: {
    users(state) {
      return state.users || {}
    },
    usersArray(state, getters) {
      const array = []
      for (let key in getters.users) {
        const user = getters.users[key]
        user.id = key
        array.push(user)
      }
      return array
    },
    usersOnline(state, getters) {
      return _.pickBy(getters.users, value => value.status === "online")
    },
    usersOnlineArray(state, getters) {
      const array = []
      for (let key in getters.usersOnline) {
        const user = getters.usersOnline[key]
        user.id = key
        array.push(user)
      }
      return array
    }
  }
}

export default GameUsers
