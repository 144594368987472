<template>
  <v-layout justify-center>
    <v-flex class="volume">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div dark v-on="on">
            <v-slider
              :prepend-icon="icon"
              :min="min"
              :max="max"
              @click:prepend="toggle"
              v-model="volume"
            />
          </div>
        </template>
        <span>SFX Volume</span>
      </v-tooltip>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import _ from "lodash"

export default {
  name: "QuickVolumeTester",
  data() {
    return {
      min: 1,
      max: 100,
      volume: 100,
      current: 100
    }
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("soundeffect", { actual: "volume" }),
    icon() {
      return this.volume <= this.min ? "volume_down" : "volume_up"
    }
  },
  created() {
    this.volume = this.actual * 100
    this.current = this.actual * 100
  },
  watch: {
    volume(value) {
      if (value !== this.actual) {
        this.onVolumeChange(value)
        this.updateLocalVolume(value / 100)
      }
    }
  },
  methods: {
    ...mapActions("soundeffect", ["updateLocalVolume", "updateUserVolume"]),
    onVolumeChange: _.debounce(function (value) {
      console.log("volume", value / 100)
      this.updateUserVolume({ userID: this.user.id, volume: value / 100 })
    }, 1000),
    toggle() {
      if (this.volume > this.min) {
        this.current = this.volume
        this.volume = this.min
      } else {
        this.volume = this.current
      }
    }
  }
}
</script>

<style lang="scss">
.volume {
  padding: 0 15px;
  max-width: 150px;
  .v-input__prepend-outer {
    margin-top: 0;
    margin-right: 2px;
  }
  .v-input__icon--prepend .v-icon {
    color: $color-grey-light4;
  }
  .v-input--slider {
    margin-top: 0;

    .v-input__control {
      height: 25px;

      .v-input__slot {
        max-width: 2px 0;
      }

      .v-slider {
        height: auto;
        input {
          cursor: pointer;
        }

        .v-slider__track__container {
          height: 4px;
          border: solid 1px $primary_accent_color;
          border-radius: 4px;
          overflow: hidden;
          .v-slider__track-fill {
            height: 4px;
            background: $primary_accent_color !important;
          }
        }
        .v-slider__thumb-container:before {
          display: none;
        }
        .v-slider__thumb {
          transform: none !important;
          width: 6px;
          height: 16px;
          border: none !important;
          border-radius: 2px;
          left: -3px;
          top: -8px;
          background: $color-white !important;
          cursor: pointer;
        }
        .v-slider__thumb-label__container {
          display: none !important;
        }
      }
    }
  }
}
</style>
