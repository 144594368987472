import firebase from "firebase/app"
import "firebase/database"
import EventEmitter from "events"

export default class FirebaseSubscription {
  constructor(string) {
    console.log("FirebaseSubscription created")
    this.ref = firebase.database().ref(string)
    const ee = new EventEmitter()
    ee.once("newListener", event => {
      ee.on("removeListener", event => {
        if (event === "value" && ee.listenerCount("value") === 0) {
          this.ref.off("value", this.subscription)
          console.log("FirebaseSubscription deleted")
        }
      })
      if (event === "value") {
        this.subscription = this.ref.on("value", snapshot => {
          ee.emit("value", snapshot.val())
        })
      }
    })
    return ee
  }
}
