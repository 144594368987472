import firebase from "firebase/app"
import "firebase/database"

const fetchClient = async ({ clientID }) => {
  const snapshot = await firebase
    .database()
    .ref(`clients/${clientID}`)
    .once("value")

  return snapshot.val()
}

const fetchUsersInPresense = async ({ clientID }) => {
  const snapshot = await firebase
    .database()
    .ref(`client/${clientID}/presense`)
    .once("value")
  return snapshot.val()
}

export { fetchClient, fetchUsersInPresense }

export class ClientService {
  /**
   * @param {string} orgID
   * @param {string} themeID
   * @param {Record<string, object>} games
   */
  static async applyThemeToGames(orgID, themeID, games) {
    const ref = await firebase.database().ref(`org/${orgID}/games`)

    /** @type {Record<string, string>} */
    const update = {}

    for (const gameID in games) {
      update[`${gameID}/themeID`] = themeID
    }

    return ref.update(update)
  }

  static getPresenceRef({ clientID, userID }) {
    return firebase.database().ref(`client/${clientID}/presense/${userID}`)
  }

  static async createUserPresence({
    userID,
    clientID,
    firstname,
    lastname,
    identifier
  }) {
    const ref = ClientService.getPresenceRef({ clientID, userID })
    const snapshot = await ref.once("value")
    const data = snapshot.val()
    const now = Date.now()
    identifier = identifier || null
    if (!data) {
      // only create
      await ref.update({
        lastSeen: now,
        firstSeen: now,
        firstname,
        lastname,
        identifier
      })
    }
  }
}
