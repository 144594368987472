<template>
  <div
    v-if="currentMode != 'over'"
    style="position: relative; z-index: 2"
    class="score-table-root"
  >
    <span
      v-for="(team, i) in dataMutable"
      :key="'score-table-item-' + i"
      class="team-score-span"
      :style="isHost || isAudit || isSpectator ? 'cursor: pointer;' : ''"
    >
      <span @click="onTeamSelect(team)">
        <span v-if="showIcon" class="team-icon">
          <v-icon class="result-team-icon">
            {{ team.icon || "radio_button_unchecked" }}
          </v-icon>
        </span>
        <span class="orange-text">
          <span v-if="isHost && !showIcon">
            <span v-if="totalPlays(team.id) > 0"> ** </span>
          </span>
          {{ team.name
          }}<span class="colon" :class="{ show: teamPoints && isHost }">:</span>
        </span>
      </span>
      <transition name="fade">
        <span v-if="!!teamPoints && isHost" class="team-points">
          <span
            @click.exact="increaseScore(team)"
            @click.alt="decreaseScore(team)"
            @click.ctrl="decreaseScore(team)"
            @click.shift="toggleControls(team.id)"
          >
            {{ parseInt(team.totalScore) }}pts
          </span>
          <span class="plus-minus-wrap" v-if="!!team.controls && isHost">
            <span
              class="plus-minues-controls minus"
              @mousedown="onDown(team.id)"
              @mouseup="onRelease(team.id)"
            >
              -
            </span>
            <span
              class="plus-minues-controls plus"
              @mousedown="onUp(team.id)"
              @mouseup="onRelease(team.id)"
            >
              +
            </span>
          </span>
        </span>
      </transition>
    </span>
  </div>
</template>

<script>
import { Power2, TweenMax } from "gsap/TweenMax"
import { mapGetters } from "vuex"
export default {
  name: "ScoreTable",
  props: {
    teamIDs: Array,
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dataMutable: [],
      timer: null,
      tween: null
    }
  },
  mounted() {
    this.dataMutable = this.teamsByName.map(obj => ({
      ...obj,
      controls: false,
      totalScore: obj.totalScore || 0
    }))
  },
  watch: {
    teamsByName: {
      handler: function (array) {
        if (Array.isArray(this.dataMutable) && Array.isArray(array)) {
          // update the local name if the computed one
          // has changed
          array.forEach(({ id, name }) => {
            const found = this.dataMutable.find(team => team.id === id)
            if (!found) return
            if (found.name === name) return
            found.name = name
          })
          this.animateScore(array)
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters([
      "nOfMissionTries",
      "missionPlaysArray",
      "onlineUsersGroupedByTeam",
      "getCurrentMode",
      "team",
      "chats"
    ]),
    ...mapGetters("auth", ["isHost", "isAudit", "isSpectator"]),
    teams() {
      return this.teamIDs.map(teamID => ({ ...this.chats[teamID], id: teamID }))
    },
    teamsByName() {
      return this.teams
      /*return this.teams.sort((a, b) => {
        var nameA = a.name.toUpperCase()
        var nameB = b.name.toUpperCase()
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })*/
    },
    teamPoints() {
      return true
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    currentMode() {
      return this.getCurrentMode
    },
    plays() {
      return this.missionPlaysArray.filter(play => play.teamID === this.teamId)
    }
  },
  methods: {
    onTeamSelect(team) {
      this.$emit("reoderTeam", team)
    },
    startTimer(foo, delay = 1000) {
      this.timer = setTimeout(foo, delay)
    },
    stopTimer() {
      clearTimeout(this.timer)
    },
    totalPlays(teamID) {
      return this.missionPlaysArray.filter(play => play.teamID === teamID)
        .length
    },
    onUp(teamID) {
      // clear the timer if any
      this.stopTimer()
      const team = this.dataMutable.find(({ id }) => id === teamID)
      if (!team) return
      // +1 on click
      team.totalScore = team.totalScore + 1
      const obj = {
        totalScore: team.totalScore
      }
      // start the change of the score
      this.tween = TweenMax.to(obj, 3, {
        totalScore: team.totalScore + 99,
        ease: Power2.easeIn,
        roundProps: "totalScore",
        onUpdate: this.setScore,
        onUpdateParams: [obj, team]
      })
    },
    onDown(teamID) {
      const team = this.dataMutable.find(({ id }) => id === teamID)
      if (!team) return
      // +1 on click
      if (team.totalScore.num < 1) return
      this.stopTimer()
      team.totalScore = Math.max(0, team.totalScore - 1)
      const obj = {
        totalScore: team.totalScore
      }
      // start the change of the score
      this.tween = TweenMax.to(obj, 3, {
        totalScore: Math.max(0, team.totalScore - 99),
        ease: Power2.easeIn,
        roundProps: "totalScore",
        onUpdate: this.setScore,
        onUpdateParams: [obj, team]
      })
    },
    onRelease(teamID) {
      console.log("onRelease")
      const ON_RELEASE_DELAY = 1500
      if (this.tween) this.tween.kill()
      const team = this.dataMutable.find(({ id }) => id === teamID)
      if (!team) return
      // we give some time to change thier mind
      // about the given score
      this.startTimer(() => {
        this.stopTimer()
        team.controls = false
        // and submit as the actual result
        this.dispatchScore(teamID, team.totalScore)
      }, ON_RELEASE_DELAY)
    },
    setScore({ totalScore }, target) {
      target.totalScore = totalScore
    },
    animateScore(to) {
      this.dataMutable.forEach(object => {
        const target = to.find(obj => obj.id === object.id)
        const { totalScore } = object
        const obj = { totalScore }
        TweenMax.to(obj, 1.5, {
          totalScore: target.totalScore,
          roundProps: "totalScore",
          onUpdate: this.setScore,
          ease: Power2.easeInOut,
          onUpdateParams: [obj, object]
        })
      })
    },
    toggleControls(teamID) {
      const team = this.dataMutable.find(({ id }) => id === teamID)
      team.controls = !team.controls
    },
    dispatchScore(teamID, score) {
      console.log("dispatchScore", score)
      const obj = {}
      if (isNaN(score)) return
      if (score > 1000000 || score < 0) return
      obj.teamScore = score
      obj.teamID = teamID
      console.log(obj)
      this.$store.dispatch("setTeamScore", obj)
    },
    increaseScore(team) {
      console.log("increaseScore", team)
      if (this.isHost) {
        const obj = {}
        const num = team.totalScore + parseInt(this.currentMission.points / 2)
        console.log(num)
        if (num > 1000000) return
        if (isNaN(num)) return
        obj.teamScore = num
        obj.teamID = team.id
        this.$store.dispatch("setTeamScore", obj)
      }
    },
    decreaseScore(team) {
      if (this.isHost) {
        const obj = {}
        const num = team.totalScore - parseInt(this.currentMission.points / 2)
        if (num < 0) return
        if (isNaN(num)) return
        obj.teamScore = num
        obj.teamID = team.id
        this.$store.dispatch("setTeamScore", obj)
      }
    }
  }
}
</script>

<style lang="scss">
.score-table-root {
  user-select: none;
  line-height: 1;
  .plus-minus-wrap {
    display: inline-block;
    padding: 0 2px;
  }
  .plus-minues-controls {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $color-white;
    line-height: 12px;
    font-size: 14px;
    font-weight: bold;
    margin: 0 1px;
    &.minus {
      color: $color-wrong;
    }
    &.plus {
      color: $color-correct;
    }
  }
  .result-team-icon {
    font-size: 26px;
    line-height: 28px;
    color: $color-white !important;
  }
  .team-score-span > span {
    vertical-align: middle;
  }
  .team-score-span {
    display: inline-block;
  }
  .team-score-span:not(:last-child) {
    margin-right: 20px;
  }
  .team-icon {
    width: 28px;
    height: 28px;
    display: inline-block;
    margin-right: 3px;
    vertical-align: middle;
  }
  .orange-text {
    vertical-align: middle;
  }
  .colon {
    opacity: 0;
    &.show {
      opacity: 1;
      transition: opacity 0.25s ease-out 0.25s;
    }
  }
  .team-points {
    display: inline-block;
    padding-left: 5px;
    & > span {
      vertical-align: middle;
    }
  }
  .fade-enter-active {
    max-width: 100px;
    transition: opacity 0.25s ease-out 0.25s, max-width 0.25s ease-out;
  }
  .fade-leave-active {
    max-width: 100px;
    transition: opacity 0s, max-width 0.25s ease-out;
  }
  .fade-enter,
  .fade-leave-to {
    max-width: 0;
    opacity: 0;
  }
}
</style>
