var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      class: [
        "svg-logo",
        "svg-logo--small",
        { "svg-logo--colored": _vm.colored }
      ],
      attrs: { viewBox: "0 0 218 123" }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M238 148l-37-12 4-13-37 11 23 31 5-13 18 6a39 39 0 11-23-56 39 39 0 0115 9 2 2 0 001 0 2 2 0 002-1 72 72 0 019-15 2 2 0 000-1 2 2 0 00-1-2 62 62 0 1022 59 3 3 0 000-1 2 2 0 00-1-2z",
          transform: "translate(-117 -78)"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M294 81a61 61 0 00-77 39 59 59 0 00-3 9 2 2 0 001 1 3 3 0 001 1l37 12-5 13 37-11-22-31-5 13-18-6a39 39 0 118 47 2 2 0 00-2-1 2 2 0 00-2 2 68 68 0 01-9 15 2 2 0 000 1 2 2 0 001 2 60 60 0 0019 10 61 61 0 1039-116z",
          transform: "translate(-117 -78)"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }