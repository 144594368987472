import firebase from "firebase/app"
import "firebase/database"

const DEFAULT_MAX_PLAYERS_PER_GAME = 24

import { Role } from "../helpers"
import { getGameUsersRef } from "./user.service"
import { getCurrentUser } from "./auth.service"

const fetchGameUsers = async ({ gameID }) => {
  const snapshot = await getGameUsersRef({ gameID }).once("value")
  return snapshot.val()
}

const fetchOrgs = async () => {
  const snapshot = await firebase
    .database()
    .ref("orgs")
    .once("value")
  return snapshot.val()
}

const fetchGames = async ({ orgID, clientID }) => {
  let ref = null
  if (clientID) {
    ref = firebase
      .database()
      .ref(`org/${orgID}/games`)
      .orderByChild("clientID")
      .equalTo(clientID)
  } else {
    ref = firebase.database().ref(`org/${orgID}/games`)
  }
  const snapshot = await ref.once("value")
  return snapshot.val()
}

const fetchGameObjects = async ({ orgID, clientID }) => {
  let ref = null
  if (clientID) {
    ref = firebase
      .database()
      .ref(`org/${orgID}/game`)
      .orderByChild("clientID")
      .equalTo(clientID)
  } else {
    ref = firebase.database().ref(`org/${orgID}/game`)
  }
  const snapshot = await ref.once("value")
  return snapshot.val()
}

const fetchGameObject = async ({ orgID, gameID }) => {
  const snapshot = await firebase
    .database()
    .ref(`org/${orgID}/game/${gameID}`)
    .once("value")
  return snapshot.val()
}

const fetchGame = async ({ orgID, gameID }) => {
  const snapshot = await firebase
    .database()
    .ref(`org/${orgID}/games/${gameID}`)
    .once("value")

  return snapshot.val()
}

const fetchDetailedGameObject = async ({ orgID, gameID }) => {
  const snapshot = await firebase
    .database()
    .ref(`org/${orgID}/game/${gameID}`)
    .once("value")

  return snapshot.val()
}

const fetchGameTeamsObject = async ({ orgID, gameID }) => {
  const snapshot = await firebase
    .database()
    .ref(`org/${orgID}/game/${gameID}/teams`)
    .once("value")

  return snapshot.val()
}
/**
 *
 * @param orgID
 * @param gameID
 * @return {Promise<any>}
 */
const fetchGameTeams = async ({ orgID, gameID }) => {
  const snapshot = await firebase
    .database()
    .ref(`org/${orgID}/game/${gameID}/teams`)
    .once("value")
  return snapshot.val()
}

const canUserJoinGame = async ({ userID, gameID, orgID, role }) => {
  if (!gameID)
    throw new Error("Cannot verify a user without a destination game ID")
  if (!orgID) throw new Error("Cannot verify a user without an organization ID")
  if (!userID) throw new Error("Cannot verify a user without an ID")
  if (!role) throw new Error("Cannot verify a user without permissions")

  // console.log("gameID in canUserJoinGame", gameID)
  // console.log("orgID in canUserJoinGame", orgID)

  if (role === Role.Audit) return null
  if (role === Role.Spectator) return null

  const [game, gameUsers] = await Promise.all([
    fetchGame({ orgID, gameID }),
    fetchGameUsers({ gameID })
  ])

  // console.log("game in canUserJoinGame", game)

  if (!game)
    throw new Error(
      `Cannot find a game with ID ${gameID} in organization ${orgID}`
    )

  const values = Object.values(gameUsers || {})

  if (role === Role.Host) {
    // console.log("checking host in", values)
    const hosts = values.filter(user => {
      return (
        user.gameID === gameID &&
        user.status === "online" &&
        user.role === Role.Host
      )
    })
    hosts.sort((a, b) => {
      const bN = b.loginTimestamp || 0
      const aN = a.loginTimestamp || 0
      return bN - aN
    })
    const host = hosts[0]
    // if no host online, you are good to go
    // if (!host) console.log("no hosts online found")
    if (!host) return null
    // ask about a conflict
    const { firstname, lastname, id } = host

    if (id === userID) console.log("you are the same user")
    if (id === userID) return null

    const message = `You are about to break into ${firstname} ${lastname}'s game. You sure you want to do that?'`
    const answer = window.confirm(message)

    if (answer) {
      return null
    } else {
      throw new Error("Aborted")
    }
  }

  const online = values.filter(user => {
    return (
      user.gameID === gameID &&
      user.status === "online" &&
      user.role === Role.Player &&
      user.id !== userID
    )
  })

  const nOfGamePlayersOnline = online.length
  const maxPlayersPerGame = game.players || DEFAULT_MAX_PLAYERS_PER_GAME

  if (nOfGamePlayersOnline >= maxPlayersPerGame) {
    return `The game is full. Only ${maxPlayersPerGame} player(s) allowed`
  } else {
    return null
  }
}

const fetchGamesByRunStatus = async ({ orgID, value }) => {
  const snapshot = await firebase
    .database()
    .ref(`org/${orgID}/games`)
    .orderByChild("runStatus")
    .equalTo(value)
    .once("value")

  return snapshot.val()
}

const fetchMissions = async ({ orgID, gameID }) => {
  const snapshot = await firebase
    .database()
    .ref(`org/${orgID}/game/${gameID}/missions`)
    .once("value")
  return snapshot.val()
}

const isGamePlayedByUser = ({ originalGameID, theKey, userPlayedGames }) => {
  const gameID = originalGameID || theKey
  return !!userPlayedGames && !!userPlayedGames[gameID]
}

const getGameIDByEmailMapping = async ({
  orgID,
  clientID,
  userPlayedGames
}) => {
  const user = getCurrentUser()
  if (!user) return null
  const email = user.email
  if (!email) return null
  const userEmail = email.toLowerCase()
  const games = await fetchGames({ orgID, clientID })
  const game = Object.values(games).find(
    item =>
      !item.deletedTimestamp &&
      !item.deactivate &&
      !item.ondeck &&
      !item.endTimestamp &&
      item.matchEmails &&
      item.matchEmails.indexOf(userEmail) >= 0 &&
      !isGamePlayedByUser({ ...item, userPlayedGames })
  )
  if (game) return game.theKey || game.id
  return null
}

const overrideGameTeams = async (array, gameID, orgID) => {
  // array: [{ id: ..., name: ... }, { id: ..., name: ... }, { id: ..., name: ... }]
  const { teams } = await fetchGameObject({ orgID, gameID })
  const update = Object.values(teams)
    .map((team, i) => ({ ...team, id: array[i].id, name: array[i].name }))
    .reduce((acc, val) => {
      acc[val.id] = val
      return acc
    }, {})

  await firebase
    .database()
    .ref(`org/${orgID}/game/${gameID}/teams`)
    .set(update)
}

export {
  fetchOrgs,
  canUserJoinGame,
  fetchGame,
  fetchGames,
  fetchGamesByRunStatus,
  fetchGameObjects,
  fetchGameUsers,
  fetchGameObject,
  fetchMissions,
  fetchGameTeams,
  getGameIDByEmailMapping,
  isGamePlayedByUser,
  overrideGameTeams,
  fetchDetailedGameObject,
  fetchGameTeamsObject
}
