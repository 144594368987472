var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    {
      ref: "root",
      staticClass: "social-fact-match",
      class: { compress: _vm.isHost },
      attrs: { "d-flex": "", "fill-height": "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "justify-center": "", "fill-height": "" } },
        [
          _c(
            "v-flex",
            { staticClass: "fact-match-player-row", attrs: { shrink: "" } },
            [
              _c(
                "transition-group",
                {
                  staticClass: "social-player-transition-wrap",
                  attrs: { name: "social-player-transition", tag: "div" }
                },
                _vm._l(_vm.players, function(player) {
                  return _c("FactMatchPlayer", {
                    key: "fact-match-player-" + player.id,
                    staticClass: "fact-match-player-col",
                    attrs: {
                      userInfo: _vm.getUserInfo(player.id),
                      teamIcon: _vm.getTeam(player.id).icon,
                      userID: player.id,
                      imageOnly: _vm.photoFacts,
                      imageURL: _vm.imageFact(player)
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { class: { "no-transition": _vm.target }, attrs: { shrink: "" } },
            [
              _c(
                "draggable",
                _vm._b(
                  {
                    staticClass: "fact-match-fact-row",
                    attrs: { tag: "div", disabled: _vm.isDragAndDropDisabled },
                    on: {
                      change: function($event) {
                        return _vm.onUpdate()
                      }
                    },
                    model: {
                      value: _vm.facts,
                      callback: function($$v) {
                        _vm.facts = $$v
                      },
                      expression: "facts"
                    }
                  },
                  "draggable",
                  { ghostClass: "ghost-class" },
                  false
                ),
                [
                  _c(
                    "transition-group",
                    {
                      staticClass: "flex d-flex justify-center",
                      attrs: { tag: "div", name: "fact-transition" }
                    },
                    _vm._l(_vm.facts, function(item) {
                      return _c(
                        "v-flex",
                        {
                          key: item.id,
                          staticClass: "fact-match-fact",
                          class: {
                            selected: item.id === _vm.target,
                            disabled: _vm.isDragAndDropDisabled
                          },
                          attrs: { "d-flex": "" },
                          on: {
                            mousedown: function($event) {
                              return _vm.onMouseDown(item.id)
                            },
                            mouseup: function($event) {
                              return _vm.onMouseDown(null)
                            },
                            dragend: function($event) {
                              return _vm.onMouseDown(null)
                            }
                          }
                        },
                        [
                          !_vm.photoFacts
                            ? _c(
                                "v-flex",
                                { staticStyle: { "align-self": "center" } },
                                [_vm._v(" " + _vm._s(item.fact.correct) + " ")]
                              )
                            : _c(
                                "v-flex",
                                { staticStyle: { "align-self": "center" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(item.fact.firstName) + " "
                                  )
                                ]
                              )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "transition",
            { attrs: { name: "collapse-transition", mode: "out-in" } },
            [
              _vm.showSubmit
                ? _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "fact-match-submit child",
                          on: {
                            click: function($event) {
                              return _vm.onSubmit()
                            }
                          }
                        },
                        [_vm._v(" Submit ")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showCursor,
            expression: "showCursor"
          }
        ],
        ref: "cursor",
        staticClass: "cursor-image",
        attrs: { src: require("../../../../../assets/hand-cursor-image.svg") }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }