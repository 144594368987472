var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "signin-page",
      attrs: { "pa-0": "", "fill-height": "", fluid: "" }
    },
    [
      _vm.ready
        ? _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c(
                "v-flex",
                {
                  staticClass: "signin-header",
                  attrs: { "text-xs-center": "", shrink: "" }
                },
                [
                  _c("img", {
                    staticClass: "rtb-logo-image",
                    attrs: { src: _vm.$theme.getImage("logo"), alt: "Logo" }
                  }),
                  _c("div", { staticClass: "header-shape" }, [
                    _c("div", { staticClass: "left-shape" }),
                    _c("div", { staticClass: "right-shape" }),
                    _c("div", { staticClass: "center-shape" }),
                    _c(
                      "div",
                      {
                        staticClass: "tagline",
                        style: "color: #" + _vm.taglineColor + " !important"
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$theme.getMeta("tagline")) + " "
                        )
                      ]
                    )
                  ])
                ]
              ),
              _c(
                "v-flex",
                {
                  staticClass: "carousel-wrap",
                  staticStyle: {
                    height: "100%",
                    "max-height": "30%",
                    "min-height": "160px"
                  },
                  attrs: { "d-flex": "" }
                },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "header-message",
                      attrs: { shrink: "", "text-xs-center": "" }
                    },
                    [
                      _vm.title !== null && _vm.title !== undefined
                        ? _c("h1", [_vm._v(_vm._s(_vm.title))])
                        : !!_vm.id
                        ? _c("v-progress-circular", {
                            staticClass: "spinner",
                            attrs: { indeterminate: "" }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("SigninCarousel", {
                    staticClass: "carousel",
                    attrs: { images: _vm.images }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "box-wrap", attrs: { "d-flex": "" } },
                [
                  _c(
                    "RtbCard",
                    { staticClass: "signin-rtb-card" },
                    [
                      _c(
                        "v-layout",
                        {
                          staticStyle: { "align-self": "center" },
                          attrs: { column: "" }
                        },
                        [
                          _vm.loading
                            ? _c(
                                "v-layout",
                                { staticClass: "spinner-container" },
                                [
                                  _c("v-progress-circular", {
                                    staticClass: "spinner",
                                    attrs: { indeterminate: "" }
                                  })
                                ],
                                1
                              )
                            : !!_vm.error
                            ? _c(
                                "v-layout",
                                {
                                  staticClass: "error-container",
                                  attrs: { column: "" }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "error-message",
                                      attrs: {
                                        "d-flex": "",
                                        "justify-center": "",
                                        "align-center": ""
                                      }
                                    },
                                    [
                                      _vm.emailIsNotVerified
                                        ? _c("div", [
                                            _vm._v(
                                              " We have sent an email with a confirmation link to verify your email address."
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " If you do not receive it, please check your spam folder or "
                                            ),
                                            _c(
                                              "a",
                                              {
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.onResendEmailVerification(
                                                      $event
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("Click here")]
                                            ),
                                            _vm._v(" for resending the link ")
                                          ])
                                        : [
                                            _vm._v(
                                              " " + _vm._s(_vm.error) + " "
                                            )
                                          ]
                                    ],
                                    2
                                  ),
                                  _vm.mode !== "dead-end"
                                    ? _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            shrink: "",
                                            "d-flex": "",
                                            "justify-center": "",
                                            "align-center": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "error-ok",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.onOkay($event)
                                                }
                                              }
                                            },
                                            [_vm._v(" OK ")]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : [
                                _c(
                                  "transition",
                                  { attrs: { name: "fade", mode: "out-in" } },
                                  [
                                    _vm.mode === _vm.modes.EmailAndNames
                                      ? _c("EmailLogin", {
                                          staticClass: "login-fields",
                                          on: { submit: _vm.onEmailSignIn }
                                        })
                                      : _vm._e(),
                                    _vm.mode == "login"
                                      ? _c(
                                          "v-flex",
                                          { staticClass: "login-fields" },
                                          [
                                            _c(
                                              "form",
                                              {
                                                on: {
                                                  submit: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.onSignin($event)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-layout",
                                                  { attrs: { column: "" } },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: _vm.email,
                                                          expression: "email"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "signin-text-input game-login-text-input",
                                                      attrs: {
                                                        type: "email",
                                                        placeholder: "EMAIL",
                                                        required: ""
                                                      },
                                                      domProps: {
                                                        value: _vm.email
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.email =
                                                            $event.target.value
                                                        }
                                                      }
                                                    }),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: _vm.password,
                                                          expression: "password"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "signin-text-input game-login-text-input",
                                                      attrs: {
                                                        type: "password",
                                                        placeholder: "PASSWORD",
                                                        required: ""
                                                      },
                                                      domProps: {
                                                        value: _vm.password
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.password =
                                                            $event.target.value
                                                        }
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "v-layout",
                                                  {
                                                    staticClass: "buttons",
                                                    attrs: { row: "" }
                                                  },
                                                  [
                                                    _c("GoogleButton", {
                                                      on: {
                                                        click:
                                                          _vm.signInWithGoogle
                                                      }
                                                    }),
                                                    _c("input", {
                                                      staticClass:
                                                        "login-btn signin-btn",
                                                      attrs: {
                                                        type: "submit",
                                                        value: "Join The Game"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            !!_vm.id
                                              ? _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "forgot-message",
                                                    attrs: {
                                                      shrink: "",
                                                      "text-xs-right": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass: "forgot",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.mode = "forgot"
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Forgot Password?"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            !!_vm.id
                                              ? _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "register-here-message",
                                                    attrs: {
                                                      shrink: "",
                                                      "text-xs-center": ""
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Don't have an account? "
                                                    ),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "register-here",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.mode =
                                                              "register"
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("Register Here")]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm.mode == "register"
                                      ? _c(
                                          "v-card",
                                          { staticClass: "register-fields" },
                                          [
                                            _c(
                                              "form",
                                              {
                                                attrs: {
                                                  id: "register-form",
                                                  autocomplete: "off"
                                                },
                                                on: {
                                                  submit: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.onSignup($event)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-layout",
                                                  { attrs: { wrap: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs6: "" } },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value: _vm.email,
                                                              expression:
                                                                "email"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "signin-text-input game-login-text-input",
                                                          attrs: {
                                                            type: "email",
                                                            placeholder:
                                                              "EMAIL",
                                                            autocomplete: "off",
                                                            required: ""
                                                          },
                                                          domProps: {
                                                            value: _vm.email
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.email =
                                                                $event.target.value
                                                            }
                                                          }
                                                        })
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs6: "" } },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.username,
                                                              expression:
                                                                "username"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "signin-text-input game-login-text-input",
                                                          attrs: {
                                                            type: "text",
                                                            placeholder:
                                                              "USERNAME",
                                                            autocomplete: "off",
                                                            required: ""
                                                          },
                                                          domProps: {
                                                            value: _vm.username
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.username =
                                                                $event.target.value
                                                            }
                                                          }
                                                        })
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs6: "" } },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.firstname,
                                                              expression:
                                                                "firstname"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "signin-text-input game-login-text-input",
                                                          attrs: {
                                                            type: "text",
                                                            placeholder:
                                                              "FIRST NAME",
                                                            autocomplete: "off",
                                                            required: ""
                                                          },
                                                          domProps: {
                                                            value: _vm.firstname
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.firstname =
                                                                $event.target.value
                                                            }
                                                          }
                                                        })
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs6: "" } },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.lastname,
                                                              expression:
                                                                "lastname"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "signin-text-input game-login-text-input",
                                                          attrs: {
                                                            type: "text",
                                                            placeholder:
                                                              "LAST NAME",
                                                            autocomplete: "off",
                                                            required: ""
                                                          },
                                                          domProps: {
                                                            value: _vm.lastname
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.lastname =
                                                                $event.target.value
                                                            }
                                                          }
                                                        })
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs6: "" } },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.password,
                                                              expression:
                                                                "password"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "signin-text-input game-login-text-input",
                                                          attrs: {
                                                            autocomplete:
                                                              "new-password",
                                                            type: "password",
                                                            placeholder:
                                                              "PASSWORD",
                                                            required: "",
                                                            minlength: "8"
                                                          },
                                                          domProps: {
                                                            value: _vm.password
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.password =
                                                                $event.target.value
                                                            }
                                                          }
                                                        })
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs6: "" } },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.confirmPassword,
                                                              expression:
                                                                "confirmPassword"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "signin-text-input game-login-text-input",
                                                          attrs: {
                                                            autocomplete:
                                                              "new-password",
                                                            type: "password",
                                                            placeholder:
                                                              "CONFIRM PASSWORD",
                                                            required: "",
                                                            minlength: "8"
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.confirmPassword
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.confirmPassword =
                                                                $event.target.value
                                                            }
                                                          }
                                                        })
                                                      ]
                                                    ),
                                                    false
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "text-xs-center text-sm-center text-md-center text-lg-center",
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "image-uploader",
                                                              {
                                                                attrs: {
                                                                  debug: 1,
                                                                  "max-width": 200,
                                                                  quality: 0.7,
                                                                  "auto-rotate": true,
                                                                  preview: true,
                                                                  "class-name": [
                                                                    "fileinput",
                                                                    {
                                                                      "fileinput--loaded": !!_vm.imageUrl
                                                                    }
                                                                  ],
                                                                  "output-format":
                                                                    "verbose",
                                                                  capture:
                                                                    "environment",
                                                                  accept:
                                                                    ".jpg,.png,.jpeg"
                                                                },
                                                                on: {
                                                                  input:
                                                                    _vm.setImage
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    ref:
                                                                      "fileGrabber",
                                                                    attrs: {
                                                                      slot:
                                                                        "upload-label",
                                                                      for:
                                                                        "fileInput"
                                                                    },
                                                                    slot:
                                                                      "upload-label"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "figure",
                                                                      [
                                                                        _c(
                                                                          "svg",
                                                                          {
                                                                            attrs: {
                                                                              xmlns:
                                                                                "http://www.w3.org/2000/svg",
                                                                              width:
                                                                                "32",
                                                                              height:
                                                                                "32",
                                                                              viewBox:
                                                                                "0 0 32 32"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "path",
                                                                              {
                                                                                staticClass:
                                                                                  "path1",
                                                                                attrs: {
                                                                                  d:
                                                                                    "M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "upload-caption"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            !!_vm.imageUrl
                                                                              ? "Replace Image"
                                                                              : "Upload Profile Pic"
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  {
                                                    staticClass: "buttons",
                                                    attrs: { row: "" }
                                                  },
                                                  [
                                                    _c("GoogleButton", {
                                                      on: {
                                                        click:
                                                          _vm.signInWithGoogle
                                                      }
                                                    }),
                                                    _c("input", {
                                                      staticClass:
                                                        "login-btn signin-btn",
                                                      attrs: {
                                                        type: "submit",
                                                        value: "Register"
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.checkRegisterFields
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass: "forgot-message",
                                                attrs: {
                                                  shrink: "",
                                                  "text-xs-right": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "forgot",
                                                    on: {
                                                      click: function($event) {
                                                        _vm.mode = "forgot"
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("Forgot Password?")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass:
                                                  "login-here-message",
                                                attrs: {
                                                  shrink: "",
                                                  "text-xs-center": ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " Already have an account? "
                                                ),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "register-here",
                                                    on: {
                                                      click: function($event) {
                                                        _vm.mode = "login"
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("Login here")]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm.mode == "forgot"
                                      ? _c(
                                          "v-card",
                                          { staticClass: "forgot-fields" },
                                          [
                                            _c(
                                              "form",
                                              {
                                                attrs: { id: "forgot-form" },
                                                on: {
                                                  submit: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.onForgot($event)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-layout",
                                                  { attrs: { wrap: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        attrs: {
                                                          layout: "",
                                                          column: ""
                                                        }
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value: _vm.email,
                                                              expression:
                                                                "email"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "signin-text-input game-login-text-input",
                                                          attrs: {
                                                            type: "email",
                                                            placeholder:
                                                              "EMAIL",
                                                            required: ""
                                                          },
                                                          domProps: {
                                                            value: _vm.email
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.email =
                                                                $event.target.value
                                                            }
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  {
                                                    staticClass: "buttons",
                                                    attrs: { row: "" }
                                                  },
                                                  [
                                                    _c("GoogleButton", {
                                                      on: {
                                                        click:
                                                          _vm.signInWithGoogle
                                                      }
                                                    }),
                                                    _c("input", {
                                                      staticClass:
                                                        "login-btn signin-btn",
                                                      attrs: {
                                                        type: "submit",
                                                        value: "Forgot"
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.checkForgotFields
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass:
                                                  "login-here-message",
                                                attrs: {
                                                  shrink: "",
                                                  "text-xs-center": ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " Already have an account? "
                                                ),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "register-here",
                                                    on: {
                                                      click: function($event) {
                                                        _vm.mode = "login"
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("Login here")]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm.mode == "anonymous"
                                      ? _c("v-card", [
                                          _c(
                                            "form",
                                            {
                                              on: {
                                                submit: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.signInUserAnonymously(
                                                    $event
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-layout",
                                                { attrs: { column: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "center",
                                                        "margin-bottom": "10px"
                                                      }
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          " Enter your first and last name to enter"
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm.customInputType ===
                                                  "select"
                                                    ? [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              "d-flex": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  row: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      "d-flex":
                                                                        "",
                                                                      xs8: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-layout",
                                                                      {
                                                                        attrs: {
                                                                          column:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-flex",
                                                                          [
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives: [
                                                                                  {
                                                                                    name:
                                                                                      "model",
                                                                                    rawName:
                                                                                      "v-model",
                                                                                    value:
                                                                                      _vm.firstname,
                                                                                    expression:
                                                                                      "firstname"
                                                                                  }
                                                                                ],
                                                                                staticClass:
                                                                                  "signin-text-input game-login-text-input",
                                                                                attrs: {
                                                                                  type:
                                                                                    "text",
                                                                                  placeholder:
                                                                                    "First Name",
                                                                                  required:
                                                                                    ""
                                                                                },
                                                                                domProps: {
                                                                                  value:
                                                                                    _vm.firstname
                                                                                },
                                                                                on: {
                                                                                  input: function(
                                                                                    $event
                                                                                  ) {
                                                                                    if (
                                                                                      $event
                                                                                        .target
                                                                                        .composing
                                                                                    ) {
                                                                                      return
                                                                                    }
                                                                                    _vm.firstname =
                                                                                      $event.target.value
                                                                                  }
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-flex",
                                                                          [
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives: [
                                                                                  {
                                                                                    name:
                                                                                      "model",
                                                                                    rawName:
                                                                                      "v-model",
                                                                                    value:
                                                                                      _vm.lastname,
                                                                                    expression:
                                                                                      "lastname"
                                                                                  }
                                                                                ],
                                                                                staticClass:
                                                                                  "signin-text-input game-login-text-input",
                                                                                attrs: {
                                                                                  type:
                                                                                    "text",
                                                                                  placeholder:
                                                                                    "Last Name",
                                                                                  required:
                                                                                    ""
                                                                                },
                                                                                domProps: {
                                                                                  value:
                                                                                    _vm.lastname
                                                                                },
                                                                                on: {
                                                                                  input: function(
                                                                                    $event
                                                                                  ) {
                                                                                    if (
                                                                                      $event
                                                                                        .target
                                                                                        .composing
                                                                                    ) {
                                                                                      return
                                                                                    }
                                                                                    _vm.lastname =
                                                                                      $event.target.value
                                                                                  }
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    staticClass:
                                                                      "game-login-dropdown",
                                                                    attrs: {
                                                                      "d-flex":
                                                                        "",
                                                                      xs4: "",
                                                                      "ml-2": ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-select",
                                                                      {
                                                                        attrs: {
                                                                          items:
                                                                            _vm.customInputOptions,
                                                                          label:
                                                                            _vm.customInputLabel,
                                                                          "item-text":
                                                                            "label",
                                                                          "item-value":
                                                                            "value",
                                                                          "background-color":
                                                                            "#e1e1e1",
                                                                          dense:
                                                                            "",
                                                                          "single-line":
                                                                            "",
                                                                          "loader-height":
                                                                            "40",
                                                                          color:
                                                                            "#413d3c",
                                                                          required:
                                                                            ""
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.customInput,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.customInput = $$v
                                                                          },
                                                                          expression:
                                                                            "customInput"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    : _vm.customInputType ===
                                                      "text"
                                                    ? [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              "d-flex": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  row: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      "d-flex":
                                                                        "",
                                                                      xs8: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-layout",
                                                                      {
                                                                        attrs: {
                                                                          column:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-flex",
                                                                          [
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives: [
                                                                                  {
                                                                                    name:
                                                                                      "model",
                                                                                    rawName:
                                                                                      "v-model",
                                                                                    value:
                                                                                      _vm.firstname,
                                                                                    expression:
                                                                                      "firstname"
                                                                                  }
                                                                                ],
                                                                                staticClass:
                                                                                  "signin-text-input game-login-text-input",
                                                                                attrs: {
                                                                                  type:
                                                                                    "text",
                                                                                  placeholder:
                                                                                    "First Name",
                                                                                  required:
                                                                                    ""
                                                                                },
                                                                                domProps: {
                                                                                  value:
                                                                                    _vm.firstname
                                                                                },
                                                                                on: {
                                                                                  input: function(
                                                                                    $event
                                                                                  ) {
                                                                                    if (
                                                                                      $event
                                                                                        .target
                                                                                        .composing
                                                                                    ) {
                                                                                      return
                                                                                    }
                                                                                    _vm.firstname =
                                                                                      $event.target.value
                                                                                  }
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-flex",
                                                                          [
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives: [
                                                                                  {
                                                                                    name:
                                                                                      "model",
                                                                                    rawName:
                                                                                      "v-model",
                                                                                    value:
                                                                                      _vm.lastname,
                                                                                    expression:
                                                                                      "lastname"
                                                                                  }
                                                                                ],
                                                                                staticClass:
                                                                                  "signin-text-input game-login-text-input",
                                                                                attrs: {
                                                                                  type:
                                                                                    "text",
                                                                                  placeholder:
                                                                                    "Last Name",
                                                                                  required:
                                                                                    ""
                                                                                },
                                                                                domProps: {
                                                                                  value:
                                                                                    _vm.lastname
                                                                                },
                                                                                on: {
                                                                                  input: function(
                                                                                    $event
                                                                                  ) {
                                                                                    if (
                                                                                      $event
                                                                                        .target
                                                                                        .composing
                                                                                    ) {
                                                                                      return
                                                                                    }
                                                                                    _vm.lastname =
                                                                                      $event.target.value
                                                                                  }
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      "d-flex":
                                                                        "",
                                                                      xs4: "",
                                                                      "ml-2": ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              _vm.customInput,
                                                                            expression:
                                                                              "customInput"
                                                                          }
                                                                        ],
                                                                        staticClass:
                                                                          "signin-text-input game-login-text-input",
                                                                        attrs: {
                                                                          type:
                                                                            "text",
                                                                          placeholder:
                                                                            _vm.customInputLabel
                                                                        },
                                                                        domProps: {
                                                                          value:
                                                                            _vm.customInput
                                                                        },
                                                                        on: {
                                                                          input: function(
                                                                            $event
                                                                          ) {
                                                                            if (
                                                                              $event
                                                                                .target
                                                                                .composing
                                                                            ) {
                                                                              return
                                                                            }
                                                                            _vm.customInput =
                                                                              $event.target.value
                                                                          }
                                                                        }
                                                                      }
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    : _vm.customInputType ===
                                                      "password"
                                                    ? [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              "d-flex": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  row: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      "d-flex":
                                                                        "",
                                                                      xs8: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-layout",
                                                                      {
                                                                        attrs: {
                                                                          column:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-flex",
                                                                          [
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives: [
                                                                                  {
                                                                                    name:
                                                                                      "model",
                                                                                    rawName:
                                                                                      "v-model",
                                                                                    value:
                                                                                      _vm.firstname,
                                                                                    expression:
                                                                                      "firstname"
                                                                                  }
                                                                                ],
                                                                                staticClass:
                                                                                  "signin-text-input game-login-text-input",
                                                                                attrs: {
                                                                                  type:
                                                                                    "text",
                                                                                  placeholder:
                                                                                    "First Name",
                                                                                  required:
                                                                                    ""
                                                                                },
                                                                                domProps: {
                                                                                  value:
                                                                                    _vm.firstname
                                                                                },
                                                                                on: {
                                                                                  input: function(
                                                                                    $event
                                                                                  ) {
                                                                                    if (
                                                                                      $event
                                                                                        .target
                                                                                        .composing
                                                                                    ) {
                                                                                      return
                                                                                    }
                                                                                    _vm.firstname =
                                                                                      $event.target.value
                                                                                  }
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-flex",
                                                                          [
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives: [
                                                                                  {
                                                                                    name:
                                                                                      "model",
                                                                                    rawName:
                                                                                      "v-model",
                                                                                    value:
                                                                                      _vm.lastname,
                                                                                    expression:
                                                                                      "lastname"
                                                                                  }
                                                                                ],
                                                                                staticClass:
                                                                                  "signin-text-input game-login-text-input",
                                                                                attrs: {
                                                                                  type:
                                                                                    "text",
                                                                                  placeholder:
                                                                                    "Last Name",
                                                                                  required:
                                                                                    ""
                                                                                },
                                                                                domProps: {
                                                                                  value:
                                                                                    _vm.lastname
                                                                                },
                                                                                on: {
                                                                                  input: function(
                                                                                    $event
                                                                                  ) {
                                                                                    if (
                                                                                      $event
                                                                                        .target
                                                                                        .composing
                                                                                    ) {
                                                                                      return
                                                                                    }
                                                                                    _vm.lastname =
                                                                                      $event.target.value
                                                                                  }
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      "d-flex":
                                                                        "",
                                                                      xs4: "",
                                                                      "ml-2": ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              _vm.customInput,
                                                                            expression:
                                                                              "customInput"
                                                                          }
                                                                        ],
                                                                        staticClass:
                                                                          "signin-text-input game-login-text-input",
                                                                        attrs: {
                                                                          type:
                                                                            "password",
                                                                          autocomplete:
                                                                            "new-password",
                                                                          placeholder:
                                                                            _vm.customInputLabel
                                                                        },
                                                                        domProps: {
                                                                          value:
                                                                            _vm.customInput
                                                                        },
                                                                        on: {
                                                                          input: function(
                                                                            $event
                                                                          ) {
                                                                            if (
                                                                              $event
                                                                                .target
                                                                                .composing
                                                                            ) {
                                                                              return
                                                                            }
                                                                            _vm.customInput =
                                                                              $event.target.value
                                                                          }
                                                                        }
                                                                      }
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    : [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.firstname,
                                                              expression:
                                                                "firstname"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "signin-text-input game-login-text-input",
                                                          attrs: {
                                                            type: "text",
                                                            placeholder:
                                                              "First Name",
                                                            required: ""
                                                          },
                                                          domProps: {
                                                            value: _vm.firstname
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.firstname =
                                                                $event.target.value
                                                            }
                                                          }
                                                        }),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.lastname,
                                                              expression:
                                                                "lastname"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "signin-text-input game-login-text-input",
                                                          attrs: {
                                                            type: "text",
                                                            placeholder:
                                                              "Last Name",
                                                            required: ""
                                                          },
                                                          domProps: {
                                                            value: _vm.lastname
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.lastname =
                                                                $event.target.value
                                                            }
                                                          }
                                                        })
                                                      ]
                                                ],
                                                2
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c("input", {
                                                    staticClass:
                                                      "login-btn signin-btn anonymous-btn",
                                                    attrs: {
                                                      type: "submit",
                                                      value: "Join The Game"
                                                    }
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      : _vm.mode == "anonymous-password"
                                      ? _c("v-card", [
                                          _c(
                                            "form",
                                            {
                                              on: {
                                                submit: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.signInUserAnonymouslyWithPassword(
                                                    $event
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-layout",
                                                { attrs: { column: "" } },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.firstname,
                                                        expression: "firstname"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "signin-text-input",
                                                    attrs: {
                                                      type: "text",
                                                      placeholder: "First Name",
                                                      required: ""
                                                    },
                                                    domProps: {
                                                      value: _vm.firstname
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.firstname =
                                                          $event.target.value
                                                      }
                                                    }
                                                  }),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.lastname,
                                                        expression: "lastname"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "signin-text-input",
                                                    attrs: {
                                                      type: "text",
                                                      placeholder: "Last Name",
                                                      required: ""
                                                    },
                                                    domProps: {
                                                      value: _vm.lastname
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.lastname =
                                                          $event.target.value
                                                      }
                                                    }
                                                  }),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.password,
                                                        expression: "password"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "signin-text-input",
                                                    attrs: {
                                                      autocomplete: "password",
                                                      type: "password",
                                                      placeholder: "PASSWORD",
                                                      required: ""
                                                    },
                                                    domProps: {
                                                      value: _vm.password
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.password =
                                                          $event.target.value
                                                      }
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c("input", {
                                                    staticClass:
                                                      "login-btn signin-btn anonymous-btn",
                                                    attrs: {
                                                      type: "submit",
                                                      value: "Join The Game"
                                                    }
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      : _vm.mode == "reset-pass-status"
                                      ? _c(
                                          "v-card",
                                          { staticClass: "passRestStatus" },
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass:
                                                  "login-here-message",
                                                attrs: {
                                                  shrink: "",
                                                  "text-xs-center": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "text" },
                                                  [
                                                    _vm._v(
                                                      " Your Password is reset. Please check your email. "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass:
                                                  "login-here-message",
                                                attrs: {
                                                  shrink: "",
                                                  "text-xs-center": ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " Already have an account? "
                                                ),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "register-here",
                                                    on: {
                                                      click: function($event) {
                                                        _vm.mode = "login"
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("Login here")]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm.mode == "reset-password"
                                      ? _c("v-card", [
                                          _c(
                                            "form",
                                            {
                                              on: {
                                                submit: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.onResetPassword(
                                                    $event
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-layout",
                                                { attrs: { column: "" } },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.password,
                                                        expression: "password"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "signin-text-input",
                                                    attrs: {
                                                      type: "password",
                                                      placeholder:
                                                        "New password",
                                                      required: "",
                                                      minlength: "8"
                                                    },
                                                    domProps: {
                                                      value: _vm.password
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.password =
                                                          $event.target.value
                                                      }
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c("input", {
                                                    staticClass:
                                                      "login-btn signin-btn anonymous-btn",
                                                    attrs: {
                                                      type: "submit",
                                                      value: "Reset password"
                                                    }
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      : _vm.mode == "verify-email"
                                      ? _c("v-card", [
                                          _c(
                                            "form",
                                            {
                                              on: {
                                                submit: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.onVerifyEmailContinue(
                                                    $event
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  staticClass: "text-message",
                                                  attrs: { column: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        "text-xs-center": ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Your email has been verified "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        "text-xs-center": ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " You can now sign in with your new account "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c("input", {
                                                    staticClass:
                                                      "login-btn signin-btn anonymous-btn",
                                                    attrs: {
                                                      type: "submit",
                                                      value: "Continue"
                                                    }
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.$theme.getMeta("hideGoGame")
                ? _c("v-flex", { staticClass: "start-your-own" }, [
                    _c(
                      "a",
                      {
                        staticClass: "gogame-url",
                        attrs: {
                          target: "_blank",
                          href:
                            "https://www.thegogame.com/team-building/remote-game?hsCtaTracking=c99293dc-e833-4ae1-a2c5-74496e764663%7C44e1ced1-c732-4506-bca7-f26ef4eaaf93"
                        }
                      },
                      [
                        _c("img", {
                          attrs: { src: require("@/assets/gogame-link.png") }
                        })
                      ]
                    )
                  ])
                : _vm._e(),
              _c(
                "v-flex",
                {
                  staticClass: "copyrights",
                  attrs: { "text-xs-center": "", shrink: "" }
                },
                [
                  _c("p", [
                    _c(
                      "a",
                      {
                        staticClass: "cta",
                        attrs: {
                          href:
                            "https://www.thegogame.com/team-building/remote-game?hsCtaTracking=c99293dc-e833-4ae1-a2c5-74496e764663%7C44e1ced1-c732-4506-bca7-f26ef4eaaf93",
                          target: "_blank"
                        }
                      },
                      [_vm._v("Start Your Own Game!")]
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      " Copyright ©️ " +
                        _vm._s(_vm.currentYear) +
                        " The Go Game. All rights reserved. "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://www.thegogame.com/team-building/privacy-policy/",
                          target: "_blank"
                        }
                      },
                      [_vm._v("Privacy Policy")]
                    )
                  ])
                ]
              )
            ],
            1
          )
        : _c("GameLoginOverlay")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }