import invariant from "invariant"

import { MODULE_NAME } from "@/modules/theme/store"
import { GetterTypes } from "@/modules/theme/store/getter-types"
import { DEFAULT_STYLES, DEFAULT_IMAGES, DEFAULT_META } from "../constants"

import { StyleManager } from "../entities/StyleManager"

export const ThemePlugin = {
  install(Vue, { store }) {
    const instance = new Vue({
      computed: {
        styles() {
          return store.getters[`${MODULE_NAME}/${GetterTypes.STYLES}`]
        },
        scope() {
          return store.state.theme.scope
        }
      },
      watch: {
        styles: {
          handler(value) {
            if (value) {
              console.log("🎨 Applying the styles...")
              StyleManager.set(value)
            }
          },
          immediate: true
        },
        scope: {
          handler(value) {
            document.documentElement.setAttribute(
              "data-theme-scope",
              value.toLowerCase()
            )
          },
          immediate: true
        }
      }
    })
    Vue.prototype.__ThemePlugin__ = {
      instance
    }

    Vue.prototype.$theme = {
      /** @param {string} color */
      get(color) {
        const styles = store.getters[`${MODULE_NAME}/${GetterTypes.STYLES}`]
        if (styles && color in styles) {
          return styles[color]
        }

        return DEFAULT_STYLES[color]
      },
      /**
       * @param {string} image
       * @returns {string}
       */
      getImage(image) {
        const path = store.getters[`${MODULE_NAME}/${GetterTypes.IMAGES}`](
          image
        )

        return path ? path : DEFAULT_IMAGES[image]
      },
      /**
       * @type { <K extends keyof import('../types').ThemeMeta>(key: K) => (import('../types').ThemeMeta)[K]>}
       * @returns {T}
       */
      getMeta(key) {
        let value = store.getters[`${MODULE_NAME}/${GetterTypes.META}`](key)
        if (!value) {
          value = DEFAULT_META[key]
        }
        invariant(value !== undefined, `Unable to get theme meta. Key: ${key}`)
        return value
      }
    }
  }
}
