import { MutationTypes } from "./mutation-types"

/** @type {import('vuex').MutationTree<import('./typedef').State>} */
export const mutations = {
  /** @param {import('./constants').ThemeScope} payload */
  [MutationTypes.SET_SCOPE](state, payload) {
    state.scope = payload
  },
  /** @param {(import('../types').Theme | null)} payload */
  [MutationTypes.SET_THEME](state, payload) {
    state.theme = payload
  }
}
