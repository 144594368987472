<template functional>
  <div :style="`background-image: url(${props.imageUrl})`">
    <slot />
  </div>
</template>

<script>
import { DEFAULT_PROFILE_IMAGE_BIG } from "@/config"

export default {
  name: "UserImage",
  functional: true,
  props: {
    imageUrl: {
      type: String,
      default: DEFAULT_PROFILE_IMAGE_BIG
    }
  }
}
</script>
<style scoped>
div {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
