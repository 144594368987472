/**
 * @module NavigatorPlugin
 * @description The module is responsible for providing unified API for navigation
 */

import { Role } from "../helpers"

/** @type import('vue').PluginObject<{ store: import('vuex').Store<any>, router: import('vue-router/types/router').VueRouter }> */
export const NavigatorPlugin = {
  install(Vue, { store, router }) {
    Vue.prototype.$navigator = {
      /**
       * @param {string} route
       */
      async navigateTo(route) {
        const user = this._getUser()
        await this._updateUserLocation(route)
        // Developer should ALWAYS `.replace` for '/logout` since `/logout` route blocks navigation to the previous routes and can ocassionaly cause logut during history navigation
        if (route.includes("logout")) {
          return router.replace(route)
        }

        if (user.role === Role.Host) {
          return router.push(route)
        } else {
          return router.replace(route)
        }
      },
      /**
       * @return {import('../types/user').User} User
       */
      _getUser() {
        return store.getters["auth/user"]
      },
      _updateUserLocation(route) {
        return store.dispatch("GameUsers/updateUserLocation", route)
      }
    }
  }
}
