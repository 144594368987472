import { PluginObject } from "vue"

import type ClientService from "@/services/client"
import type GameService from "@/services/game"

interface Services {
  client: ClientService
  game: GameService
}

type NameMeCorrectly<T> = {
  [P in keyof T]: () => Promise<{ new (): T[P] }>
}

export class ServiceManager {
  private _map: NameMeCorrectly<Services>
  private _services: Partial<Services>

  constructor() {
    this._map = {
      client: () => import("@/services/client").then(module => module.default),
      game: () => import("@/services/game").then(module => module.default)
    }
    this._services = {}
  }

  // TODO(Andrew): remove `as` casting somehow
  async getService<K extends keyof Services>(name: K): Promise<Services[K]> {
    if (this._services[name]) return this._services[name] as Services[K]
    const Service = await this._map[name]()
    this._services[name] = new Service() as Services[K]
    return this._services[name] as Services[K]
  }
}

export const ServicePlugin: PluginObject<never> = {
  install(Vue) {
    Vue.prototype.$services = new ServiceManager()
  }
}
