import firebase from "firebase/app"
import "firebase/database"
import { fetchTwilioToken } from "@/services/api.service"

let subscription = null
let subscriptionRef = null

let roomSubscription = null
let roomSubscriptionRef = null

let usersSubscriptionRefs = {}
let usersSubscription = {}

const LiveChat = {
  namespaced: true,
  state: {
    isUserLocked: false,
    requests: {},
    roomID: null,
    room: null,
    MAX_N_OF_MEMBERS: 6,
    originatorTimestamp: null
  },
  mutations: {
    UPDATE_REQUESTS(state, { requests }) {
      state.requests = requests
    },
    UPDATE_ROOM_ID(state, { roomID }) {
      state.roomID = roomID
    },
    UPDATE_ROOM(state, { room }) {
      state.room = room
    },
    UPDATE_USER_LOCK(state, { status }) {
      state.isUserLocked = status
    },
    UPDATE_ORIGINATOR_TIMESTAMP(state, { timestamp }) {
      state.originatorTimestamp = timestamp
    }
  },
  actions: {
    async updateRoomMission({ rootGetters, state }, payload) {
      const clientID = rootGetters["auth/clientID"]
      const roomID = state["roomID"]
      if (!roomID) throw new Error("Cannot update game ID without room ID")
      await firebase
        .database()
        .ref(`client/${clientID}/calls/rooms/${roomID}/currentMission`)
        .set(payload)
    },
    async updateRoomGameID({ rootGetters, state }, { gameID }) {
      const clientID = rootGetters["auth/clientID"]
      const roomID = state["roomID"]
      if (!roomID) throw new Error("Cannot update game ID without room ID")
      await firebase
        .database()
        .ref(`client/${clientID}/calls/rooms/${roomID}/gameID`)
        .set(gameID)
    },
    async isUserBusy({ rootGetters }, { userID }) {
      const clientID = rootGetters["auth/clientID"]
      const snapshot = await firebase
        .database()
        .ref(`client/${clientID}/calls/users/${userID}/rooms`)
        .orderByChild("status")
        .equalTo("active")
        .limitToLast(1)
        .once("value")

      const value = snapshot.val()

      return !!value
    },
    async toggleRoomLock({ state }) {
      if (!state.roomID) throw new Error("No active room found")
      if (!state.room) throw new Error("No active room found")
      if (!roomSubscriptionRef) throw new Error("No active room found")
      await roomSubscriptionRef.update({ locked: !state.room.locked })
    },
    async toggleUserLock({ state, commit }) {
      commit("UPDATE_USER_LOCK", { status: !state.isUserLocked })
    },
    async fetchRoomUsers({ rootGetters }, { roomID }) {
      const clientID = rootGetters["auth/clientID"]
      const snapshot = await firebase
        .database()
        .ref(`client/${clientID}/calls/rooms/${roomID}/users`)
        .orderByChild("status")
        .equalTo("active")
        .once("value")
      return snapshot.val()
    },
    async unsubscribe({ commit }) {
      if (subscriptionRef) subscriptionRef.off("child_added", subscription)
      commit("UPDATE_REQUESTS", { requests: [] })
    },
    async subscribe({ commit, rootGetters }) {
      const { id } = rootGetters["auth/user"]
      const clientID = rootGetters["auth/clientID"]
      if (subscriptionRef) subscriptionRef.off("child_added", subscription)

      subscriptionRef = firebase
        .database()
        .ref(`client/${clientID}/calls/users/${id}/rooms`)
        .orderByChild("status")
        .equalTo("requested")

      subscription = subscriptionRef.on("child_added", snapshot => {
        commit("UPDATE_REQUESTS", { requests: [snapshot.val()] })
      })
    },
    unsubscribeFromRoom({ commit }) {
      if (roomSubscriptionRef)
        roomSubscriptionRef.off("value", roomSubscription)
      commit("UPDATE_ROOM", { room: null })
    },
    async subscribeToRoom({ commit, rootGetters }, { roomID }) {
      if (roomSubscriptionRef)
        roomSubscriptionRef.off("value", roomSubscription)
      const clientID = rootGetters["auth/clientID"]

      roomSubscriptionRef = firebase
        .database()
        .ref(`client/${clientID}/calls/rooms/${roomID}`)

      return new Promise(resolve => {
        roomSubscription = roomSubscriptionRef.on("value", snapshot => {
          const value = snapshot.val()
          const room = value ? { id: roomID, ...value } : null
          if (!room) throw new Error("No room to subscribe")
          commit("UPDATE_ROOM", { room })
          resolve()
        })
      })
    },
    async connect({ dispatch, commit, rootGetters, state }, { roomID }) {
      const isBusy = rootGetters["twilio/isBusy"]
      const token = rootGetters["auth/token"]
      const { id: userID } = rootGetters["auth/user"]
      commit("UPDATE_ROOM_ID", { roomID })
      console.log("store updated with roomID", roomID)
      if (isBusy) await dispatch("twilio/disconnect", null, { root: true })
      const twilioToken = await fetchTwilioToken({ token, userID })
      const { MAX_N_OF_MEMBERS: max } = state
      const config = { roomID, userID, max, token: twilioToken, join: true }
      await dispatch("twilio/connect", config, { root: true })
    },
    async initiate({ dispatch, commit, rootGetters }) {
      const { id } = rootGetters["auth/user"]
      const clientID = rootGetters["auth/clientID"]
      await dispatch("end")
      const timestamp = Date.now()

      const roomsRef = firebase.database().ref(`client/${clientID}/calls/rooms`)

      const roomID = roomsRef.push().key

      const roomRef = firebase
        .database()
        .ref(`client/${clientID}/calls/rooms/${roomID}/users/${id}`)

      const userRef = firebase
        .database()
        .ref(`client/${clientID}/calls/users/${id}/rooms/${roomID}`)

      roomRef.onDisconnect().update({ status: "ended" })
      userRef.onDisconnect().update({ status: "ended" })

      const doc = {
        userID: id,
        status: "active",
        timestamp: timestamp,
        initiatorID: id,
        roomID: roomID
      }

      await Promise.all([
        roomRef.update(doc),
        userRef.update(doc),
        dispatch("connect", { roomID })
      ])

      await dispatch("subscribeToRoom", { roomID })

      commit("UPDATE_ORIGINATOR_TIMESTAMP", { timestamp: Date.now() })

      return roomID
    },
    async invite({ rootGetters, dispatch, state }, { userID }) {
      const clientID = rootGetters["auth/clientID"]
      const { id } = rootGetters["auth/user"]
      const timestamp = Date.now()

      let roomID = null

      if (state.roomID) {
        roomID = state.roomID
      } else {
        roomID = await dispatch("initiate")
      }

      console.log("inviting to roomID", roomID)

      const roomRef = firebase
        .database()
        .ref(`client/${clientID}/calls/users/${userID}/rooms/${roomID}`)

      const userRef = firebase
        .database()
        .ref(`client/${clientID}/calls/rooms/${roomID}/users/${userID}`)

      const doc = {
        userID: userID,
        status: "requested",
        timestamp: timestamp,
        initiatorID: id,
        roomID: roomID
      }

      await Promise.all([roomRef.update(doc), userRef.set(doc)])
    },
    async getRoomStatus({ dispatch, state }, { roomID }) {
      const users = await dispatch("fetchRoomUsers", { roomID })
      console.log("getRoomStatus/users", users)
      const keys = Object.keys(users || {})
      const n = keys.length
      const { MAX_N_OF_MEMBERS: max } = state
      if (n < 1) {
        return { status: "empty" }
      } else if (n > max - 1) {
        return { status: "full" }
      } else {
        return { status: "available" }
      }
    },
    async enter({ rootGetters, dispatch }, { roomID }) {
      const { id } = rootGetters["auth/user"]
      const clientID = rootGetters["auth/clientID"]

      await dispatch("end")

      const timestamp = Date.now()

      const roomRef = firebase
        .database()
        .ref(`client/${clientID}/calls/users/${id}/rooms/${roomID}`)

      const userRef = firebase
        .database()
        .ref(`client/${clientID}/calls/rooms/${roomID}/users/${id}`)

      const { status } = await dispatch("getRoomStatus", { roomID })

      if (status === "full") {
        await dispatch("decline", { roomID })
        throw new Error("The room is full. Try again later or find another one")
      } else if (status === "empty") {
        await dispatch("decline", { roomID })
        return console.warn("Empty room")
      }

      userRef.onDisconnect().update({ status: "ended" })
      roomRef.onDisconnect().update({ status: "ended" })

      const doc = {
        userID: id,
        status: "active",
        timestamp: timestamp,
        initiatorID: id,
        roomID: roomID
      }

      await Promise.all([
        userRef.update(doc),
        roomRef.update(doc),
        dispatch("connect", { roomID })
      ])

      await dispatch("subscribeToRoom", { roomID })
    },
    async accept({ rootGetters, dispatch }, { roomID }) {
      const { id } = rootGetters["auth/user"]
      const clientID = rootGetters["auth/clientID"]

      await dispatch("end")

      const roomRef = firebase
        .database()
        .ref(`client/${clientID}/calls/users/${id}/rooms/${roomID}`)

      const userRef = firebase
        .database()
        .ref(`client/${clientID}/calls/rooms/${roomID}/users/${id}`)

      const { status } = await dispatch("getRoomStatus", { roomID })

      if (status === "full") {
        await dispatch("decline", { roomID })
        return console.warn(
          "The room is full. Try again later or find another one"
        )
      } else if (status === "empty") {
        await dispatch("decline", { roomID })
        return console.warn("Empty room")
      }

      userRef.onDisconnect().update({ status: "ended" })
      roomRef.onDisconnect().update({ status: "ended" })

      await Promise.all([
        userRef.update({ status: "active" }),
        roomRef.update({ status: "active" }),
        dispatch("connect", { roomID })
      ])

      await dispatch("subscribeToRoom", { roomID })
    },
    async end({ rootGetters, state, commit, dispatch }) {
      const { id } = rootGetters["auth/user"]
      const clientID = rootGetters["auth/clientID"]
      const { roomID } = state

      if (roomID) {
        const roomRef = firebase
          .database()
          .ref(`client/${clientID}/calls/users/${id}/rooms/${roomID}`)

        const userRef = firebase
          .database()
          .ref(`client/${clientID}/calls/rooms/${roomID}/users/${id}`)

        await Promise.all([
          userRef.update({ status: "ended" }),
          roomRef.update({ status: "ended" })
        ])

        roomRef.onDisconnect().cancel()
      } else {
        console.warn("No currently active room")
      }

      await dispatch("unsubscribeFromRoom")
      await dispatch("twilio/disconnect", null, { root: true })
      commit("UPDATE_ROOM_ID", { roomID: null })
    },
    async decline({ rootGetters }, { roomID }) {
      const { id } = rootGetters["auth/user"]
      const clientID = rootGetters["auth/clientID"]

      const roomRef = firebase
        .database()
        .ref(`client/${clientID}/calls/users/${id}/rooms/${roomID}`)
      const userRef = firebase
        .database()
        .ref(`client/${clientID}/calls/rooms/${roomID}/users/${id}`)

      await Promise.all([
        userRef.update({ status: "declined" }),
        roomRef.update({ status: "declined" })
      ])
    },
    /**
     * Subscribe to new users and unsubscribe from user who was removed from list
     *
     * @param dispatch
     * @param usersIDs
     * @param callback
     */
    refreshSubscriptions({ dispatch }, { usersIDs, callback }) {
      usersIDs.forEach(userID => {
        if (!userID) return
        if (!usersSubscriptionRefs[userID]) {
          const subscribePromise = dispatch("subscribeToUserRoom", { userID })
          subscribePromise.then(callback)
        }
        Object.keys(usersSubscriptionRefs).forEach(userID => {
          if (!usersIDs.includes(userID)) {
            dispatch("unsubscribeFromUserRoom", { userID })
          }
        })
      })
    },
    /**
     *
     * @param dispatch
     */
    async removeAllSubscriptions({ dispatch }) {
      await Promise.all([
        Object.keys(usersSubscriptionRefs).map(userID =>
          dispatch("unsubscribeFromUserRoom", { userID })
        )
      ])
    },
    /**
     * Subscribe to the specific User Room by userID
     * @param rootGetters
     * @param dispatch
     * @param userID
     * @return {Promise<any>}
     */
    subscribeToUserRoom({ rootGetters, dispatch }, { userID }) {
      const clientID = rootGetters["auth/clientID"]
      subscriptionRef = firebase
        .database()
        .ref(`client/${clientID}/calls/users/${userID}/rooms`)
        .orderByChild("status")
        .equalTo("active")
        .limitToLast(1)
      if (usersSubscriptionRefs[userID]) {
        dispatch("unsubscribeFromUserRoom", { userID })
      }
      usersSubscriptionRefs[userID] = subscriptionRef
      return new Promise(resolve => {
        subscription = subscriptionRef.on("child_added", snapshot => {
          dispatch("unsubscribeFromUserRoom", { userID })
          resolve(snapshot.val())
        })
        usersSubscription[userID] = subscription
      })
    },
    /**
     * Unsubscribe from the specific user room
     * @param store
     * @param userID
     */
    unsubscribeFromUserRoom(store, { userID }) {
      if (usersSubscriptionRefs[userID]) {
        usersSubscriptionRefs[userID].off(
          "child_added",
          usersSubscription[userID]
        )
        delete usersSubscriptionRefs[userID]
        delete usersSubscription[userID]
      }
    }
  },
  getters: {
    originatorTimestamp({ originatorTimestamp }) {
      return originatorTimestamp
    },
    max(state) {
      return state.MAX_N_OF_MEMBERS
    },
    room(state) {
      return state.room
    },
    requests(state) {
      return state.requests
    },
    roomUsers(state) {
      return state.room ? state.room.users : null
    },
    isUserLocked(state) {
      return state.isUserLocked
    },
    requestsArray({ requests }) {
      if (!requests) return []
      return Object.entries(requests).map(([id, call]) => ({ ...call, id }))
    },
    roomID({ roomID }) {
      return roomID
    }
  }
}

export default LiveChat
