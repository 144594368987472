<template>
  <video ref="video" @canplay="$emit('ready')" />
</template>

<script>
export default {
  name: "UserVideo",
  props: {
    track: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.track.attach(this.$refs.video)
  },
  beforeDestroy() {
    this.track.detach(this.$refs.video)
  }
}
</script>
<style scoped>
video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
