<template>
  <v-flex d-flex class="team-specifics-card">
    <transition name="card-flip-transition" mode="out-in">
      <v-flex
        :key="state"
        d-flex
        class="card-front-side card"
        v-if="state === 'results'"
      >
        <v-layout column fill-height>
          <v-flex shrink class="team-name-wrap">
            <v-layout
              row
              style="margin: 0 0.8em; justify-content: space-between"
            >
              <v-flex style="flex-grow: 0">
                <v-icon class="team-icon">
                  {{ teamIcon }}
                </v-icon>
                <span class="team-name">{{ teamName }} </span>
              </v-flex>
              <v-flex
                v-if="!isMultiTeam"
                style="flex-grow: 0"
                d-flex
                class="team-name-team-total"
              >
                {{ teamTotal + "pts" }}
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex class="team-info-wrap">
            <v-flex class="team-info-scroll">
              <v-layout column>
                <v-flex
                  v-for="(obj, idx) in info"
                  class="team-spec-card-wrap"
                  :key="'team-spec-card-' + idx"
                >
                  <v-layout row class="team-spec-card">
                    <v-flex grow class="left-col">
                      <v-layout row>
                        <!-- Team rank -->
                        <!-- <v-flex shrink class="v-align">
                          <div class="info-number">
                            {{ idx + 1 }}
                          </div>
                        </v-flex> -->
                        <!-- Team icon -->
                        <v-flex v-if="obj.title" shrink class="v-align">
                          <div class="team-spec-title">
                            <div class="team-spec-title-wrap">
                              {{ obj.title }}
                            </div>
                          </div>
                        </v-flex>
                        <!-- Team name and score -->
                        <!-- <v-flex grow class="v-align">
                          <div class="team-spec-details">
                            {{ obj.details }}
                          </div>
                        </v-flex> -->
                      </v-layout>
                    </v-flex>
                    <!-- Team total-->
                    <v-flex shrink class="v-align team-total">
                      <div v-html="obj.points"></div>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-flex>
        </v-layout>
        <div class="ribbon-wrap" v-if="position">
          <img
            v-if="position === 1"
            width="40"
            class="colored-ribbon-image"
            :src="require('../../../assets/ribbon-blue.png')"
          />
          <img
            v-if="position === 2"
            width="40"
            class="colored-ribbon-image"
            :src="require('../../../assets/ribbon-red.png')"
          />
          <img
            v-if="position >= 3"
            width="40"
            class="colored-ribbon-image"
            :src="require('../../../assets/ribbon-white.png')"
          />
          <div
            class="ribbon-team-position"
            :class="'ribbon-team-position-color-' + position"
          >
            <span>{{ position }}</span
            ><!--
         --><span class="nth">{{ positionSuffix }}</span>
          </div>
        </div>
      </v-flex>
      <v-flex :key="state" d-flex class="card-back-side card" v-else>
        <div class="ribbon-bg"></div>
        <v-layout column justify-center class="position-text">
          <div>{{ positionText }}</div>
        </v-layout>
      </v-flex>
    </transition>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "TeamSpecificsCard",
  data() {
    return {
      // isMulti: true
    }
  },
  props: {
    teamName: {
      type: String,
      default: "Error"
    },
    teamTotal: {
      type: Number,
      default: 0
    },
    state: {
      type: String,
      default: "hidden"
    },
    teamIcon: {
      type: String,
      default: "radio_button_unchecked"
    },
    numeric: {
      type: Boolean,
      default: false
    },
    position: Number,
    info: Array
  },
  computed: {
    ...mapGetters("group", ["isMultiTeam"]),
    positionSuffix() {
      if (this.position === 1) return "ST"
      else if (this.position === 2) return "ND"
      else if (this.position === 3) return "RD"
      else return "TH"
    },
    positionText() {
      if (this.numeric) {
        if (this.position === 1) {
          return "1ST"
        } else if (this.position === 2) {
          return "2ND"
        } else if (this.position === 3) {
          return "3RD"
        } else {
          return this.position + "TH"
        }
      } else {
        if (this.position === 1) {
          return "FIRST"
        } else if (this.position === 2) {
          return "SECOND"
        } else if (this.position === 3) {
          return "THIRD"
        } else {
          return "TH"
        }
      }
    }
  }
}
</script>
<style lang="scss">
.team-specifics-card {
  line-height: 1;
  perspective: 2000px;

  .card-flip-transition-enter-active,
  .card-flip-transition-leave-active {
    transition: transform ease 0.3s;
    transform: rotateX(0deg);
  }
  .card-flip-transition-enter {
    transform: rotateX(90deg);
  }
  .card-flip-transition-leave-to {
    transform: rotateX(-90deg);
  }
  .team-info-wrap {
    position: relative;
    font-size: 0.75em;
    margin: 5px;
  }
  .team-name-wrap {
    text-align: center;
    font-size: 1.3rem;
    background-color: $primary_accent_color;
    color: $color-white;
    margin: 5px;
    padding: 0.5rem;
    border-radius: 4px;
    .team-name {
      display: inline-block;
      vertical-align: middle;
    }
    .team-icon {
      display: inline-block;
      vertical-align: middle;
      color: $color-white;
      font-size: 26px;
      margin-right: 0.25rem;
    }
  }
  .card {
    @extend .rtb-border, .rtb-border-radius, .rtb-box-shadow;
  }
  .card-front-side {
    background-color: $color-white;
    position: relative;
  }
  .card-back-side {
    background-color: $primary_accent_color;
    position: relative;
    .ribbon-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("../../../assets/ribbon-back.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-size: 25%;
    }
    .position-text {
      user-select: none;
      position: relative;
      font-size: 5rem;
      text-align: center;
      font-weight: bold;
      color: $color-white;
    }
  }
  .team-spec-title-wrap {
    // background-color: $color-white;
    padding: 0 5px;
    font-size: 1.3em;
    line-height: 1.6em;
    // color: $primary_accent_color;
  }
  .v-align {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .team-spec-card {
    font-size: 1.2em;
    font-weight: bold;
  }
  .team-spec-card-wrap:not(:last-child) {
    margin-bottom: 0.6em;
  }
  .left-col {
    background-color: $color-secondary-light;
    padding: 3px 8px;
  }
  .info-number {
    text-align: center;
    width: 1.8em;
    height: 1.8em;
    line-height: 1.9em;
    border-radius: 3px;
    background-color: $color-secondary-dark;
    color: $color-white;
    font-weight: bold;
    margin-right: 12px;
    font-size: 1em;
  }
  .team-score-now {
    font-size: 1.2em;
    line-height: 1.8em;
  }
  .team-spec-details {
    font-size: 1.05em;
    line-height: 1.95em;
  }
  .team-spec-title {
    height: 2em;
    margin-right: 12px;
    position: relative;
  }
  .team-total {
    text-align: center;
    min-width: 23%;
    font-size: 1.2em;
    background-color: $color-secondary-dark;
    color: $color-white;
    & > div > span {
      display: inline-block;
      vertical-align: middle;
      &.pts {
        font-weight: normal;
        padding-left: 0.25em;
        position: relative;
        font-size: 0.9em;
        top: -0.1em;
      }
    }
  }
  .total-pts {
    font-size: 70%;
    position: relative;
    top: -0.27em;
    margin-left: 1px;
  }
  .team-info-scroll {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .ribbon-wrap {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-33%) translateY(-20%);
  }
  .ribbon-team-position {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 38px;
    font-weight: bold;
    color: $color-black;
    padding-left: 3px;
    padding-right: 3px;
    .nth {
      position: relative;
      font-size: 60%;
      top: -0.5em;
    }
  }
  .team-name-team-total {
    text-align: center;
    align-items: center;
    justify-content: center;
    color: $color-white;
    font-weight: bold;
  }
}
</style>
