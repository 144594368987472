<template>
  <v-container pa-0 fluid fill-height class="rtb-game-team">
    <v-layout column justify-center>
      <div>
        <v-layout
          row
          no-wrap
          justify-center
          class="rtb-game-team__generic-player-row"
        >
          <GenericPlayer
            v-for="player in players"
            :key="`generic-player-${player.id}-${player.teamID}`"
            :isMutable="player.id === user.id || isHost"
            :isMutedGlobally="player.mutedGlobally"
            :isMuted="player.muted"
            :skip="player.skip"
            :isHost="isHost"
            :selected="player.selected"
            :bus="bus"
            :mode="mode"
            :isTop="isTop"
            :isPotato="isPotato"
            :isTeamMision="!isIndividualMission"
            :firstname="player.firstname"
            :lastname="player.lastname"
            :maxWidth="maxWidth"
            :userID="player.id"
            :location="player.location"
            :playStatus="playStatus(player.id)"
            :teamPosition="teamPosition"
            :imageURL="player.image"
            :teamID="teamID"
            @onSelected="selectTheUser(player.id, teamID)"
            @onSkip="skipTheUser(player.id, teamID)"
            @onMute="toggleAudio(player)"
          >
            <TeamIconTag
              v-if="showOnlyScribe"
              :teamID="teamID"
              class="rtb-game-team__player-team-icon"
            />
          </GenericPlayer>
        </v-layout>
      </div>
    </v-layout>
    <audio ref="audio" v-if="teamID == user.teamID">
      <source :src="scribeSound" type="audio/mpeg" />
    </audio>
    <v-tooltip right v-if="isHost && isTop">
      <template v-slot:activator="{ on }">
        <v-switch
          v-on="on"
          dense
          v-model="showOnboardingPlayers"
          class="rtb-game-team__show-onboarding-switch"
        />
      </template>
      <span>Show Onboarding Players</span>
    </v-tooltip>
  </v-container>
</template>

<script>
import GenericPlayer from "@/components/GroupTeams/Common/Player/GenericPlayer"
import TeamIconTag from "@/components/GroupTeams/Common/TeamIconTag"
import { mapActions, mapGetters, mapState } from "vuex"
import Vue from "vue"
import { Role } from "@/helpers"
import {
  updateUserSelectStatus,
  updateUserSkipStatus,
  updateUserMuteStatus
} from "@/services/user.service"

const scribeSound = require("@/assets/scribe.mp3")
const TRACKABLE_MODES = ["huddle", "play", "social", "voting"]
const SILENT_MODES = ["social"]
const SILENT_MISSION_TYPES = [
  "Freeform",
  "Team Name",
  "Poll",
  "Categories",
  "Fact Match"
]

export default {
  name: "Team",
  components: {
    GenericPlayer,
    TeamIconTag
  },
  data() {
    return {
      isTeamHidden: false,
      maxWidth: parseInt(window.innerHeight / 2 - 100),
      bus: new Vue(),
      scribeSound: scribeSound,
      showOnboardingPlayers: false
    }
  },
  computed: {
    ...mapGetters({
      plays: "missionPlaysArray",
      missionID: "currentMission",
      mission: "getCurrentMission",
      teams: "chats"
    }),
    ...mapGetters(["onlineUsersGroupedByTeam", "isScribe"]),
    ...mapGetters("auth", ["isHost"]),
    ...mapState("GameUsers", ["location"]),
    playType() {
      return this.mission ? this.mission.playType : null
    },
    behavior() {
      return this.mission ? this.mission.behavior : null
    },
    canPassScribe() {
      return this.mission ? !!this.mission.passScribe : false
    },
    teamPlays() {
      return this.plays.filter(team => team.teamID === this.teamID)
    },
    players() {
      const array = this.onlineUsersGroupedByTeam[this.teamID]
      if (!Array.isArray(array)) return []
      const path = this.$route.path
      if (this.isHost && this.showOnboardingPlayers) {
        return this.showOnlyScribe
          ? array
              .filter(user => user.selected && user.role === Role.Player)
              .map(user => ({ ...user, location: this.location?.[user.id] }))
          : array
              .filter(user => user.role === Role.Player)
              .map(user => ({ ...user, location: this.location?.[user.id] }))
      } else {
        return this.showOnlyScribe
          ? array.filter(
              user =>
                user.selected &&
                user.role === Role.Player &&
                this.location?.[user.id] === path
            )
          : array.filter(
              user =>
                user.role === Role.Player && this.location?.[user.id] === path
            )
      }
    },
    user() {
      return this.$store.state.auth.user
    },
    isIndividualMission() {
      return String(this.playType).toLowerCase().indexOf("individual") > -1
    },
    isTrackingProgress() {
      return (
        TRACKABLE_MODES.indexOf(this.mode) > -1 && !this.isIndividualMission
      )
    },
    isSilent() {
      return (
        SILENT_MISSION_TYPES.includes(this.behavior) ||
        SILENT_MODES.includes(this.mode)
      )
    }
  },
  watch: {
    isScribe(newValue) {
      if (this.teamID == this.user.teamID && newValue && this.$refs.audio) {
        this.$refs.audio.play()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.audio) this.$refs.audio.volume = 0.25
    })
  },
  methods: {
    ...mapActions(["updateUser"]),
    async selectTheUser(userID, teamID) {
      const player = this.players.find(({ id }) => id === userID)

      if (!player) throw new Error(`player ${userID} is undefined`)

      const isPlayer = this.user.role === "player"
      const isTheSameTeam = this.user.teamID === teamID
      const isSelected = this.user.selected
      const canPassScribe =
        this.canPassScribe && isPlayer && isSelected && isTheSameTeam

      if (this.isHost) {
        const selected = !player.selected
        const muted = selected ? false : !!player.muted
        await updateUserSelectStatus({ userID: player.id, selected })
        await updateUserMuteStatus({ userID: player.id, muted })
      } else if (canPassScribe) {
        // select your team mate
        await updateUserSelectStatus({ userID: player.id, selected: true })
        await updateUserMuteStatus({ userID: player.id, muted: false })
        await updateUserSelectStatus({ userID: this.user.id, selected: false })
      }
    },
    async skipTheUser(userID) {
      if (this.isHost) {
        const player = this.players.find(({ id }) => id === userID)
        const selected = !player.skip
        await updateUserSkipStatus({ userID: player.id, selected })
      }
    },
    playStatus(userID) {
      const plays = this.teamPlays.filter(play => play.userID === userID)
      return {
        waiting: false,
        submitted: !!plays.length,
        winner:
          this.isSilent || !this.isIndividualMission
            ? false
            : !!plays.find(play => play.score > 0)
      }
    },
    async toggleAudio({ muted, id }) {
      await this.updateUser({ userID: id, obj: { muted: !muted } })
    },
    huddle() {
      this.bus.$emit("huddle")
    },
    unhuddle() {
      this.bus.$emit("unhuddle")
    },
    showTeam() {
      this.isTeamHidden = false
    },
    hideTeam() {
      this.isTeamHidden = true
    },
    togglePlayers() {
      this.isTeamHidden = !this.isTeamHidden
    }
  },
  props: {
    mode: String,
    showOnlyScribe: {
      type: Boolean,
      default: false
    },
    isPotato: {
      type: Boolean,
      default: false
    },
    teamPosition: {
      type: Number,
      default: 0
    },
    isTop: {
      type: Boolean,
      default: false
    },
    starPlayerID: {
      type: String,
      default: null
    },
    teamID: {
      required: true
    }
  }
}
</script>

<style lang="scss">
.rtb-game-team {
  &__generic-player-row {
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
  }
  &__player-team-icon {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-8%) translateY(-8%);
    z-index: 10;
  }
  &__player-location-label {
    position: absolute;
    width: 100%;
    text-align: center;
  }
  &__show-onboarding-switch {
    position: absolute;
    top: 5px;
    left: 9px;
    margin: 0 !important;
    padding: 0;
    .v-messages {
      display: none;
    }
    .v-input__slot {
      margin-bottom: 0;
    }
    .v-input--selection-controls__input {
      width: 28px;
      margin-right: 0;
    }
    .v-input--selection-controls__ripple {
      color: $primary_accent_color !important;
      caret-color: $primary_accent_color !important;
    }
    .v-input--switch__track {
      height: 10px;
      top: calc(50% - 5px);
      color: $color-primary-dark !important;
      overflow: hidden;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($color-black, 0.33);
      }
    }
    .v-input--switch__thumb {
      height: 16px;
      width: 16px;
      top: calc(50% - 8px);
      color: $primary_accent_color !important;
      caret-color: $primary_accent_color !important;
      box-shadow: none !important;
    }
    &.v-input--is-dirty .v-input--switch__thumb {
      transform: translate(12px, 0);
    }
  }
}
</style>
