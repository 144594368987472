var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "meeting-container",
      attrs: { column: "", "justify-center": "", "fill-height": "" }
    },
    [
      _c(
        "div",
        { staticStyle: { "overflow-y": "auto" } },
        [
          _c(
            "v-flex",
            { staticClass: "meeting-container-wrap", attrs: { shrink: "" } },
            _vm._l(_vm.videoGridWraped, function(row, i) {
              return _c(
                "v-layout",
                {
                  key: "meeting-row-" + i,
                  staticStyle: { margin: "4px" },
                  attrs: { row: "", wrap: "", "justify-center": "" }
                },
                _vm._l(row, function(player, j) {
                  return _c("MeetingPlayer", {
                    key: "meeting-user-" + player.id + "-" + i + "-" + j,
                    attrs: {
                      player: player,
                      videoHeight: _vm.size,
                      videoWidth: _vm.size,
                      colCount: row.length
                    }
                  })
                }),
                1
              )
            }),
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }