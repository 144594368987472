<template>
  <div class="overlay">
    <!-- TODO(Andrew): replate with $theme.get -->
    <v-progress-circular
      color="#f36523"
      indeterminate
      class="overlay__spinner"
    />
  </div>
</template>

<script>
import Vue from "vue"

export default Vue.extend({
  name: "GameLoginOverlay"
})
</script>

<style lang="scss" scoped>
/** TODO(Andrew): move to helper classes */
.overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
