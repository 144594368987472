<template>
  <v-flex d-flex class="rtb-card">
    <slot />
  </v-flex>
</template>

<script>
export default {
  name: "RtbCard"
}
</script>

<style lang="scss" scoped>
.rtb-card {
  @extend .rtb-border, .rtb-border-radius;
  position: relative;
  overflow: hidden;
  background-color: $color-white;
  padding: 5px;
  box-shadow: 0 0 3px rgba($color-black, 0.5);
}
</style>
