/**
 * @module DevicesPlugin
 * @description The module is responsible for storing all the connected devices to the store
 */

import { MutationTypes } from "../store/TwilioModule"

/**
 *
 * @param {MediaDeviceInfo} device
 * @return {boolean}
 */
function isDeviceInfoValid(device) {
  return device.deviceId !== ""
}

/** @type import('vue').PluginObject<{ module: string, store: import('vuex').Store<any> }> */
export const DevicesPlugin = {
  install(Vue, { module, store }) {
    Vue.prototype.$devices = {
      tryToEnumerateDevices() {
        return navigator.mediaDevices
          .getUserMedia({ video: true, audio: true })
          .then(stream => {
            stream.getTracks().forEach(track => track.stop())
            return navigator.mediaDevices.enumerateDevices().then(devices => {
              const cameras = devices.filter(
                device =>
                  device.kind === "videoinput" &&
                  isDeviceInfoValid(device) === true
              )
              const microphones = devices.filter(
                device =>
                  device.kind === "audioinput" &&
                  isDeviceInfoValid(device) === true
              )

              store.commit(module + "/" + MutationTypes.SET_CAMERAS, cameras)
              store.commit(
                module + "/" + MutationTypes.SET_MICROPHONES,
                microphones
              )

              const [c] = cameras
              const [m] = microphones

              if (
                c !== undefined &&
                isDeviceInfoValid(c) &&
                store.state[module].camera === null
              ) {
                store.commit(
                  module + "/" + MutationTypes.SET_CAMERA,
                  c.deviceId
                )
              }

              if (
                m !== undefined &&
                isDeviceInfoValid(m) &&
                store.state[module].microphone === null
              ) {
                store.commit(
                  module + "/" + MutationTypes.SET_MICROPHONE,
                  m.deviceId
                )
              }
            })
          })
      }
    }
  }
}
