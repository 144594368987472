export class AudioManager {
  /**
   * @param {HTMLAudioElement} element
   * @param {number} step
   * @returns {Promise<{ success: boolean }>}
   */
  static fadeOut(element, step = 0.005) {
    return new Promise(resolve => {
      const interval = setInterval(() => {
        if (element.volume >= step) {
          element.volume -= step
        } else {
          clearInterval(interval)
          resolve({ success: true })
        }
      }, 32)
    })
  }
}
