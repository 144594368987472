var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "slide-transition", mode: "out-in" } },
    [
      _vm.mediaToShow && _vm.mediaToShow !== "none"
        ? _c("AssetMapper", {
            attrs: { mode: _vm.mode, drawing: _vm.isDrawingMission }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }