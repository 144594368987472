var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.user.speechToText,
          expression: "user.speechToText"
        }
      ],
      staticClass: "transcript-container"
    },
    _vm._l(_vm.transcriptions, function(transcript, index) {
      return _c(
        "div",
        {
          key: index,
          ref: "transcript",
          refInFor: true,
          staticClass: "transcript"
        },
        [
          _c("span", { staticClass: "username" }, [
            _vm._v(_vm._s(transcript.firstname) + ": ")
          ]),
          _vm._v(" " + _vm._s(transcript.transcription) + " ")
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }