import Video from "twilio-video"

/**
 * RemoteTrack
 * @typedef {import('twilio-video').RemoteTrack} RemoteTrack
 */

/**
 * RemoteTrackPublication
 * @typedef {import('twilio-video').RemoteTrackPublication} RemoteTrackPublication
 */

/**
 * RemoteParticipant
 * @typedef {import('twilio-video').RemoteParticipant} RemoteParticipant
 */

const TWILIO_RENDER_DIMENSIONS_STANDARD = {
  high: {
    width: 380,
    height: 380
  },
  standard: {
    width: 256,
    height: 256
  },
  low: {
    width: 128,
    height: 128
  }
}
const TWILIO_RENDER_DIMENSIONS_LOW = {
  high: {
    width: 225,
    height: 225
  },
  standard: {
    width: 128,
    height: 128
  },
  low: {
    width: 64,
    height: 64
  }
}

class TwilioRoom {
  constructor() {
    this._room = null
    this._users = {}
    this._identity = null
    this.onUpdate = () => {}
  }
  static isSupportedBrowser() {
    return /Edge/.test(navigator.userAgent) === false && Video.isSupported
  }
  static connectToRoom({
    name,
    max = 35,
    videoTrack,
    audioTrack,
    token,
    potato
  }) {
    const tracks = []
    if (videoTrack) tracks.push(videoTrack)
    if (audioTrack) tracks.push(audioTrack)
    if (!token) throw new Error("Need a token to connect to Twilio")
    if (!name) throw new Error("Need a room name to connect to Twilio")
    return Video.connect(token, {
      name,
      tracks,
      // logLevel: "debug",
      preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
      trackSwitchOffMode: "detected",
      bandwidthProfile: {
        trackSwitchOffMode: "detected",
        maxAudioBitrate: 16000,
        video: {
          mode: "collaboration",
          dominantSpeakerPriority: "high",
          maxTracks: max,
          renderDimensions: potato
            ? TWILIO_RENDER_DIMENSIONS_LOW
            : TWILIO_RENDER_DIMENSIONS_STANDARD
        }
      }
    })
  }
  async connect({
    token,
    identity,
    max,
    name,
    potato,
    onUpdate = () => {},
    onDisconnect = () => {}
  }) {
    this.onUpdate = onUpdate
    this._identity = identity
    this._room = await TwilioRoom.connectToRoom({ name, token, max, potato })
    this._room.once("disconnected", (room, error) => {
      this._cleanUp()
      onDisconnect(error)
      console.log(`disconnected from ${room.name}`)
    })
    this._onTrackSubscribed = this._onTrackSubscribed.bind(this)
    this._onTrackUnsubscribed = this._onTrackUnsubscribed.bind(this)
    this._room.on("trackSubscribed", this._onTrackSubscribed)
    this._room.on("trackUnsubscribed", this._onTrackUnsubscribed)
  }
  _cleanUp() {
    this._room.off("trackSubscribed", this._onTrackSubscribed)
    this._room.off("trackUnsubscribed", this._onTrackUnsubscribed)
    this._room.localParticipant.tracks.forEach(publication => {
      publication.track.stop()
      publication.unpublish()
    })
    this._users = {}
    this._notify()
  }
  disconnect() {
    if (this._room) {
      this._cleanUp()
      this._room.disconnect()
    }
  }
  /**
   * @param {RemoteTrack} track
   * @param {RemoteTrackPublication} _
   * @param {RemoteParticipant} participant
   */
  _onTrackSubscribed(track, _, { identity }) {
    if (!this._users[identity]) this._users[identity] = {}
    if (track.kind === "video") {
      this._users[identity].video = track
      this._notify()
    } else if (track.kind === "audio") {
      this._users[identity].audio = track
      this._notify()
    }
  }
  /**
   * @param {RemoteTrack} track
   * @param {RemoteTrackPublication} _
   * @param {RemoteParticipant} participant
   */
  _onTrackUnsubscribed(track, _, { identity }) {
    if (this._users[identity]) {
      if (track.kind === "video" && this._users[identity].video) {
        delete this._users[identity].video
        this._notify()
      } else if (track.kind === "audio" && this._users[identity].audio) {
        delete this._users[identity].audio
        this._notify()
      }
    }
  }
  async join({ priority = "standard", videoTrack, audioTrack } = {}) {
    if (!this._room)
      throw new Error("You need to connect to the room before joining it")

    if (this._users[this._identity])
      throw new Error(
        "Cannot join the room because your identity in use by someone else"
      )

    // connect your local input to the remote room
    /* const publications = */
    await this._room.localParticipant.publishTracks([videoTrack, audioTrack], {
      priority
    })

    // save your local input into the map
    this._users[this._identity] = {
      video: videoTrack
    }

    this._notify()
  }
  _notify() {
    this.onUpdate(this._users)
  }
}

const TWILIO_REGIONS = [
  {
    id: "gll",
    location: "Global Low Latency (default)",
    hostName: "global.vss.twilio.com",
    port: 443,
    protocol: "WSS"
  },
  {
    id: "au1",
    location: "Australia",
    hostName: "au1.vss.twilio.com",
    port: 443,
    protocol: "WSS"
  },
  {
    id: "br1",
    location: "Brazil",
    hostName: "br1.vss.twilio.com",
    port: 443,
    protocol: "WSS"
  },
  {
    id: "de1",
    location: "Germany",
    hostName: "de1.vss.twilio.com",
    port: 443,
    protocol: "WSS"
  },
  {
    id: "ie1",
    location: "Ireland",
    hostName: "ie1.vss.twilio.com",
    port: 443,
    protocol: "WSS"
  },
  {
    id: "in1",
    location: "India",
    hostName: "in1.vss.twilio.com",
    port: 443,
    protocol: "WSS"
  },
  {
    id: "jp1",
    location: "Japan",
    hostName: "jp1.vss.twilio.com",
    port: 443,
    protocol: "WSS"
  },
  {
    id: "sg1",
    location: "Singapore",
    hostName: "sg1.vss.twilio.com",
    port: 443,
    protocol: "WSS"
  },
  {
    id: "us1",
    location: "US East Coast (Virginia)",
    hostName: "us1.vss.twilio.com",
    port: 443,
    protocol: "WSS"
  },
  {
    id: "us2",
    location: "US West Coast (Oregon)",
    hostName: "us2.vss.twilio.com",
    port: 443,
    protocol: "WSS"
  }
]

export { TwilioRoom, TWILIO_REGIONS }
