<template>
  <v-flex
    :style="{ visibility: isOpen ? 'visible' : 'hidden' }"
    d-flex
    justify-center
    class="popup-overlay end-game-popup"
    align-center
  >
    <transition name="popup">
      <v-flex v-if="isOpen" d-flex pa-4 class="popup-content">
        <transition name="slide">
          <v-layout column justify-center v-if="cloneStep === 1">
            <v-flex shrink mt-2 class="text-xs-center popup-text">
              You can end the game or copy it to play again soon.
            </v-flex>
            <v-flex shrink mt-4 justify-center>
              <v-layout row justify-space-between>
                <button @click.stop="onEndGame" class="popup-button">
                  End Game
                </button>
                <button @click.stop="cloneStep = 2" class="popup-button">
                  Copy
                </button>
              </v-layout>
            </v-flex>
          </v-layout>
        </transition>
        <transition name="slide">
          <v-layout column justify-center v-if="cloneStep === 2">
            <v-flex shrink mt-4 class="text-xs-center popup-text">
              <div>Start the game in how many minutes?</div>
              <v-text-field
                class="start-in mt-2 v-text-field--white"
                v-model="startIn"
                required
              />
            </v-flex>
            <v-flex shrink mt-2 justify-center>
              <v-layout row justify-space-between>
                <button @click.stop="onCreateCopy" class="popup-button">
                  Create
                </button>
              </v-layout>
            </v-flex>
          </v-layout>
        </transition>
        <div class="close-symbol" @click.stop="onClose">×</div>
      </v-flex>
    </transition>
  </v-flex>
</template>

<script>
export const ACTION_CLOSE = "close"
export const ACTION_END_GAME = "end_game"
export const ACTION_CREATE_COPY = "create_copy"
export const DEFAULT_START_IN = 15

export default {
  name: "EndGamePopup",
  data: () => ({
    isOpen: false,
    promiseResolve: null,
    cloneStep: 1,
    startIn: DEFAULT_START_IN // minutes
  }),
  methods: {
    open() {
      this.isOpen = true
      this.cloneStep = 1
      this.startIn = DEFAULT_START_IN
      return new Promise(resolve => (this.promiseResolve = resolve))
    },
    onClose() {
      this.isOpen = false
      this.promiseResolve({ action: ACTION_CLOSE })
    },
    onCreateCopy() {
      this.isOpen = false
      this.promiseResolve({
        action: ACTION_CREATE_COPY,
        startIn: this.startIn || DEFAULT_START_IN
      })
    },
    onEndGame() {
      this.isOpen = false
      this.promiseResolve({ action: ACTION_END_GAME })
    }
  }
}
</script>

<style lang="scss">
.end-game-popup {
  .start-in {
    max-width: 20%;
    min-width: 100px;
    &.v-text-field {
      margin: auto;
    }
    input {
      text-align: center;
    }
  }
  .v-text-field--white {
    color: $color-white;

    &.primary--text {
      color: $primary_accent_color !important;
      caret-color: $primary_accent_color !important;
    }
    .primary--text {
      color: $primary_accent_color !important;
      caret-color: $primary_accent_color !important;
    }
    input {
      color: $color-white !important;
      background: rgba($color-white, 0.1);
    }
  }
  .slide-leave-active,
  .slide-enter-active {
    transition: 0.3s;
  }
  .slide-enter {
    transform: translate(100%, 0);
  }
  .slide-leave-to {
    transform: translate(-100%, 0);
  }
}
</style>
