<template>
  <UserImage :imageUrl="imageUrl">
    <UserVideo
      v-if="track && !disable"
      :track="track"
      @ready="$emit('ready')"
      ref="video"
    />
  </UserImage>
</template>

<script>
import { mapGetters } from "vuex"
import UserImage from "./UserImage"
import UserVideo from "./UserVideo"

export default {
  name: "UserMedia",
  components: {
    UserVideo,
    UserImage
  },
  props: {
    identity: {
      type: String,
      required: true
    },
    disable: Boolean,
    imageUrl: String,
    videoTrack: Object
  },
  computed: {
    ...mapGetters("twilio", ["users"]),
    track() {
      return this.videoTrack || this?.users?.[this.identity]?.videoTrack
    }
  }
}
</script>
