var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "border", staticClass: "team-tag-wrap" }, [
    _c(
      "div",
      {
        staticClass: "team-tag",
        on: {
          click: function($event) {
            if (
              $event.ctrlKey ||
              $event.shiftKey ||
              $event.altKey ||
              $event.metaKey
            ) {
              return null
            }
            return _vm.resetMissionTeam()
          }
        }
      },
      [
        _c(
          "div",
          { ref: "whispercover", staticClass: "team-tag-whisper-cover" },
          [
            _c("div", { staticClass: "team-tag-whisper" }, [
              _c("div", { staticClass: "team-whisper-shape-cover" }, [
                _c("div", { ref: "shape", staticClass: "team-whisper-shape" }, [
                  _c("div", { staticClass: "team-tag-shape-left origin" }),
                  _c("div", { staticClass: "team-tag-shape-left white" })
                ])
              ]),
              _c(
                "div",
                {
                  ref: "whisper",
                  staticClass: "team-whisper",
                  class: _vm.whisperClass,
                  on: {
                    click: function($event) {
                      return _vm.changeTeam()
                    }
                  }
                },
                [_vm.isGameOver ? _c("span", [_vm._v(" Join Team")]) : _vm._e()]
              )
            ])
          ]
        ),
        _c("div", { ref: "team", staticClass: "team-tag-team" }, [
          _c("div", { staticClass: "team-tag-shape-left" }),
          _c("div", { staticClass: "team-tag-team-name" }, [
            _vm._v(" " + _vm._s(_vm.team.name || "null") + " ")
          ]),
          _c("div", { staticClass: "team-tag-shape-right" })
        ]),
        _c("div", { ref: "cover", staticClass: "team-tag-strikes-cover" }, [
          _c("div", { staticClass: "team-tag-strikes" }, [
            _c(
              "div",
              { staticClass: "team-strikes" },
              _vm._l(_vm.strikes, function(strike, index) {
                return _c("StrikeIcon", {
                  key: _vm.team.id + "-x-" + index,
                  staticClass: "strike-icon",
                  attrs: { color: strike.color }
                })
              }),
              1
            ),
            _c("div", { staticClass: "team-strikes-shape-cover" }, [
              _c("div", { ref: "shape", staticClass: "team-strikes-shape" }, [
                _c("div", { staticClass: "team-tag-shape-right white" }),
                _c("div", { staticClass: "team-tag-shape-right origin" })
              ])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }