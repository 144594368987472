import LogRocket from "logrocket"
import createPlugin from "logrocket-vuex"

const LOG_ROCKET_KEY = "ndk2s5/remotely-fun"
let initialized = false
let identified = false

const initializeLogRocket = store => {
  if (initialized) return
  initialized = true
  LogRocket.init(LOG_ROCKET_KEY)
  const plugin = createPlugin(LogRocket)
  plugin(store)
}

const identifyLogRocketUser = ({
  userID,
  username,
  firstname,
  lastname,
  email,
  gameID,
  clientID
}) => {
  if (identified) return
  identified = true
  LogRocket.identify(userID, {
    name: username,
    firstname,
    lastname,
    email,
    gameID,
    clientID
  })
}

export { initializeLogRocket, identifyLogRocketUser }
