<template>
  <v-container pa-0 fill-height fluid class="signin-page">
    <v-layout v-if="ready" column>
      <v-flex text-xs-center shrink class="signin-header">
        <img :src="$theme.getImage('logo')" class="rtb-logo-image" alt="Logo" />
        <div class="header-shape">
          <div class="left-shape"></div>
          <div class="right-shape"></div>
          <div class="center-shape"></div>
          <div class="tagline" :style="`color: #${taglineColor} !important`">
            {{ $theme.getMeta("tagline") }}
          </div>
        </div>
      </v-flex>

      <v-flex
        style="height: 100%; max-height: 30%; min-height: 160px"
        d-flex
        class="carousel-wrap"
      >
        <v-flex shrink text-xs-center class="header-message">
          <h1 v-if="title !== null && title !== undefined">{{ title }}</h1>
          <v-progress-circular v-else-if="!!id" indeterminate class="spinner" />
        </v-flex>
        <SigninCarousel :images="images" class="carousel" />
      </v-flex>

      <v-flex d-flex class="box-wrap">
        <RtbCard class="signin-rtb-card">
          <v-layout column style="align-self: center">
            <v-layout v-if="loading" class="spinner-container">
              <v-progress-circular indeterminate class="spinner" />
            </v-layout>

            <v-layout column v-else-if="!!error" class="error-container">
              <v-flex d-flex justify-center align-center class="error-message">
                <div v-if="emailIsNotVerified">
                  We have sent an email with a confirmation link to verify your
                  email address.<br />
                  If you do not receive it, please check your spam folder or
                  <a href="#" @click="onResendEmailVerification($event)"
                    >Click here</a
                  >
                  for resending the link
                </div>
                <template v-else>
                  {{ error }}
                </template>
              </v-flex>
              <v-flex
                shrink
                d-flex
                justify-center
                align-center
                v-if="mode !== 'dead-end'"
              >
                <a href="#" class="error-ok" @click.prevent="onOkay"> OK </a>
              </v-flex>
            </v-layout>

            <template v-else>
              <transition name="fade" mode="out-in">
                <EmailLogin
                  v-if="mode === modes.EmailAndNames"
                  @submit="onEmailSignIn"
                  class="login-fields"
                />
                <v-flex v-if="mode == 'login'" class="login-fields">
                  <form @submit.prevent="onSignin">
                    <v-layout column>
                      <input
                        class="signin-text-input game-login-text-input"
                        v-model="email"
                        type="email"
                        placeholder="EMAIL"
                        required
                      />
                      <input
                        class="signin-text-input game-login-text-input"
                        v-model="password"
                        type="password"
                        placeholder="PASSWORD"
                        required
                      />
                    </v-layout>
                    <v-layout row class="buttons">
                      <GoogleButton @click="signInWithGoogle" />
                      <input
                        class="login-btn signin-btn"
                        type="submit"
                        value="Join The Game"
                      />
                    </v-layout>
                  </form>
                  <v-flex
                    shrink
                    v-if="!!id"
                    text-xs-right
                    class="forgot-message"
                  >
                    <a @click="mode = 'forgot'" class="forgot"
                      >Forgot Password?</a
                    >
                  </v-flex>
                  <v-flex
                    shrink
                    text-xs-center
                    class="register-here-message"
                    v-if="!!id"
                  >
                    Don't have an account?
                    <a @click="mode = 'register'" class="register-here"
                      >Register Here</a
                    >
                  </v-flex>
                </v-flex>
                <v-card v-else-if="mode == 'register'" class="register-fields">
                  <form
                    @submit.prevent="onSignup"
                    id="register-form"
                    autocomplete="off"
                  >
                    <v-layout wrap>
                      <v-flex xs6>
                        <input
                          class="signin-text-input game-login-text-input"
                          v-model="email"
                          type="email"
                          placeholder="EMAIL"
                          autocomplete="off"
                          required
                        />
                      </v-flex>
                      <v-flex xs6>
                        <input
                          class="signin-text-input game-login-text-input"
                          v-model="username"
                          type="text"
                          placeholder="USERNAME"
                          autocomplete="off"
                          required
                        />
                      </v-flex>
                      <v-flex xs6>
                        <input
                          class="signin-text-input game-login-text-input"
                          v-model="firstname"
                          type="text"
                          placeholder="FIRST NAME"
                          autocomplete="off"
                          required
                        />
                      </v-flex>
                      <v-flex xs6>
                        <input
                          class="signin-text-input game-login-text-input"
                          v-model="lastname"
                          type="text"
                          placeholder="LAST NAME"
                          autocomplete="off"
                          required
                        />
                      </v-flex>
                      <v-flex xs6>
                        <input
                          class="signin-text-input game-login-text-input"
                          v-model="password"
                          autocomplete="new-password"
                          type="password"
                          placeholder="PASSWORD"
                          required
                          minlength="8"
                        />
                      </v-flex>
                      <v-flex xs6>
                        <input
                          class="signin-text-input game-login-text-input"
                          v-model="confirmPassword"
                          autocomplete="new-password"
                          type="password"
                          placeholder="CONFIRM PASSWORD"
                          required
                          minlength="8"
                        />
                      </v-flex>
                      <v-flex
                        v-if="false"
                        xs12
                        class="text-xs-center text-sm-center text-md-center text-lg-center"
                      >
                        <image-uploader
                          :debug="1"
                          :max-width="200"
                          :quality="0.7"
                          :auto-rotate="true"
                          :preview="true"
                          :class-name="[
                            'fileinput',
                            { 'fileinput--loaded': !!imageUrl }
                          ]"
                          output-format="verbose"
                          capture="environment"
                          accept=".jpg,.png,.jpeg"
                          @input="setImage"
                        >
                          <label
                            ref="fileGrabber"
                            slot="upload-label"
                            for="fileInput"
                          >
                            <figure>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                              >
                                <path
                                  class="path1"
                                  d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                                />
                              </svg>
                            </figure>
                            <span class="upload-caption">{{
                              !!imageUrl
                                ? "Replace Image"
                                : "Upload Profile Pic"
                            }}</span>
                          </label>
                        </image-uploader>
                      </v-flex>
                    </v-layout>
                    <v-layout row class="buttons">
                      <GoogleButton @click="signInWithGoogle" />
                      <input
                        class="login-btn signin-btn"
                        type="submit"
                        value="Register"
                        @click="checkRegisterFields"
                      />
                    </v-layout>
                  </form>
                  <v-flex shrink text-xs-right class="forgot-message">
                    <a @click="mode = 'forgot'" class="forgot"
                      >Forgot Password?</a
                    >
                  </v-flex>
                  <v-flex shrink text-xs-center class="login-here-message">
                    Already have an account?
                    <a @click="mode = 'login'" class="register-here"
                      >Login here</a
                    >
                  </v-flex>
                </v-card>

                <v-card v-else-if="mode == 'forgot'" class="forgot-fields">
                  <form @submit.prevent="onForgot" id="forgot-form">
                    <v-layout wrap>
                      <v-flex layout column>
                        <input
                          class="signin-text-input game-login-text-input"
                          v-model="email"
                          type="email"
                          placeholder="EMAIL"
                          required
                        />
                      </v-flex>
                    </v-layout>
                    <v-layout row class="buttons">
                      <GoogleButton @click="signInWithGoogle" />
                      <input
                        class="login-btn signin-btn"
                        type="submit"
                        value="Forgot"
                        @click="checkForgotFields"
                      />
                    </v-layout>
                  </form>
                  <v-flex shrink text-xs-center class="login-here-message">
                    Already have an account?
                    <a @click="mode = 'login'" class="register-here"
                      >Login here</a
                    >
                  </v-flex>
                </v-card>

                <v-card v-else-if="mode == 'anonymous'">
                  <form @submit.prevent="signInUserAnonymously">
                    <v-layout column>
                      <v-flex style="text-align: center; margin-bottom: 10px">
                        <b> Enter your first and last name to enter</b>
                      </v-flex>

                      <template v-if="customInputType === 'select'">
                        <v-flex d-flex>
                          <v-layout row>
                            <v-flex d-flex xs8>
                              <v-layout column>
                                <v-flex>
                                  <input
                                    class="signin-text-input game-login-text-input"
                                    v-model="firstname"
                                    type="text"
                                    placeholder="First Name"
                                    required
                                  />
                                </v-flex>
                                <v-flex>
                                  <input
                                    class="signin-text-input game-login-text-input"
                                    v-model="lastname"
                                    type="text"
                                    placeholder="Last Name"
                                    required
                                  />
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex d-flex xs4 ml-2 class="game-login-dropdown">
                              <v-select
                                v-model="customInput"
                                :items="customInputOptions"
                                :label="customInputLabel"
                                item-text="label"
                                item-value="value"
                                background-color="#e1e1e1"
                                dense
                                single-line
                                loader-height="40"
                                color="#413d3c"
                                required
                              />
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </template>

                      <template v-else-if="customInputType === 'text'">
                        <v-flex d-flex>
                          <v-layout row>
                            <v-flex d-flex xs8>
                              <v-layout column>
                                <v-flex>
                                  <input
                                    class="signin-text-input game-login-text-input"
                                    v-model="firstname"
                                    type="text"
                                    placeholder="First Name"
                                    required
                                  />
                                </v-flex>
                                <v-flex>
                                  <input
                                    class="signin-text-input game-login-text-input"
                                    v-model="lastname"
                                    type="text"
                                    placeholder="Last Name"
                                    required
                                  />
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex d-flex xs4 ml-2>
                              <input
                                class="signin-text-input game-login-text-input"
                                v-model="customInput"
                                type="text"
                                :placeholder="customInputLabel"
                              />
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </template>

                      <template v-else-if="customInputType === 'password'">
                        <v-flex d-flex>
                          <v-layout row>
                            <v-flex d-flex xs8>
                              <v-layout column>
                                <v-flex>
                                  <input
                                    class="signin-text-input game-login-text-input"
                                    v-model="firstname"
                                    type="text"
                                    placeholder="First Name"
                                    required
                                  />
                                </v-flex>
                                <v-flex>
                                  <input
                                    class="signin-text-input game-login-text-input"
                                    v-model="lastname"
                                    type="text"
                                    placeholder="Last Name"
                                    required
                                  />
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex d-flex xs4 ml-2>
                              <input
                                class="signin-text-input game-login-text-input"
                                v-model="customInput"
                                type="password"
                                autocomplete="new-password"
                                :placeholder="customInputLabel"
                              />
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </template>

                      <template v-else>
                        <input
                          class="signin-text-input game-login-text-input"
                          v-model="firstname"
                          type="text"
                          placeholder="First Name"
                          required
                        />
                        <input
                          class="signin-text-input game-login-text-input"
                          v-model="lastname"
                          type="text"
                          placeholder="Last Name"
                          required
                        />
                      </template>
                    </v-layout>

                    <v-layout row>
                      <input
                        class="login-btn signin-btn anonymous-btn"
                        type="submit"
                        value="Join The Game"
                      />
                    </v-layout>
                  </form>
                </v-card>

                <v-card v-else-if="mode == 'anonymous-password'">
                  <form @submit.prevent="signInUserAnonymouslyWithPassword">
                    <v-layout column>
                      <input
                        class="signin-text-input"
                        v-model="firstname"
                        type="text"
                        placeholder="First Name"
                        required
                      />
                      <input
                        class="signin-text-input"
                        v-model="lastname"
                        type="text"
                        placeholder="Last Name"
                        required
                      />
                      <input
                        class="signin-text-input"
                        v-model="password"
                        autocomplete="password"
                        type="password"
                        placeholder="PASSWORD"
                        required
                      />
                    </v-layout>
                    <v-layout row>
                      <input
                        class="login-btn signin-btn anonymous-btn"
                        type="submit"
                        value="Join The Game"
                      />
                    </v-layout>
                  </form>
                </v-card>

                <v-card
                  v-else-if="mode == 'reset-pass-status'"
                  class="passRestStatus"
                >
                  <v-flex shrink text-xs-center class="login-here-message">
                    <span class="text">
                      Your Password is reset. Please check your email.
                    </span>
                  </v-flex>

                  <v-flex shrink text-xs-center class="login-here-message">
                    Already have an account?
                    <a @click="mode = 'login'" class="register-here"
                      >Login here</a
                    >
                  </v-flex>
                </v-card>
                <v-card v-else-if="mode == 'reset-password'">
                  <form @submit.prevent="onResetPassword">
                    <v-layout column>
                      <input
                        class="signin-text-input"
                        v-model="password"
                        type="password"
                        placeholder="New password"
                        required
                        minlength="8"
                      />
                    </v-layout>
                    <v-layout row>
                      <input
                        class="login-btn signin-btn anonymous-btn"
                        type="submit"
                        value="Reset password"
                      />
                    </v-layout>
                  </form>
                </v-card>
                <v-card v-else-if="mode == 'verify-email'">
                  <form @submit.prevent="onVerifyEmailContinue">
                    <v-layout column class="text-message">
                      <v-flex text-xs-center>
                        Your email has been verified
                      </v-flex>
                      <v-flex text-xs-center>
                        You can now sign in with your new account
                      </v-flex>
                    </v-layout>
                    <v-layout row>
                      <input
                        class="login-btn signin-btn anonymous-btn"
                        type="submit"
                        value="Continue"
                      />
                    </v-layout>
                  </form>
                </v-card>
              </transition>
            </template>
          </v-layout>
        </RtbCard>
      </v-flex>

      <v-flex v-if="!$theme.getMeta('hideGoGame')" class="start-your-own">
        <a
          target="_blank"
          href="https://www.thegogame.com/team-building/remote-game?hsCtaTracking=c99293dc-e833-4ae1-a2c5-74496e764663%7C44e1ced1-c732-4506-bca7-f26ef4eaaf93"
          class="gogame-url"
        >
          <img src="@/assets/gogame-link.png" />
        </a>
      </v-flex>
      <v-flex text-xs-center shrink class="copyrights">
        <p>
          <a
            class="cta"
            href="https://www.thegogame.com/team-building/remote-game?hsCtaTracking=c99293dc-e833-4ae1-a2c5-74496e764663%7C44e1ced1-c732-4506-bca7-f26ef4eaaf93"
            target="_blank"
            >Start Your Own Game!</a
          >
        </p>
        <p>
          Copyright ©️ {{ currentYear }} The Go Game. All rights reserved.
          <a
            href="https://www.thegogame.com/team-building/privacy-policy/"
            target="_blank"
            >Privacy Policy</a
          >
        </p>
      </v-flex>
    </v-layout>
    <GameLoginOverlay v-else />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import RtbCard from "@/components/GroupTeams/Common/Games/RtbCard"
import SigninCarousel from "@/components/User/SigninCarousel"
import GameLoginOverlay from "./GameLoginOverlay.vue"
import GoogleButton from "@/components/GoogleButton.vue"

import { MODULE_NAME as THEME_MODULE_NAME } from "@/modules/theme/store"
import { MutationTypes as ThemeModuleMutationTypes } from "@/modules/theme/store/mutation-types"

import { OrgService } from "@/services/org.service"
import { fetchGame } from "@/services/game.service"
import { fetchOriginCountry } from "@/services/ip.service"
import { fetchClient } from "@/services/client.service"
import {
  confirmPasswordReset,
  verifyPasswordResetCode,
  applyActionCode
} from "@/services/auth.service"
import { Client, Navigation } from "../../helpers"
import { initializeLogRocket } from "../../services/logrocket.service"
import firebase from "firebase/app"
import { GameLoginModes } from "./types"
import EmailLogin from "./modes/EmailLogin"

import { DEFAULT_IMAGES } from "@/modules/theme/constants"
import {
  MODULE_NAME as AUTH_MODULE_NAME,
  MutationTypes as AuthModuleMutationTypes
} from "@/store/AuthModule"

const LOGIN_FORGET_DELAY = 1000 * 60 * 60 * 24 * 5

class RegionError extends Error {}

export default {
  name: "Gamelogin",
  props: ["id"],
  data() {
    return {
      modes: { ...GameLoginModes },
      ready: false,
      customInput: null,
      customInputType: null,
      customInputOptions: [],
      email: "",
      mode: GameLoginModes.Anonymous,
      username: "",
      password: "",
      confirmPassword: "",
      imageName: "",
      imageUrl: "",
      firstname: null,
      lastname: null,
      audit: null,
      game: null,
      title: null,
      initializing: true,
      actionCode: null,
      continueUrl: null,
      client: null,
      fetchingClient: false
    }
  },
  components: {
    EmailLogin,
    RtbCard,
    SigninCarousel,
    GameLoginOverlay,
    GoogleButton
  },
  computed: {
    ...mapGetters("auth", ["error", "status"]),
    identifier() {
      return this.customInput
        ? this.customInput
        : this.customInputType &&
          this.customInputOptions &&
          this.customInputOptions.length
        ? // default to the last item on the list
          this.customInputOptions[this.customInputOptions.length - 1]
        : null
    },
    images() {
      const images = this.$theme.getImage("loginCarousel")
      return images && Client.isImagesValid(images)
        ? Client.getNormalizedImages(images)
        : DEFAULT_IMAGES.loginCarousel
    },
    currentYear() {
      var d = new Date()
      return d.getFullYear()
    },
    loading() {
      return this.status === "loading" || this.initializing
    },
    emailIsNotVerified() {
      return this.status === "email_not_verified"
    },
    customID() {
      return Navigation.parseUrlID(this.id)
    },
    gameID() {
      return this.customID ? this.customID.gameID : null
    },
    clientID() {
      return this.customID ? this.customID.clientID : null
    },
    taglineColor() {
      const { client } = this
      if (client !== null && client.taglineColor !== undefined) {
        return client.taglineColor
      }
      return null
    }
  },
  async created() {
    const {
      audit,
      onboarding,
      anon,
      mode: queryMode,
      oobCode: actionCode,
      continueUrl
    } = this.$route.query
    const now = Date.now()

    let mode = null

    if (audit === "1") {
      this.audit = true
    } else if (audit === "0") {
      this.audit = false
    }

    try {
      if (onboarding === "1") {
        await this.updateLastKnownCheck(null)
      } else if (onboarding === "0") {
        await this.updateLastKnownCheck(Date.now())
      }
    } catch (e) {
      console.warn(e)
    }

    if (queryMode === "resetPassword") {
      mode = "reset-password"
      this.title = "Reset Password"
      this.actionCode = actionCode
      this.continueUrl = continueUrl
      try {
        this.email = await verifyPasswordResetCode(this.actionCode)
      } catch ({ message }) {
        this.updateError({ message })
      }
    } else if (queryMode === "verifyEmail") {
      mode = "verify-email"
      this.title = "Email verification"
      this.continueUrl = continueUrl
      try {
        await applyActionCode(actionCode)
      } catch ({ message }) {
        this.updateError({ message })
      }
    } else {
      if (this.clientID) {
        try {
          this.fetchingClient = true
          const client = await fetchClient({ clientID: this.clientID })
          if (client) {
            this.$store.commit(
              `${AUTH_MODULE_NAME}/${AuthModuleMutationTypes.UPDATE_CLIENT}`,
              { client }
            )

            if (client.themeID) {
              const themes = await OrgService.getOrgThemes(client.orgID)

              if (themes && themes[client.themeID]) {
                this.$store.commit(
                  `${THEME_MODULE_NAME}/${ThemeModuleMutationTypes.SET_THEME}`,
                  themes[client.themeID]
                )
              }
            }
          }

          this.client = client
          // if the number of attendees is blank or 0
          // or the has reached the max capacity
          // then redirect to the link listed right there.
          if (client.redirectURL && !client.maxCapacity) {
            window.location = client.redirectURL
          }
          this.fetchingClient = false

          const {
            orgID,
            gameID: clientGameID,
            tournament,
            logrocket,
            regularLogin,
            customInputType,
            customInputOptions,
            customInputLabel,
            blacklist,
            matchEmails
          } = client

          if (blacklist) {
            let country
            try {
              country = await fetchOriginCountry()
            } catch (e) {
              console.error(e)
              throw new RegionError(`Sorry, we can't detect your region.`)
            }
            if (!country)
              throw new RegionError(`Sorry, we can't detect your region.`)
            if (blacklist.toLowerCase().indexOf(country.toLowerCase()) > -1) {
              throw new RegionError(
                `Sorry, access to this game is limited for your region.`
              )
            }
          }

          if (
            customInputType === "select" &&
            customInputOptions &&
            customInputLabel &&
            typeof customInputOptions === "string"
          ) {
            const array = customInputOptions.split(",")
            if (array.length > 1) {
              this.customInputType = customInputType
              this.customInputOptions = array
              this.customInputLabel = customInputLabel
            }
          } else if (
            (customInputType === "text" || customInputType === "password") &&
            customInputLabel
          ) {
            this.customInputType = customInputType
            this.customInputLabel = customInputLabel
          }

          if (logrocket) initializeLogRocket(this.$store)

          const snapshot = await firebase
            .database()
            .ref(`auth/client/${this.clientID}/auth`)
            .once("value")

          const auth = snapshot.val()
          if (matchEmails && matchEmails.length) {
            mode = GameLoginModes.EmailAndNames
          } else if (regularLogin) {
            mode = "login"
          } else if (anon !== "0" && auth) {
            mode = "anonymous-password"
          }

          const gameID = this.gameID || clientGameID
          let game = null
          if (gameID) {
            game = await fetchGame({ orgID, gameID })
            if (game && game.regularLogin) mode = "login"
          }

          if (tournament) {
            this.title = client.name
          } else if (gameID) {
            const message = `Game ${gameID} not found in client ${this.clientID}`
            if (!game) throw new Error(message)
            const { logrocket, name, externalName } = game
            // Override mode by game setting
            if (logrocket) initializeLogRocket(this.$store)
            this.title = externalName || name
          } else {
            this.title = client.name
          }
        } catch (e) {
          console.warn(e)
          this.ready = true
          this.title = ""
          if (e instanceof RegionError) {
            this.mode = "dead-end"
            await this.updateError({ message: e.message })
            this.initializing = false
            return
          }
        }
      } else {
        mode = "login"
      }

      try {
        if (anon === "1") {
          await this.updateLocalClientSettingLoginTimestamp(0)
        } else if (anon === "0") {
          await this.updateLocalClientSettingLoginTimestamp(now)
          mode = "login"
        } else {
          const settings = await this.getLocalClientSetting({ clientID: "0" })
          const { regular } = settings || {}
          const { timestamp } = regular || {}
          if (timestamp + LOGIN_FORGET_DELAY > now) {
            await this.updateLocalClientSettingLoginTimestamp(now)
            mode = "login"
          }
        }
      } catch (e) {
        console.warn(e)
      }
    }

    if (mode) this.mode = mode

    this.initializing = false

    if (this.mode === GameLoginModes.Anonymous) {
      const { firstname, lastname } = this.$route.query
      this.firstname = firstname
      this.lastname = lastname
      if (firstname && lastname) {
        try {
          await this.signInUserAnonymously()
          return
        } catch (e) {
          console.error(e)
        }
      }
    }

    this.ready = true
  },
  beforeDestroy() {
    this.$store.commit(
      `${THEME_MODULE_NAME}/${ThemeModuleMutationTypes.SET_THEME}`,
      null
    )
  },
  methods: {
    ...mapActions("auth", [
      "updateLastKnownCheck",
      "signGoogleUserIn",
      "signUserIn",
      "signUserInWithEmail",
      "checkEmailMatching",
      "signUserUp",
      "forgotPass",
      "updateError",
      "signUserInAnonymously",
      "signUserInAnonymouslyWithPassword",
      "updateLocalClientSettingLoginTimestamp",
      "getLocalClientSetting",
      "resendEmailVerification"
    ]),
    checkForgotFields() {},
    async signInWithGoogle() {
      await this.signGoogleUserIn({
        audit: this.audit,
        ...Navigation.parseUrlID(this.id)
      })
    },
    async onSignin() {
      await this.signUserIn({
        email: this.email,
        password: this.password,
        audit: this.audit,
        identifier: this.identifier,
        ...Navigation.parseUrlID(this.id)
      })
    },
    async onEmailSignIn({ firstname, lastname, email }) {
      const { clientID, gameID } = Navigation.parseUrlID(this.id)
      const gameEmail = `${clientID}.${email}`
      try {
        await this.checkEmailMatching({ email, client: this.client })
        await this.signUserInWithEmail({
          firstname,
          lastname,
          email: gameEmail,
          identifier: this.identifier,
          orgID: this.client.orgID,
          clientID,
          gameID,
          client: this.client
        })
      } catch (e) {
        console.error(e)
      }
    },
    async onSignup() {
      await this.signUserUp({
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        password: this.password,
        username: this.username,
        image: this.imageUrl,
        audit: this.audit,
        identifier: this.identifier,
        ...Navigation.parseUrlID(this.id)
      })
    },
    async onForgot() {
      await this.forgotPass({ email: this.email })
      this.mode = "reset-pass-status"
    },
    onOkay() {
      if (this.emailIsNotVerified) {
        this.mode = "login"
      } else if (this.continueUrl) {
        // Verification Email or Reset password uses continueUrl.
        // Use redirect method for call created()
        window.location.href = this.continueUrl
        return
      }
      this.updateError({ message: null })
    },
    onResendEmailVerification(event) {
      event.preventDefault()
      this.resendEmailVerification()
    },
    setImage({ dataUrl }) {
      this.imageUrl = dataUrl
    },
    checkRegisterFields() {
      if (this.password !== this.confirmPassword) this.confirmPassword = ""
    },
    async signInUserAnonymously() {
      if (this.customInputType && !this.customInput) {
        return this.updateError({
          message: `${this.customInputLabel} is required`
        })
      }
      await this.signUserInAnonymously({
        firstname: this.firstname,
        lastname: this.lastname,
        audit: this.audit,
        identifier: this.identifier,
        ...Navigation.parseUrlID(this.id)
      })
    },
    async signInUserAnonymouslyWithPassword() {
      if (this.customInputType && !this.customInput) {
        return this.updateError({
          message: `${this.customInputLabel} is required`
        })
      }
      await this.signUserInAnonymouslyWithPassword({
        firstname: this.firstname,
        lastname: this.lastname,
        audit: this.audit,
        password: this.password,
        identifier: this.identifier,
        ...Navigation.parseUrlID(this.id)
      })
    },
    /**
     * Actions on reset password:
     *  - reset password
     *  - login with new password
     *  - continue flow for continueUrl
     * @return {Promise<void>}
     */
    async onResetPassword() {
      try {
        await confirmPasswordReset(this.actionCode, this.password)
        // Get id from continueUrl
        let id = this.continueUrl.split("/").slice(-1).pop().split("?")[0]
        await this.signUserIn({
          email: this.email,
          password: this.password,
          audit: this.audit,
          ...Navigation.parseUrlID(id)
        })
      } catch (er) {
        this.updateError({ message: er.message })
      }
    },
    async onVerifyEmailContinue() {
      window.location.href = this.continueUrl
    }
  }
}
</script>

<style lang="scss">
.fade-enter-active {
  transition-duration: 0.25s;
  transition-delay: 0.1s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-leave-active {
  transition-duration: 0s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
  height: 0;
}

.signin-page {
  background-image: url("../../assets/honeycomb-texture-light.png");
  background-repeat: repeat;
  background-size: 35px;
  background-color: $color-secondary-dark;
  position: relative;
  .forgot-message {
    font-size: 11px;
    padding-top: 2px;
    padding-right: 6px;
    .forgot {
      transition: all 0.1s ease-out;
      color: $color-tertiary-dark;
      &:hover {
        color: $primary_accent_color;
      }
    }
  }
  #forgot-form {
    padding: 44px 0;
  }
  .spinner {
    width: 28px;
    height: 28px;
  }
  .rtb-logo-image {
    position: relative;
    width: auto !important;
    max-height: 100px;
    z-index: 2;
    margin-top: 10px;
    margin-bottom: -17px;
  }
  .carousel {
    width: 100%;
  }
  .register-here {
    padding: 0 2px;
    color: $primary_accent_color;
    font-weight: 500;
    &:hover {
      filter: brightness(0.9);
    }
  }
  .signin-header {
    position: relative;
    padding-top: 18px;
    margin-bottom: 65px;
    background-color: $color-primary-dark;
    border-bottom: 1px solid $primary_accent_color;
  }
  .register-here-message,
  .login-here-message {
    padding-top: 5px;
    margin-bottom: -5px;
    font-size: 18px;
  }
  .spinner-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: $primary_accent_color;
    background: $color-white;
    z-index: 1;

    .spinner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .error-container {
    position: absolute;
    opacity: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: $color-white;
    width: 80%;
    z-index: 2;
  }

  .error-container {
    opacity: 1;
    transition: opacity 0.25s ease-out 1s;
  }

  .signin-rtb-card {
    width: 100%;
    max-width: 670px;
    min-height: 274px;
    padding: 20px 90px;
  }

  .box-wrap {
    align-items: center;
    justify-content: center;
    margin: 0 14px 14px;

    .game-login-dropdown {
      font-size: 20px !important;
      .v-input__slot {
        margin: 0;
        height: 40px;
        &:after,
        &:before {
          display: none !important;
        }
        .v-label {
          color: $color-secondary-dark !important;
          margin-left: 10px;
        }
      }
    }

    .game-login-text-input {
      font-size: 20px;
      padding: 0 12px;
      height: 50px;
      width: 100%;
      margin-bottom: 18px;
      color: $color-secondary-dark;
      background-color: $color-grey-light2;
      border: solid 1px $color-grey-light2;
      &:focus {
        outline: none !important;
        border: solid 1px rgba($color-black, 0.1);
        background-color: $color-grey-light2;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        font-size: 16px !important;
        -webkit-box-shadow: 0 0 0 30px $color-grey-light2 inset !important;
      }
      &::placeholder {
        color: $color-secondary-dark;
      }
    }
  }

  .learn-more-message {
    color: $color-white;
    font-size: 20px;
    font-weight: 200;
    padding: 14px 24px 24px;
    .gogame-url {
      color: $primary_accent_color;
      font-weight: 600;
      &:active,
      &:visited,
      &:focus {
        color: $primary_accent_color;
      }
      &:hover {
        color: $color-white;
      }
    }
  }

  .anonymous-btn {
    margin: auto;
  }
  .signin-btn {
    padding: 2px;
    color: $color-white;
    display: block;
    border-radius: 2px;
    cursor: pointer;
  }
  .login-btn {
    font-size: 24px;
    line-height: 38px;
    height: 38px;
    margin-top: 3px;
    outline: none;
    text-transform: uppercase;
    color: $color-white;
    padding: 0 15px;
    border-radius: 10px;
    font-weight: 600;
    background-color: $primary_accent_color;
    margin-left: auto;
    &:hover {
      filter: brightness(1.1);
    }
  }
  .password-input-wrap {
    margin-top: 10px;
  }

  .header-message {
    position: absolute;
    z-index: 2;
    width: 100%;
    margin: 0;
    padding: 0 30px;
    top: 50%;
    color: $color-white;
    transform: translateY(-50%);

    h1 {
      font-size: 60px;
      line-height: 1;
      font-weight: 500;
      font-style: italic;
      text-transform: uppercase;
    }
  }
  .carousel-wrap {
    height: 100%;
    max-height: 30%;
    min-height: 160px;
    position: relative;
    background-color: $color-primary-dark;
    margin-top: 35px;
    margin-bottom: 20px;
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba($color-primary-dark, 0.5);
    }
  }
  .error-ok {
    display: inline-block;
    flex: 0 0 auto !important;
    text-decoration: none;
    color: $color-white;
    padding: 5px 20px;
    background-color: $primary_accent_color;
    border-radius: 5px;
    &:hover {
      filter: brightness(1.1);
    }
  }
  .error-message {
    color: $color-wrong;
    font-size: 16px;
    overflow: auto;
    margin-bottom: 10px;
    text-align: center;
  }
  .text-message {
    font-size: 16px;
    overflow: auto;
    margin-bottom: 10px;
    text-align: center;
  }
  .header-shape {
    position: absolute;
    width: 770px;
    height: 56px;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    .left-shape,
    .right-shape,
    .center-shape {
      position: absolute;
      background-color: $color-primary-dark;
    }
    .left-shape {
      left: 0;
      border-bottom: 1px solid $primary_accent_color;
    }
    .right-shape {
      right: 0;
      border-right: 1px solid $primary_accent_color;
    }
    .center-shape {
      width: calc(100% - 78px);
      height: 58px;
      left: 39px;
      top: -2px;
      border-bottom: 1px solid $primary_accent_color;
    }
    .left-shape,
    .right-shape {
      width: 78px;
      height: 78px;
      margin-top: -38px;
      transform: rotate(45deg);
    }
    .tagline {
      position: absolute;
      left: 50%;
      bottom: 0;
      width: max-content;
      max-width: calc(100% - 112px);
      font-weight: 600;
      padding: 4px 20px;
      font-size: 18px;
      line-height: 1.25em;
      color: $color-white;
      background: $primary_accent_color;
      transform: translate(-50%, 50%);
      clip-path: polygon(
        12px 0%,
        calc(100% - 12px) 0%,
        100% 50%,
        calc(100% - 12px) 100%,
        12px 100%,
        0% 50%
      );
    }
  }

  .start-your-own {
    position: fixed;
    bottom: calc((100vh - 560px) / 2);
    right: calc((100% - 670px) / 4);
    width: 220px;
    transform: translate(50%, 50%);
    min-height: 30px;
    a,
    a:active,
    a:visited,
    a:focus {
      display: inline-block;
      text-decoration: none;
    }
    img {
      width: 100%;
    }
  }

  .copyrights {
    margin: 0 auto;
    padding: 0 0 15px;
    width: 100%;
    text-align: center;
    transform: none;
    p {
      font-weight: bold;
      display: none;
      color: $secondary_accent_color;
      text-transform: uppercase;
      margin: 0;
      font-size: 15px;
      color: $color-grey-light3;
      font-size: 12px;
      a {
        color: $color-grey-light3;
        &:hover {
          color: $color-grey-light5;
        }
      }
      a.cta {
        display: none;
        font-size: 13px;
        font-weight: bold;
        color: $secondary_accent_color;
        text-transform: uppercase;
        text-decoration: none;
        transition: all 0.1s ease-out;
        &:focus,
        &:active,
        &:visited {
          text-decoration: none;
        }
        &:hover {
          filter: brightness(1.2);
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .signin-page {
    .signin-rtb-card {
      max-width: 600px;
      padding: 20px 50px;
    }
    .start-your-own {
      right: calc((100% - 600px) / 4);
    }
  }
}

@media (max-width: 1300px) {
  .signin-page .header-message h1 {
    font-size: 50px;
  }
}
@media (max-width: 1150px) {
  .signin-page {
    .header-message h1 {
      font-size: 45px;
    }
  }
}
@media (max-width: 950px) {
  .signin-page {
    .header-shape {
      width: 680px !important;
    }
    .header-message h1 {
      font-size: 35px;
    }
    .start-your-own {
      display: none;
    }
    .copyrights p a.cta {
      display: block;
    }
  }
}
@media (max-width: 900px) {
  .signin-page {
    .header-shape {
      width: 600px !important;
    }
  }
}
@media (max-width: 800px) {
  .signin-page {
    .header-shape {
      width: 100% !important;
    }
  }
}

@media (max-width: 650px) {
  .signin-page {
    .header-message h1 {
      font-size: 30px;
    }
  }
}

@media (max-width: 1150px), (max-height: 1000px) {
  .signin-page {
    #forgot-form {
      padding: 33px 0;
    }
    .rtb-logo-image {
      max-width: 320px;
    }
    .header-shape {
      width: 720px;
    }
    .box-wrap {
      .game-login-dropdown {
        font-size: 17px !important;
      }
      .game-login-text-input {
        height: 40px;
        margin-bottom: 10px;
        font-size: 17px;
      }
    }
    .signin-rtb-card {
      max-width: 460px;
      padding: 14px 20px;
      min-height: 213px;
    }
    .register-here-message,
    .login-here-message {
      font-size: 16px;
    }
    .login-btn {
      height: 30px;
      line-height: 32px;
      font-size: 16px;
    }
    .start-your-own {
      margin-left: 15px;
      right: calc((100vw - 460px) / 4);
    }
  }
}

@media (max-height: 920px) {
  .signin-page {
    .rtb-logo-image {
      max-width: 300px;
    }
    .header-shape {
      width: 680px;
    }
    .signin-rtb-card {
      min-height: 203px;
    }
    .game-login-dropdown {
      font-size: 16px !important;
      .v-input__slot {
        height: 35px;
      }
    }
    .game-login-text-input {
      height: 35px;
      font-size: 16px;
    }
  }
}

@media (max-height: 850px) {
  .signin-page {
    .login-btn {
      height: 30px;
      line-height: 30px;
      font-size: 16px;
    }
  }
}

@media (max-height: 800px) {
  .signin-page {
    .rtb-logo-image {
      width: 290px;
    }
    .header-shape {
      height: 50px;
      .left-shape,
      .right-shape {
        width: 70px;
        height: 70px;
        margin-top: -34px;
      }
      .center-shape {
        height: 52px;
        left: 35px;
        width: calc(100% - 70px);
      }
    }
    .carousel-wrap {
      margin-top: 25px;
      margin-bottom: 14px;
    }
    .register-here-message,
    .login-here-message {
      padding-bottom: -10px;
      font-size: 15px;
    }
    .start-your-own {
      bottom: calc((70vh - 200px) / 2);
    }
  }
}

@media (max-height: 750px) {
  .signin-page {
    .rtb-logo-image {
      width: 270px;
    }
    .header-shape .tagline {
      font-size: 17px;
    }
  }
}

@media (max-height: 600px) {
  .signin-page .start-your-own {
    position: absolute;
    bottom: 125px;
  }
}

.v-card,
.rtb-card {
  box-shadow: none !important;
}

#register-form {
  margin: 0 -9px;
}
#register-form .flex.xs6 {
  padding: 0 9px;
}
#register-form .buttons {
  padding: 0 9px;
}
</style>
