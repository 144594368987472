import firebase from "firebase/app"
import "firebase/database"

let chatsSubscriptionRef = null
let chatsSubscription = null

const ChatModule = {
  state: {
    chats: {},
    chat_loading: false,
    allTeams: []
  },
  mutations: {
    setMessagesEmpty(state) {
      state.messages = []
    },
    setTeams(state, payload) {
      state.chats = payload
    },
    setAllTeams(state, payload) {
      state.allTeams = payload
    },
    SET_CHAT_LOADING(state, payload) {
      state.chat_loading = payload
    }
  },
  actions: {
    sendMessage({ commit }, payload) {
      console.log("send = " + payload.username)
      let chatID = payload.chatID
      const message = {
        user: payload.username,
        content: payload.content,
        date: payload.date
      }
      firebase
        .database()
        .ref("messages")
        .child(chatID)
        .child("messages")
        .push(message)
        .then(data => {})
        .catch(error => {
          console.log(error)
        })
    },
    subscribeToChats({ commit }, { orgID, gameID }) {
      if (chatsSubscriptionRef)
        chatsSubscriptionRef.off("value", chatsSubscription)

      chatsSubscriptionRef = firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/teams`)
        .orderByChild("show")
        .equalTo(true)

      return new Promise(resolve => {
        chatsSubscription = chatsSubscriptionRef.on("value", snapshot => {
          commit("setTeams", snapshot.val())
          resolve()
        })
      })
    },
    async createChat({ rootState, dispatch }, payload) {
      const path = "org/" + rootState.orgID + "/game/" + rootState.gameID

      const newPostKey = firebase
        .database()
        .ref(path)
        .child("teams")
        .push().key

      const updates = {}

      updates[path + "/teams/" + newPostKey] = payload

      await dispatch("group/addTeamToGroup", newPostKey)

      await firebase
        .database()
        .ref()
        .update(updates)

      return newPostKey
    },
    async createTeam({ rootState, dispatch }, payload) {
      if (!payload.name)
        throw new Error("Cannot create a new team without a name")
      if (!payload.icon)
        throw new Error("Cannot create a new team withoutan an icon")

      const path = "org/" + rootState.orgID + "/game/" + rootState.gameID
      const promise = await firebase
        .database()
        .ref(path)
        .child("teams")
        .push()

      const teamID = promise.key
      const updates = {}
      updates[path + "/teams/" + teamID] = payload

      await firebase
        .database()
        .ref()
        .update(updates)

      await dispatch("group/addTeamToGroup", teamID)

      const obj = {}
      obj.key = teamID
      obj.iteration = payload.iteration
      return obj
    },
    async deleteTeam({ rootState, dispatch }, payload) {
      if (!payload.id) throw new Error("Cannot delete a team with an ID")
      // first delete from the group as it's still referencing the team object
      // and only then the team itself
      await dispatch("group/removeTeamFromGroup", payload)

      const path = "org/" + rootState.orgID + "/game/" + rootState.gameID
      await firebase
        .database()
        .ref(path + "/teams/" + payload.id)
        .remove()
    },
    async changeTeamName({ rootState }, payload) {
      const path = "org/" + rootState.orgID + "/game/" + rootState.gameID
      await firebase
        .database()
        .ref(path)
        .child("teams/" + payload.id)
        .update({ name: payload.name })
    },
    resetTeams({ rootState: { orgID, gameID } }, { teamIDs }) {
      const update = teamIDs.reduce((acc, val) => {
        acc[`${val}/flippedState`] = "hidden"
        acc[`${val}/submit`] = false
        return acc
      }, {})
      return firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/teams`)
        .update(update)
    },
    async updateTeam({ rootState }, payload) {
      const obj = {
        ...payload,
        color: payload.color || null
      }

      const path = "org/" + rootState.orgID + "/game/" + rootState.gameID
      await firebase
        .database()
        .ref(path)
        .child("teams/" + payload.id)
        .update(obj)
    }
  },
  getters: {
    messages(state) {
      return state.messages
    },
    chats(state) {
      return state.chats
    },
    allTeams(state) {
      return state.allTeams
    }
  }
}

export default ChatModule
