<script>
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"

import SvgIcon from "./base/SvgIcon.vue"

export default Vue.extend({
  name: "GoogleButton",
  functional: true,
  render(h, { data }) {
    return h(
      "button",
      mergeData(data, {
        attrs: { type: "button" },
        staticClass: "google-button"
      }),
      [
        h(SvgIcon, {
          props: { name: "google" },
          staticClass: "google-button__icon"
        }),
        h("span", undefined, "Sign in with Google")
      ]
    )
  }
})
</script>

<style lang="scss">
.google-button {
  font-size: 14px;
  height: 36px;
  padding: 2px 12px 2px 2px;
  border-radius: 2px;
  background-color: $color-blue;
  color: $color-white;

  &__icon {
    width: 32px;
    height: 32px;
    padding: 4px;
    margin-right: 8px;
    background-color: $color-white;
  }

  span {
    vertical-align: middle;
  }
}
</style>
