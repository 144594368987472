var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        {
          staticClass: "black grey--text lobby-toolbar",
          attrs: { app: "", dark: "", height: "56" }
        },
        [
          _c(
            "v-layout",
            { attrs: { "align-center": "" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "toolbar-btn",
                            _vm._g(
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.editSettings()
                                  }
                                }
                              },
                              on
                            ),
                            [_c("svg-icon", { attrs: { name: "cog" } })],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Edit Settings")])]
              ),
              _c(
                "v-toolbar-title",
                {
                  staticClass: "game-name",
                  staticStyle: { "font-size": "15px" },
                  attrs: { width: "400" }
                },
                [
                  _vm.game
                    ? _c("span", { attrs: { id: "theGame" } }, [
                        _vm._v(_vm._s(_vm.game.name))
                      ])
                    : _vm._e()
                ]
              ),
              _c("v-divider", { attrs: { vertical: "" } }),
              _c(
                "v-flex",
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c("center", [
                                _c(
                                  "div",
                                  _vm._g({ staticClass: "auditors" }, on),
                                  [_vm._v(_vm._s(_vm.auditorsCount))]
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("People auditing this game")])]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-layout",
            {
              staticClass: "number-input max-players",
              class: _vm.maxPlayersLength
            },
            [
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.maxPlayers || "--"))
              ]),
              _c("div", { staticClass: "arrows" }, [
                _c("div", {
                  staticClass: "up",
                  on: {
                    mousedown: function($event) {
                      return _vm.handleIncMaxPlayers()
                    }
                  }
                }),
                _c("div", {
                  staticClass: "down",
                  on: {
                    mousedown: function($event) {
                      return _vm.handleDecMaxPlayers()
                    }
                  }
                })
              ]),
              _c("label", [_vm._v("max"), _c("br"), _vm._v("players")])
            ]
          ),
          _c("v-switch", {
            staticClass: "switch-locked",
            attrs: { label: "Game Locked" },
            on: {
              change: function($event) {
                return _vm.toggleLocked()
              }
            },
            model: {
              value: _vm.locked,
              callback: function($$v) {
                _vm.locked = $$v
              },
              expression: "locked"
            }
          }),
          _c("v-divider", { attrs: { vertical: "" } }),
          _vm.allTeams.length < _vm.MAX_NUM_OF_TEAMS
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              {
                                staticClass: "add-team",
                                attrs: { circle: "", small: "", fab: "" },
                                on: { click: _vm.onCreateTeam }
                              },
                              [_c("v-icon", [_vm._v("add")])],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3655908368
                  )
                },
                [_c("span", [_vm._v("Add Team")])]
              )
            : _vm._e(),
          _c(
            "v-layout",
            {
              staticClass: "right-items",
              attrs: { "align-center": "", "justify-end": "" }
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "toolbar-btn",
                            _vm._g(
                              {
                                attrs: {
                                  text: "Team Talk",
                                  theme: "grey",
                                  active: _vm.getEveryoneAudioStatus
                                },
                                on: { click: _vm.handleToggleEveryoneAudio }
                              },
                              on
                            ),
                            [_c("svg-icon", { attrs: { name: "ear" } })],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("All Audio On")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "toolbar-btn",
                            _vm._g(
                              {
                                attrs: {
                                  text: "Mute All",
                                  theme: "grey",
                                  active: _vm.getEveryoneMuted
                                },
                                on: { click: _vm.handleToggleEveryoneMuted }
                              },
                              on
                            ),
                            [_c("svg-icon", { attrs: { name: "mute" } })],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Mute Everyone")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "toolbar-btn",
                            _vm._g(
                              {
                                attrs: {
                                  text: "Local Mute",
                                  theme: "grey",
                                  active: _vm.isUsersLocallyMuted
                                },
                                on: { click: _vm.toggleLocalMute }
                              },
                              on
                            ),
                            [_c("svg-icon", { attrs: { name: "mute" } })],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Mute everyone locally")])]
              ),
              _c("v-divider", { attrs: { vertical: "" } }),
              _c("AudioPlayer", { staticClass: "audio-player" }),
              _c("v-divider", { attrs: { vertical: "" } }),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "div",
                            _vm._g(
                              {
                                staticClass: "dashboard-btn",
                                attrs: { dark: "" },
                                on: { click: _vm.pushToGameSettings }
                              },
                              on
                            ),
                            [_vm._v(" DASH ")]
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Go to Editor")])]
              ),
              _c("v-divider", { attrs: { vertical: "" } }),
              _c(
                "div",
                {
                  staticClass: "logout",
                  on: {
                    click: function($event) {
                      return _vm.logout()
                    }
                  }
                },
                [_vm._v("OUT")]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "div",
                            _vm._g(
                              {
                                staticClass: "game-btn",
                                attrs: { dark: "" },
                                on: { click: _vm.pushToGame }
                              },
                              on
                            ),
                            [_vm._v(" GO TO GAME ")]
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Join the Game")])]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.passedGame
        ? _c(
            "v-dialog",
            {
              attrs: { width: "60%" },
              model: {
                value: _vm.editGameSettings,
                callback: function($$v) {
                  _vm.editGameSettings = $$v
                },
                expression: "editGameSettings"
              }
            },
            [
              _c("GameSettings", {
                attrs: { game: _vm.passedGame },
                on: {
                  closeGameSettings: function($event) {
                    return _vm.closeEdit()
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400px" },
          model: {
            value: _vm.editTeam,
            callback: function($$v) {
              _vm.editTeam = $$v
            },
            expression: "editTeam"
          }
        },
        [
          _c(
            "v-layout",
            { attrs: { "justify-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-toolbar",
                    {
                      attrs: {
                        color: _vm.$theme.get("--primary-accent-color"),
                        dark: "",
                        height: "50px"
                      }
                    },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(_vm._s(_vm.teamModalTitle) + " ")
                      ]),
                      _c("v-spacer")
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "", "grid-list-md": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                [
                                  _c(
                                    "form",
                                    {
                                      on: {
                                        submit: function($event) {
                                          $event.preventDefault()
                                          return _vm.onSignin($event)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              name: "firstname",
                                              label: "Team Name"
                                            },
                                            model: {
                                              value: _vm.team.name,
                                              callback: function($$v) {
                                                _vm.$set(_vm.team, "name", $$v)
                                              },
                                              expression: "team.name"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.teamIcons,
                                              label: "Team Icon",
                                              dense: ""
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "selection",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "team-icon-input selected"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(data.item)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "item",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "team-icon-input"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(data.item) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ]),
                                            model: {
                                              value: _vm.team.icon,
                                              callback: function($$v) {
                                                _vm.$set(_vm.team, "icon", $$v)
                                              },
                                              expression: "team.icon"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "pick-team-btn-cancel",
                                          on: {
                                            click: function($event) {
                                              _vm.editTeam = false
                                            }
                                          }
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                      _vm.teamAction === "edit"
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "pick-team-btn-dismiss",
                                              on: { click: _vm.dismissTeam }
                                            },
                                            [_vm._v(" Dismiss ")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "pick-team-btn-save",
                                          on: { click: _vm.saveTeam }
                                        },
                                        [_vm._v(" Save ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }