<template>
  <div class="host-toolbar">
    <v-toolbar app dark height="56">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <!-- TODO: -->
          <toolbar-btn
            v-on="on"
            @click="editSettings"
            style="margin-left: -8px"
          >
            <svg-icon name="cog" />
          </toolbar-btn>
        </template>
        <span>Edit Settings</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <toolbar-btn v-on="on" @click.shift="resetGame">
            <svg-icon name="sync-alt" />
          </toolbar-btn>
        </template>
        <span>Shift-Click to Reset Game</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <toolbar-btn v-on="on" @click="resetSubmission">
            <svg-icon name="undo-alt" />
          </toolbar-btn>
        </template>
        <span>Reset Mission</span>
      </v-tooltip>

      <v-tooltip bottom v-if="!endGameProgress">
        <template v-slot:activator="{ on }">
          <toolbar-btn v-on="on" @click="onEndGame">
            <svg-icon name="hourglass-end" />
          </toolbar-btn>
        </template>
        <span>End Game</span>
      </v-tooltip>
      <v-progress-circular v-else indeterminate class="spinner" />

      <v-tooltip bottom v-if="!!getScreenshotStream">
        <template v-slot:activator="{ on }">
          <toolbar-btn
            v-on="on"
            @click="captureScreenshot('MANUAL_SCREENSHOT')"
            style="margin-left: -8px"
          >
            <v-icon
              v-if="!processingScreenshotDone"
              :class="{ 'screenshot-processing': getIsProcessingScreenshot }"
            >
              camera
            </v-icon>
            <v-icon v-if="processingScreenshotDone">done</v-icon>
          </toolbar-btn>
        </template>
        <span>Take Screenshot (R)</span>
      </v-tooltip>

      <v-divider vertical />

      <v-flex xs3>
        <v-select
          ref="missionsSelect"
          ml-1
          :items="missionList"
          label="Missions"
          solo
          item-text="name"
          item-value="id"
          :value="computedMissionID"
          class="select-mission v-select--with-icons"
          @focus="disableKeyboardShortcuts"
          @input="onMissionSelectInput"
          @blur="enableKeyboardShortcuts"
        >
          <template #selection="{ item }">
            <div class="selected">
              <svg-icon :name="item.icon" class="v-list__icon" />
              {{ item.pos + 1 }}
              {{ item.name }}
            </div>
          </template>
          <template #item="{ item }">
            <svg-icon :name="item.icon" class="v-list__icon" />
            {{ item.pos + 1 }}
            {{ item.name }}
          </template>
        </v-select>
      </v-flex>

      <v-flex xs1 px-2>
        <v-select
          ref="modesSelect"
          :items="modes"
          solo
          item-text="name"
          item-value="id"
          :value="mode"
          @focus="disableKeyboardShortcuts"
          @input="onModeSelectInput"
          @blur="enableKeyboardShortcuts"
        />
      </v-flex>
      <v-flex>
        <v-tooltip key="auditors" bottom>
          <template v-slot:activator="{ on }">
            <center>
              <div v-on="on" class="auditors">{{ auditorsCount }}</div>
            </center>
          </template>
          <span>People auditing this game</span>
        </v-tooltip>
      </v-flex>
      <v-divider vertical />
      <v-tooltip key="scribe-team" bottom>
        <template v-slot:activator="{ on }">
          <toolbar-btn
            v-on="on"
            size="lg"
            theme="grey"
            @click.exact="onSelectOneScribePerTeam"
            @click.alt="onSelectOneScribePerTeamAlt"
          >
            <svg-icon name="next-scribe" />
          </toolbar-btn>
        </template>
        <span>One Scribe Per Team</span>
      </v-tooltip>

      <v-tooltip v-if="isLipdub" key="scribe" bottom>
        <template v-slot:activator="{ on }">
          <toolbar-btn v-on="on" theme="grey" @click="onSelectOneScribe">
            <svg-icon name="scribe" />
          </toolbar-btn>
        </template>
        <span>Select One Scribe</span>
      </v-tooltip>

      <v-tooltip key="talk" bottom>
        <template v-slot:activator="{ on }">
          <toolbar-btn
            v-on="on"
            text="Team Talk"
            theme="grey"
            :active="getEveryoneAudioStatus"
            @click="handleToggleEveryoneAudio"
          >
            <svg-icon name="ear" />
          </toolbar-btn>
        </template>
        <span>All Audio On</span>
      </v-tooltip>

      <v-tooltip key="mute" bottom>
        <template v-slot:activator="{ on }">
          <toolbar-btn
            v-on="on"
            text="Mute All"
            theme="grey"
            :active="getEveryoneMuted"
            @click="handleToggleEveryoneMuted"
          >
            <svg-icon name="mute" />
          </toolbar-btn>
        </template>
        <span>Mute Everyone</span>
      </v-tooltip>

      <v-divider vertical />

      <v-tooltip key="record" bottom>
        <template v-slot:activator="{ on }">
          <toolbar-btn
            v-on="on"
            theme="grey"
            :active="showRecordingDialog"
            @click="showRecordingDialog = true"
            class="host-toolbar__record-btn"
          >
            <svg-icon name="record" />
          </toolbar-btn>
        </template>
        <span>Record Video</span>
      </v-tooltip>

      <v-tooltip key="headroom" bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
            @click="toggleIsMaxHeadRoomVideo"
            v-if="isMaxHeadRoomVideo && hasFeauredHostVideo"
            class="host-toolbar__play-stop"
          >
            <v-icon>stop</v-icon>
          </v-btn>
          <v-btn
            v-on="on"
            icon
            @click="toggleIsMaxHeadRoomVideo"
            :disabled="!hasFeauredHostVideo"
            class="host-toolbar__play-stop"
            v-else
          >
            <v-icon>play_arrow</v-icon>
          </v-btn>
        </template>
        <span>Toggle Max Headroom</span>
      </v-tooltip>

      <v-divider vertical />

      <v-tooltip v-if="mission && mission.photo" key="photo" bottom>
        <template v-slot:activator="{ on }">
          <toolbar-btn
            v-on="on"
            :active="gameStatus.media === 'photo'"
            @click="toggleGameMedia"
          >
            <svg-icon name="picture" />
          </toolbar-btn>
        </template>
        <span>Show/Hide Mission Photo</span>
      </v-tooltip>

      <v-tooltip v-if="mission && mission.youtube" key="video" bottom>
        <template v-slot:activator="{ on }">
          <toolbar-btn
            v-on="on"
            :active="gameStatus.media === 'video'"
            @click="toggleGameMedia"
          >
            <svg-icon name="video" />
          </toolbar-btn>
        </template>
        <span>Show/Hide Mission Video</span>
      </v-tooltip>

      <v-tooltip v-if="mission && mission.audio" key="audio" bottom>
        <template v-slot:activator="{ on }">
          <toolbar-btn
            v-on="on"
            :active="gameStatus.media === 'audio'"
            @click="toggleGameMedia"
          >
            <svg-icon name="audio" />
          </toolbar-btn>
        </template>
        <span>Show/Hide Mission Audio</span>
      </v-tooltip>

      <div class="flex-grow-1"></div>

      <v-tooltip v-if="displayAnonymusIcon" key="anonymous" bottom>
        <template v-slot:activator="{ on }">
          <toolbar-btn
            v-on="on"
            :active="votingAnon"
            size="lg"
            @click="toggleVotingAnon"
          >
            <svg-icon name="anonymous"></svg-icon>
          </toolbar-btn>
        </template>
        <span>Anonymus voting</span>
      </v-tooltip>

      <v-tooltip v-if="displayTipJarIcon" key="tip-jar" bottom>
        <template v-slot:activator="{ on }">
          <toolbar-btn
            v-on="on"
            :active="game.showTipJar"
            @click="toggleTipJar"
          >
            <svg-icon name="tip-jar" />
          </toolbar-btn>
        </template>
        <span>Show Tip Jar</span>
      </v-tooltip>

      <v-divider vertical v-if="displayAnonymusIcon || displayTipJarIcon" />
      <AudioPlayer v-once class="audio-player" />

      <v-divider vertical />

      <v-tooltip key="link" bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
            class="login-player"
            :to="`/gamelogin/${urlID}?auth=0`"
            target="_blank"
          >
            <v-icon>flash_on</v-icon>
          </v-btn>
        </template>
        <span>Link to {{ game.name }} </span>
      </v-tooltip>

      <v-tooltip key="lobby" bottom>
        <template v-slot:activator="{ on }">
          <toolbar-btn v-on="on" size="lg" @click="pushToLobby">
            <svg-icon name="lobby" />
          </toolbar-btn>
        </template>
        <span>Back to Lobby</span>
      </v-tooltip>

      <v-tooltip key="edit" bottom>
        <template v-slot:activator="{ on }">
          <div dark v-on="on" class="game-editor" @click="pushToGameSettings">
            DASH
          </div>
        </template>
        <span>Edit Missions</span>
      </v-tooltip>
    </v-toolbar>

    <v-dialog
      v-model="editGameSettings"
      v-if="!!passedGame && editGameSettings"
      width="60%"
    >
      <GameSettings :game="passedGame" @closeGameSettings="closeEdit" />
    </v-dialog>
    <end-game-popup ref="endGamePopup" />
    <Popup
      :header="'Record Video for Max HeadRoom'"
      :isOpen="showRecordingDialog"
      @onClose="showRecordingDialog = false"
    >
      <RecordVideo
        :clientID="clientID"
        :gameID="gameID"
        :videoTarget="'game'"
        :hasFeaturedRecording="true"
        @onPosted="onRecordingPosted"
      />
    </Popup>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import { KEY_SPACE, KEY_LEFT } from "keycode-js"
import arrayShuffle from "array-shuffle"

import { GameMission } from "@/entities/GameMission"
import { GameMode } from "@/entities/GameMode"

import { ActionTypes as PlayModuleActionTypes } from "@/store/PlayModule"
import { GameEvent } from "@/event"

import ToolbarBtn from "@/components/GroupTeams/Common/ToolbarBtn.vue"
import AudioPlayer from "@/components/GroupTeams/Misc/AudioPlayer"
import EndGamePopup from "@/components/Popups/EndGamePopup"
import {
  ACTION_CREATE_COPY,
  ACTION_END_GAME,
  ACTION_CLOSE
} from "@/components/Popups/EndGamePopup"
import { TournamentService } from "@/services/tournament/tournament.service"
import { Role } from "@/helpers"
import { PlayerSelector } from "@/entities/game/PlayerSelector"
import Popup from "@/components/Popups/Popup"
import RecordVideo from "@/components/Recording/RecordVideo"

const GameSettings = () => import("@/components/Game/GameSettings")

const pause = (delay = 1000) =>
  new Promise(resolve => setTimeout(resolve, delay))

const GAME_RESET_CONFIRM_MESSAGE =
  "Are you sure you want to RESET THE WHOLE GAME? This is irreversible"
const RESET_MISSION_MESSAGE_TEMPLATE = mission =>
  `Are you sure you want to RESET THIS MISSION: ${mission}?`

const serialize = string =>
  String(string).replace(/:.*/, "").replace(/ /g, "-").toLowerCase()

const TEAM_PLAY_TYPES = [
  undefined,
  null,
  "Team: Speed Matters",
  "Team: Speed Does Not Matter"
]

const MAX_NUMBER_OF_FACTS = 5
const MANUAL_SCREENSHOT = "MANUAL_SCREENSHOT"
const SELECTED = "selected"
const MUTED = "muted"

export default {
  name: "DirectGroup",
  data() {
    return {
      modeToKeep: GameMode.Default,
      passedGame: null,
      editGameSettings: false,
      endGameProgress: false,
      processingScreenshotDone: null,
      missionIDOverride: null,
      isKeyboardShortcutsDisabled: false,
      showRecordingDialog: false
    }
  },
  components: {
    ToolbarBtn,
    GameSettings,
    AudioPlayer,
    EndGamePopup,
    Popup,
    RecordVideo
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.onKeyUp)
    this.$bus.$off(GameEvent.MoveNext, this.advance)
    this.$bus.$off(
      GameEvent.DisableKeyboardShortcuts,
      this.disableKeyboardShortcuts
    )
    this.$bus.$off(
      GameEvent.EnableKeyboardShortcuts,
      this.enableKeyboardShortcuts
    )
  },
  async created() {
    window.addEventListener("keyup", this.onKeyUp)
    this.$bus.$on(GameEvent.MoveNext, this.advance)
    this.$bus.$on(
      GameEvent.DisableKeyboardShortcuts,
      this.disableKeyboardShortcuts
    )
    this.$bus.$on(
      GameEvent.EnableKeyboardShortcuts,
      this.enableKeyboardShortcuts
    )
    this.playerSelector = new PlayerSelector(Object.keys(this.teams))
  },
  // currentMode can't be pickteam
  // this is very interesting...
  watch: {
    getIsProcessingScreenshot(value) {
      if (value) return
      this.processingScreenshotDone = true
      setTimeout(() => {
        this.processingScreenshotDone = false
      }, 2000)
    },
    mission(value) {
      if (value) {
        if (value.behavior === GameMission.Lipdub) {
          this.$store.dispatch(
            PlayModuleActionTypes.RESET_LIPDUB_MISSION_LYRICS_INDEX
          )
        }
      }
    }
  },
  computed: {
    ...mapGetters({ host: "gameHost" }),
    ...mapGetters("screenshot", [
      "getScreenshotStream",
      "getLocalScreenshotStatus",
      "getIsProcessingScreenshot"
    ]),
    ...mapState(["gameID", "orgID"]),
    ...mapState("group", ["modes", "currentGlobalTeam"]),
    ...mapGetters("group", ["isMultiTeam", "votingOverride"]),
    ...mapGetters([
      "getEveryoneAudioStatus",
      "getEveryoneMuted",
      "user",
      "isToolBoxOpen",
      "game",
      "plays",
      "gameStatus",
      "urlID",
      "missions"
    ]),
    ...mapGetters({
      teams: "chats",
      onlineUsers: "onlineUsersArray",
      mode: "getCurrentMode",
      missionID: "currentMission"
    }),
    ...mapGetters("auth", ["clientID", "hasPreGame", "isHost", "user"]),
    mission() {
      return this.missions ? this.missions[this.missionID] : null
    },
    votingAnon() {
      return !!this.gameStatus && !!this.gameStatus.votingAnon
    },
    isLipdub() {
      return !!this.mission && this.mission.behavior === GameMission.Lipdub
    },
    isGameOver() {
      return !!this.mission && this.mission.name == GameMission.GameOver
    },
    isTeamMission() {
      return this.mission
        ? TEAM_PLAY_TYPES.includes(this.mission.playType)
        : false
    },
    computedMissionID() {
      return this.missionIDOverride || this.missionID
    },
    missionList() {
      return Object.entries(this.missions)
        .map(([id, mission]) => ({
          id,
          icon: `missions/${serialize(mission.behavior)}`,
          name: mission.name,
          pos: mission.pos
        }))
        .sort((a, b) => a.pos - b.pos)
    },
    auditorsCount() {
      return this.onlineUsers.filter(({ role }) => role === Role.Audit).length
    },
    displayAnonymusIcon() {
      return this.mode === GameMode.Voting || this.votingOverride
    },
    displayTipJarIcon() {
      return (
        !this.game.tipsDisabled &&
        this.isGameOver &&
        (this.votingOverride ||
          this.mode === GameMode.Voting ||
          this.mode === GameMode.Social ||
          this.mode === GameMode.Meeting ||
          this.mode === GameMode.Over) &&
        this.host &&
        this.host.venmo &&
        this.host.paypal
      )
    },
    originalGameID() {
      return this.game.originalGameID || this.gameID
    },
    isMaxHeadRoomVideo() {
      return this.game?.isMaxHeadRoomVideo || false
    },
    getFeauredHostVideo() {
      return this.game?.featuredRecording?.url
    },
    hasFeauredHostVideo() {
      return !!this.getFeauredHostVideo
    }
  },
  methods: {
    ...mapActions("Games", ["updateGameAny"]),
    ...mapActions([
      "updateUsersInBulk",
      "describeAllUsers",
      "resetTeams",
      "updateCurrentMode",
      "updateGameMedia"
    ]),
    ...mapActions("group", [
      "toggleEveryoneAudio",
      "toggleEveryoneMuted",
      "updateCurrentGlobalTeam",
      "updateCurrentLocalTeam",
      "updateVotingOverride"
    ]),
    ...mapActions("pregame", ["updateMessage"]),
    ...mapActions("screenshot", ["captureScreenshot"]),
    onSelectOneScribe() {
      this.selectNextPlayers({ type: "all" })
      if (this.isLipdub) this.$store.dispatch("incrementLipdubIndex")
    },
    onSelectOneScribePerTeam() {
      this.selectNextPlayers()
      if (this.isLipdub) this.$store.dispatch("incrementLipdubIndex")
    },
    onSelectOneScribePerTeamAlt() {
      this.selectNextPlayers({ add: true })
      if (this.isLipdub) this.$store.dispatch("incrementLipdubIndex")
    },
    async onEndGame() {
      try {
        if (this.game.starter) throw new Error("The game has not started yet")
        if (this.game.tournamentID) {
          this.endGameProgress = true

          const teams = await TournamentService.fetchTournamentGameStatistics(
            this.clientID,
            this.game.tournamentID,
            this.originalGameID
          )

          const gameTeams = Object.values(teams || {}).filter(
            team => team.gameID === this.gameID
          )

          if (!gameTeams.length) {
            await this.saveStatistic()
          } else {
            console.warn("This game has already been ended")
          }

          await this.endGame()
          await this.updateMessage("Bye!")
        } else if (this.hasPreGame) {
          let response = await this.$refs.endGamePopup.open()
          if (response.action === ACTION_CLOSE) return
          this.endGameProgress = true
          if (response.action === ACTION_CREATE_COPY) {
            // Create Copy of the game and set current as deleted
            await this.saveStatistic()
            try {
              await this.endGameAndMakeCopy(response.startIn)
            } catch (e) {
              console.error(e)
              await this.updateMessage(e.message)
              await this.endGame()
              return
            }
          } else if (response.action === ACTION_END_GAME) {
            await this.saveStatistic()
            await this.endGame()
          }
        } else {
          await this.endGame()
        }
      } catch (e) {
        console.error(e)
        await this.updateMessage(e.message)
      } finally {
        this.endGameProgress = false
      }
    },
    async endGameAndMakeCopy(startIn) {
      const now = Date.now()
      if (!this.originalGameID) throw new Error("originalGameID is undefined")
      const newGameID = await this.$store.dispatch(
        "Games/copyFromOriginalGame",
        {
          originalGameID: this.originalGameID,
          orgID: this.game.orgID || this.orgID,
          clientID: this.clientID,
          startIn: startIn,
          hostID: this.user.id
        }
      )
      await this.$store.dispatch("Games/updateGame", {
        ...this.game,
        theKey: this.gameID,
        endTimestamp: now,
        clientID: this.clientID,
        deletedTimestamp: now
      })
      await this.$store.dispatch("recording/copyAnnouncementVideo", {
        clientID: this.clientID,
        srcTargetID: this.gameID,
        distTargetID: newGameID
      })
      await this.$store.dispatch("auth/initializeToGame", {
        gameID: newGameID,
        clientID: this.clientID
      })
    },
    async endGame() {
      await this.$store.dispatch("Games/updateGame", {
        ...this.game,
        theKey: this.gameID,
        endTimestamp: Date.now()
      })
    },
    async saveStatistic() {
      await TournamentService.setTeamsStatistic({
        orgID: this.game.orgID || this.orgID,
        clientID: this.clientID,
        gameID: this.gameID,
        originalGameID: this.originalGameID,
        tournamentID: this.game.tournamentID || 0,
        hostID: this.user.id
      })
    },
    pushToLobby() {
      this.$router.push(`/lobby/${this.urlID}`)
    },
    closeEdit() {
      this.editGameSettings = false
    },
    nextTeam() {
      const teamIDs = Object.keys(this.teams || {})
      const index = teamIDs.findIndex(
        teamID => teamID === this.currentGlobalTeam
      )
      const newTeamID =
        teamIDs.length - 1 < index + 1 ? teamIDs[0] : teamIDs[index + 1]
      if (newTeamID) return this.updateCurrentGlobalTeam(newTeamID)
    },
    prevTeam() {
      const teamIDs = Object.keys(this.teams || {})
      const index = teamIDs.findIndex(
        teamID => teamID === this.currentGlobalTeam
      )
      const newTeamID =
        index - 1 < 0 ? teamIDs[teamIDs.length - 1] : teamIDs[index - 1]
      if (newTeamID) return this.updateCurrentGlobalTeam(newTeamID)
    },
    editSettings() {
      this.passedGame = this.game
      this.passedGame.theKey = this.gameID
      this.editGameSettings = true
    },
    onKeyUp(e) {
      // console.log("THE KEY", e)
      if (this.editGameSettings) return
      if (this.isToolBoxOpen) return
      if (this.isKeyboardShortcutsDisabled) return
      if (e.keyCode == 39) return this.overrideToNextMission()
      if (e.keyCode == KEY_SPACE && !e.shiftKey) return this.onSpacebarUp()
      if (e.keyCode == KEY_SPACE && e.shiftKey)
        return this.onSelectOneScribePerTeam()
      if (e.keyCode == KEY_LEFT) return this.overrideToPreviousMission()
      if (e.keyCode == 221) return this.nextTeam()
      if (e.keyCode == 219) return this.prevTeam()
      if (e.keyCode == 77) return this.handleToggleEveryoneMuted()
      if (e.keyCode == 78) return this.handleToggleEveryoneAudio()
      if (e.keyCode == 81) return this.selectNextPlayers()
      if (e.keyCode == 82) return this.captureScreenshot(MANUAL_SCREENSHOT)
      if (e.keyCode == 13) return this.applyOverrideMissionID()
    },
    onSpacebarUp() {
      const type = this.mission ? this.mission.behavior : null
      const name = this.mission ? this.mission.name : null

      if (
        (type === GameMission.FactMatch && this.mode === GameMode.Results) ||
        (name === GameMission.GameOver && this.mode === GameMode.Over)
      ) {
        this.$bus.$emit(GameEvent.FlipFactMatchCard)
      } else {
        this.advance()
      }
    },
    prevMission() {},
    logout() {
      if (confirm("Are you sure you want LOGOUT?")) this.$router.push("/logout")
    },
    resetGame() {
      if (confirm(GAME_RESET_CONFIRM_MESSAGE)) {
        console.warn("RESETTING ENTIRE GAME BY HOST")
        this.$store.dispatch("removeBuzz")
        this.$store.dispatch("purgeLipdub")
        this.$store.dispatch("deleteVotes")
        this.$store.dispatch("deleteGiphy")
        this.$store.dispatch("deletePlays")
        this.$store.dispatch("drawing/purgeGameDrawings")

        this.navigateTo(this.missionList[0] && this.missionList[0].id)

        const teamIDs = Object.keys(this.teams || {})

        teamIDs.forEach((teamID, i) => {
          this.$store.dispatch("updateTeam", {
            id: teamID,
            totalScore: 0,
            otherTeamFacts: [],
            submit: false,
            flippedState: "hidden",
            name: `Team ${i + 1}`
          })
        })

        this.$store.dispatch("updateGameStatusAny", { flippedTeamID: null })
      }
    },
    toggleVotingAnon() {
      this.$store.dispatch("updateGameStatusAny", {
        votingAnon: !this.votingAnon
      })
    },
    toggleTipJar() {
      this.$store.dispatch("Games/updateGameAny", {
        theKey: this.gameID,
        showTipJar: !this.game.showTipJar
      })
    },
    async pushToGameSettings() {
      await this.$router.push(`/game/${this.urlID}/settings`)
    },
    async resetSubmission() {
      if (
        this.mission &&
        confirm(RESET_MISSION_MESSAGE_TEMPLATE(this.mission.name))
      ) {
        const missionID = this.missionID

        if (this.mission.behavior === GameMission.Lipdub) {
          await this.$store.dispatch(
            PlayModuleActionTypes.RESET_LIPDUB_MISSION_LYRICS_INDEX
          )
        }
        await this.$store.dispatch("drawing/clearTeams")
        await this.$store.dispatch("removeBuzz")
        await this.$store.dispatch("deleteGiphy")
        await this.$store.dispatch("deleteMissionPlays", { missionID })

        const teamIDs = Object.keys(this.teams || {})

        teamIDs.forEach(teamID => {
          this.$store.dispatch("updateTeam", {
            id: teamID,
            flippedState: "hidden"
          })
        })

        await this.$store.dispatch("updateGameStatusAny", {
          flippedTeamID: null
        })
      }
    },
    async advance() {
      if (this.missionIDOverride) this.missionIDOverride = null

      const mission = this.missions ? this.missions[this.missionID] : null

      if (
        mission &&
        mission.behavior === GameMission.Lipdub &&
        this.mode === GameMode.Social
      ) {
        return Promise.all([
          this.$store.dispatch("incrementLipdubIndex"),
          this.selectNextPlayers({ type: "all" })
        ])
      }

      let media = null

      if (
        mission &&
        (this.mode === GameMode.Play ||
          this.mode === GameMode.Huddle ||
          this.mode === GameMode.Social)
      ) {
        if (mission.audio) {
          media = "audio"
        } else if (mission.youtube) {
          media = "video"
        } else if (mission.photo) {
          media = "photo"
        }
      }

      // Just update game media and that's it
      if (media && !this.gameStatus.media)
        return this.updateGameMedia({ media })

      const modes = mission
        ? this.votingOverride
          ? this.modes.filter(mode => mission[mode] && mode !== GameMode.Voting)
          : this.modes.filter(mode => mission[mode])
        : []

      if (!modes.length) modes.push(GameMode.Default)

      if (this.mode === modes[modes.length - 1]) {
        await this.navigateToNextMission()
      } else {
        const index = modes.findIndex(mode => mode === this.mode)
        await this.navigateTo(null, modes[index + 1])
      }
    },
    getCurrentMissionIndex() {
      return this.missionList.findIndex(({ id }) => id == this.missionID)
    },
    applyOverrideMissionID() {
      if (!this.missionIDOverride) return
      const missionID = this.missionIDOverride
      this.missionIDOverride = null
      const mission = this.missions[missionID]
      const [mode] = mission ? this.modes.filter(mode => mission[mode]) : []
      return this.navigateTo(missionID, mode || GameMode.Default)
    },
    overrideToNextMission() {
      const targetID = this.missionIDOverride || this.missionID
      const index = this.missionList.findIndex(({ id }) => id == targetID)
      const newIndex = this.missionList.length - 1 < index + 1 ? 0 : index + 1
      const newMissionID = this.missionList[newIndex].id
      this.missionIDOverride = newMissionID
    },
    overrideToPreviousMission() {
      const targetID = this.missionIDOverride || this.missionID
      const index = this.missionList.findIndex(({ id }) => id == targetID)
      const newIndex = Math.max(index - 1, 0)
      const newMissionID = this.missionList[newIndex].id
      this.missionIDOverride = newMissionID
    },
    navigateToNextMission() {
      const index = this.getCurrentMissionIndex()
      const newIndex = this.missionList.length - 1 < index + 1 ? 0 : index + 1
      const newMissionID = this.missionList[newIndex].id
      const mission = this.missions[newMissionID]
      const [mode] = mission ? this.modes.filter(mode => mission[mode]) : []
      return this.navigateTo(newMissionID, mode || GameMode.Default)
    },
    assignFactsToTeams(missionID) {
      const teamIDs = Object.keys(this.teams || {})
      const users = this.onlineUsers.filter(item => item.role === Role.Player)
      const array = []
      // filling array with all facts available for the
      // current mission

      const plays = this.plays || []
      const factPlays = plays.filter(item => item.missionID === missionID)

      for (let i = 0; i < users.length; i++) {
        const user = users[i]
        const fact = factPlays.find(item => item.userID == user.id)
        if (fact) {
          const obj = {}
          obj.id = user.id
          obj.name = user.firstname
          obj.teamID = user.teamID
          obj.fact = fact
          array.push(obj)
        }
      }

      // setting up a synced list for the team objects
      const promises = teamIDs.map(id => {
        // filter out the facts that belong the current team
        let facts = array.filter(({ fact }) => fact.teamID !== id)
        facts = arrayShuffle(facts)
        // only MAX_NUMBER_OF_FACTS allowed
        if (facts.length > MAX_NUMBER_OF_FACTS) {
          facts = facts.slice(0, MAX_NUMBER_OF_FACTS)
        }
        // add the index prop
        const factsIdx = facts.map((obj, index) => ({ ...obj, index }))
        const obj = {}
        // shuffle once for all teams
        if (factsIdx.length > 1) {
          obj.otherTeamFacts = arrayShuffle(factsIdx)
        } else {
          obj.otherTeamFacts = factsIdx
        }
        obj.id = id
        // update
        return this.$store.dispatch("updateTeam", obj)
      })
      return Promise.all(promises)
    },
    resetVoting() {
      return this.$store.dispatch("updateGameStatusAny", { votingAnon: true })
    },
    toggleGameMedia() {
      let media = null
      if (this.mission.audio) {
        media = "audio"
      } else if (this.mission.youtube) {
        media = "video"
      } else if (this.mission.photo) {
        media = "photo"
      }
      this.updateGameMedia({
        media: this.gameStatus && this.gameStatus.media === media ? null : media
      })
    },
    async selectNextPlayers({ type, delay, add } = {}) {
      if (delay) await pause(this.isMultiTeam ? 1500 : 200)

      const map = {}
      const teamIDs = Object.keys(this.teams || {})

      // only the ones that have a video stream can be scribes
      const users = this.onlineUsers
        .filter(
          user => !user.onboarding && !user.skip && user.role === Role.Player
        )
        .sort((a, b) => {
          if (a.id < b.id) return -1
          if (a.id > b.id) return 1
          return 0
        })

      if (!users.length) return

      // describe all
      if (!add) {
        this.onlineUsers.forEach(({ id: userID }) => {
          map[userID] = {
            userID,
            collection: [{ key: SELECTED, value: false }]
          }
        })
      }
      // here we selectively override the map of players
      if (!this.isTeamMission) {
        if (this.isMultiTeam) {
          const usersGroupedByTeamID = users.reduce((acc, val) => {
            if (acc[val.teamID]) {
              acc[val.teamID].push(val)
            } else {
              acc[val.teamID] = [val]
            }
            return acc
          }, {})
          Object.values(usersGroupedByTeamID)
            .map(users => arrayShuffle(users).splice(0, 2))
            .reduce((acc, val) => {
              acc = acc.concat(val)
              return acc
            }, [])
            .forEach(({ id: userID }) => {
              map[userID] = {
                userID,
                collection: [{ key: SELECTED, value: true }]
              }
            })
        } else {
          users.forEach(({ id: userID }) => {
            map[userID] = {
              userID,
              collection: [{ key: SELECTED, value: true }]
            }
          })
        }
      } else if (type === "all") {
        const user = this.getNextPlayer({ users })
        if (!user) return console.warn(`Cannot select the next scribe for all`)
        const { id: userID } = user
        map[userID] = {
          userID,
          collection: [
            { key: SELECTED, value: true },
            { key: MUTED, value: false }
          ]
        }
      } else {
        // select onegetNextPlayer scribe in a team
        teamIDs.forEach(teamID => {
          const user = this.getNextPlayer({ teamID, users })
          if (!user)
            return console.warn(
              `Cannot select the next scribe for team ${teamID}`
            )
          const { id: userID } = user
          map[userID] = {
            userID,
            collection: [
              { key: SELECTED, value: true },
              { key: MUTED, value: false }
            ]
          }
        })
      }
      await this.updateUsersInBulk({ array: Object.values(map) })
    },
    getNextPlayer({ teamID, users }) {
      try {
        return this.playerSelector.getNextPlayer(users, teamID)
      } catch (e) {
        console.error(e)
        return null
      }
    },
    toggleSocial() {
      if (this.mode !== GameMode.Social) {
        this.modeToKeep = this.mode
        return this.navigateTo(null, GameMode.Social)
      } else {
        this.modeToKeep = null
        return this.navigateTo(null, this.modeToKeep || GameMode.Default)
      }
    },
    async navigateTo(missionID, mode) {
      const mission =
        this.missions && missionID ? this.missions[missionID] : this.mission

      const modes = mission ? this.modes.filter(mode => mission[mode]) : []
      const modeToUse = mode || modes[0]
      let modeTo = null

      const endTime =
        (GameMode.Play || GameMode.Huddle) && mission
          ? Date.now() + (parseInt(mission.time) || 0) * 1000
          : null

      if (
        mission &&
        mission.name !== GameMission.GameOver &&
        !missionID &&
        modeToUse === GameMode.Voting
      ) {
        if (!this.votingOverride) await this.updateVotingOverride(true)
      } else {
        modeTo = modeToUse
      }

      if (modeTo && this.votingOverride) await this.updateVotingOverride(false)

      if (
        (modeTo === GameMode.Social || modeTo === GameMode.Huddle) &&
        mission &&
        mission.behavior === GameMission.FactMatch &&
        mission.freeFormMissionID
      ) {
        await this.assignFactsToTeams(mission.freeFormMissionID)
      }

      await this.$store.dispatch("updateGameStatus2", {
        mission: missionID ? { ...mission, theKey: missionID } : null,
        endTime,
        mode: modeTo
      })

      await this.resetTeams({ teamIDs: Object.keys(this.teams) })

      if (modeTo === GameMode.Over) {
        const array = this.onlineUsers.reduce((acc, val) => {
          acc.push({
            userID: val.id,
            collection: [
              { key: SELECTED, value: false },
              { key: MUTED, value: false }
            ]
          })
          return acc
        }, [])
        await this.updateUsersInBulk({ array })
      } else if (missionID) {
        await this.selectNextPlayers({ delay: true })
      }
    },
    handleToggleEveryoneAudio() {
      this.toggleEveryoneAudio({ mutedStatus: this.getEveryoneAudioStatus })
      this.toggleEveryoneMuted({ mutedStatus: true })
    },
    handleToggleEveryoneMuted() {
      this.toggleEveryoneMuted({ mutedStatus: this.getEveryoneMuted })
      this.toggleEveryoneAudio({ mutedStatus: true })
    },
    async pushToPreGame() {
      await this.$router.push(`/reception/${this.clientID}`)
    },
    /** @param {string} missionID */
    onMissionSelectInput(missionID) {
      this.navigateTo(missionID)
      this.$refs.missionsSelect.blur()
    },
    /** @param {string} modeID */
    onModeSelectInput(modeID) {
      this.navigateTo(null, modeID)
      this.$refs.modesSelect.blur()
    },
    disableKeyboardShortcuts() {
      this.isKeyboardShortcutsDisabled = true
    },
    enableKeyboardShortcuts() {
      this.isKeyboardShortcutsDisabled = false
    },
    onRecordingPosted() {
      // Close popup after half second
      setTimeout(() => (this.showRecordingDialog = false), 500)
    },
    toggleIsMaxHeadRoomVideo() {
      if (!this.getFeauredHostVideo) return
      let obj = {
        theKey: this.game.theKey,
        isMaxHeadRoomVideo: !this.game.isMaxHeadRoomVideo
      }
      this.updateGameAny(obj)
    }
  }
}
</script>

<style lang="scss">
.v-menu__content {
  border-radius: 0;
  border-bottom: solid 4px $primary_accent_color;
}
.v-list__tile {
  height: 25px;
  &:hover {
    background: $color-grey-light1 !important;
  }
}
.v-list__tile.primary--text {
  color: $primary_accent_color !important;
}

.v-input__icon .primary--text {
  color: $color-white !important;
}

.v-select__selection {
  padding-left: 12px;
  margin: 0;
}

.v-dialog__content {
  .v-dialog {
    overflow: visible;
  }
}

.game-settings-wrap {
  .v-dialog {
    overflow: visible;
  }
}

.host-toolbar {
  &__play-stop {
    margin-left: -0px !important;
    margin-right: -5px !important;
  }

  &__record-btn {
    margin-left: -5px !important;
    margin-right: -4px !important;
  }

  .direct {
    background-color: $color-red;
  }

  .v-select__selections {
    position: relative;
  }

  .v-list {
    padding: 0;
  }

  .theme--dark.v-toolbar {
    background: $color-black;
  }

  .v-toolbar {
    z-index: $layer_toolbar;
    border-bottom: solid 1px $primary_accent_color;

    .v-toolbar__content {
      padding: 0 20px;
      & > .flex {
        height: 100%;
      }
    }
  }

  .menu {
    width: 40px;
    margin: 15px 30px 0 0;
    cursor: pointer;
    span {
      display: block;
      height: 2px;
      margin-bottom: 8px;
      background: $primary_accent_color;
      transition: all 0.1s ease-out;
    }
    &:hover span {
      background: $color-white;
    }
  }

  .audio-player .select-audio.v-select {
    margin-top: 4px;
    &.v-input--is-label-active {
      margin-top: 9px;
    }
  }
  .audio-player .v-btn--icon.stop-btn,
  .audio-player .v-btn--icon.prev-btn,
  .audio-player .v-btn--icon.next-btn {
    margin-top: 4px !important;
  }

  .v-select {
    height: 25px;
    margin-top: 14px;
  }
  .v-select__slot {
    height: 25px;
  }
  .v-input__slot {
    min-height: unset;
  }
  .v-text-field.v-text-field--solo .v-input__control {
    min-height: unset;
    height: 16px;
  }
  .theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
    background: $color-tertiary-dark;
    border-radius: 0;
  }
  .v-select--is-menu-active.theme--dark.v-text-field--solo
    > .v-input__control
    > .v-input__slot {
    background: $primary_accent_color;
  }
  .v-text-field,
  .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
    padding: 0;
  }

  .v-toolbar__content .v-btn--icon,
  .v-toolbar__extension .v-btn--icon {
    margin: 9px;
    height: 35px;
  }
  .v-btn--icon:before {
    border-radius: 0;
  }

  .v-select__selections .v-select__selection {
    margin: 0;
  }

  .next-mode {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .v-divider--vertical {
    height: 35px;
    min-height: 35px;
    max-height: 35px;
    margin: 9px 10px;
    background: $color-grey2;
  }

  .end-game-icon {
    margin-top: 2px;
    display: inline-block;
    font-size: 23px;
    cursor: pointer;
    &:hover {
      color: $primary_accent_color;
    }
  }

  .auditors {
    position: relative;
    display: inline-block;
    height: 31px;
    margin: 10px 5px;
    line-height: 31px;
    color: $color-white;
    font-weight: 500;
    font-size: 20px;
    opacity: 0.5;
    cursor: default;
  }
  .login-player {
    margin-left: 0 !important;
    margin-right: 5px !important;
  }

  .game-editor {
    background: $primary_accent_color;
    padding: 6px 11px;
    margin-left: 10px;
    margin-right: 10px;
    height: 25px;
    line-height: 14px;
    color: $color-white;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    transition: all 0.1s ease-out;
    &:hover {
      color: $primary_accent_color;
      background: $color-white;
    }
  }

  .edit-settings {
    margin-right: 10px;
  }

  .logout {
    margin-top: 15px;
    margin-left: 10px;
    height: 22px;
    line-height: 21px;
    font-size: 12px;
    white-space: nowrap;
    text-align: center;
    border: solid 1px $primary_accent_color;
    border-radius: 12px;
    font-weight: 600;
    color: $color-grey-light4;
    padding: 0 15px;
    cursor: pointer;

    &:hover {
      color: $color-white;
      background: $primary_accent_color;
    }
  }

  .auditors-online {
    font-weight: bolder;
    font-size: 18px;
    border: 1px solid $primary_accent_color;
    padding: 2px;
    border-radius: 5px;
  }

  .screenshot-processing {
    animation: rotation 3s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
</style>
