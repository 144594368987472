<template>
  <v-flex class="recording-indicator">
    <v-tooltip right v-model="show" :close-delay="1500">
      <template v-slot:activator="{ on }">
        <v-flex
          d-flex
          align-center
          @mouseover="mouseover"
          @mouseleave="mouseleave"
          @click="click"
        >
          <v-icon class="recording-indicator__icon">
            fiber_manual_record
          </v-icon>
          <span class="recording-indicator__text">LIVE</span>
        </v-flex>
      </template>
      <span @mouseover="mouseover" @mouseleave="mouseleave">
        <template v-if="!!copiedText">
          {{ copiedText }}
        </template>
        <template v-else>
          <a class="recording-indicator__href" @click.prevent="copy">
            {{ boradcastUrl }}
          </a>
        </template>
      </span>
    </v-tooltip>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "RecordingIndicator",
  computed: {
    ...mapGetters("screenshot", ["getInuseTwitchAccount"]),
    boradcastUrl() {
      return this.getInuseTwitchAccount && this.getInuseTwitchAccount.url
    }
  },
  data() {
    return {
      show: false,
      copiedText: null,
      hoverTimeout: null
    }
  },
  watch: {
    show(value) {
      if (!value) {
        setTimeout(() => {
          this.copiedText = null
        }, 300)
      }
    }
  },
  methods: {
    mouseover() {
      this.show = true
      clearTimeout(this.hoverTimeout)
    },
    mouseleave() {
      this.hoverTimeout = setTimeout(() => {
        this.show = false
      }, 1000)
    },
    click() {
      const win = window.open(this.boradcastUrl, "_blank")
      win.focus()
    },
    copy() {
      const el = document.createElement("textarea")
      el.value = this.boradcastUrl
      el.setAttribute("readonly", "")
      el.style.position = "absolute"
      el.style.left = "-9999px"
      document.body.appendChild(el)
      el.select()
      document.execCommand("copy")
      document.body.removeChild(el)
      this.copiedText = "Copied!"
      this.mouseleave()
    }
  }
}
</script>

<style lang="scss">
.recording-indicator {
  @keyframes bounce {
    0% {
      text-shadow: 0px 0px 2px rgb(249, 0, 62), 0px 0px 2px rgba(0, 0, 0, 0.5);
      transform: scale(1);
    }
    50% {
      text-shadow: 0px 0px 4px rgb(249, 0, 62), 0px 0px 2px rgba(0, 0, 0, 0.5);
      transform: scale(1.1);
    }
    100% {
      text-shadow: 0px 0px 2px rgb(249, 0, 62), 0px 0px 2px rgba(0, 0, 0, 0.5);
      transform: scale(1);
    }
  }

  .v-tooltip__content {
    pointer-events: auto;
  }

  cursor: pointer;
  user-select: none;

  &__href {
    text-decoration: underline;
    color: #fff;
  }

  &__icon {
    color: $color-wrong !important;
    font-size: 25px;
    animation-name: bounce;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  &__text {
    color: #fff;
    font-size: 12px;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
  }
}
</style>
