var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "rtb-header" },
    [
      _c(
        "v-layout",
        { staticClass: "rtb-header-wrap" },
        [
          _c(
            "v-layout",
            { attrs: { row: "", shrink: "" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-layout",
                            { staticClass: "logo", attrs: { row: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticStyle: { position: "relative" },
                                  attrs: {
                                    shrink: "",
                                    "d-flex": "",
                                    "align-center": ""
                                  }
                                },
                                [
                                  _vm.customLogo
                                    ? _c("img", {
                                        staticClass: "rtb-header__img-logo",
                                        style: _vm.customLogoStyle,
                                        attrs: { src: _vm.customLogo }
                                      })
                                    : _c("logo-small", {
                                        staticClass: "rtb-header__svg-logo"
                                      })
                                ],
                                1
                              ),
                              !_vm.customLogo
                                ? _c("v-flex", { staticClass: "remote-text" }, [
                                    _vm._v(" REMOTE ")
                                  ])
                                : _vm._e(),
                              !!_vm.headline
                                ? _c(
                                    "v-flex",
                                    { staticClass: "page-headline" },
                                    [_vm._v(" " + _vm._s(_vm.headline) + " ")]
                                  )
                                : _vm._e(),
                              _vm.isHost &&
                              _vm.hasPreGame &&
                              !!_vm.expectedEndTime
                                ? _c("GameEndCountdown", {
                                    attrs: {
                                      endTime: _vm.expectedEndTime,
                                      endTimeLabel: "Round Has Ended",
                                      endInTimeLabel: "Round ends in"
                                    }
                                  })
                                : _vm.isHost && !!_vm.endTime
                                ? _c("GameEndCountdown", {
                                    attrs: { endTime: _vm.endTime }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("version: ")])]
              ),
              _vm._t("headline"),
              _vm._t("potato"),
              _vm._t("speechToText")
            ],
            2
          ),
          _vm._t("wrap")
        ],
        2
      ),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }