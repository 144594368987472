var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { ref: "root", staticClass: "player-icon", class: _vm.getIconClasses },
    [
      _vm.canPlayStars
        ? _c("StarExplosion", {
            ref: "stars",
            staticClass: "star-explosion",
            attrs: {
              radius: _vm.radius,
              nOfStar1: 4,
              nOfStar2: 8,
              maxStar1Size: 10,
              maxStar2Size: 13,
              speed: 0.5,
              shape: _vm.shape
            }
          })
        : _vm._e(),
      _c(
        "RatioContainer",
        {
          on: {
            mouseover: function($event) {
              _vm.active = true
            },
            mouseleave: function($event) {
              _vm.active = false
            },
            dblclick: _vm.onSelected,
            click: function($event) {
              if (!$event.shiftKey) {
                return null
              }
              return _vm.onSkip($event)
            }
          }
        },
        [
          _vm._t("default"),
          _vm.skip && _vm.isHost
            ? _c("div", { staticClass: "player-points" }, [_vm._v("SKIP")])
            : _vm._e(),
          _c(
            "v-flex",
            {
              ref: "circle1",
              staticClass: "player-outline",
              class: _vm.clickable,
              attrs: { "d-flex": "" }
            },
            [
              _c("UserMedia", {
                attrs: {
                  identity: _vm.userID,
                  imageUrl: _vm.imageURL,
                  disable: _vm.covered
                }
              }),
              _vm.isMutable
                ? _c(
                    "div",
                    {
                      staticClass: "mute-btn",
                      class: { active: _vm.showMuteButton }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "v-icon",
                            { staticClass: "icon", on: { click: _vm.onMute } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.isMuted ? "volume_off" : "volume_up"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "player-name" },
                [
                  _vm.isHost && _vm.location
                    ? [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "div",
                                        _vm._g({}, on),
                                        [
                                          _c("UserName", {
                                            attrs: {
                                              firstname: _vm.firstname,
                                              lastname: _vm.lastname
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2745616001
                            )
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.location))])]
                        )
                      ]
                    : [
                        _c(
                          "div",
                          [
                            _c("UserName", {
                              attrs: {
                                firstname: _vm.firstname,
                                lastname: _vm.lastname
                              }
                            })
                          ],
                          1
                        )
                      ]
                ],
                2
              ),
              _c("div", { staticClass: "scribe-indicator" }, [
                _c("div", [
                  _c(
                    "div",
                    { ref: "scribe", staticClass: "scribe-indicator-spans" },
                    [
                      _c("span", [_vm._v("S")]),
                      _c("span", [_vm._v("C")]),
                      _c("span", [_vm._v("R")]),
                      _c("span", [_vm._v("I")]),
                      _c("span", [_vm._v("B")]),
                      _c("span", [_vm._v("E")])
                    ]
                  )
                ])
              ])
            ],
            1
          ),
          _c("div", { ref: "circle2", staticClass: "winner-outline" })
        ],
        2
      ),
      !!_vm.teamPosition
        ? _c("div", { staticClass: "ribbon-wrap" }, [
            _vm.teamPosition === 1
              ? _c("img", {
                  staticClass: "colored-ribbon-image",
                  attrs: {
                    width: "65",
                    src: require("../../../../assets/ribbon-blue.png")
                  }
                })
              : _vm.teamPosition === 2
              ? _c("img", {
                  staticClass: "colored-ribbon-image",
                  attrs: {
                    width: "65",
                    src: require("../../../../assets/ribbon-red.png")
                  }
                })
              : _c("img", {
                  staticClass: "colored-ribbon-image",
                  attrs: {
                    width: "65",
                    src: require("../../../../assets/ribbon-white.png")
                  }
                }),
            _c("div", { staticClass: "ribbon-team-position" }, [
              _c("span", [_vm._v(_vm._s(_vm.teamPosition))]),
              _c("span", { staticClass: "nth" }, [
                _vm._v(_vm._s(_vm.positionSuffix))
              ])
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }