var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "RtbCard",
    { staticClass: "meeting-announcement-card" },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "justify-center": "" } },
        [
          _c("v-flex", { staticClass: "text-md-center" }, [
            _vm._v(" " + _vm._s(_vm.game.meetingAnnouncement) + " ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }