exports.local = "http://localhost:5001/chat-remote/us-central1/"
exports.remote = "https://remoteteambuilding.com/API/"
// exports.remote = "http://localhost:5001/rtb-staging/us-central1/API/"

// Return which API URL to use for the current development
function getApiUrl() {
  // if local deveopment
  const appMode = process.env.VUE_APP_MODE
  if (appMode && appMode === "local")
    return "https://remoteteambuilding.com/API/"
  if (appMode && appMode === "testing")
    return "https://rtb-testing-beta.firebaseapp.com/API/"

  switch (process.env.NODE_ENV) {
    case "rtbdevelopment":
      return "https://rtb-dev2000.firebaseapp.com/API/"
    case "production":
      return "https://remoteteambuilding.com/API/"
    case "development":
      return "https://rtb-dev2000.firebaseapp.com/API/"
    case "staging":
      return "https://rtb-staging.firebaseapp.com/API/"
    case "testing":
      return "https://rtb-testing-beta.firebaseapp.com/API/"
    default:
      return "https://rtb-dev2000.firebaseapp.com/API/"
  }
}

exports.getApiUrl = getApiUrl
