/**
 * HostPosition
 * @readonly
 * @enum {string} HostPosition
 */
export const HostPosition = {
  Default: "default",
  Minimized: "minimized",
  Rouned: "rounded"
}
