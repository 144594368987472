var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "signin-carousel", attrs: { "d-flex": "" } },
    _vm._l(_vm.display, function(url, i) {
      return _c(
        "v-flex",
        {
          key: i + "-slide",
          staticClass: "carousel-slide",
          style: "width: " + 100 / _vm.slidesPerRow + "%"
        },
        [
          _c("transition", { attrs: { name: "image-transition" } }, [
            _c("img", {
              key: url,
              staticClass: "carousel-image",
              attrs: { src: url, alt: "" }
            })
          ])
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }