<template>
  <div style="margin-top: 20px">
    <h2>Screenshot Settings</h2>
    <div>
      <v-switch v-model="isScreenSharingEnabled" label="Enable Screenshot">
        <template v-slot:label>
          <span class="v-label-white">{{
            !isScreenSharingEnabled ? "Enable Screenshot" : "Disable Screenshot"
          }}</span>
        </template>
      </v-switch>
    </div>
    <div>
      <v-btn @click="downloadScreenshots()" class="rtb-button">{{
        processingDownload ? "processing..." : "Download Screenshots"
      }}</v-btn>
    </div>
    <div>
      <v-select
        v-if="isScreenSharingEnabled"
        class="rtb-select"
        :items="getFreeTwitchAccounts"
        label="Twitch Accounts"
        item-text="name"
        item-value="key"
        v-model="twitchKey"
        solo
        dark
      />
    </div>
    <div class="bradcast-link" v-if="getIsBroadcastingDataToTwitch">
      Broadcasting to:
      <a :href="getInuseTwitchAccount.url" target="_blank"
        >{{ getInuseTwitchAccount.url }}
      </a>
    </div>
    <div
      v-if="
        isScreenSharingEnabled &&
        (userHasTwitchAccount || getFreeTwitchAccounts.length)
      "
    >
      <v-btn
        @click="handleStreamButtonClicked"
        class="rtb-button"
        :disabled="getIsProcessingBroadcastingDataToTwitch"
        >{{
          !getIsBroadcastingDataToTwitch
            ? "Broadcast Game"
            : "Stop Broadcast..."
        }}</v-btn
      >
    </div>
    <div v-if="isScreenSharingEnabled && !getFreeTwitchAccounts.length">
      All twitch accounts are in use
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { downloadScreenshot } from "@/services/api.service"

export default {
  name: "Screenshot",
  data() {
    return {
      processingDownload: null,
      twitchKey: null
    }
  },
  watch: {
    async isScreenSharingEnabled(value) {
      if (value) {
        this.subscribeToTwitchAccounts()
      } else {
        await this.unsubscribeToTwitchAccounts()
        this.stopTwitchBroadcast()
      }
    },
    userOnlineStatus(status) {
      if (status === "online" && this.getIsBroadcastingDataToTwitch) {
        this.cancelOnDisconnectActions()
        this.onDisconnectActions()
      }
      console.log("status changed ==>>>", status)
    }
  },
  computed: {
    ...mapGetters("screenshot", [
      "getScreenshotStream",
      "getLocalScreenshotStatus",
      "getIsBroadcastingDataToTwitch",
      "getTwitchAccounts",
      "getFreeTwitchAccounts",
      "getInuseTwitchAccount",
      "getIsProcessingBroadcastingDataToTwitch"
    ]),
    ...mapGetters("pregame", ["gamesArray"]),
    ...mapGetters(["orgID", "gameID"]),
    ...mapGetters("auth", ["token", "user"]),
    isScreenSharingEnabled: {
      get() {
        return this.getLocalScreenshotStatus
      },
      async set(status) {
        this.localToggleScreenShot(status)
      }
    },
    userHasTwitchAccount() {
      return this.user.twitchAccount
    },
    userOnlineStatus() {
      return this.user.status
    }
  },
  async beforeDestroy() {
    window.removeEventListener("beforeunload", this.stopTwitchBroadcast())
    if (Object.keys(this.getInuseTwitchAccount).length) {
      await this.updateTwitchAccount({
        key: this.getInuseTwitchAccount.key,
        obj: {
          inuse: false,
          gameDetail: {},
          timestamp: null
        }
      })
    }
    await this.unsubscribeToTwitchAccounts()
  },
  created() {
    window.addEventListener("beforeunload", this.stopTwitchBroadcast())
  },
  methods: {
    ...mapActions("screenshot", [
      "localToggleScreenShot",
      "streamToTwitch",
      "stopTwitchStreaming",
      "subscribeToTwitchAccounts",
      "unsubscribeToTwitchAccounts",
      "updateTwitchAccount",
      "onDisconnectActions",
      "cancelOnDisconnectActions"
    ]),
    ...mapActions("Games", ["updateGameAny"]),
    async downloadScreenshots() {
      try {
        this.processingDownload = true
        const response = await downloadScreenshot({
          token: this.token,
          gameID: this.gameID,
          orgID: this.orgID
        })
        this.processingDownload = false
        if (response.status !== "success") throw new Error(response.message)

        let a = document.createElement("a")
        a.setAttribute("href", response.data.url)
        a.setAttribute("target", "_blank")
        a.click()
      } catch (e) {
        this.processingDownload = false
        console.log(e.message)
      }
    },
    async broadcastStream() {
      const twitchAccount =
        this.getTwitchAccounts.find(
          account => account.key === this.twitchKey
        ) || {}
      try {
        await this.streamToTwitch(twitchAccount)
      } catch (e) {
        console.warn(e.message)
      }
    },
    async stopTwitchBroadcast() {
      try {
        this.stopTwitchStreaming(this.getInuseTwitchAccount.key)
      } catch (e) {
        console.warn(e.message)
      }
    },
    handleStreamButtonClicked() {
      !this.getIsBroadcastingDataToTwitch
        ? this.broadcastStream()
        : this.stopTwitchBroadcast()
    }
  }
}
</script>

<style lang="scss">
.v-label-white {
  color: white;
  font-weight: bolder;
}
</style>
