import Vue from "vue"
import Vuetify, { VLayout } from "vuetify/lib"
import "vuetify/src/stylus/app.styl"
import "@fortawesome/fontawesome-free/css/all.css"

Vue.use(Vuetify, {
  iconfont: ["md", "fa"],
  components: {
    VLayout
  }
})
