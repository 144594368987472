<template>
  <v-layout column justify-center>
    <div class="social-player">
      <v-flex class="fact-match-player-container" ref="root">
        <RatioContainer class="ratio-container">
          <div class="outline-border">
            <!-- Player -->
            <UserImage v-if="imageOnly" :imageUrl="imageURL" />
            <UserMedia v-else :identity="userID" :imageUrl="imageURL" />
            <!-- Name -->
            <v-layout
              v-if="!imageOnly"
              row
              class="fact-match-player-bottom-info"
            >
              <v-layout column justify-center class="fact-match-player-name">
                <v-flex>
                  <UserName :firstname="firstname" :lastname="lastname" />
                </v-flex>
              </v-layout>
            </v-layout>
            <!-- Team -->
            <div class="fact-match-player-team">
              <div class="fact-match-player-team-bg">
                <v-icon class="fact-match-player-team-icon">
                  {{ teamIcon || "radio_button_unchecked" }}
                </v-icon>
              </div>
            </div>
          </div>
        </RatioContainer>
      </v-flex>
    </div>
  </v-layout>
</template>

<script>
import UserMedia from "@/components/GroupTeams/Common/User/UserMedia"
import UserImage from "@/components/GroupTeams/Common/User/UserImage"
import RatioContainer from "@/components/GroupTeams/Common/RatioContaier"
import UserName from "@/components/GroupTeams/Common/User/UserName"
export default {
  name: "FactMatchPlayer",
  components: {
    RatioContainer,
    UserImage,
    UserMedia,
    UserName
  },
  props: {
    imageURL: String,
    src: MediaStream,
    teamIcon: String,
    userID: String,
    userInfo: Object,
    imageOnly: Boolean
  },
  computed: {
    firstname() {
      return String(this.userInfo.firstname)
    },
    lastname() {
      return String(this.userInfo.lastname)
    }
  }
}
</script>

<style lang="scss" scoped>
.social-player {
  position: relative;
}
.fact-match-player-team {
  position: absolute;
  top: -1px;
  right: -1px;
  color: $color-white;
  font-size: 1.2rem;
}

.fact-match-player-team-bg {
  background-color: $color-white;
  border-radius: 0 0 0 8px;
  width: 41px;
  height: 41px;
  padding-left: 2px;
  padding-bottom: 2px;
  text-align: center;
}
.fact-match-player-team-icon {
  font-size: 30px;
  line-height: 40px;
  color: $color-black;
}
.fact-match-player-bottom-info {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 26px;
  padding-top: 3px;
  padding-left: 3px;
  padding-right: 3px;
  background-color: rgba($color-black, 0.5);
}
.fact-match-player-name {
  width: 100%;
  text-align: center;
  font-size: 0.8em;
  color: $color-white;
  & > div {
    align-self: center;
  }
}
.fact-match-player-container {
  position: relative;
}
.ratio-container {
  overflow: visible;
}
.outline-border {
  @extend .rtb-border, .rtb-box-shadow;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  border-radius: 9px;
  overflow: hidden;
  background-color: $primary_accent_color;
  // This is a fix for not filling half a pixel in
  // chrome for decimal size value of the parent
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $color-white;
  }
}
.inline-border {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 3px solid $color-white;
  background-color: $color-black;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
}
.offline-text {
  position: relative;
  user-select: none;
  color: $color-black;
  text-shadow: 1px 1px 1px rgba($color-white, 0.8);
}
</style>
