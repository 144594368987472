import Vue from "vue"
import Router from "vue-router"
import { store } from "@/store/index"

/** Admin pages */
const Admin = () => import("@/components/Game/Admin")
const Orgs = () => import("@/components/Game/Orgs")
const Hosts = () => import("@/components/Game/Hosts")
const Priority = () => import("@/components/Game/Priority")
const Scripts = () => import("@/pages/Scripts")
const AdminGames = () => import("@/components/Game/AdminGames")
const Twitch = () => import("@/components/Game/Twitch")

const GameEdit = () => import("@/components/Game/Edit")
const ClientSettings = () => import("@/components/Game/ClientSettings")
const Games = () => import("@/components/Game/Index")
const Events = () => import("@/components/Game/Events")
const Clients = () => import("@/components/Game/Clients")
const PickTeams = () => import("@/components/GroupTeams/Common/PickTeams")
const Onboard = () => import("@/components/GroupTeams/Common/Onboard")
const GameUsers = () => import("@/components/User/GameUsers")
const Logout = () => import("@/components/User/Logout")
const PreGame = () => import("@/pages/PreGame")
const EventSettings = () => import("@/pages/EventSettings")
const Landing = () => import("@/pages/Landing")

import PlayerGroup from "@/components/GroupTeams/Player"
import GameLogin from "@/components/User/GameLogin"

import { Role, Navigation } from "../helpers"

import { ThemeScope } from "@/modules/theme/store/constants"
import { GuardFactory as ThemeGuardFactory } from "@/modules/theme/router/guard"

Vue.use(Router)

const authGuard = (to, from, next) => {
  const { authorize } = to.meta
  const authorized = store.getters["auth/authorized"]
  const role = store.getters["auth/role"]

  if (authorize) {
    const { id: fromUrlID } = from.params
    const { id: toUrlID } = to.params
    const urlID = toUrlID || fromUrlID
    const { gameID, clientID } = Navigation.parseUrlID(urlID)
    const newUrlID = Navigation.createUrlID({ gameID, clientID })
    // not logged in so redirect to login page with the return url
    if (!authorized) {
      console.warn("Not authorized")
      return next({ path: `/login/${newUrlID}` })
    }
    // check if route is restricted by role
    if (authorize.length && !authorize.includes(role)) {
      console.warn("Not authorized. User role doesn't match")
      return next({ path: `/login/${newUrlID}` })
    }
  }

  console.log(`dispatching ${to.path}`)
  store.dispatch("auth/updateLastKnownUrl", to.path)
  next()
}

const router = new Router({
  routes: [
    {
      path: "/admin",
      name: "admin",
      component: Admin,
      children: [
        {
          path: "/",
          redirect: "/admin/orgs"
        },
        {
          path: "orgs",
          name: "orgs",
          component: Orgs,
          beforeEnter: authGuard,
          meta: { authorize: [Role.Host] }
        },
        {
          path: "hosts",
          name: "hosts",
          component: Hosts,
          beforeEnter: authGuard,
          meta: { authorize: [Role.Host] }
        },
        {
          path: "priority",
          name: "priority",
          component: Priority,
          beforeEnter: authGuard,
          meta: { authorize: [Role.Host] }
        },
        {
          path: "scripts",
          name: "scripts",
          component: Scripts,
          props: true,
          beforeEnter: authGuard,
          meta: { authorize: [Role.Host] }
        },
        {
          path: "games",
          name: "games",
          component: AdminGames,
          props: route => ({ time: route.query.time }),
          beforeEnter: authGuard,
          meta: { authorize: [Role.Host] }
        },
        {
          path: "twitch",
          name: "twitch",
          component: Twitch,
          props: route => ({ time: route.query.time }),
          beforeEnter: authGuard,
          meta: { authorize: [Role.Host] }
        }
      ]
    },
    {
      path: "/reception/:id?",
      name: "pregame",
      component: PreGame,
      props: true,
      beforeEnter: authGuard,
      meta: { authorize: [] }
    },
    {
      path: "/reception/:id/settings",
      name: "eventSettings",
      component: EventSettings,
      props: true,
      beforeEnter: authGuard,
      meta: { authorize: [Role.Host] }
    },
    {
      path: "/login/:id?",
      name: "login",
      component: GameLogin,
      props: true
    },
    {
      path: "/gamelogin/:id",
      redirect: to => {
        const { params, query } = to
        if (params.id) {
          return { path: "/login:id?", query }
        } else {
          return { path: "/login", query }
        }
      }
    },
    {
      path: "/lobby/:id",
      name: "lobby",
      component: PickTeams,
      beforeEnter: authGuard,
      props: true,
      meta: { authorize: [], theme: { scope: ThemeScope.GAME } }
    },
    {
      path: "/logout",
      name: "logout",
      component: Logout,
      meta: { authorize: [] }
    },
    {
      path: "/game/:id",
      beforeEnter: authGuard,
      props: true,
      name: "game",
      component: PlayerGroup,
      meta: { authorize: [], theme: { scope: ThemeScope.GAME } }
    },
    {
      path: "/latecomer/:id",
      beforeEnter: authGuard,
      props: true,
      name: "GameLatecomer",
      component: () => import("@/components/Game/GameLatecomer.vue"),
      meta: { authorize: [], applyGameStyles: true }
    },
    {
      path: "/welcome/:id",
      name: "welcome",
      component: Onboard,
      beforeEnter: authGuard,
      meta: { authorize: [] }
    },
    {
      path: "/game/:id/settings",
      name: "gameSettings",
      component: GameEdit,
      beforeEnter: authGuard,
      meta: { authorize: [Role.Host] }
    },
    {
      path: "/games",
      name: "games",
      component: Games,
      beforeEnter: authGuard,
      meta: { authorize: [Role.Host] }
    },
    {
      path: "/clients",
      name: "Clients",
      component: Clients,
      beforeEnter: authGuard,
      meta: { authorize: [Role.Host] }
    },
    {
      path: "/clients/:id/settings",
      name: "Client Settings",
      component: ClientSettings,
      beforeEnter: authGuard,
      meta: { authorize: [Role.Host] }
    },
    {
      path: "/events",
      name: "events",
      component: Events,
      beforeEnter: authGuard,
      meta: { authorize: [Role.Host] }
    },
    {
      path: "/game/:id/users",
      name: "GameUsers",
      component: GameUsers,
      beforeEnter: authGuard,
      meta: { authorize: [Role.Host] }
    },
    {
      path: "/",
      name: "Landing",
      component: Landing
    },
    {
      path: "*",
      redirect: { name: "login" }
    }
  ],

  mode: "history"
})

router.beforeEach(ThemeGuardFactory.create({ store }))

export default router
