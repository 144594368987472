/**
 * GameMode
 * @readonly
 * @enum {string} GameMode
 */
export const GameMode = {
  Welcome: "welcome",
  Default: "welcome",
  Results: "results",
  Over: "over",
  Voting: "voting",
  Social: "social",
  Meeting: "meeting",
  Play: "play",
  Huddle: "huddle"
}
