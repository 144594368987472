<template>
  <audio ref="audio" @canplay="$emit('ready')" />
</template>

<script>
export default {
  name: "UserAudio",
  props: {
    track: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.track.attach(this.$refs.audio)
  },
  beforeDestroy() {
    this.track.detach(this.$refs.audio)
  }
}
</script>
<style scoped>
audio {
  pointer-events: none;
  position: fixed;
  bottom: 0;
  right: 0;
}
</style>
