/**
 * @readonly
 * @enum {string}
 */
export const GameEvent = {
  FlipFactMatchCard: "FlipFactMatchCard",
  MoveNext: "MoveNext",
  DisableKeyboardShortcuts: "DisableKeyboardShortcuts",
  EnableKeyboardShortcuts: "EnableKeyboardShortcuts"
}
