<template>
  <v-layout column justify-center fill-height class="voting-container">
    <v-flex xs2 id="select-challenge-container" v-if="isGameOver">
      <v-select
        id="select-challenge"
        :items="missionList"
        label="Select Challenge"
        solo
        item-text="name"
        v-model="currentLocalMissionID"
        class="select-mission v-select--with-icons"
        item-value="id"
      >
        <template #selection="{ item }">
          <div class="selected">
            <svg-icon :name="item.icon" class="v-list__icon"></svg-icon>
            {{ item.pos }}
            {{ item.name }}
          </div>
        </template>
        <template #item="{ item }">
          <svg-icon :name="item.icon" class="v-list__icon"></svg-icon>
          {{ item.pos }}
          {{ item.name }}
        </template>
      </v-select>
    </v-flex>
    <v-flex shrink>
      <v-layout row justify-center>
        <v-flex class="voting-container-wrap">
          <!-- Controls -->
          <img
            v-if="currentPage > 0"
            class="prev-btn"
            @click="prevSlide"
            @mouseover="autoplay = false"
            :src="require('../../../../assets/carousel-arrow-prev.png')"
          />
          <img
            v-if="currentPage < pageCount - 1"
            class="next-btn"
            @click="nextSlide"
            @mouseover="autoplay = false"
            :src="require('../../../../assets/carousel-arrow-next.png')"
          />
          <Carousel
            :key="`${nOfVoteElements}-${currentLocalMissionID}`"
            :perPage="nOfSlidesPerPage"
            :paginationEnabled="false"
            :navigationEnabled="false"
            :loop="true"
            :autoplay="autoplay"
            :mouseDrag="false"
            :autoplayTimeout="3000"
            :style="{ maxWidth: carouselMaxWidth }"
            v-if="voteElements.length"
            class="carousel"
            ref="carousel"
            @pageChange="pageChange"
          >
            <Slide
              v-for="(media, idx) in voteElements"
              :key="'voting-element-' + idx"
            >
              <v-layout column class="voting-element-wrap">
                <transition name="dropdown-transition" mode="out-in">
                  <v-flex v-if="showNames">
                    <v-flex class="voting-element-header">
                      <v-icon class="voting-element-team-icon">
                        {{
                          getTeam(media.teamID).icon || "radio_button_unchecked"
                        }}
                      </v-icon>
                      <span class="user-name">
                        <template
                          v-if="
                            (!!media &&
                              media.behavior === 'Draw: Pictionary') ||
                            (!!media && media.behavior === 'Draw: Each Team')
                          "
                        >
                          {{ getTeamNameByID(media.teamID) }}
                        </template>
                        <template v-else>
                          <UserName
                            :firstname="media.firstname"
                            :lastname="media.lastname"
                          />
                        </template>
                      </span>
                    </v-flex>
                  </v-flex>
                </transition>
                <v-flex
                  class="voting-element"
                  :class="{
                    youtube: isYouTubeVoting,
                    drawing: isDrawingVoting
                  }"
                >
                  <RatioContainer
                    @mouseover="autoplay = false"
                    class="ratio-container"
                    :ratio="getRatio"
                  >
                    <!-- Voting element -->
                    <v-layout
                      column
                      fill-height
                      v-if="media.correct"
                      class="voting-element-content"
                    >
                      <template v-if="isMediaVoting">
                        <v-layout
                          column
                          grow
                          justify-center
                          class="voting-element-media-wrap"
                        >
                          <v-flex d-flex v-if="media.youTubeUrl">
                            <YouTubeContainer
                              style="align-self: center"
                              :videoCode="media.youTubeUrl"
                            />
                          </v-flex>
                          <v-flex v-else style="box-sizing: border-box">
                            <img
                              class="votin-element-image"
                              :src="showImage(media.correct)"
                            />
                          </v-flex>
                        </v-layout>
                        <v-flex
                          shrink
                          v-if="media.correct.caption"
                          class="votin-element-caption"
                        >
                          {{ media.correct.caption }}
                        </v-flex>
                      </template>
                      <template v-else>
                        <v-layout column grow justify-center>
                          <v-flex shrink>
                            {{ media.correct }}
                          </v-flex>
                        </v-layout>
                      </template>
                    </v-layout>
                    <!-- Waiting... -->
                    <v-layout
                      column
                      fill-height
                      justify-center
                      v-else
                      class="voting-element-waiting"
                    >
                      <v-flex shrink class="votin-element-waiting-text">
                        Waiting...
                      </v-flex>
                    </v-layout>
                  </RatioContainer>
                </v-flex>
                <v-flex class="voting-element-star-info">
                  <span
                    v-if="!!currentMission && !!currentMission.noVote && isHost"
                    >HOST
                  </span>
                  <span v-if="showRating(media)">VOTE </span>

                  <StarRating
                    v-if="showRating(media)"
                    class="vote-element-star-rating"
                    :read-only="false"
                    :increment="0.5"
                    :padding="1"
                    :star-size="16"
                    :show-rating="false"
                    :border-width="0"
                    :inactive-color="$theme.get('--disabled-color')"
                    active-color="#efe73c"
                    :border-color="'transparent'"
                    @rating-selected="rated($event, media)"
                  />
                  <span> {{ media.score || 0 }} PTS</span>
                </v-flex>
              </v-layout>
            </Slide>
          </Carousel>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import StarRating from "vue-star-rating"
import * as moment from "moment"
import { Carousel, Slide } from "vue-carousel"
const YouTubeContainer = () =>
  import("@/components/GroupTeams/Common/YouTubeContainer.vue")
import RatioContainer from "@/components/GroupTeams/Common/RatioContaier.vue"
import UserName from "@/components/GroupTeams/Common/User/UserName.vue"
import { Role } from "@/helpers"

const PICTIONARY_MISSION_TYPE = "Draw: Pictionary"
const DRAWING_EACH_TEAM_MISISON_TYPE = "Draw: Each Team"

const GAME_OVER_COMPATIBLE_MISSION_TYPES = [
  DRAWING_EACH_TEAM_MISISON_TYPE,
  PICTIONARY_MISSION_TYPE,
  "Take Photo",
  "Giphy",
  "Photo",
  "Freeform"
]

const DRAWING_MISSION_TYPE = [
  DRAWING_EACH_TEAM_MISISON_TYPE,
  PICTIONARY_MISSION_TYPE
]

const MEDIA_MISSION_TYPES = [
  "Photo",
  "Take Photo",
  "URL",
  "Giphy",
  DRAWING_EACH_TEAM_MISISON_TYPE,
  PICTIONARY_MISSION_TYPE
]

const serializeIcon = string =>
  String(string).replace(/:.*/, "").replace(/ /g, "-").toLowerCase()

const getIcon = type => serializeIcon(`missions/${type}`)

export default {
  name: "Voting",
  components: {
    YouTubeContainer,
    StarRating,
    Carousel,
    Slide,
    RatioContainer,
    UserName
  },
  async created() {
    await this.$store.dispatch("subscribeToVoting")
    // console.log("Mission List 1", this.missionList)
    // const mission = this.missionList[this.missionList.length - 1]
    // if (mission) this.currentLocalMissionID = mission.id
    this.missionsArray = Object.entries(this.missions).map(([id, mission]) => ({
      ...mission,
      id
    }))
  },

  mounted() {
    console.log("Mission List 2", this.missionList)

    this.currentLocalMissionID = this.missionList.filter(
      item => item.behavior == "Draw: Each Team"
    ).id
    if (!this.currentLocalMissionID) {
      const mission = this.missionList[this.missionList.length - 1]
      if (mission) this.currentLocalMissionID = mission.id
    }
    this.missionsArray = Object.entries(this.missions).map(([id, mission]) => ({
      ...mission,
      id
    }))
  },
  async beforeDestroy() {
    await this.$store.dispatch("unsubscribeFromVoting")
  },
  data() {
    return {
      autoplay: true,
      currentPage: 0,
      currentLocalMissionID: null,
      missionsArray: []
    }
  },
  watch: {
    isGameOver: {
      handler(value) {
        if (value) {
          // console.log("Mission List", this.missionList)
          // const mission = this.missionList[this.missionList.length - 1]
          // if (mission) this.currentLocalMissionID = mission.id
        } else {
          this.currentLocalMissionID = null
        }
      },
      immediate: true
    },
    currentLocalMissionID(value) {
      if (value && this.$refs.carousel) {
        this.currentPage = 0
        this.$refs.carousel.goToPage(0)
      }
    },
    allVotingElementsReady(value) {
      if (!this.getLocalScreenshotStatus) return
      setTimeout(async () => {
        if (value) {
          try {
            await this.captureScreenshot("VOTING")
          } catch (e) {
            console.warn("Could not take screenshot: ", e.message)
          }
        }
      }, 1000)
    }
  },
  computed: {
    ...mapGetters("GameUsers", ["users", "usersArray"]),
    ...mapGetters([
      "missions",
      "missionPlays",
      "gameStatus",
      "user",
      "votes",
      "onlineUsersGroupedByTeam",
      "styles"
    ]),
    ...mapGetters("auth", ["isHost"]),
    ...mapGetters({ teams: "chats" }),
    ...mapGetters("screenshot", ["getLocalScreenshotStatus"]),
    isGameOver() {
      return (
        this.realCurrentMission && this.realCurrentMission.name == "Game Over"
      )
    },
    showNames() {
      return this.isGameOver || !this.gameStatus.votingAnon
    },
    missionList() {
      const arr = this.missionsArray
        .filter(({ behavior, title }) => {
          return (
            GAME_OVER_COMPATIBLE_MISSION_TYPES.includes(behavior) &&
            !title.includes("Rules")
          )
        })
        .filter(({ id }) => {
          return this.playsArray.some(({ missionID }) => missionID == id)
        })
        .map(({ id, behavior, title }) => ({
          id,
          icon: getIcon(behavior),
          name: title,
          behavior
        }))
      console.log("ARRRRR", arr)
      var temp = arr.filter(item => item.behavior == "Draw: Each Team")
      if (temp[0]) {
        this.currentLocalMissionID = temp[0].id
      } else {
        const mission = arr[arr.length - 1]
        if (mission) this.currentLocalMissionID = mission.id
      }
      return arr
    },
    getRatio() {
      if (this.isYouTubeVoting) {
        return "2:1"
      } else if (this.isDrawingVoting) {
        return "16:10"
      } else {
        return "1:1"
      }
    },
    teamID() {
      return this.user.teamID
    },
    nOfVoteElements() {
      return this.voteElementsPre.length
    },
    carouselMaxWidth() {
      let vh = 0
      if (this.isYouTubeVoting) vh = 62
      else if (this.isDrawingVoting) vh = 58
      else vh = 45
      const OFFSET = 50
      return `calc((${vh}vh - ${OFFSET}px) * ${this.nOfSlidesPerPage})`
    },
    pageCount() {
      if (!this.nOfSlidesPerPage) return 1
      return Math.ceil(this.voteElements.length / this.nOfSlidesPerPage)
    },
    isDrawingVoting() {
      return (
        this.currentMission &&
        DRAWING_MISSION_TYPE.includes(this.currentMission.behavior)
      )
    },
    isYouTubeVoting() {
      return this.voteElementsPre.filter(obj => obj.youTubeUrl).length > 0
    },
    nOfSlidesPerPage() {
      // if at least one YouTube video found in the results
      // decrease the number of results per slide
      if (this.isYouTubeVoting || this.isDrawingVoting) {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 1
          case "sm":
            return Math.min(2, this.nOfVoteElements)
          case "md":
            return Math.min(3, this.nOfVoteElements)
          case "lg":
            return Math.min(3, this.nOfVoteElements)
          case "xl":
            return Math.min(4, this.nOfVoteElements)
          default:
            return 1
        }
      } else {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 1
          case "sm":
            return Math.min(3, this.nOfVoteElements)
          case "md":
            return Math.min(4, this.nOfVoteElements)
          case "lg":
            return Math.min(5, this.nOfVoteElements)
          case "xl":
            return Math.min(5, this.nOfVoteElements)
          default:
            return 1
        }
      }
    },
    voteElements() {
      return this.voteElementsPre
    },
    voteElementsPre() {
      const users = this.allSelectedPlayers.map(user => {
        const { id, firstname, lastname, role, teamID, username } = user
        return {
          userID: id,
          firstname,
          lastname,
          role,
          teamID,
          username
        }
      })
      const current = users.map(user => {
        const play = this.missionPlaysArray.find(
          ({ userID }) => userID === user.userID
        )
        if (play) {
          const { behavior, correct, score, id, missionID } = play
          let youTubeUrl = null
          try {
            youTubeUrl = this.getYouTubeURL(correct.image)
          } catch (e) {
            console.log(e.message)
          }
          return {
            ...user,
            id,
            behavior,
            correct,
            score,
            youTubeUrl,
            missionID
          }
        } else {
          return {
            ...user,
            id: null,
            youTubeUrl: null
          }
        }
      })

      let offline = []

      if (this.isGameOver) {
        offline = this.missionPlaysArray
      } else {
        offline = this.missionPlaysArray.filter(
          ({ id: id1 }) => !current.some(({ id: id2 }) => id1 === id2)
        )
      }

      // match the props

      const offlinePopulatedProps = offline.map(obj => {
        const {
          userID,
          role,
          teamID,
          username,
          behavior,
          correct,
          score,
          missionID
        } = obj
        const { firstName: firstname, lastName: lastname } = obj
        let youTubeUrl = null
        try {
          youTubeUrl = this.getYouTubeURL(correct.image)
        } catch (e) {
          console.log(e.message)
        }
        return {
          userID,
          firstname,
          lastname,
          role,
          teamID,
          username,
          behavior,
          correct,
          score,
          missionID,
          youTubeUrl
        }
      })
      console.log("MISSION PLAY ARRAY", this.missionPlaysArray)
      console.log("OFFLINE", offlinePopulatedProps)
      if (this.isGameOver) {
        // game over Pictionary results
        if (this.missionType === PICTIONARY_MISSION_TYPE) {
          return offlinePopulatedProps.filter(
            ({ correct }) => correct && correct.image
          )
        } else {
          return offlinePopulatedProps
        }
      } else {
        // join the online and offline
        return current.concat(offlinePopulatedProps)
      }
    },
    isTeamMission() {
      return (
        this.currentMission.playType == "Team: Speed Matters" ||
        this.currentMission.playType == "Team: Speed Does Not Matter" ||
        this.currentMission.playType == undefined
      )
    },
    // to enforece component re render
    nOfSelectedPlayers() {
      return this.allSelectedPlayers.length
    },
    allSelectedUsers() {
      return this.usersArray.filter(user => user.selected)
    },
    missionType() {
      return this.currentMission ? this.currentMission.behavior : null
    },
    allSelectedPlayers() {
      return this.allSelectedUsers.filter(
        ({ role, status }) =>
          role !== Role.Host && role !== Role.Audit && status == "online"
      )
    },
    isMediaVoting() {
      return MEDIA_MISSION_TYPES.includes(this.missionType)
    },
    currentMissionID() {
      return this.currentLocalMissionID || this.$store.getters.currentMission
    },
    realCurrentMission() {
      return this.$store.getters.getCurrentMission
    },
    currentMission() {
      return this.missions[this.currentMissionID]
    },
    playsArray() {
      return this.$store.getters.plays
    },
    missionPlaysArray() {
      if (this.isGameOver) {
        return this.playsArray.filter(
          ({ missionID }) => missionID == this.currentMissionID
        )
      } else {
        return this.missionPlays
      }
    },
    allVotingElementsReady() {
      return (
        this.voteElements.length &&
        this.voteElements.every(({ correct }) => !!correct)
      )
    }
  },
  methods: {
    ...mapActions("screenshot", ["captureScreenshot"]),
    showImage(media) {
      if (Array.isArray(media)) {
        return media[0].image
      } else {
        return media.image
      }
    },
    getTeamNameByID(teamID) {
      return this.teams && this.teams[teamID] ? this.teams[teamID].name : null
    },
    showRating(media) {
      if (this.isGameOver) {
        return false
      } else if (this.isHost && media.correct) {
        return true
      } else if (this.currentMission && !this.currentMission.noVote) {
        if (this.isTeamMission) {
          return media.correct && media.teamID !== this.teamID
        } else {
          return media.correct && media.userID !== this.user.id
        }
      } else {
        return false
      }
    },
    numTeamPlayersSelected(teamID) {
      const array = this.onlineUsersGroupedByTeam[teamID] || []
      return array.filter(({ selected }) => selected).length
    },
    getUserInfo(userID) {
      const { firstname, lastname } = this.users[userID] || {}
      return { firstname, lastname }
    },
    pageChange(n) {
      this.currentPage = n
    },
    nextSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage())
    },
    prevSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage())
    },
    getTeam(teamID) {
      return (this.teams && this.teams[teamID]) || {}
    },
    getYouTubeURL(url) {
      return url ? url.split("v=")[1] : null
    },
    getRatingScore(user) {
      const avg = this.getAvgRating(user.userID)
      var points = this.currentMission.points
      if (!this.isTeamMission)
        points = points / this.numTeamPlayersSelected(user.teamID)
      const ratio = avg / 5
      return parseInt(points * ratio)
    },
    getAvgRating(userID) {
      if (this.votes) {
        var vArr = Object.values(this.votes)
        vArr = vArr.filter(vote => {
          return (
            vote.ratedUserID == userID &&
            vote.missionID == this.currentMissionID
          )
        })
        var totalVote = 0
        for (var v in vArr) {
          totalVote = totalVote + vArr[v].vote
        }
        if (vArr.length == 0) {
          return 0
        } else {
          return totalVote / vArr.length
        }
      } else {
        return 0
      }
    },
    rated(rate, user) {
      console.log("USER ", user)
      let foundVoteID
      if (this.votes) {
        let vArr = Object.entries(this.votes)
        for (let v in vArr) {
          if (
            vArr[v][1].ratedUserID == user.userID &&
            vArr[v][1].votingUserID == this.user.id &&
            vArr[v][1].missionID == this.currentMissionID
          ) {
            foundVoteID = vArr[v][0]
          }
        }
      }

      const foundPlayObject = this.missionPlays
        .slice()
        .reverse()
        .find(play => play.userID === user.userID)

      const foundPlay =
        foundPlayObject && foundPlayObject.id ? foundPlayObject.id : null

      var obj = {}
      var sTime = new Date()
      if (foundVoteID) obj.id = foundVoteID
      obj.teamID = 0
      obj.ratedUserID = user.userID
      obj.votingUserID = this.user.id
      obj.missionID = this.currentMissionID
      obj.mission = this.currentMission.name
      let points = this.currentMission.points
      if (!this.isTeamMission)
        points = points / this.numTeamPlayersSelected(user.teamID)
      obj.points = parseInt(points)
      obj.time = moment(sTime).unix()
      obj.readTime = moment(sTime).format("LLLL")
      obj.show = false
      obj.vote = rate
      console.log("POINTS ", points)
      this.$store.dispatch("addVote", obj)

      var score = parseInt(this.getRatingScore(user))
      var teamID = user.teamID
      console.log("TEAMDID = ", teamID)
      obj = {}
      let teamScore
      if (foundPlayObject) {
        console.log("TESAMS", this.teams)
        console.log("FOUND PLAY", foundPlayObject)
        teamScore = this.teams[foundPlayObject.teamID].totalScore
        obj = foundPlayObject
        var oldScore = foundPlayObject.score
        obj.id = foundPlay
        obj.score = score
        teamScore = teamScore - oldScore
        teamScore = teamScore + score
      } else {
        teamScore = this.teams[teamID].totalScore
        var c = this.currentMission
        obj.behavior = c.behavior
        obj.correct = "vote"
        obj.mission = c.name
        obj.missionID = this.currentMissionID
        let points = c.points
        if (!this.isTeamMission)
          points = points / this.numTeamPlayersSelected(user.teamID)
        obj.points = points
        console.log("POINTS ", points)
        obj.score = score
        obj.readTime = moment(sTime).format("LLLL")
        obj.result = true
        obj.show = true
        obj.teamID = teamID
        obj.time = moment(sTime).unix()
        obj.userID = user.userID
        // var userObj = user
        // obj.username = userObj.username
        // obj.firstName = userObj.firstname
        // obj.image = userObj.image
      }

      this.$store.dispatch("addPlay", obj)
      obj = {}
      obj.userID = user.userID
      obj.teamID = teamID
      if (foundPlay) {
        obj.teamScore = teamScore
      } else {
        obj.teamScore = teamScore + score
      }
      obj.totalScore = score

      this.$store.dispatch("setTeamScore", obj)
      var userobj = {}
      userobj.totalScore = user.totalScore + score
      userobj.userID = user.userID
      this.$store.dispatch("setScore", obj)
    },
    sortedArray(arr, field, desc) {
      if (desc) {
        return arr.sort((a, b) => a[field] - b[field])
      } else {
        return arr.sort((a, b) => b[field] - a[field])
      }
    },
    getTeamID(userID) {
      const found = this.missionPlays.find(play => play.userID === userID)
      return found && found.teamID ? found.teamID : 0
    }
  }
}
</script>
<style lang="scss">
.voting-container {
  font-size: 1em;
  line-height: 1;

  #select-challenge-container {
    width: 300px;
    text-align: center;
    margin: 0 auto;
  }

  .v-select {
    height: 25px;
    margin-top: 9px;
  }
  .v-select__slot {
    height: 25px;
  }
  .v-input__slot {
    min-height: unset;
  }
  .v-text-field.v-text-field--solo .v-input__control {
    min-height: unset;
    height: 16px;
  }
  .theme--dark,
  .theme--light {
    &.v-text-field--solo > .v-input__control > .v-input__slot {
      background: $color-secondary-dark;
      border: solid 1px $primary_accent_color;
      border-radius: 0;
    }
    &.v-select {
      .v-select__selections {
        color: $color-white;
        padding: 0 1px;
      }
      .v-select__slot .v-label {
        color: $color-white;
        padding: 0 10px;
      }
    }
  }
  .v-select--is-menu-active.theme--dark.v-text-field--solo
    > .v-input__control
    > .v-input__slot {
    background: $primary_accent_color;
  }
  .v-text-field,
  .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
    padding: 0;
  }

  .voting-container-wrap {
    width: 100%;
    margin: auto;
  }
  .voting-element-header {
    opacity: 1;
    transition: all 0.3s ease 0.4s;
    padding-top: 3px;
  }
  .dropdown-transition-enter-active {
    max-height: 50px;
    transition: all 0.4s ease 0s;
  }
  .dropdown-transition-leave-active {
    max-height: 50px;
    transition: all 0.4s ease 0.3s;
    .voting-element-header {
      transition: all 0.3s ease 0s;
    }
  }
  .dropdown-transition-enter,
  .dropdown-transition-leave-to {
    max-height: 0px;
    .voting-element-header {
      opacity: 0;
    }
  }
  .voting-element-media-wrap {
    position: relative;
  }
  .next-btn,
  .prev-btn {
    position: absolute;
    top: 50%;
    width: 50px;
    height: 70px;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 2;
    &:hover {
      transform: translateY(-50%) scale(0.95);
    }
  }
  .prev-btn {
    left: 1%;
  }
  .next-btn {
    right: 1%;
  }
  .vote-element-star-rating {
    display: inline-block !important;
  }
  .voting-element-content {
    background-color: $color-white;
  }
  .voting-element-waiting {
    font-size: 1.3rem;
    color: $color-disabled;
    background-color: $color-tertiary-dark;
  }
  .voting-element-wrap {
    position: relative;
    margin-left: 8px;
    margin-right: 8px;
  }
  .voting-element {
    @extend .rtb-border, .rtb-box-shadow, .rtb-border-radius;
    width: 99%;
    width: calc(100% - 8px);
    max-width: 45vh !important;
    max-width: calc(45vh - 80px) !important;
    min-width: 166px;
    overflow: hidden;
    margin: 4px auto;

    &.youtube {
      max-width: 62vh !important;
      max-width: calc(62vh - 80px) !important;
    }
    &.drawing {
      max-width: 58vh !important;
      max-width: calc(58vh - 80px) !important;
    }
  }
  .voting-element-team-icon {
    color: $color-white;
    font-size: 1.8rem;
    display: inline-block;
    vertical-align: middle;
  }
  .user-name {
    color: $color-white;
    font-size: 1.3rem;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.3rem;
  }
  .votin-element-image {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 8px;
  }
  .votin-element-caption {
    padding: 4px 8px;
    font-weight: bold;
    font-size: 1.2rem;
  }
  .voting-element-star-info {
    color: $color-white;
    font-size: 1rem;
    & > div,
    & > span {
      vertical-align: middle;
    }
  }
  .vue-star-rating-star {
    polygon {
      stroke-width: 0;
    }
  }
  .carousel {
    margin: 0 auto;
    box-sizing: content-box;
  }
  .youtube-container iframe {
    max-width: 100%;
  }
}
</style>
