import firebase from "firebase/app"
import "firebase/database"
import _, { pickBy } from "lodash"

export const MODULE_NAME = "allusers"

export const ACTION_TYPES = {
  FETCH_DEBUG_USERS: "FETCH_DEBUG_USERS"
}

export const GETTER_TYPES = {
  GET_USER_BY_ID: "GET_USER_BY_ID",
  GET_USERS_BY_ID: "GET_USERS_BY_ID",
  GET_USERS_BY_TEAM_ID: "GET_USERS_BY_TEAM_ID",
  GET_HOSTS_BY_GAME_ID: "GET_HOSTS_BY_GAME_ID"
}

import { Role } from "@/helpers"

const UserSearch = {
  namespaced: true,
  state: {
    debugUsers: {},
    users: {}
  },
  mutations: {
    UPDATE_USERS(state, { users }) {
      state.users = users || {}
    },
    UPDATE_DEBUG_USERS(state, { users }) {
      state.debugUsers = users || {}
    }
  },
  actions: {
    async purgeUsers({ commit }) {
      commit("UPDATE_USERS", { users: {} })
    },
    async fetchUsers({ commit }, { clientID }) {
      const ref = firebase
        .database()
        .ref(`org/1/users`)
        .orderByChild("clientID")
        .equalTo(clientID)
        .limitToFirst(5000)

      const snapshot = await ref.once("value")

      commit("UPDATE_USERS", { users: snapshot.val() })
    },
    async [ACTION_TYPES.FETCH_DEBUG_USERS](
      { commit },
      { count } = { count: 1000 }
    ) {
      const ref = firebase
        .database()
        .ref(`org/1/users`)
        .limitToFirst(count)

      const snapshot = await ref.once("value")

      const users = snapshot.val()

      for (const id in users) {
        users[id].status = "online"
      }

      commit("UPDATE_DEBUG_USERS", { users })
    }
  },
  getters: {
    users: state => state.users,
    usersArray(state) {
      // const before = performance.now()
      // const array = Object.entries(state.users).map(([id, user]) => ({
      //   ...user,
      //   id
      // }))
      const array = []
      for (let key in state.users) {
        const user = state.users[key]
        user.id = key
        array.push(user)
      }
      // const after = performance.now()
      // console.log("usersArray computation time", after - before)
      return array
    },
    onlineUsersArray(state, getters) {
      // const before = performance.now()
      return getters.usersArray.filter(user => user.status === "online")
      // const after = performance.now()
      // console.log("onlineUsersArray computation time", after - before)
      // return array
    },
    onlineUsersGroupedByGameID(state, getters) {
      // const before = performance.now()
      return getters.onlineUsersArray.reduce((acc, val) => {
        const gameID = val.gameID || "unassigned"
        if (acc[gameID]) {
          acc[gameID].push(val)
        } else {
          acc[gameID] = [val]
        }
        return acc
      }, {})
      // const after = performance.now()
      // console.log("onlineUsersGroupedByGameID computation time", after - before)
      // return object
    },
    [GETTER_TYPES.GET_USER_BY_ID]: state => id => {
      return state.users[id]
    },
    /**
     * Returns detailed information about users.
     * Filters out the result of invalid users.
     */
    [GETTER_TYPES.GET_USERS_BY_ID]: (state, getters) => id => {
      return id
        .map(id => getters[GETTER_TYPES.GET_USER_BY_ID](id))
        .filter(user => _.isNil(user) === false)
    },
    [GETTER_TYPES.GET_USERS_BY_TEAM_ID]: state => teamID => {
      return pickBy(
        state.users,
        user => user.teamID === teamID && user.status === "online"
      )
    },
    [GETTER_TYPES.GET_HOSTS_BY_GAME_ID]: state => gameID => {
      return pickBy(
        state.users,
        user => user.gameID === gameID && user.role === Role.Host
      )
    }
  }
}

export default UserSearch
