import firebase from "firebase/app"
import "firebase/database"
import _ from "lodash"
import { UserFilter } from "../helpers"

const fetchUser = async ({ userID }) => {
  const snapshot = await getUserRef({ userID }).once("value")
  return snapshot.val()
}

const fetchUserIDByEmail = async email => {
  const snapshot = await firebase
    .database()
    .ref(`users/private`)
    .orderByChild("email")
    .equalTo(email)
    .limitToFirst(1)
    .once("value")
  return Object.keys(snapshot.val() || {})[0]
}

const fetchUserEmail = async userID => {
  const snapshot = await firebase
    .database()
    .ref(`users/private/${userID}/email`)
    .once("value")
  return snapshot.val()
}

const fetchUsersByRole = async role => {
  const snapshot = await firebase
    .database()
    .ref(`org/1/users`)
    .orderByChild("role")
    .equalTo(role)
    .once("value")
  return snapshot.val()
}

const updateUser = async ({ userID, obj }) => {
  await getUserRef({ userID }).update(obj)
}

const getGameUsersRef = ({ gameID }) => {
  return firebase
    .database()
    .ref(`org/1/users`)
    .orderByChild("gameID")
    .equalTo(gameID)
}

const getUserRef = ({ userID }) => {
  return firebase.database().ref(`org/1/users/${userID}`)
}

const updateUserSelectStatus = ({ userID, selected }) =>
  getUserRef({ userID })
    .child("selected")
    .set(selected)

const updateUserSkipStatus = ({ userID, selected }) =>
  getUserRef({ userID })
    .child("skip")
    .set(selected)

const updateUserMuteStatus = ({ userID, muted }) =>
  getUserRef({ userID })
    .child("muted")
    .set(muted)

/**
 * @param { string } clientID
 * @return {Promise<number>} Amount of users which `.status` is equals to "online"
 */
const getOnlineUsersCountByClientID = async clientID => {
  const ref = firebase
    .database()
    .ref("org/1/users")
    .orderByChild("clientID")
    .equalTo(clientID)

  const snapshot = await ref.once("value")
  const users = snapshot.val()

  if (users !== null) {
    // do not take `.role === Role.Host` into account
    const filteredOnlineUsers = _.omitBy(
      users,
      user =>
        UserFilter.hostPredicate(user) === true || user.status === "offline"
    )

    return Object.keys(filteredOnlineUsers).length
  } else {
    return 0
  }
}

export {
  fetchUser,
  updateUser,
  getUserRef,
  getGameUsersRef,
  getOnlineUsersCountByClientID,
  updateUserSelectStatus,
  updateUserSkipStatus,
  updateUserMuteStatus,
  fetchUsersByRole,
  fetchUserEmail,
  fetchUserIDByEmail
}
