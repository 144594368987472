var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      class: _vm.classes,
      attrs: {
        "xmlns:dc": "http://purl.org/dc/elements/1.1/",
        "xmlns:cc": "http://creativecommons.org/ns#",
        "xmlns:rdf": "http://www.w3.org/1999/02/22-rdf-syntax-ns#",
        "xmlns:svg": "http://www.w3.org/2000/svg",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd",
        "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape",
        width: "60.520851mm",
        height: "111.39215mm",
        viewBox: "0 0 60.520851 111.39215",
        version: "1.1",
        id: "svg8",
        "sodipodi:docname": "45-45-90-triangle.svg",
        "inkscape:version": "0.92.4 (5da689c313, 2019-01-14)"
      }
    },
    [
      _c("defs", { attrs: { id: "defs2" } }),
      _c("sodipodi:namedview", {
        attrs: {
          id: "base",
          pagecolor: "#ffffff",
          bordercolor: "#666666",
          borderopacity: "1.0",
          "inkscape:pageopacity": "0.0",
          "inkscape:pageshadow": "2",
          "inkscape:zoom": "0.98994949",
          "inkscape:cx": "118.03414",
          "inkscape:cy": "257.95205",
          "inkscape:document-units": "mm",
          "inkscape:current-layer": "layer1",
          showgrid: "false",
          "fit-margin-top": "0",
          "fit-margin-left": "0",
          "fit-margin-right": "0",
          "fit-margin-bottom": "-0.2",
          "inkscape:window-width": "1920",
          "inkscape:window-height": "1001",
          "inkscape:window-x": "-9",
          "inkscape:window-y": "-9",
          "inkscape:window-maximized": "1",
          "inkscape:snap-global": "true"
        }
      }),
      _c(
        "metadata",
        { attrs: { id: "metadata5" } },
        [
          _c(
            "rdf:RDF",
            [
              _c(
                "cc:Work",
                { attrs: { "rdf:about": "" } },
                [
                  _c("dc:format", [_vm._v("image/svg+xml")]),
                  _c("dc:type", {
                    attrs: {
                      "rdf:resource": "http://purl.org/dc/dcmitype/StillImage"
                    }
                  }),
                  _c("dc:title")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "g",
        {
          attrs: {
            "inkscape:label": "Layer 1",
            "inkscape:groupmode": "layer",
            id: "layer1"
          }
        },
        [
          _c("path", {
            staticStyle: {
              "stroke-width": "0.99999994",
              fill: "var(--primary-accent-color)"
            },
            attrs: {
              d:
                "M 210.88281,0 0,210.88281 210.88281,421.76562 h 17.85742 V 0 Z",
              id: "rect10",
              "inkscape:connector-curvature": "0",
              transform: "scale(0.26458333)"
            }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }