<template>
  <v-container class="main" bg fill-height grid-list-md text-xs-center fluid>
    <v-layout row wrap align-center>
      <v-flex>
        <v-layout row>
          <v-flex>
            <h3 class="waiting">Loading...</h3>
            <div>
              <v-progress-circular
                indeterminate
                color="purple"
              ></v-progress-circular>
            </div>
            <div>
              <v-btn v-if="false" @click="pickteams">Pick Teams</v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "WaitingForTeam",
  components: {},
  data() {
    return {}
  },
  methods: {
    pickteams() {
      this.$router.push("/pickteams")
    }
  }
}
</script>

<style scoped lang="scss">
.full {
  max-height: 100%;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: stretch;
}
.waiting {
  color: $color-grey-light1;
}
</style>
