var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm._l(_vm.getGameEffects, function(effect) {
        return _c("audio", {
          key: "effect-" + effect.name,
          attrs: { src: effect.source, id: effect.name }
        })
      }),
      _c("audio", {
        ref: "audio_player",
        attrs: { loop: _vm.getAudioLoopStatus },
        on: { ended: _vm.onEnded }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }