<script>
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"
/**
 * @param { string } value
 */
function isAspectRation(value) {
  const [a, b] = value.split(":")
  return isNaN(a) === false && isNaN(b) === false
}
export default Vue.extend({
  name: "RatioContainer",
  functional: true,
  props: {
    ratio: {
      type: String,
      default: "1:1",
      validator: isAspectRation
    }
  },
  render(h, { props, data, children }) {
    const values = props.ratio.split(":").map(Number)
    const paddingTop = `${(values[1] / values[0]) * 100}%`
    return h(
      "v-flex",
      mergeData(
        {
          staticClass: "ratio-container",
          style: { paddingTop }
        },
        data
      ),
      [h("div", { staticClass: "ratio-container__element" }, children)]
    )
  }
})
</script>

<style lang="scss">
.ratio-container {
  position: relative;
  width: 100%;
  &__element {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    font-size: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>
