var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.authorized && _vm.isHost
        ? [
            _vm.isLobby
              ? _c("LobbyToolbar")
              : _vm.isGame
              ? _c("DirectGroup")
              : _c(
                  "v-toolbar",
                  {
                    staticClass: "black grey--text",
                    attrs: { app: "", flat: "", dark: "", height: "33px" }
                  },
                  [
                    _c(
                      "v-toolbar-title",
                      {
                        staticStyle: { "font-size": "15px" },
                        attrs: { width: "400" }
                      },
                      [
                        !!_vm.org
                          ? _c(
                              "span",
                              {
                                attrs: { id: "theOrg" },
                                on: { click: _vm.pushToGames }
                              },
                              [_vm._v(" " + _vm._s(_vm.org.name) + " ")]
                            )
                          : _vm._e(),
                        !!_vm.game
                          ? _c("span", { attrs: { id: "game" } }, [
                              _vm._v(" > " + _vm._s(_vm.game.name))
                            ])
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "v-flex",
                      { attrs: { shrink: "" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: { click: _vm.pushToGameSettings }
                          },
                          [_c("v-icon", [_vm._v("edit")])],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { shrink: "" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              icon: "",
                              to: "/gamelogin/" + _vm.urlID + "?auth=0",
                              target: "_blank"
                            }
                          },
                          [_c("v-icon", [_vm._v("flash_on")])],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._l(_vm.menuItems, function(item) {
                      return _c(
                        "v-toolbar-items",
                        { key: item.title },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "grey--text",
                              attrs: { to: item.route, flat: "" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "grey--text",
                                  attrs: { left: "" }
                                },
                                [_vm._v(_vm._s(item.icon))]
                              ),
                              _c("div", { staticClass: "hidden-xs-only" }, [
                                _vm._v(_vm._s(item.title))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
          ]
        : _vm._e(),
      _c("v-content", [_c("router-view")], 1),
      _c(
        "Popup",
        {
          attrs: { isOpen: !!_vm.popupText, mode: "okay" },
          on: { onClose: _vm.onPopupClose, onResponse: _vm.onPopupClose }
        },
        [_vm._v(" " + _vm._s(_vm.popupText) + " ")]
      ),
      !!_vm.localVersion
        ? _c("div", { staticClass: "app-version-label" }, [
            _c("div", [_vm._v(" " + _vm._s(_vm.localVersion) + " ")])
          ])
        : _vm._e(),
      _c("video", {
        staticStyle: { display: "none" },
        attrs: { id: "screenshotRootVidEl", muted: "" },
        domProps: { muted: true }
      }),
      _vm.isPreGame ? _c("GameStartSound") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }