<template>
  <div class="fullscreen-toggle">
    <slot :toggle="toggle">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <a
            v-on="on"
            href="#"
            role="button"
            class="fullscreen-toggle__activator"
            aria-label="Toggle Fullscreen"
            @click.prevent="toggle"
          >
            <svg-icon name="expand-regular" size="small" />
          </a>
        </template>
        <span>Fullscreen</span>
      </v-tooltip>
    </slot>
  </div>
</template>

<script>
import Vue from "vue"

export default Vue.extend({
  name: "FullscreenToggle",
  methods: {
    toggle() {
      if (document.fullscreenEnabled) {
        if (document.fullscreenElement) {
          document.exitFullscreen()
        } else {
          document.body.requestFullscreen().catch(e => alert(e.message))
        }
      } else {
        alert("Sorry, seems like your browser doesn't support fullscreen mode")
      }
    }
  }
})
</script>

<style lang="scss">
.fullscreen-toggle {
  &__activator {
    color: $color-grey;

    &:hover {
      color: $primary_accent_color;
    }
  }
}
</style>
