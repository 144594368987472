var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "game-countdown",
      on: {
        click: function($event) {
          return _vm.pause()
        }
      }
    },
    [
      _c("countdown", {
        ref: "countdown",
        attrs: { interval: 1000, time: _vm.endTime },
        on: {
          progress: _vm.trackProgress,
          end: function($event) {
            return _vm.trackProgress({
              totalSeconds: 0,
              totalHours: 0,
              seconds: 0,
              minutes: 0
            })
          }
        }
      }),
      _c("div", { ref: "timer", staticClass: "timer" }, [
        _c("span", [_vm._v(_vm._s(_vm.time))])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }