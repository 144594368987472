var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-flex", { staticClass: "email-login" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.$emit("submit", _vm.form)
          }
        }
      },
      [
        _c(
          "v-layout",
          { attrs: { wrap: "" } },
          [
            _c("v-flex", { staticClass: "px-2", attrs: { xs6: "" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.firstname,
                    expression: "form.firstname"
                  }
                ],
                staticClass: "signin-text-input game-login-text-input",
                attrs: {
                  type: "text",
                  placeholder: "FIRST NAME",
                  autocomplete: "off",
                  required: ""
                },
                domProps: { value: _vm.form.firstname },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "firstname", $event.target.value)
                  }
                }
              })
            ]),
            _c("v-flex", { staticClass: "px-2", attrs: { xs6: "" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.lastname,
                    expression: "form.lastname"
                  }
                ],
                staticClass: "signin-text-input game-login-text-input",
                attrs: {
                  type: "text",
                  placeholder: "LAST NAME",
                  autocomplete: "off",
                  required: ""
                },
                domProps: { value: _vm.form.lastname },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "lastname", $event.target.value)
                  }
                }
              })
            ]),
            _c("v-flex", { staticClass: "px-2", attrs: { xs12: "" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.email,
                    expression: "form.email"
                  }
                ],
                staticClass: "signin-text-input game-login-text-input",
                attrs: {
                  type: "text",
                  placeholder: "EMAIL",
                  autocomplete: "off",
                  required: ""
                },
                domProps: { value: _vm.form.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "email", $event.target.value)
                  }
                }
              })
            ])
          ],
          1
        ),
        _c("v-layout", { attrs: { row: "" } }, [
          _c("input", {
            staticClass: "login-btn signin-btn anonymous-btn",
            attrs: { type: "submit", value: "Join The Game" }
          })
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }