var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "audio",
    {
      ref: "startsound",
      staticClass: "game-start-sound",
      attrs: { preload: "auto" }
    },
    [
      _c("source", {
        attrs: {
          src: require("@/assets/game-start-countdown.mp3"),
          type: "audio/mpeg"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }