<template>
  <canvas ref="canvas" />
</template>

<script>
import { TweenMax, Power3, Back } from "gsap/TweenMax"
const STAR_1_URL = require("../../../assets/star-image-1.png")
const STAR_2_URL = require("../../../assets/star-image-2.png")

export default {
  name: "StarExplosion",
  props: {
    nOfStar1: {
      default: 10,
      type: Number
    },
    nOfStar2: {
      default: 33,
      type: Number
    },
    speed: {
      default: 1,
      type: Number
    },
    maxStar1Size: {
      default: 60,
      type: Number
    },
    maxStar2Size: {
      default: 30,
      type: Number
    },
    radius: {
      default: 300,
      type: Number
    },
    shape: {
      default: "circle",
      type: String
    }
  },
  data() {
    return {
      canvas: null,
      canvasCtx: null,
      images: [],
      objectsToDraw: [],
      // don't let the animations overlap
      isPlaying: false
    }
  },
  methods: {
    canvasLoop() {
      this.canvasCtx.clearRect(0, 0, this.width, this.height)
      this.canvasCtx.save()
      this.canvasCtx.shadowBlur = 3
      this.canvasCtx.shadowColor = "rgba(255, 255, 255, 0.5)"
      this.objectsToDraw.forEach(obj => {
        this.canvasCtx.save()
        this.canvasCtx.globalAlpha = obj.opacity
        const size = obj.size * obj.scale
        const offset = size / 2
        this.canvasCtx.drawImage(
          this.images[obj.type - 1],
          obj.x - offset,
          obj.y - offset,
          size,
          size
        )
        this.canvasCtx.restore()
      })
      this.canvasCtx.restore()
    },
    onUpdate() {
      this.objectsToDraw.forEach(obj => {
        TweenMax.killTweensOf(obj)
        obj.opacity = 0
      })
      try {
        TweenMax.ticker.removeEventListener("tick", this.canvasLoop)
      } catch (e) {
        console.log(e.message)
      }
      // update the sizing
      this.isPlaying = false
      this.canvas.width = this.width
      this.canvas.height = this.height
      this.canvasCtx = this.canvas.getContext("2d")
    },
    getSquarePoint({ size, area }) {
      const functions = [
        () => {
          return {
            x: this.getRandomInt(size, area - size),
            y: size
          }
        },
        () => {
          return {
            x: this.getRandomInt(size, area - size),
            y: area - size
          }
        },
        () => {
          return {
            x: size,
            y: this.getRandomInt(size, area - size)
          }
        },
        () => {
          return {
            x: area - size,
            y: this.getRandomInt(size, area - size)
          }
        }
      ]
      return functions[this.getRandomInt(0, functions.length - 1)]()
    },
    getCirclePoint({ radius, angle, size }) {
      return {
        x: radius + Math.cos(angle) * (radius - size),
        y: radius + Math.sin(angle) * (radius - size)
      }
    },
    getPoint({ shape, size, angle, area, radius }) {
      if (shape === "square") {
        return this.getSquarePoint({ size, area })
      } else {
        return this.getCirclePoint({ radius, angle, size })
      }
    },
    setInitialState() {
      this.objectsToDraw.forEach(obj => {
        obj.scale = 1
        obj.opacity = 1
        const size = obj.type === 1 ? this.maxStar1Size : this.maxStar2Size
        obj.size = this.getRandomInt(size / 2, size)
        if (this.shape === "square") {
          obj.x = this.radius2
          obj.y = this.radius2
        } else {
          obj.angle = Math.random() * Math.PI * 2
          const size = obj.size * obj.scale
          obj.x = this.radius2 + Math.cos(obj.angle) * (this.radius1 - size)
          obj.y = this.radius2 + Math.sin(obj.angle) * (this.radius1 - size)
        }
      })
    },
    init() {
      let i
      for (i = 0; i < this.nOfStar2; i++) this.objectsToDraw.push({ type: 2 })
      for (i = 0; i < this.nOfStar1; i++) this.objectsToDraw.push({ type: 1 })
    },
    animate() {
      if (this.isPlaying) return
      this.isPlaying = true

      TweenMax.ticker.addEventListener("tick", this.canvasLoop)

      // set base values
      this.setInitialState()

      const stars1 = this.objectsToDraw.filter(({ type }) => type === 1)
      const stars2 = this.objectsToDraw.filter(({ type }) => type === 2)

      stars2.forEach(obj => {
        const point = this.getPoint({
          shape: this.shape,
          area: this.radius2 * 2,
          size: obj.size * obj.scale,
          angle: obj.angle,
          radius: this.radius2
        })
        const { x, y } = point
        const delay = this.getRandomFloat(0, 1 * this.speed)

        setTimeout(() => {
          if (!this.isPlaying) return
          TweenMax.to(obj, 1 * this.speed, {
            x,
            y,
            overwrite: false,
            ease: Power3.easeOut
          })
          TweenMax.to(obj, 0.5 * this.speed, {
            scale: 2,
            overwrite: false,
            ease: Power3.easeInOut
          }).delay(0.25 * this.speed)
          TweenMax.to(obj, 0.25 * this.speed, {
            scale: 1,
            overwrite: false,
            opacity: 0,
            ease: Power3.easeInOut
          }).delay(0.5 * this.speed)
        }, delay * this.speed * 1000)
      })

      stars1.forEach(obj => {
        const point = this.getPoint({
          shape: this.shape,
          area: this.radius2 * 2,
          size: obj.size * obj.scale,
          angle: obj.angle,
          radius: this.radius2
        })
        const { x, y } = point
        const delay = this.getRandomFloat(0, 0.8 * this.speed)

        setTimeout(() => {
          if (!this.isPlaying) return
          TweenMax.to(obj, 1.5 * this.speed, {
            x,
            y,
            scale: 1.5,
            overwrite: false,
            ease: Back.easeOut.config(0.8)
          })
          TweenMax.to(obj, 0.25, {
            scale: 1.3,
            overwrite: false,
            opacity: 0,
            ease: Power3.easeInOut
          }).delay(1.25 * this.speed)
        }, delay * this.speed * 1000)
      })

      const duration = (1 * this.speed + 2 * this.speed) * 1000

      // plus a little offset just in case
      setTimeout(() => {
        TweenMax.ticker.removeEventListener("tick", this.canvasLoop)
        // enforce cleaning in case of a lag
        this.canvasCtx.clearRect(0, 0, this.width, this.height)
        this.isPlaying = false
      }, duration + 500)
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    getRandomFloat(min, max) {
      return (Math.random() * (min - max) + max).toFixed(4)
    },
    getImage(url) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        const onLoad = () => {
          img.removeEventListener("load", onLoad)
          img.removeEventListener("error", onError)
          resolve(img)
        }
        const onError = e => {
          img.removeEventListener("load", onLoad)
          img.removeEventListener("error", onError)
          reject(e)
        }
        img.addEventListener("load", onLoad)
        img.addEventListener("error", onError)
        img.src = url
      })
    }
  },
  computed: {
    radius2() {
      return this.radius
    },
    radius1() {
      return this.radius2 * 0.5
    },
    width() {
      return this.radius2 * 2
    },
    height() {
      return this.radius2 * 2
    }
  },
  watch: {
    radius2() {
      this.onUpdate()
    },
    shape() {
      this.onUpdate()
    }
  },
  mounted() {
    this.canvas = this.$refs.canvas
    this.canvas.width = this.width
    this.canvas.height = this.height
    this.canvasCtx = this.canvas.getContext("2d")
    Promise.all([this.getImage(STAR_1_URL), this.getImage(STAR_2_URL)])
      .then(images => {
        this.images = images
        this.init()
        // set base values
        this.setInitialState()
      })
      .catch(e => console.log(e.message))
    // we don't want stars all messed up after switching browser tabs
    window.addEventListener("blur", this.onUpdate)
  },
  beforeDestroy() {
    try {
      TweenMax.ticker.removeEventListener("tick", this.canvasLoop)
    } catch (e) {
      console.log(e.message)
    }
    try {
      window.removeEventListener("blur", this.onUpdate)
    } catch (e) {
      console.log(e.message)
    }
  }
}
</script>
