<template>
  <v-flex
    d-flex
    style="align-items: flex-start"
    ref="root"
    class="generic-result"
  >
    <v-layout column justify-center style="min-height: 100%">
      <v-flex
        shrink
        v-for="(team, index) in dataMutable"
        :key="'team-result-' + team.id"
        class="team-result"
        :class="{ 'team-result-top': team.top }"
      >
        <v-layout row>
          <v-flex class="left-col">
            <v-layout row>
              <!-- Team rank -->
              <div class="team-rank">
                {{ index + 1 }}
              </div>
              <!-- Team icon -->
              <v-icon class="result-team-icon">
                {{ team.icon || "radio_button_unchecked" }}
              </v-icon>
              <!-- Team name and score -->
              <v-flex class="v-align team-name">
                <v-layout row justify-space-between>
                  <div class="team-name-span">
                    {{ team.name }}
                  </div>
                  <div
                    class="team-score-now"
                    :class="{ pointer: isHost }"
                    @click.exact="onIncreaseMissionScore(team)"
                    @click.shift="onDecreaseMissionScore(team)"
                  >
                    {{ team.score > 0 ? "+" + team.score : 0
                    }}<span class="score-pts">pts</span>
                  </div>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- Team total-->
          <v-flex
            class="v-align team-total"
            :class="{ pointer: isHost }"
            @click.exact="onIncreaseTotalScore(team)"
            @click.alt="onDecreaseTotalScore(team)"
            @click.shift="onDecreaseTotalScore(team)"
          >
            <div>{{ team.totalScore }}<span class="total-pts">pts</span></div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import { Power2, TweenMax } from "gsap/TweenMax"

export default {
  name: "Result",
  props: {
    data: Array,
    isHost: Boolean
  },
  data() {
    return {
      dataMutable: []
    }
  },
  methods: {
    onDecreaseMissionScore(team) {
      if (this.isHost) this.$emit("onIncreaseMissionScore", { team, score: 30 })
    },
    onIncreaseMissionScore(team) {
      if (this.isHost) this.$emit("onDecreaseMissionScore", { team, score: 30 })
    },
    onIncreaseTotalScore(team) {
      console.log("INCRESE")
      if (this.isHost) this.$emit("onIncreaseTotalScore", { team, score: 30 })
    },
    onDecreaseTotalScore(team) {
      if (this.isHost) this.$emit("onDecreaseTotalScore", { team, score: 30 })
    },
    setScore({ score, totalScore }, target) {
      target.score = score
      target.totalScore = totalScore
    },
    animateScore(to, stagger = false, staggerDelay = 0) {
      this.dataMutable.forEach((object, index) => {
        const target = to.find(obj => obj.id === object.id)
        if (!target) return
        const { score, totalScore } = object
        const obj = { score, totalScore }

        const onComplete = () => {
          if (index === to.length - 1) {
            this.dataMutable = to.map(team => ({ ...team }))
            // the top team is always green
            this.dataMutable[0].top = true
            // to ensure that teams with the same
            // number of points and leading have
            // green color
            if (this.dataMutable.length > 1) {
              for (let i = 1; i < this.dataMutable.length; i++) {
                if (
                  this.dataMutable[i].totalScore ===
                  this.dataMutable[i - 1].totalScore
                ) {
                  this.dataMutable[i].top = true
                } else {
                  break
                }
              }
            }
          }
        }

        TweenMax.to(obj, 1.3, {
          score: target.score,
          totalScore: target.totalScore,
          roundProps: "score,totalScore",
          onUpdate: this.setScore,
          ease: Power2.easeInOut,
          delay: stagger ? staggerDelay * index : 0,
          onUpdateParams: [obj, object],
          onComplete
        })
      })
    }
  },
  watch: {
    data: {
      handler: function (newValue) {
        console.log(newValue)
        this.animateScore(newValue)
      },
      deep: true
    }
  },
  mounted() {
    this.dataMutable = this.data.map(team => ({
      ...team,
      score: 0,
      totalScore: 0
    }))

    // $nextTick because the component is wrapped
    // into <transition>
    this.$nextTick(() => {
      const collection = this.$refs.root.getElementsByClassName("team-result")
      TweenMax.staggerFrom(
        collection,
        0.5,
        {
          y: 50,
          opacity: 0,
          delay: 0.3
        },
        0.2
      )
      setTimeout(() => this.animateScore(this.data, true, 0.05), 5)
    })
  }
}
</script>

<style lang="scss">
$generic-result-icon-size: 32px;
$generic-result-icon-spacing: 12px;
$gneric-result-pts-size: 96px;

.generic-result {
  overflow: auto;
  .generic-result-scroll {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .pointer {
    cursor: pointer;
  }
  .result-team-icon {
    font-size: $generic-result-icon-size;
    color: $color-tertiary-dark;
  }
  .v-align {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .team-result {
    font-size: 1.2em;
    font-weight: bold;
  }
  .left-col {
    min-width: 0;
    background-color: $color-grey-light2;
    padding: 6px 12px;
    transition: background-color 0.5s !important;

    > .row {
      align-items: center;
    }
  }
  .team-rank {
    width: $generic-result-icon-size;
    height: $generic-result-icon-size;
    flex-shrink: 0;
    margin-right: $generic-result-icon-spacing;
    line-height: 1.9em;
    border-radius: 3px;
    background-color: $color-secondary-dark;
    color: $color-white;
    font-weight: bold;
    font-size: 1em;
    transition: background-color 0.5s !important;
  }
  .team-score-now {
    font-size: 1.2em;
    line-height: 1.8em;
  }
  .team-name {
    min-width: 0;
  }
  .team-name-span {
    font-size: 1.05em;
    line-height: 2.25em;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .team-total {
    text-align: center;
    width: $gneric-result-pts-size;
    max-width: $gneric-result-pts-size;
    flex-shrink: 0;
    font-size: 1.2em;
    background-color: $color-secondary-dark;
    color: $color-white;
    transition: background-color 0.5s !important;
  }
  .result-team-icon {
    margin-right: $generic-result-icon-spacing;
  }
  .score-pts,
  .total-pts {
    font-size: 70%;
    position: relative;
    top: -0.27em;
    margin-left: 1px;
  }
  .team-result.team-result-top .left-col {
    background-color: $color-mint2;
  }
  .team-result.team-result-top .team-total {
    background-color: $color-correct;
  }
  .team-result.team-result-top .team-rank {
    background-color: $color-correct;
  }
  .team-result:not(:last-child) {
    margin-bottom: 10px;
  }
}
</style>
