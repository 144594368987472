<script>
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"

// TODO: use <button></button> tag
export default Vue.extend({
  name: "ToolbarBtn",
  functional: true,
  props: {
    text: {
      type: String
    },
    active: {
      type: Boolean
    },
    size: {
      type: String,
      default: "default"
      // validator: val => ["default", "lg"].indexOf(val) !== -1
    },
    theme: {
      type: String,
      default: "default"
    }
  },
  render(h, { props, children, data }) {
    return h(
      "div",
      mergeData(data, {
        staticClass: "toolbar-btn",
        class: {
          [`toolbar-btn--size--${props.size}`]: true,
          [`toolbar-btn--theme--${props.theme}`]: true,
          "toolbar-btn--with-text": props.text !== undefined,
          "--active": props.active === true
        }
      }),
      [
        h("span", { staticClass: "toolbar-btn__inner" }, [
          children,
          props.text !== undefined
            ? h("span", { staticClass: "toolbar-btn__text" }, props.text)
            : null
        ])
      ]
    )
  }
})
</script>

<style lang="scss">
.toolbar-btn {
  padding: 8px;
  // TODO:
  cursor: pointer;
  color: $color_white;

  svg {
    flex-shrink: 0;
  }

  &__inner {
    display: flex;
    align-items: center;
  }

  &__text {
    margin-left: 6px;
    line-height: 1.15;
    text-transform: uppercase;
    font: {
      size: 12px;
      weight: 700;
    }
  }

  &--size {
    &--default {
      svg {
        width: 28px;
        height: 28px;
      }
    }

    &--lg {
      svg {
        width: 34px;
        height: 34px;
      }
    }
  }

  &--with-text {
    // TODO: move outside of component
    max-width: 80px;
    flex-shrink: 0;
    padding: {
      right: 4px;
      left: 4px;
    }
  }

  &--theme {
    &--grey {
      color: $color_grey;
    }
  }

  &.--active {
    color: $color_primary_accent;
  }

  &:hover {
    color: $color_primary_accent;
  }
}
</style>
