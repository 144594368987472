import axios from "axios"
import { getApiUrl } from "@/api/function"
const API_URL = getApiUrl()

// const fetchOriginCountry = async () => {
//   try {
//     const { data } = await axios.get(`http://ip-api.com/json`)
//     return data.country.toLowerCase()
//   } catch (e) {
//     throw new Error("Security Violation Error")
//   }
// }

const fetchOriginCountry = async () => {
  try {
    const url = `${API_URL}access/ip`
    const config = {
      responseType: "json",
      headers: {
        "content-type": "application/json"
      }
    }
    const { data } = await axios.post(url, null, config)
    return data.country
  } catch (e) {
    if (e.response) {
      console.log(e.response.data)
      console.log(e.response.status)
      console.log(e.response.headers)
      throw new Error(e.response.data.message)
    } else if (e.request) {
      console.log(e.request)
      throw new Error(e.request)
    } else {
      throw new Error(e.message)
    }
  }
}

export { fetchOriginCountry }
