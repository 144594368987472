var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "voting-container",
      attrs: { column: "", "justify-center": "", "fill-height": "" }
    },
    [
      _vm.isGameOver
        ? _c(
            "v-flex",
            { attrs: { xs2: "", id: "select-challenge-container" } },
            [
              _c("v-select", {
                staticClass: "select-mission v-select--with-icons",
                attrs: {
                  id: "select-challenge",
                  items: _vm.missionList,
                  label: "Select Challenge",
                  solo: "",
                  "item-text": "name",
                  "item-value": "id"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "selection",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "div",
                            { staticClass: "selected" },
                            [
                              _c("svg-icon", {
                                staticClass: "v-list__icon",
                                attrs: { name: item.icon }
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(item.pos) +
                                  " " +
                                  _vm._s(item.name) +
                                  " "
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "item",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("svg-icon", {
                            staticClass: "v-list__icon",
                            attrs: { name: item.icon }
                          }),
                          _vm._v(
                            " " +
                              _vm._s(item.pos) +
                              " " +
                              _vm._s(item.name) +
                              " "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  492594434
                ),
                model: {
                  value: _vm.currentLocalMissionID,
                  callback: function($$v) {
                    _vm.currentLocalMissionID = $$v
                  },
                  expression: "currentLocalMissionID"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-flex",
        { attrs: { shrink: "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", "justify-center": "" } },
            [
              _c(
                "v-flex",
                { staticClass: "voting-container-wrap" },
                [
                  _vm.currentPage > 0
                    ? _c("img", {
                        staticClass: "prev-btn",
                        attrs: {
                          src: require("../../../../assets/carousel-arrow-prev.png")
                        },
                        on: {
                          click: _vm.prevSlide,
                          mouseover: function($event) {
                            _vm.autoplay = false
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.currentPage < _vm.pageCount - 1
                    ? _c("img", {
                        staticClass: "next-btn",
                        attrs: {
                          src: require("../../../../assets/carousel-arrow-next.png")
                        },
                        on: {
                          click: _vm.nextSlide,
                          mouseover: function($event) {
                            _vm.autoplay = false
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.voteElements.length
                    ? _c(
                        "Carousel",
                        {
                          key:
                            _vm.nOfVoteElements +
                            "-" +
                            _vm.currentLocalMissionID,
                          ref: "carousel",
                          staticClass: "carousel",
                          style: { maxWidth: _vm.carouselMaxWidth },
                          attrs: {
                            perPage: _vm.nOfSlidesPerPage,
                            paginationEnabled: false,
                            navigationEnabled: false,
                            loop: true,
                            autoplay: _vm.autoplay,
                            mouseDrag: false,
                            autoplayTimeout: 3000
                          },
                          on: { pageChange: _vm.pageChange }
                        },
                        _vm._l(_vm.voteElements, function(media, idx) {
                          return _c(
                            "Slide",
                            { key: "voting-element-" + idx },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "voting-element-wrap",
                                  attrs: { column: "" }
                                },
                                [
                                  _c(
                                    "transition",
                                    {
                                      attrs: {
                                        name: "dropdown-transition",
                                        mode: "out-in"
                                      }
                                    },
                                    [
                                      _vm.showNames
                                        ? _c(
                                            "v-flex",
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  staticClass:
                                                    "voting-element-header"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "voting-element-team-icon"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getTeam(
                                                              media.teamID
                                                            ).icon ||
                                                              "radio_button_unchecked"
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "user-name"
                                                    },
                                                    [
                                                      (!!media &&
                                                        media.behavior ===
                                                          "Draw: Pictionary") ||
                                                      (!!media &&
                                                        media.behavior ===
                                                          "Draw: Each Team")
                                                        ? [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.getTeamNameByID(
                                                                    media.teamID
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        : [
                                                            _c("UserName", {
                                                              attrs: {
                                                                firstname:
                                                                  media.firstname,
                                                                lastname:
                                                                  media.lastname
                                                              }
                                                            })
                                                          ]
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "voting-element",
                                      class: {
                                        youtube: _vm.isYouTubeVoting,
                                        drawing: _vm.isDrawingVoting
                                      }
                                    },
                                    [
                                      _c(
                                        "RatioContainer",
                                        {
                                          staticClass: "ratio-container",
                                          attrs: { ratio: _vm.getRatio },
                                          on: {
                                            mouseover: function($event) {
                                              _vm.autoplay = false
                                            }
                                          }
                                        },
                                        [
                                          media.correct
                                            ? _c(
                                                "v-layout",
                                                {
                                                  staticClass:
                                                    "voting-element-content",
                                                  attrs: {
                                                    column: "",
                                                    "fill-height": ""
                                                  }
                                                },
                                                [
                                                  _vm.isMediaVoting
                                                    ? [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            staticClass:
                                                              "voting-element-media-wrap",
                                                            attrs: {
                                                              column: "",
                                                              grow: "",
                                                              "justify-center":
                                                                ""
                                                            }
                                                          },
                                                          [
                                                            media.youTubeUrl
                                                              ? _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      "d-flex":
                                                                        ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "YouTubeContainer",
                                                                      {
                                                                        staticStyle: {
                                                                          "align-self":
                                                                            "center"
                                                                        },
                                                                        attrs: {
                                                                          videoCode:
                                                                            media.youTubeUrl
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _c(
                                                                  "v-flex",
                                                                  {
                                                                    staticStyle: {
                                                                      "box-sizing":
                                                                        "border-box"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("img", {
                                                                      staticClass:
                                                                        "votin-element-image",
                                                                      attrs: {
                                                                        src: _vm.showImage(
                                                                          media.correct
                                                                        )
                                                                      }
                                                                    })
                                                                  ]
                                                                )
                                                          ],
                                                          1
                                                        ),
                                                        media.correct.caption
                                                          ? _c(
                                                              "v-flex",
                                                              {
                                                                staticClass:
                                                                  "votin-element-caption",
                                                                attrs: {
                                                                  shrink: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      media
                                                                        .correct
                                                                        .caption
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    : [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: {
                                                              column: "",
                                                              grow: "",
                                                              "justify-center":
                                                                ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  shrink: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      media.correct
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                ],
                                                2
                                              )
                                            : _c(
                                                "v-layout",
                                                {
                                                  staticClass:
                                                    "voting-element-waiting",
                                                  attrs: {
                                                    column: "",
                                                    "fill-height": "",
                                                    "justify-center": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "votin-element-waiting-text",
                                                      attrs: { shrink: "" }
                                                    },
                                                    [_vm._v(" Waiting... ")]
                                                  )
                                                ],
                                                1
                                              )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { staticClass: "voting-element-star-info" },
                                    [
                                      !!_vm.currentMission &&
                                      !!_vm.currentMission.noVote &&
                                      _vm.isHost
                                        ? _c("span", [_vm._v("HOST ")])
                                        : _vm._e(),
                                      _vm.showRating(media)
                                        ? _c("span", [_vm._v("VOTE ")])
                                        : _vm._e(),
                                      _vm.showRating(media)
                                        ? _c("StarRating", {
                                            staticClass:
                                              "vote-element-star-rating",
                                            attrs: {
                                              "read-only": false,
                                              increment: 0.5,
                                              padding: 1,
                                              "star-size": 16,
                                              "show-rating": false,
                                              "border-width": 0,
                                              "inactive-color": _vm.$theme.get(
                                                "--disabled-color"
                                              ),
                                              "active-color": "#efe73c",
                                              "border-color": "transparent"
                                            },
                                            on: {
                                              "rating-selected": function(
                                                $event
                                              ) {
                                                return _vm.rated($event, media)
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(media.score || 0) +
                                            " PTS"
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }