var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { "justify-center": "" } },
    [
      _c(
        "v-flex",
        { staticClass: "volume" },
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "div",
                        _vm._g({ attrs: { dark: "" } }, on),
                        [
                          _c("v-slider", {
                            attrs: {
                              "prepend-icon": _vm.icon,
                              min: _vm.min,
                              max: _vm.max
                            },
                            on: { "click:prepend": _vm.toggle },
                            model: {
                              value: _vm.volume,
                              callback: function($$v) {
                                _vm.volume = $$v
                              },
                              expression: "volume"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("SFX Volume")])]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }