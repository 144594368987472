import randomItem from "random-item"

/**
 * @typedef {Object} Player
 * @property {string} id
 * @property {string} teamID
 * @property {boolean} selected
 */

/**
 * @typedef {Object} Team
 * @property {string} id
 * @property {string[]} players
 */

class PlayerEntity {
  /**
   * @param {Player} player
   * @returns {boolean}
   */
  static isSelected(player) {
    return player.selected
  }
}

export class PlayerSelector {
  /**
   * @param {string[]} teams
   */
  constructor(teams) {
    this._teams = teams.map(this._createTeam)
  }

  /**
   * @param {Player[]} players
   * @param {string} [teamID]
   */
  getNextPlayer(players, teamID) {
    if (players.length === 0) {
      throw new Error("[PlayerSelector]: empty iterators are not supported")
    }

    // We want to get player only from a specific team
    if (teamID) {
      const team = this._getTeam(teamID)
      return this._getNextTeamPlayer(team, players)
    } else {
      return this._getNextPlayer(players)
    }
  }

  clear() {
    this._teams.forEach(team => {
      team.selectedPlayers = []
    })
  }

  /**
   * @private
   * @param {Player[]} players
   */
  _getNextPlayer(players) {
    const teamsCount = this._teams.length
    let checkedTeamsCount = 0

    if (players.every(PlayerEntity.isSelected)) {
      const player = randomItem(players)
      this._addToSelected(player.teamID, player.id)
      return player
    }
    // We search for the first selected player
    const firstSelectedPlayer = this._getFirstSelectedPlayer(players)
    /** @type {string} */
    let teamID

    // If first selected player is found we get his teamID
    if (firstSelectedPlayer) {
      teamID = firstSelectedPlayer.teamID
    } else if (this._teams.length > 0) {
      teamID = randomItem(this._teams).id
    }

    if (teamID) {
      let team = this._getNextTeam(teamID)

      /** @type {Player | undefined} */
      let player
      while (player === undefined && checkedTeamsCount < teamsCount) {
        player = this._getNextTeamPlayer(team, players)

        if (player) {
          return player
        } else {
          checkedTeamsCount++
          team = this._getNextTeam(team.id)
        }
      }
    }

    return undefined
  }

  /**
   * @private
   * @param {Team} players
   * @param {Player[]} players
   * @returns {Player | undefined}
   */
  _getNextTeamPlayer(team, players) {
    const teamPlayers = this._getTeamPlayers(players, team.id)

    if (teamPlayers.length === 0) {
      return undefined
    } else if (teamPlayers.length === 1) {
      return teamPlayers[0]
    } else {
      // All players have been selected, we reset a buffer
      if (team.selectedPlayers.length >= teamPlayers.length)
        team.selectedPlayers = []

      // Handle case when all the players are selected
      if (teamPlayers.every(PlayerEntity.isSelected)) {
        return randomItem(teamPlayers)
      }

      const player = teamPlayers.find(
        player =>
          team.selectedPlayers.includes(player.id) === false &&
          player.selected !== true
      )

      if (player) {
        this._addToSelected(team.id, player.id)
        return player
      }
    }

    return undefined
  }

  /**
   * @private
   * @param {Player[]} players
   * @returns {Player | undefined}
   */
  _getFirstSelectedPlayer(players) {
    return players.find(PlayerEntity.isSelected)
  }

  /**
   * @private
   * @param {Player[]} players
   * @param {string} teamID
   * @returns {Player[]}
   */
  _getTeamPlayers(players, teamID) {
    return players.filter(player => player.teamID === teamID)
  }

  /**
   * @private
   * @param {string} teamID
   * @param {string} playerID
   */
  _addToSelected(teamID, playerID) {
    this._getTeam(teamID).selectedPlayers.push(playerID)
  }

  /**
   * @private
   * @param {string} teamID
   * @returns {Team}
   */
  _createTeam(teamID) {
    return {
      id: teamID,
      selectedPlayers: []
    }
  }

  /**
   * @private
   * @param {string} teamID
   * @returns {Team}
   */
  _getTeam(teamID) {
    const value = this._teams.find(team => team.id === teamID)
    if (value) return value
    throw new Error(`[PlayerSelector]: team with ${teamID} is not found`)
  }

  /**
   * @private
   * @param {string} teamID
   * @param {Team}
   */
  _getNextTeam(teamID) {
    const index = this._teams.findIndex(team => team.id === teamID)

    if (index !== -1) {
      const value = index + 1
      return this._teams[value > this._teams.length - 1 ? 0 : value]
    }

    throw new Error(`[PlayerSelector]: team with ${teamID} is not found`)
  }
}
