<template>
  <transition name="slide-transition" mode="out-in">
    <AssetMapper
      :mode="mode"
      :drawing="isDrawingMission"
      v-if="mediaToShow && mediaToShow !== 'none'"
    />
  </transition>
</template>

<script>
const AssetMapper = () =>
  import("@/components/GroupTeams/Common/AssetMapper.vue")

import { mapGetters } from "vuex"
import { GameMission } from "@/entities/GameMission"
import { GameMode } from "@/entities/GameMode"

export default {
  name: "AssetSlide",
  components: {
    AssetMapper
  },
  props: {
    mode: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters("livechat", ["roomID"]),
    ...mapGetters({ mission: "getCurrentMission" }),
    isDrawingMission() {
      return (
        this.mode !== GameMode.Results &&
        !!this.mission &&
        (this.mission.behavior === GameMission.DrawingEachTeam ||
          this.mission.behavior === GameMission.DrawingPictionary ||
          this.mission.behavior === GameMission.DrawingEveryone)
      )
    },
    mediaToShow() {
      if (this.roomID) {
        if (this.mission.photo) {
          return "photo"
        } else if (this.mission.audio) {
          return "audio"
        } else if (this.mission.youtube) {
          return "video"
        } else {
          return "none"
        }
      } else {
        return this.isDrawingMission
          ? "drawing"
          : this.$store.getters.gameStatus.media || "none"
      }
    }
  }
}
</script>

<style scoped lang="scss">
.slide-transition-enter-active,
.slide-transition-leave-active {
  transition: transform ease 0.35s;
  transform: translateX(0%);
}
.slide-transition-enter,
.slide-transition-leave-to {
  transform: translateX(105%);
}
</style>
