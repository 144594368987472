/**
 * @readonly
 * @enum {string}
 */
export const GameMission = {
  FactMatch: "Fact Match",
  GameOver: "Game Over",
  Lipdub: "Lipdub",
  DrawingEachTeam: "Draw: Each Team",
  DrawingPictionary: "Draw: Pictionary",
  DrawingEveryone: "Draw: Everyone"
}

export class GameMissionEntity {
  static isDrawingMission(mission) {
    return [
      GameMission.DrawingEachTeam,
      GameMission.DrawingPictionary,
      GameMission.DrawingEveryone
    ].includes(mission.behavior)
  }
}
