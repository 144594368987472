import { ThemeService } from "@/modules/theme/services"

import { ActionTypes as RootActionTypes } from "@/store"

import { ActionTypes } from "./action-types"

/**
 * Theme
 * @typedef {import('@/modules/theme/types').Theme} Theme
 */

/**
 * @type {import('vuex').ActionTree<import('./typedef').State, any>}
 */
export const actions = {
  /**
   * @param {{ orgID: string, themeID: string | undefined, value: Theme  }} payload
   */
  [ActionTypes.UPDATE_ORG_THEME]({ dispatch }, payload) {
    let { themeID } = payload
    if (themeID == undefined) {
      themeID = ThemeService.getNewThemeID(payload.orgID)
    }
    return ThemeService.updateOrgTheme(
      payload.orgID,
      themeID,
      payload.value
    ).then(() => {
      dispatch(RootActionTypes.GET_CURRENT_ORG, undefined, { root: true })
    })
  },
  /**
   * @param {{ orgID: string, themeID: string }} payload
   */
  [ActionTypes.DELETE_ORG_THEME]({ dispatch }, payload) {
    const { orgID, themeID } = payload
    return ThemeService.deleteOrgTheme(orgID, themeID).then(() => {
      dispatch(RootActionTypes.GET_CURRENT_ORG, undefined, { root: true })
    })
  },
  /**
   * @param {{ orgID: string, themeID: string }} payload
   */
  [ActionTypes.SET_ORG_THEME]({ dispatch }, payload) {
    return ThemeService.setOrgTheme(payload.orgID, payload.themeID).then(() => {
      dispatch(RootActionTypes.GET_CURRENT_ORG, undefined, { root: true })
    })
  }
}
