import Vue from "vue"
import Vuex from "vuex"
import firebase from "firebase/app"
import "firebase/database"
import _ from "lodash"
import { Role } from "@/helpers"

import { OrgService } from "@/services/org.service"

import GameUsers from "./GameUsers"
import LiveChat from "./LiveChat"
import Profile from "./Profile"
import PreGame from "./PreGame"
import UserSearch from "./UserSearch"
// import Leaderboard from "./Leaderboard"
// import GameFeed from "./GameFeed"
import AuthModule from "./AuthModule"
import ChatModule from "./ChatModule"
import MissionModule from "./MissionModule"
import GamesModule from "./GamesModule"
import OrgModule from "./OrgModule"
import ClientModule from "./ClientModule"
import PlayModule from "./PlayModule"
import DrawingModule from "./DrawingModule"
import GroupModule from "./GroupModule"
import SoundEffectModule from "./SoundEffectModule"
import TestModule from "./TestModule"
import TwilioModule from "./TwilioModule"
import RecordingModule from "./RecordingModule"
import ScreenshotModule from "./ScreenshotModule"
import SpeechModule from "./SpeechModule"
import UserSettings from "./UserSettings"
import TournamentModule from "./TournamentModule"
import { DEBUG_ORIGINS } from "@/config"
import {
  Module as ThemeModule,
  MODULE_NAME as THEME_MODULE_NAME
} from "../modules/theme/store"

let teamSubscriptionRef = null
let gameSubscriptionRef = null

Vue.use(Vuex)

const plugins = []

export const GetterTypes = {
  LATECOMER_VIDEO_CODE: "LATECOMER_VIDEO_CODE"
}

const AVATARS = [
  require("@/assets/avatars/1.png"),
  require("@/assets/avatars/2.png"),
  require("@/assets/avatars/3.png"),
  require("@/assets/avatars/4.png"),
  require("@/assets/avatars/5.png"),
  require("@/assets/avatars/6.png"),
  require("@/assets/avatars/7.png"),
  require("@/assets/avatars/8.png"),
  require("@/assets/avatars/9.png"),
  require("@/assets/avatars/10.png"),
  require("@/assets/avatars/11.png"),
  require("@/assets/avatars/12.png"),
  require("@/assets/avatars/13.png"),
  require("@/assets/avatars/14.png"),
  require("@/assets/avatars/15.png"),
  require("@/assets/avatars/16.png")
]

export const MutationTypes = {
  SET_ORG: "SET_ORG"
}

export const ActionTypes = {
  GET_CURRENT_ORG: "GET_CURRENT_ORG"
}

const store = new Vuex.Store({
  plugins: plugins,
  modules: {
    GameUsers: GameUsers,
    livechat: LiveChat,
    profile: Profile,
    pregame: PreGame,
    allusers: UserSearch,
    auth: AuthModule,
    chat: ChatModule,
    mission: MissionModule,
    Games: GamesModule,
    org: OrgModule,
    client: ClientModule,
    play: PlayModule,
    drawing: DrawingModule,
    group: GroupModule,
    test: TestModule,
    soundeffect: SoundEffectModule,
    twilio: TwilioModule,
    recording: RecordingModule,
    screenshot: ScreenshotModule,
    UserSettings: UserSettings,
    tournament: TournamentModule,
    speech: SpeechModule,
    [THEME_MODULE_NAME]: ThemeModule
  },
  state: {
    ignoreOnboardingSelect: false,
    origin: window.location.origin || "",
    time: 0,
    loading: false,
    error: null,
    drawer: false,
    onlineUsers: [],
    allOnlineUsers: [],
    user: {},
    hosts: [],
    isToolBoxOpen: false,
    avatars: AVATARS,
    behaviors: [
      "Text",
      "Multiple Choice",
      "Buzz In",
      "Categories",
      "Freeform",
      "Info",
      "Team Name",
      "Photo",
      "Take Photo",
      "URL",
      "Giphy",
      "Poll",
      "Lipdub",
      "Draw: Each Team",
      "Draw: Pictionary",
      "Draw: Everyone",
      "Player Rating",
      "Fact Match",
      "Link"
    ],
    typicalModes: {
      Text: ["explain", "play", "results"],
      "Multiple Choice": ["explain", "play", "results"],
      "Buzz In": ["explain", "social", "results"],
      Categories: ["explain", "huddle", "results"],
      Freeform: ["explain", "huddle", "results"],
      Info: ["welcome", "play"],
      "Team Name": ["welcome", "play"],
      Photo: ["welcome", "play", "voting", "results"],
      "Take Photo": ["welcome", "play", "voting", "results"],
      URL: ["welcome", "play", "voting", "results"],
      Giphy: ["welcome", "huddle", "voting", "results"],
      Poll: ["explain", "play", "results"],
      "Draw: Each Team": ["play", "voting", "results"],
      "Draw: Pictionary": ["play", "results"],
      "Draw: Everyone": ["explain", "play", "results"],
      "Player Rating": ["explain", "play", "results"],
      "Fact Match": ["explain", "huddle", "results"],
      Link: ["play"]
    },
    playTypes: [
      "Team: Speed Matters",
      "Team: Speed Does Not Matter",
      "Individual: Speed Matters",
      "Individual: Speed Does Not Matter"
    ],
    vimeoUrl: "https://player.vimeo.com/video/",
    teamColors: [
      "#dd76d5",
      "#6d9c9f",
      "#d5dd76",
      "#F7882F",
      "#F7C331",
      "#76a2dd",
      "#dd7e76",
      "#DCC7AA",
      "#f032e6",
      "#85c198",
      "#aed886",
      "#f2e880",
      "#9fb2c3",
      "#7e88ba",
      "#fffac8",
      "#800000",
      "#aaffc3",
      "#808000",
      "#ffd8b1",
      "#000075",
      "#808080"
    ],
    teamIcons: [
      "grade",
      "favorite",
      "brightness_2",
      "tag_faces",
      "eco",
      "face",
      "brightness_1",
      "flash_on",
      "filter_vintage",
      "fitness_center",
      "school"
    ],
    gameID: 0,
    theGame: {},
    orgID: "-LakAFwa9dpR59e8YlON",
    game: null,
    actualGame: null,
    actualGameID: null,
    teamID: 0,
    developer: false,
    theOrg: null
  },
  mutations: {
    UPDATE_IGNORE_ONBOARDING_SELECT(state, payload) {
      state.ignoreOnboardingSelect = payload
    },
    UPDATE_TIME(state, payload) {
      state.time = payload
    },
    SET_USER_GAME_ID(state, gameID) {
      state.gameID = gameID
    },
    SET_GAME(state, payload) {
      state.game = payload
    },
    SET_USER_ACTUAL_GAME_ID(state, gameID) {
      state.actualGameID = gameID
    },
    SET_ACTUAL_GAME(state, payload) {
      state.actualGame = payload
    },
    SET_ORG_ID(state, payload) {
      state.orgID = payload
    },
    [MutationTypes.SET_ORG](state, payload) {
      state.theOrg = payload
    },
    setLoading(state, payload) {
      state.loading = payload
    },
    setError(state, payload) {
      state.error = payload
    },
    setHosts(state, payload) {
      state.hosts = payload
    },
    clearError(state) {
      state.error = null
    },
    setDrawer(state, payload) {
      state.drawer = payload
    },
    UPDATE_TOOLBOX_STATE(state, payload) {
      state.isToolBoxOpen = payload
    },
    UPDATE_DEVELOPER_MODE(state, payload) {
      state.developer = payload
    }
  },
  actions: {
    updateIgnoreOnboardingSelect({ commit }, payload) {
      commit("UPDATE_IGNORE_ONBOARDING_SELECT", payload)
    },
    toggleDeveloperMode({ commit, state }) {
      commit("UPDATE_DEVELOPER_MODE", !state.developer)
    },
    updateExpoTourState({ rootGetters }, payload) {
      const { id: userID } = rootGetters["auth/user"]
      return firebase
        .database()
        .ref(`org/1/users/${userID}/expotour`)
        .set(payload)
    },
    async bringBackActualGame({ commit, state }) {
      commit("SET_GAME", state.actualGame)
      commit("SET_USER_GAME_ID", state.actualGameID)
    },
    async setLocalGame({ commit }, payload) {
      commit("SET_GAME", payload)
      commit("SET_USER_GAME_ID", payload.id)
    },
    async setLocalGameID({ commit }, payload) {
      commit("SET_USER_GAME_ID", payload)
    },
    async updateTime({ commit }, payload) {
      commit("UPDATE_TIME", payload)
    },
    async updateToolBoxState({ commit }, payload) {
      commit("UPDATE_TOOLBOX_STATE", payload)
    },
    setDrawer({ commit }, payload) {
      commit("setDrawer", payload)
    },
    async setOrgID({ commit, dispatch }, orgID) {
      await dispatch("setOrg", orgID)
      commit("SET_ORG_ID", orgID)
    },
    async setOrg({ commit }, orgID) {
      const snapshot = await firebase
        .database()
        .ref("orgs/" + orgID)
        .once("value")

      commit(MutationTypes.SET_ORG, snapshot.val())
    },
    [ActionTypes.GET_CURRENT_ORG]({ state, commit }) {
      return OrgService.getOrg(state.orgID).then(org => {
        commit(MutationTypes.SET_ORG, org)
      })
    },
    async deleteAllUsers({ dispatch, rootGetters }) {
      const users = rootGetters["GameUsers/usersArray"]
      const array = users.map(({ id: userID }) => ({
        userID,
        collection: [{ key: "gameID", value: null }]
      }))
      await dispatch("updateUsersInBulk", { array })
    },
    subscribeToTheGameID({ commit }, { gameID, orgID }) {
      if (gameSubscriptionRef) gameSubscriptionRef.off("value")

      gameSubscriptionRef = firebase
        .database()
        .ref(`org/${orgID}/games/${gameID}`)

      commit("SET_USER_GAME_ID", gameID)
      commit("SET_USER_ACTUAL_GAME_ID", gameID)

      return new Promise(resolve => {
        gameSubscriptionRef.on("value", snapshot => {
          commit("SET_GAME", snapshot.val())
          commit("SET_ACTUAL_GAME", snapshot.val())
          resolve()
        })
      })
    },
    async fetchHosts({ rootState, commit }) {
      const snapshot = await firebase
        .database()
        .ref(`org/1/users`)
        .orderByChild("role")
        .equalTo("facilitator")
        .once("value")

      commit("setHosts", snapshot.val())
    },
    updateHost({ rootState }, payload) {
      const serialized = JSON.parse(JSON.stringify(payload))
      const { id: userID } = serialized
      return firebase.database().ref(`org/1/users/${userID}`).update(serialized)
    },
    async updateUserImage({ rootState }, payload) {
      var id = payload.id
      await firebase
        .database()
        .ref("org/1/users/" + id)
        .update(payload)
    },
    async addHost({ rootState }, payload) {
      const serialized = JSON.parse(JSON.stringify(payload))
      await firebase.database().ref("org/1/users").push(serialized)
    },
    async updateUserToTeamID({ dispatch }, { teamID, userID }) {
      console.log("UPDAET USER TEAM", teamID)
      await dispatch("updateUser", { userID, obj: { teamID } })
    },
    clearError({ commit }) {
      commit("clearError")
    },
    async setLowPerformance({ commit, rootState }, payload) {
      console.log("PAYLOAD", payload)
      const userID = payload.userID
      if (!userID) throw new Error("Cannot update a user without a userID")
      const obj = { potato: payload.potato }
      const path = "org/1"
      await firebase
        .database()
        .ref(path + "/users/" + userID)
        .update(obj)
    },
    async updateUser({ commit }, { userID, obj }) {
      console.log(`user ${userID} is getting an update`, obj)
      await firebase.database().ref(`org/1/users/${userID}`).update(obj)
    },
    async describeAllUsers({ rootState, dispatch, rootGetters }) {
      const { onlineUsersArray } = rootGetters
      const array = onlineUsersArray.map(({ id: userID }) => ({
        userID,
        collection: [{ key: "selected", value: false }]
      }))
      await dispatch("updateUsersInBulk", { array })
    },
    async updateUsersInBulk({ rootState }, { array }) {
      const { orgID, gameID } = rootState
      const ref = firebase.database().ref(`org/1/users`)
      const update = {}

      array.forEach(({ userID, collection }) => {
        if (!userID) return console.warn("Cannot update without a userID")
        if (!Array.isArray(collection))
          return console.warn("Got no data to update")
        collection.forEach(({ key, value }) => {
          if (!key || value === undefined)
            return console.warn("Got corrupt data to update")
          update[`/${userID}/${key}`] = value
        })
      })

      await ref.update(update)
    },
    async deletePlayer({ dispatch }, { userID }) {
      await dispatch("updateUser", { userID, obj: { gameID: null } })
    }
  },
  getters: {
    ignoreOnboardingSelect: state => state.ignoreOnboardingSelect,
    [GetterTypes.LATECOMER_VIDEO_CODE](state, _, rootState, rootGetters) {
      const key = "latecomerVideoCode"

      const { game } = rootState
      if (game && game[key]) return game[key]

      const client = rootGetters["auth/client"]
      if (client && client[key]) return client[key]

      const { theOrg: org } = state
      if (org && org[key]) return org[key]
    },
    isExpoTourCompleted(state, getters) {
      return getters.user && !!getters.user.expotour
    },
    isDebugOrigin(state) {
      return DEBUG_ORIGINS.includes(state.origin)
    },
    time(state) {
      return state.time
    },
    hosts(state) {
      return state.hosts
    },
    isToolBoxOpen(state) {
      return state.isToolBoxOpen
    },
    urlID(state, { gameID }, rootState, rootGetters) {
      const hasPreGame = rootGetters["auth/hasPreGame"]
      const clientID = rootGetters["auth/clientID"]
      if (hasPreGame && gameID) {
        return `${clientID}${gameID}`
      } else {
        return `${clientID}`
      }
    },
    user(state, getters, rootState, rootGetters) {
      return rootGetters["auth/user"]
    },
    teamID(staet, { user }) {
      return user ? user.teamID : null
    },
    team(state, { teamID }, rootState, { chats }) {
      return chats ? chats[teamID] : null
    },
    isScribe(state, { user }, rootState) {
      if (rootState.livechat.roomID) return true
      return user ? user.selected : false
    },
    drawer(state) {
      return state.drawer
    },
    loading(state) {
      return state.loading
    },
    error(state) {
      return state.error
    },
    behaviors(state) {
      return state.behaviors
    },
    typicalModes(state) {
      return state.typicalModes
    },
    playTypes(state) {
      return state.playTypes
    },
    gameID(state) {
      return state.gameID
    },
    actualGameID(state) {
      return state.actualGameID
    },
    orgID(state) {
      return state.orgID
    },
    theOrg(state) {
      return state.theOrg
    },
    game(state) {
      return state.game
    },
    actualGame(state) {
      return state.actualGame
    },
    teamColors(state) {
      return state.teamColors
    },
    teamIcons(state) {
      return state.teamIcons
    },
    gameHost(state, getters, rootState, rootGetters) {
      const { otherGroupIds } = getters
      const isMuted = rootGetters["group/isUserMutedGlobally"]
      const usersArray = rootGetters["GameUsers/usersArray"]

      const [user] = usersArray
        .filter(({ role }) => role === Role.Host)
        .sort((a, b) => (b.loginTimestamp || 0) - (a.loginTimestamp || 0))

      if (user) {
        return {
          ...user,
          mutedGlobally: isMuted({ user, userGroupId: 0, otherGroupIds }),
          muted: !!user.muted
        }
      } else {
        return null
      }
    },
    otherGroupIds(state, { teamID }, rootState, { chats }) {
      return Object.keys(chats || {}).filter(id => id !== teamID)
    },
    onlineUsersGroupedByTeam(state, { onlineUsersArray }) {
      return onlineUsersArray.reduce((acc, val) => {
        if (acc[val.teamID]) {
          acc[val.teamID].push(val)
        } else {
          acc[val.teamID] = [val]
        }
        return acc
      }, {})
    },
    onlineUsersArray(state, getters, rootState, rootGetters) {
      const isMuted = rootGetters["group/isUserMutedGlobally"]
      const { otherGroupIds } = getters
      const users = rootGetters["GameUsers/usersOnlineArray"]
      return users.map(user => {
        return {
          ...user,
          mutedGlobally: isMuted({ user, userGroupId: 0, otherGroupIds }),
          muted: !!user.muted
        }
      })
    }
  }
})

if (module.hot) {
  // accept actions and mutations as hot modules
  module.hot.accept(
    [
      /*'./mutations',*/
      "./AuthModule",
      "./ChatModule",
      "./MissionModule",
      "./PlayModule",
      "./DrawingModule",
      "./GroupModule",
      "./SoundEffectModule",
      "./GamesModule",
      "./TestModule",
      "./TwilioModule",
      // "./Leaderboard",
      // "./GameFeed",
      "./UserSearch",
      "./PreGame",
      "./Profile",
      "./LiveChat",
      "./RecordingModule",
      "./GameUsers",
      "./ScreenshotModule",
      "./UserSettings",
      "./SpeechModule"
    ],
    () => {
      store.hotUpdate({
        // mutations: newMutations,
        modules: {
          GameUsers: require("./GameUsers").default,
          livechat: require("./LiveChat").default,
          profile: require("./Profile").default,
          pregame: require("./PreGame").default,
          allusers: require("./UserSearch").default,
          // gamefeed: require("./GameFeed").default,
          // leaderboard: require("./Leaderboard").default,
          auth: require("./AuthModule").default,
          chat: require("./ChatModule").default,
          mission: require("./MissionModule").default,
          play: require("./PlayModule").default,
          drawing: require("./DrawingModule").default,
          group: require("./GroupModule").default,
          soundeffect: require("./SoundEffectModule").default,
          recording: require("./RecordingModule").default,
          Games: require("./GamesModule").default,
          twilio: require("./TwilioModule").default,
          screenshot: require("./ScreenshotModule").default,
          UserSettings: require("./UserSettings").default,
          speech: require("./SpeechModule").default
        }
      })
    }
  )
}

export { store }
