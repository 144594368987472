var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "host-toolbar" },
    [
      _c(
        "v-toolbar",
        { attrs: { app: "", dark: "", height: "56" } },
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "toolbar-btn",
                        _vm._g(
                          {
                            staticStyle: { "margin-left": "-8px" },
                            on: { click: _vm.editSettings }
                          },
                          on
                        ),
                        [_c("svg-icon", { attrs: { name: "cog" } })],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Edit Settings")])]
          ),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "toolbar-btn",
                        _vm._g(
                          {
                            on: {
                              click: function($event) {
                                if (!$event.shiftKey) {
                                  return null
                                }
                                return _vm.resetGame($event)
                              }
                            }
                          },
                          on
                        ),
                        [_c("svg-icon", { attrs: { name: "sync-alt" } })],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Shift-Click to Reset Game")])]
          ),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "toolbar-btn",
                        _vm._g({ on: { click: _vm.resetSubmission } }, on),
                        [_c("svg-icon", { attrs: { name: "undo-alt" } })],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Reset Mission")])]
          ),
          !_vm.endGameProgress
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "toolbar-btn",
                              _vm._g({ on: { click: _vm.onEndGame } }, on),
                              [
                                _c("svg-icon", {
                                  attrs: { name: "hourglass-end" }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    466852292
                  )
                },
                [_c("span", [_vm._v("End Game")])]
              )
            : _c("v-progress-circular", {
                staticClass: "spinner",
                attrs: { indeterminate: "" }
              }),
          !!_vm.getScreenshotStream
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "toolbar-btn",
                              _vm._g(
                                {
                                  staticStyle: { "margin-left": "-8px" },
                                  on: {
                                    click: function($event) {
                                      return _vm.captureScreenshot(
                                        "MANUAL_SCREENSHOT"
                                      )
                                    }
                                  }
                                },
                                on
                              ),
                              [
                                !_vm.processingScreenshotDone
                                  ? _c(
                                      "v-icon",
                                      {
                                        class: {
                                          "screenshot-processing":
                                            _vm.getIsProcessingScreenshot
                                        }
                                      },
                                      [_vm._v(" camera ")]
                                    )
                                  : _vm._e(),
                                _vm.processingScreenshotDone
                                  ? _c("v-icon", [_vm._v("done")])
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    4062193022
                  )
                },
                [_c("span", [_vm._v("Take Screenshot (R)")])]
              )
            : _vm._e(),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-flex",
            { attrs: { xs3: "" } },
            [
              _c("v-select", {
                ref: "missionsSelect",
                staticClass: "select-mission v-select--with-icons",
                attrs: {
                  "ml-1": "",
                  items: _vm.missionList,
                  label: "Missions",
                  solo: "",
                  "item-text": "name",
                  "item-value": "id",
                  value: _vm.computedMissionID
                },
                on: {
                  focus: _vm.disableKeyboardShortcuts,
                  input: _vm.onMissionSelectInput,
                  blur: _vm.enableKeyboardShortcuts
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          { staticClass: "selected" },
                          [
                            _c("svg-icon", {
                              staticClass: "v-list__icon",
                              attrs: { name: item.icon }
                            }),
                            _vm._v(
                              " " +
                                _vm._s(item.pos + 1) +
                                " " +
                                _vm._s(item.name) +
                                " "
                            )
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("svg-icon", {
                          staticClass: "v-list__icon",
                          attrs: { name: item.icon }
                        }),
                        _vm._v(
                          " " +
                            _vm._s(item.pos + 1) +
                            " " +
                            _vm._s(item.name) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs1: "", "px-2": "" } },
            [
              _c("v-select", {
                ref: "modesSelect",
                attrs: {
                  items: _vm.modes,
                  solo: "",
                  "item-text": "name",
                  "item-value": "id",
                  value: _vm.mode
                },
                on: {
                  focus: _vm.disableKeyboardShortcuts,
                  input: _vm.onModeSelectInput,
                  blur: _vm.enableKeyboardShortcuts
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            [
              _c(
                "v-tooltip",
                {
                  key: "auditors",
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c("center", [
                            _c("div", _vm._g({ staticClass: "auditors" }, on), [
                              _vm._v(_vm._s(_vm.auditorsCount))
                            ])
                          ])
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("People auditing this game")])]
              )
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-tooltip",
            {
              key: "scribe-team",
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "toolbar-btn",
                        _vm._g(
                          {
                            attrs: { size: "lg", theme: "grey" },
                            on: {
                              click: [
                                function($event) {
                                  if (
                                    $event.ctrlKey ||
                                    $event.shiftKey ||
                                    $event.altKey ||
                                    $event.metaKey
                                  ) {
                                    return null
                                  }
                                  return _vm.onSelectOneScribePerTeam($event)
                                },
                                function($event) {
                                  if (!$event.altKey) {
                                    return null
                                  }
                                  return _vm.onSelectOneScribePerTeamAlt($event)
                                }
                              ]
                            }
                          },
                          on
                        ),
                        [_c("svg-icon", { attrs: { name: "next-scribe" } })],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("One Scribe Per Team")])]
          ),
          _vm.isLipdub
            ? _c(
                "v-tooltip",
                {
                  key: "scribe",
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "toolbar-btn",
                              _vm._g(
                                {
                                  attrs: { theme: "grey" },
                                  on: { click: _vm.onSelectOneScribe }
                                },
                                on
                              ),
                              [_c("svg-icon", { attrs: { name: "scribe" } })],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2049286355
                  )
                },
                [_c("span", [_vm._v("Select One Scribe")])]
              )
            : _vm._e(),
          _c(
            "v-tooltip",
            {
              key: "talk",
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "toolbar-btn",
                        _vm._g(
                          {
                            attrs: {
                              text: "Team Talk",
                              theme: "grey",
                              active: _vm.getEveryoneAudioStatus
                            },
                            on: { click: _vm.handleToggleEveryoneAudio }
                          },
                          on
                        ),
                        [_c("svg-icon", { attrs: { name: "ear" } })],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("All Audio On")])]
          ),
          _c(
            "v-tooltip",
            {
              key: "mute",
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "toolbar-btn",
                        _vm._g(
                          {
                            attrs: {
                              text: "Mute All",
                              theme: "grey",
                              active: _vm.getEveryoneMuted
                            },
                            on: { click: _vm.handleToggleEveryoneMuted }
                          },
                          on
                        ),
                        [_c("svg-icon", { attrs: { name: "mute" } })],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Mute Everyone")])]
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-tooltip",
            {
              key: "record",
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "toolbar-btn",
                        _vm._g(
                          {
                            staticClass: "host-toolbar__record-btn",
                            attrs: {
                              theme: "grey",
                              active: _vm.showRecordingDialog
                            },
                            on: {
                              click: function($event) {
                                _vm.showRecordingDialog = true
                              }
                            }
                          },
                          on
                        ),
                        [_c("svg-icon", { attrs: { name: "record" } })],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Record Video")])]
          ),
          _c(
            "v-tooltip",
            {
              key: "headroom",
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _vm.isMaxHeadRoomVideo && _vm.hasFeauredHostVideo
                        ? _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "host-toolbar__play-stop",
                                attrs: { icon: "" },
                                on: { click: _vm.toggleIsMaxHeadRoomVideo }
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("stop")])],
                            1
                          )
                        : _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "host-toolbar__play-stop",
                                attrs: {
                                  icon: "",
                                  disabled: !_vm.hasFeauredHostVideo
                                },
                                on: { click: _vm.toggleIsMaxHeadRoomVideo }
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("play_arrow")])],
                            1
                          )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Toggle Max Headroom")])]
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _vm.mission && _vm.mission.photo
            ? _c(
                "v-tooltip",
                {
                  key: "photo",
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "toolbar-btn",
                              _vm._g(
                                {
                                  attrs: {
                                    active: _vm.gameStatus.media === "photo"
                                  },
                                  on: { click: _vm.toggleGameMedia }
                                },
                                on
                              ),
                              [_c("svg-icon", { attrs: { name: "picture" } })],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1968864543
                  )
                },
                [_c("span", [_vm._v("Show/Hide Mission Photo")])]
              )
            : _vm._e(),
          _vm.mission && _vm.mission.youtube
            ? _c(
                "v-tooltip",
                {
                  key: "video",
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "toolbar-btn",
                              _vm._g(
                                {
                                  attrs: {
                                    active: _vm.gameStatus.media === "video"
                                  },
                                  on: { click: _vm.toggleGameMedia }
                                },
                                on
                              ),
                              [_c("svg-icon", { attrs: { name: "video" } })],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    620440223
                  )
                },
                [_c("span", [_vm._v("Show/Hide Mission Video")])]
              )
            : _vm._e(),
          _vm.mission && _vm.mission.audio
            ? _c(
                "v-tooltip",
                {
                  key: "audio",
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "toolbar-btn",
                              _vm._g(
                                {
                                  attrs: {
                                    active: _vm.gameStatus.media === "audio"
                                  },
                                  on: { click: _vm.toggleGameMedia }
                                },
                                on
                              ),
                              [_c("svg-icon", { attrs: { name: "audio" } })],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3142524991
                  )
                },
                [_c("span", [_vm._v("Show/Hide Mission Audio")])]
              )
            : _vm._e(),
          _c("div", { staticClass: "flex-grow-1" }),
          _vm.displayAnonymusIcon
            ? _c(
                "v-tooltip",
                {
                  key: "anonymous",
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "toolbar-btn",
                              _vm._g(
                                {
                                  attrs: { active: _vm.votingAnon, size: "lg" },
                                  on: { click: _vm.toggleVotingAnon }
                                },
                                on
                              ),
                              [
                                _c("svg-icon", { attrs: { name: "anonymous" } })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    90654835
                  )
                },
                [_c("span", [_vm._v("Anonymus voting")])]
              )
            : _vm._e(),
          _vm.displayTipJarIcon
            ? _c(
                "v-tooltip",
                {
                  key: "tip-jar",
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "toolbar-btn",
                              _vm._g(
                                {
                                  attrs: { active: _vm.game.showTipJar },
                                  on: { click: _vm.toggleTipJar }
                                },
                                on
                              ),
                              [_c("svg-icon", { attrs: { name: "tip-jar" } })],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1361046594
                  )
                },
                [_c("span", [_vm._v("Show Tip Jar")])]
              )
            : _vm._e(),
          _vm.displayAnonymusIcon || _vm.displayTipJarIcon
            ? _c("v-divider", { attrs: { vertical: "" } })
            : _vm._e(),
          _vm._m(0),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-tooltip",
            {
              key: "link",
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "login-player",
                            attrs: {
                              icon: "",
                              to: "/gamelogin/" + _vm.urlID + "?auth=0",
                              target: "_blank"
                            }
                          },
                          on
                        ),
                        [_c("v-icon", [_vm._v("flash_on")])],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Link to " + _vm._s(_vm.game.name) + " ")])]
          ),
          _c(
            "v-tooltip",
            {
              key: "lobby",
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "toolbar-btn",
                        _vm._g(
                          {
                            attrs: { size: "lg" },
                            on: { click: _vm.pushToLobby }
                          },
                          on
                        ),
                        [_c("svg-icon", { attrs: { name: "lobby" } })],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Back to Lobby")])]
          ),
          _c(
            "v-tooltip",
            {
              key: "edit",
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "div",
                        _vm._g(
                          {
                            staticClass: "game-editor",
                            attrs: { dark: "" },
                            on: { click: _vm.pushToGameSettings }
                          },
                          on
                        ),
                        [_vm._v(" DASH ")]
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Edit Missions")])]
          )
        ],
        1
      ),
      !!_vm.passedGame && _vm.editGameSettings
        ? _c(
            "v-dialog",
            {
              attrs: { width: "60%" },
              model: {
                value: _vm.editGameSettings,
                callback: function($$v) {
                  _vm.editGameSettings = $$v
                },
                expression: "editGameSettings"
              }
            },
            [
              _c("GameSettings", {
                attrs: { game: _vm.passedGame },
                on: { closeGameSettings: _vm.closeEdit }
              })
            ],
            1
          )
        : _vm._e(),
      _c("end-game-popup", { ref: "endGamePopup" }),
      _c(
        "Popup",
        {
          attrs: {
            header: "Record Video for Max HeadRoom",
            isOpen: _vm.showRecordingDialog
          },
          on: {
            onClose: function($event) {
              _vm.showRecordingDialog = false
            }
          }
        },
        [
          _c("RecordVideo", {
            attrs: {
              clientID: _vm.clientID,
              gameID: _vm.gameID,
              videoTarget: "game",
              hasFeaturedRecording: true
            },
            on: { onPosted: _vm.onRecordingPosted }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("AudioPlayer", { staticClass: "audio-player" })
  }
]
render._withStripped = true

export { render, staticRenderFns }