<template>
  <div>
    <v-toolbar app dark class="black grey--text lobby-toolbar" height="56">
      <v-layout align-center>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <toolbar-btn v-on="on" @click="editSettings()"
              ><svg-icon name="cog"
            /></toolbar-btn>
          </template>
          <span>Edit Settings</span>
        </v-tooltip>

        <v-toolbar-title style="font-size: 15px" width="400" class="game-name">
          <span id="theGame" v-if="game">{{ game.name }}</span>
        </v-toolbar-title>

        <v-divider vertical />
        <v-flex>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <center>
                <div v-on="on" class="auditors">{{ auditorsCount }}</div>
              </center>
            </template>
            <span>People auditing this game</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
      <v-divider vertical />

      <v-layout
        class="number-input max-players"
        v-bind:class="maxPlayersLength"
      >
        <div class="value">{{ maxPlayers || "--" }}</div>
        <div class="arrows">
          <div class="up" @mousedown="handleIncMaxPlayers()"></div>
          <div class="down" @mousedown="handleDecMaxPlayers()"></div>
        </div>
        <label>max<br />players</label>
      </v-layout>

      <v-switch
        v-model="locked"
        @change="toggleLocked()"
        label="Game Locked"
        class="switch-locked"
      />

      <v-divider vertical />

      <v-tooltip bottom v-if="allTeams.length < MAX_NUM_OF_TEAMS">
        <template v-slot:activator="{ on }">
          <v-btn circle small fab @click="onCreateTeam" class="add-team">
            <v-icon>add</v-icon>
          </v-btn>
        </template>
        <span>Add Team</span>
      </v-tooltip>

      <v-layout align-center justify-end class="right-items">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <toolbar-btn
              v-on="on"
              text="Team Talk"
              theme="grey"
              :active="getEveryoneAudioStatus"
              @click="handleToggleEveryoneAudio"
              ><svg-icon name="ear"></svg-icon
            ></toolbar-btn>
          </template>
          <span>All Audio On</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <toolbar-btn
              v-on="on"
              text="Mute All"
              theme="grey"
              :active="getEveryoneMuted"
              @click="handleToggleEveryoneMuted"
              ><svg-icon name="mute"
            /></toolbar-btn>
          </template>
          <span>Mute Everyone</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <toolbar-btn
              v-on="on"
              text="Local Mute"
              theme="grey"
              :active="isUsersLocallyMuted"
              @click="toggleLocalMute"
              ><svg-icon name="mute"
            /></toolbar-btn>
          </template>
          <span>Mute everyone locally</span>
        </v-tooltip>

        <v-divider vertical />

        <AudioPlayer class="audio-player" />

        <v-divider vertical />

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div
              dark
              v-on="on"
              class="dashboard-btn"
              @click="pushToGameSettings"
            >
              DASH
            </div>
          </template>
          <span>Go to Editor</span>
        </v-tooltip>

        <v-divider vertical />

        <div class="logout" @click="logout()">OUT</div>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div dark v-on="on" class="game-btn" @click="pushToGame">
              GO TO GAME
            </div>
          </template>
          <span>Join the Game</span>
        </v-tooltip>
      </v-layout>
    </v-toolbar>

    <v-dialog v-model="editGameSettings" v-if="passedGame" width="60%">
      <GameSettings :game="passedGame" @closeGameSettings="closeEdit()" />
    </v-dialog>

    <v-dialog v-model="editTeam" max-width="400px">
      <v-layout justify-center>
        <v-flex xs12>
          <v-toolbar
            :color="$theme.get('--primary-accent-color')"
            dark
            height="50px"
          >
            <v-toolbar-title>{{ teamModalTitle }} </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card>
            <v-container fluid grid-list-md>
              <v-layout row wrap>
                <v-flex>
                  <form @submit.prevent="onSignin">
                    <v-card-text>
                      <v-text-field
                        v-model="team.name"
                        name="firstname"
                        label="Team Name"
                      />
                    </v-card-text>
                    <v-card-text>
                      <v-select
                        :items="teamIcons"
                        v-model="team.icon"
                        label="Team Icon"
                        dense
                      >
                        <template slot="selection" slot-scope="data">
                          <v-icon class="team-icon-input selected">{{
                            data.item
                          }}</v-icon>
                        </template>
                        <template slot="item" slot-scope="data">
                          <v-icon class="team-icon-input">
                            {{ data.item }}
                          </v-icon>
                        </template>
                      </v-select>
                    </v-card-text>
                  </form>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      class="pick-team-btn-cancel"
                      @click="editTeam = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      v-if="teamAction === 'edit'"
                      class="pick-team-btn-dismiss"
                      @click="dismissTeam"
                    >
                      Dismiss
                    </v-btn>
                    <v-btn class="pick-team-btn-save" @click="saveTeam">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
import AudioPlayer from "@/components/GroupTeams/Misc/AudioPlayer"
import ToolbarBtn from "@/components/GroupTeams/Common/ToolbarBtn.vue"
const GameSettings = () => import("@/components/Game/GameSettings")

import { Role } from "@/helpers"

export default {
  name: "LobbyToolbar",
  data() {
    return {
      passedGame: null,
      editGameSettings: false,
      MAX_NUM_OF_TEAMS: 10,
      MIN_NUM_OF_TEAMS: 2,
      teamAction: "Edit",
      mousePressed: false,
      team: {},
      editTeam: false,
      maxPlayers: undefined
    }
  },
  components: {
    GameSettings,
    AudioPlayer,
    ToolbarBtn
  },
  created() {
    this.locked = this.game.locked
    this.maxPlayers = this.game.players
    window.addEventListener("mouseup", this.mouseUp)
    window.addEventListener("mousemove", this.mouseUp)
  },
  beforeDestroy() {
    window.removeEventListener("mouseup", this.mouseUp)
    window.removeEventListener("mousemove", this.mouseUp)
  },
  computed: {
    ...mapState({
      gameID: state => state.gameID,
      orgID: state => state.orgID
    }),
    ...mapGetters("auth", ["clientID", "user", "role"]),
    ...mapGetters({ org: "theOrg", teams: "chats" }),
    ...mapGetters([
      "urlID",
      "teamIcons",
      "getEveryoneAudioStatus",
      "getEveryoneMuted",
      "onlineUsersArray",
      "game"
    ]),
    ...mapState("group", ["isUsersLocallyMuted"]),
    ...mapGetters("screenshot", [
      "getScreenshotStream",
      "getLocalScreenshotStatus"
    ]),
    allTeams() {
      if (!this.teams) return []
      const entries = Object.entries(this.teams)
      return entries.map((array, i) => {
        const key = array[0]
        const value = array[1]
        return {
          totalScore: value.totalScore,
          color: value.color,
          icon: value.icon,
          active: true,
          rank: i + 1,
          action: "local_activity",
          name: value.name,
          id: key
        }
      })
    },
    maxPlayersLength() {
      if (this.maxPlayers > 9) return "twoDigits"
      else return ""
    },
    route() {
      return this.$route.name
    },
    isLobby() {
      return this.route === "lobby"
    },
    isGame() {
      return this.route === "game"
    },
    isGameSettings() {
      return this.route === "gameSettings"
    },
    gameLocked() {
      this.locked = this.game.locked
      return this.game.locked
    },
    isGameLocked() {
      return this.game ? !!this.game.locked : true
    },
    teamModalTitle() {
      if (this.teamAction === "edit") {
        if (this.team.name) {
          return `EDIT ${this.team.name}`
        } else {
          return "EDIT"
        }
      } else if (this.teamAction === "create") {
        return "CREATE NEW"
      } else {
        return ""
      }
    },
    auditorsCount() {
      return this.onlineUsersArray.filter(
        ({ role }) => role === Role.Audit || role === Role.Spectator
      ).length
    }
  },
  methods: {
    ...mapMutations("group", ["UPDATE_IS_USERS_LOCALLY_MUTED"]),
    ...mapActions(["deleteTeam", "updateTeam", "createTeam"]),
    ...mapActions("Games", ["updateGameAny"]),
    ...mapActions("group", ["toggleEveryoneAudio", "toggleEveryoneMuted"]),
    ...mapActions("screenshot", ["captureScreenshot"]),
    editSettings() {
      this.passedGame = this.game
      this.passedGame.theKey = this.gameID
      this.editGameSettings = true
    },
    closeEdit() {
      this.editGameSettings = false
    },
    logout() {
      if (confirm("Are you sure you want LOGOUT?")) {
        this.$router.push("/logout")
      }
    },
    mouseUp() {
      this.mousePressed = false
    },
    handleToggleEveryoneAudio() {
      this.toggleEveryoneAudio({ mutedStatus: this.getEveryoneAudioStatus })
      this.toggleEveryoneMuted({ mutedStatus: true })
    },
    handleToggleEveryoneMuted() {
      this.toggleEveryoneMuted({ mutedStatus: this.getEveryoneMuted })
      this.toggleEveryoneAudio({ mutedStatus: true })
    },
    async pushToGame() {
      try {
        this.getLocalScreenshotStatus && this.captureScreenshot("LOBBY_TO_GAME")
      } catch (e) {
        console.warn(e.message)
      }
      await this.$store.dispatch("fetchMissions", this.gameID)
      await this.$router.push(`/game/${this.urlID}`)
    },
    async pushToGameSettings() {
      // await this.$store.dispatch("auth/initializeToGame", {
      //   gameID: this.gameID,
      //   clientID: this.clientID
      // })
      await this.$router.push(`/game/${this.urlID}/settings`)
    },
    toggleLocked() {
      var obj = {}
      obj.theKey = this.gameID
      obj.locked = this.locked
      this.updateGameAny(obj)
    },
    onEditTeam(team) {
      this.editTeam = true
      this.team = team
      this.teamAction = "edit"
    },
    onCreateTeam() {
      this.editTeam = true
      this.team = {}
      this.teamAction = "create"
    },
    getRandomInt(min, max) {
      const _min = Math.ceil(min)
      const _max = Math.floor(max)
      return Math.floor(Math.random() * (_max - _min + 1)) + _min
    },
    getRandomIcon() {
      if (this.teams) {
        const array = Object.values(this.teams)
        const pool = this.teamIcons.filter(
          string => !array.some(({ icon }) => icon === string)
        )
        return pool[this.getRandomInt(0, pool.length - 1)]
      } else {
        return this.teamIcons[this.getRandomInt(0, this.teamIcons.length - 1)]
      }
    },
    async dismissTeam() {
      if (!this.team) return
      if (!this.team.id) return
      const { id: teamID, name } = this.team
      console.log("we want to dismiss " + name + " team")

      const response = confirm(`Team ${name} will be dismissed`)
      if (!response) return console.log("aborted by user")

      const playersToDismiss = this.onlineUsersArray.filter(
        ({ teamID: userTeamID }) => userTeamID === teamID
      )

      console.log("players to dismiss")
      console.log(playersToDismiss)

      const promises = playersToDismiss.map(user =>
        this.changeTeam({ added: { element: user } }, 0)
      )

      await Promise.all(promises)

      console.log("players dismissed")

      await this.deleteTeam({ id: teamID })

      console.log(`team ${name} deleted`)

      this.editTeam = false
    },
    async saveTeam() {
      if (this.team.id) {
        await this.updateTeam(this.team)
      } else {
        const obj = {}
        obj.name = this.team.name
        obj.icon = this.team.icon || this.getRandomIcon()
        obj.show = true
        obj.muted = false
        obj.totalScore = 0
        obj.can_hear_facilitator = true
        obj.muted_facilitator = false
        obj.players = 0
        obj.active = true
        obj.slogan = "Manual"
        // console.log("TEAM", obj)
        await this.createTeam(obj)
      }
      this.team = {}
      this.editTeam = false
    },
    handleIncMaxPlayers() {
      this.mousePressed = true
      this.incMaxPlayers()
    },
    incMaxPlayers() {
      if (this.mousePressed) {
        if (!this.maxPlayers) this.maxPlayers = 1
        else this.maxPlayers++
        setTimeout(this.incMaxPlayers, 250)
      } else this.setMaxPlayers()
    },
    setMaxPlayers() {
      var curGame = this.game
      curGame.theKey = this.gameID
      curGame.players = this.maxPlayers
      this.$store.dispatch("Games/updateGame", curGame)
    },
    handleDecMaxPlayers() {
      this.mousePressed = true
      this.decMaxPlayers()
    },
    decMaxPlayers() {
      if (this.mousePressed) {
        if (this.maxPlayers > 1) this.maxPlayers--
        else this.maxPlayers = undefined
        setTimeout(this.decMaxPlayers, 250)
      } else this.setMaxPlayers()
    },
    toggleLocalMute() {
      this.UPDATE_IS_USERS_LOCALLY_MUTED(!this.isUsersLocallyMuted)
    }
  }
}
</script>

<style lang="scss">
.auditors {
  position: relative;
  display: inline-block;
  height: 31px;
  margin: 10px 5px;
  line-height: 31px;
  color: $color-white;
  font-weight: 500;
  font-size: 20px;
  opacity: 0.5;
  cursor: default;
}
.lobby-toolbar {
  background: $color-black;
  border-bottom: solid 1px $primary_accent_color !important;
  z-index: $layer_toolbar;

  .right-items {
    min-width: 650px;
  }

  .v-toolbar__content {
    padding: 0;
  }

  .v-input--switch {
    height: 37px;
  }
  .v-input--switch__track {
    top: 0;
    left: 0;
    opacity: 1;
    width: 55px;
    height: 27px;
    border-radius: 13px;
  }
  .theme--dark.v-input--switch__track {
    background: $color-white;
  }
  .v-input--switch .v-input--selection-controls__input {
    width: 55px;
    height: 27px;
  }
  .v-input--switch .v-input--selection-controls__ripple {
    left: 0;
    top: calc(50% - 23px);
  }
  .v-input--selection-controls.v-input .v-label {
    text-transform: uppercase;
    color: $color-white;
    font-size: 12px;
    line-height: 13px;
  }
  .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
    margin-bottom: 0;
  }
  .v-input--switch__thumb {
    top: calc(50% - 11px);
    left: 4px;
    top: 3px;
    width: 21px;
    height: 21px;
    box-shadow: none;
  }
  .v-input--switch.v-input--is-dirty .v-input--selection-controls__ripple,
  .v-input--switch.v-input--is-dirty .v-input--switch__thumb {
    transform: translateX(27px);
  }
  .theme--dark.v-input--switch__thumb {
    color: $color-tertiary-dark;
  }
  .accent--text {
    color: $primary_accent_color !important;
  }

  .switch-locked {
    max-width: 112px;
    margin: 0 10px;
    label {
      width: 45px;
    }
  }

  .v-divider--vertical {
    height: 35px;
    min-height: 35px;
    max-height: 35px;
    margin: 9px 10px;
    background: $color-tertiary-dark;
  }

  .game-name {
    max-width: 300px;
    line-height: 55px;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    color: $color-white;
    margin-left: 0;
  }

  .btn {
    position: relative;
    width: 35px;
    height: 31px;
    margin: 10px 10px;
    cursor: pointer;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-position: center top;
      transition: opacity 0.1s ease-out;
    }
    &:after {
      opacity: 0;
      background-position: center -31px;
    }
    &.active:before,
    &:hover:before {
      opacity: 0;
    }
    &.active:after,
    &:hover:after {
      opacity: 1;
    }
  }

  .dashboard-btn {
    background: $primary_accent_color;
    padding: 6px 11px;
    margin-left: 10px;
    margin-right: 10px;
    height: 25px;
    line-height: 14px;
    font-weight: 500;
    color: $color-white;
    text-align: center;
    cursor: pointer;
    transition: all 0.1s ease-out;
    &:hover {
      color: $primary_accent_color;
      background: $color-white;
    }
  }

  .logout {
    margin-right: 20px;
    margin-left: 10px;
    height: 22px;
    line-height: 21px;
    font-size: 12px;
    white-space: nowrap;
    font-weight: 600;
    text-align: center;
    border: solid 1px $primary_accent_color;
    border-radius: 12px;
    color: $color-grey-light4;
    padding: 0 15px;
    cursor: pointer;

    &:hover {
      color: $color-white;
      background: $primary_accent_color;
    }
  }

  .game-btn {
    background: $color-correct;
    height: 55px;
    line-height: 55px;
    font-size: 18px;
    font-weight: 600;
    padding: 0 14px;
    color: $color-white;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.1s ease-out;
    &:hover {
      background: $color-white;
      color: $color-correct;
    }
  }

  .add-team {
    position: relative;
    &.v-btn--floating.v-btn--small {
      width: 36px;
      height: 36px;
    }
    background: $primary_accent_color !important;
    &.v-btn--floating.v-btn--small .v-icon {
      font-weight: 600;
      font-size: 28px;
    }
  }

  .number-input {
    min-width: 90px;
    max-width: 90px;
    margin: 0 10px;
    user-select: none;
    &.twoDigits {
      min-width: 107px;
    }
    .value {
      line-height: 27px;
      font-size: 30px;
      color: $color-white;
    }
    .arrows {
      margin: 0 5px;
      .up,
      .down {
        width: 0;
        height: 0;
        border-style: solid;
        cursor: pointer;
      }
      .up {
        margin: 2px 0 4px;
        border-width: 0 6.5px 9px 6.5px;
        border-color: transparent transparent $color-white transparent;
        &:hover {
          border-bottom-color: $primary_accent_color;
        }
      }
      .down {
        margin: 4px 0 2px;
        border-width: 9px 6.5px 0 6.5px;
        border-color: $color-white transparent transparent transparent;
        &:hover {
          border-top-color: $primary_accent_color;
        }
      }
    }
    label {
      display: block;
      text-transform: uppercase;
      color: $color-white;
      font-size: 12px;
      line-height: 13px;
    }
  }
}

@media (max-width: 1300px) {
  .lobby-toolbar {
    .game-name {
      margin-right: 10px;
    }
    .v-divider--vertical {
      margin: 9px 0;
    }
    .switch-locked {
      margin-left: 0;
    }
    .logout {
      margin-right: 10px;
    }
  }
}
</style>
