<template>
  <v-layout column justify-center fill-height class="social-team-users">
    <v-flex
      d-flex
      align-center
      v-if="isMissionCardHidden && isHost"
      class="visibility-link"
      @click.stop="onMissionCardToggle"
    >
      <v-icon class="visibility-icon"> visibility </v-icon>
      <span class="text">
        {{ currentMission.behavior }}
      </span>
    </v-flex>

    <v-layout
      shrink
      row
      justify-center
      class="buzz-main-row"
      :class="{
        compress: isHost,
        'multi-team-social': isMultiTeamLayout,
        'no-padding': isMissionCardHidden
      }"
    >
      <v-layout
        v-if="!isMissionCardHidden"
        column
        justify-center
        class="buzz-mission"
        :class="{ clickable: isHost }"
        @dblclick.stop="onMissionCardToggle"
      >
        <!-- Asset -->
        <transition name="flip-transition" mode="out-in">
          <AssetMapper
            v-if="cardStatus === 'asset'"
            class="buzz-asset-mapper"
          />
        </transition>
        <!--  -->
        <!-- Mission -->
        <transition name="flip-transition" mode="out-in">
          <v-layout
            v-if="cardStatus === 'game'"
            column
            justify-center
            mission-container
          >
            <MissionContainer>
              <TextOnly :mode="mode" v-if="missionType === 'Buzz In'" />
              <Lipdub :mode="mode" v-else-if="missionType === 'Lipdub'" />
              <TextInput
                :mode="mode"
                v-else-if="
                  missionType === 'Text' ||
                  missionType === 'Freeform' ||
                  missionType === 'Team Name'
                "
              />
              <TextOnly v-else :mode="mode" />
            </MissionContainer>
          </v-layout>
        </transition>
        <!--  -->
      </v-layout>
      <!-- Player -->
      <div v-if="isMultiTeamLayout" class="multi-team-grid">
        <transition-group
          name="social-player-transition"
          tag="div"
          class="social-player-transition-wrap top"
        >
          <SocialPlayer
            class="buzz-player-col"
            v-for="player in selectedPlayers.slice(
              0,
              selectedPlayersMiddleIndex
            )"
            :key="`buzz-in-player-${player.id}-${player.teamID}`"
            :buzzCount="getUserBuzzCount(player.id)"
            :firstname="player.firstname"
            :lastname="player.lastname"
            :teamIcon="player.icon"
            :score="getRatingScore(player.id, player.teamID)"
            :readOnly="!showRating(player.id, player.teamID)"
            :allowKeys="nOfSelectedPlayers === 1"
            :userID="player.id"
            :isWinner="isWinner(player.id)"
            :imageURL="player.image"
            @onStarred="num => onStarred(num, player)"
          />
        </transition-group>
        <transition-group
          name="social-player-transition"
          tag="div"
          class="social-player-transition-wrap bottom"
        >
          <SocialPlayer
            class="buzz-player-col"
            v-for="player in selectedPlayers.slice(selectedPlayersMiddleIndex)"
            :key="`buzz-in-player-${player.id}-${player.teamID}`"
            :buzzCount="getUserBuzzCount(player.id)"
            :firstname="player.firstname"
            :lastname="player.lastname"
            :teamIcon="player.icon"
            :score="getRatingScore(player.id, player.teamID)"
            :readOnly="!showRating(player.id, player.teamID)"
            :allowKeys="nOfSelectedPlayers === 1"
            :userID="player.id"
            :isWinner="isWinner(player.id)"
            :imageURL="player.image"
            @onStarred="num => onStarred(num, player)"
          />
        </transition-group>
      </div>
      <div v-else class="d-flex rtb-flex-wrap">
        <transition-group
          name="social-player-transition"
          tag="div"
          class="social-player-transition-wrap"
        >
          <SocialPlayer
            class="buzz-player-col"
            v-for="player in selectedPlayers"
            :key="`buzz-in-player-${player.id}-${player.teamID}`"
            :buzzCount="getUserBuzzCount(player.id)"
            :firstname="player.firstname"
            :lastname="player.lastname"
            :teamIcon="player.icon"
            :score="getRatingScore(player.id, player.teamID)"
            :readOnly="!showRating(player.id, player.teamID)"
            :allowKeys="nOfSelectedPlayers === 1"
            :userID="player.id"
            :isWinner="isWinner(player.id)"
            :imageURL="player.image"
            @onStarred="num => onStarred(num, player)"
          />
        </transition-group>
      </div>
    </v-layout>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

import * as moment from "moment"
const SocialPlayer = () =>
  import("@/components/GroupTeams/Common/Player/SocialPlayer.vue")
const AssetMapper = () =>
  import("@/components/GroupTeams/Common/AssetMapper.vue")
const TextOnly = () =>
  import("@/components/GroupTeams/Common/Games/TextOnly.vue")
const TextInput = () =>
  import("@/components/GroupTeams/Common/Games/TextInput.vue")
const Lipdub = () => import("@/components/GroupTeams/Common/Games/Lipdub.vue")
const MissionContainer = () =>
  import("@/components/GroupTeams/Common/Games/MissionContainer.vue")

const NUMBER_OF_STARS = 5

export default {
  name: "SocialTeamUsers",
  components: {
    SocialPlayer,
    TextOnly,
    TextInput,
    MissionContainer,
    AssetMapper,
    Lipdub
  },
  async created() {
    await this.subscribeToBuzz()
    await this.subscribeToVoting()
  },
  async beforeDestroy() {
    await this.unsubscribeFromBuzz()
    await this.unsubscribeFromVoting()
  },
  computed: {
    ...mapGetters("auth", ["isHost"]),
    ...mapGetters("group", ["isMultiTeam"]),
    ...mapGetters(["onlineUsersArray", "missions", "votes"]),
    ...mapGetters({ mode: "getCurrentMode" }),
    isMissionCardHidden() {
      try {
        return !!this.missions[this.currentMissionID].hidden
      } catch (e) {
        console.warn(e)
        return false
      }
    },
    cardStatus() {
      return this.mediaToShow ? "asset" : "game"
    },
    isMultiTeamLayout() {
      if (this.isMultiTeam) {
        if (this.isMissionCardHidden && this.nOfSelectedPlayers > 5) {
          return true
        } else if (!this.isMissionCardHidden && this.nOfSelectedPlayers > 3) {
          return true
        }
      }
      return false
    },
    selectedPlayersMiddleIndex() {
      return Math.ceil(this.nOfSelectedPlayers / 2)
    },
    nOfSelectedPlayers() {
      return this.selectedPlayers.length
    },
    selectedPlayers() {
      return this.onlineUsersArray
        .filter(({ selected }) => selected)
        .map(user => ({ ...user, icon: this.getTeamIconByTeamID(user.teamID) }))
    },
    mediaToShow() {
      const media = this.gameStatus.media
      return media === "none" || media === "facilitator" ? null : media
    },
    gameStatus() {
      return this.$store.getters.gameStatus
    },
    missionPlays() {
      return this.$store.getters.missionPlays
    },
    missionPlaysArray() {
      return this.$store.getters.missionPlaysArray
    },
    missionBuzz() {
      return Object.values(this.buzz || {})
        .filter(buzz => buzz.missionID === this.currentMissionID)
        .sort((a, b) => a.timestamp - b.timestamp)
    },
    missionType() {
      return this.currentMission.behavior
    },
    teams() {
      return this.$store.getters.chats
    },
    user() {
      return this.$store.getters.user
    },
    currentTeamID() {
      return this.$store.getters.teamID
    },
    numSelected() {
      return this.selectedPlayers.length
    },
    gameID() {
      return this.$store.getters.gameID
    },
    orgID() {
      return this.$store.getters.orgID
    },
    currentMissionID() {
      return this.$store.getters.currentMission
    },
    buzz() {
      return this.$store.getters.buzz
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    teamMission() {
      if (
        this.currentMission.playType == "Team: Speed Matters" ||
        this.currentMission.playType == "Team: Speed Does Not Matter" ||
        this.currentMission.playType == undefined
      ) {
        return true
      }
      return false
    },
    playerCanRate() {
      return (
        this.currentMission.behavior === "Player Rating" && !this.user.selected
      )
    },
    speedMatters() {
      if (
        this.currentMission.playType == "Team: Speed Matters" ||
        this.currentMission.playType == "Individual: Speed Matters" ||
        this.currentMission.playType == undefined
      ) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapActions([
      "updateSocialMissionStatus",
      "subscribeToBuzz",
      "subscribeToVoting",
      "unsubscribeFromBuzz",
      "unsubscribeFromVoting"
    ]),
    getTeamIconByTeamID(teamID) {
      return this.teams && this.teams[teamID] ? this.teams[teamID].icon : null
    },
    async onMissionCardToggle() {
      if (!this.isHost) return
      await this.updateSocialMissionStatus({
        missionID: this.currentMissionID,
        hidden: !this.isMissionCardHidden
      })
    },
    showRating(userID, teamID) {
      return (this.playerCanRate && teamID != this.currentTeamID) || this.isHost
    },
    onStarred(rate, user) {
      console.log("SCORE GIVEN", user)
      console.log("TEAMS =", this.teams)
      let foundVoteID
      if (this.votes) {
        let vArr = Object.entries(this.votes)
        for (let v in vArr) {
          if (
            vArr[v][1].ratedUserID == user.id &&
            vArr[v][1].votingUserID == this.user.id &&
            vArr[v][1].missionID == this.currentMissionID
          ) {
            foundVoteID = vArr[v][0]
          }
        }
      }

      const foundPlayObject = this.missionPlays
        .slice()
        .reverse()
        .find(({ userID }) => userID === user.id)
      const foundPlay =
        foundPlayObject && foundPlayObject.id ? foundPlayObject.id : null

      var obj = {}
      var sTime = new Date()
      if (foundVoteID) obj.id = foundVoteID
      obj.teamID = 0
      obj.ratedUserID = user.id
      obj.votingUserID = this.user.id
      obj.missionID = this.currentMissionID
      obj.mission = this.currentMission.name
      obj.points = this.currentMission.points
      obj.time = moment(sTime).unix()
      obj.readTime = moment(sTime).format("LLLL")
      obj.show = false
      obj.vote = rate

      this.$store.dispatch("addVote", obj)

      var score = parseInt(this.getRatingScore(user.id, user.teamID))
      var teamID = user.teamID
      obj = {}
      let teamScore
      if (foundPlay) {
        teamScore = this.teams[foundPlayObject.teamID].totalScore
        obj = foundPlayObject
        const oldScore = foundPlayObject.score
        obj.id = foundPlay
        obj.score = score
        teamScore = teamScore - oldScore
        teamScore = teamScore + score
      } else {
        teamScore = this.teams[teamID].totalScore
        var c = this.currentMission
        obj.behavior = c.behavior
        obj.correct = "vote"
        obj.mission = c.name
        obj.missionID = this.currentMissionID
        obj.points = c.points
        obj.score = score
        obj.readTime = moment(sTime).format("LLLL")
        obj.result = true
        obj.show = true
        obj.teamID = teamID
        obj.time = moment(sTime).unix()
        obj.userID = user.id
      }

      this.$store.dispatch("addPlay", obj)
      obj = {}
      obj.userID = user.id
      obj.teamID = teamID
      if (foundPlay) {
        obj.teamScore = teamScore
      } else {
        obj.teamScore = teamScore + score
      }
      obj.totalScore = score
      console.log("SET TEAM SCORE", obj)
      this.$store.dispatch("setScore", obj)
      this.$store.dispatch("setTeamScore", obj)
    },
    isWinner(userID) {
      return this.missionCompleted(userID)
    },
    missionCompleted(userID) {
      const arr = this.missionPlaysArray.filter(
        item =>
          // an alternative way to find a winner
          (parseInt(item.score) > 0 || item.result) &&
          item.userID === userID &&
          item.show
      )
      return arr.length > 0
    },
    getUserBuzzCount(userID) {
      return this.missionBuzz.findIndex(buzz => buzz.userID === userID) + 1
    },
    getRatingScore(userID, teamID) {
      const avg = this.getAvgRating(userID)
      // get score based on the number of players for lipdub
      var points = 0
      if (this.currentMission.behavior == "Lipdub") {
        const players = this.numOfPlayers(teamID)
        points = parseInt(this.currentMission.points) / players
      } else {
        points = parseInt(this.currentMission.points)
      }
      const ratio = avg / NUMBER_OF_STARS
      return points * ratio
    },
    numOfPlayers(chat_id) {
      return this.onlineUsersArray.filter(({ teamID }) => teamID === chat_id)
        .length
    },
    getAvgRating(userID) {
      if (this.votes) {
        var vArr = Object.values(this.votes)
        vArr = vArr.filter(vote => {
          return (
            vote.ratedUserID == userID &&
            vote.missionID == this.currentMissionID
          )
        })
        var totalVote = 0
        for (var v in vArr) {
          totalVote = totalVote + vArr[v].vote
        }
        if (vArr.length == 0) {
          return 0
        } else {
          return totalVote / vArr.length
        }
      } else {
        return 0
      }
    }
  }
}
</script>

<style lang="scss">
.social-team-users {
  .flip-transition-enter-active,
  .flip-transition-leave-active {
    transition: all ease 0.3s;
    transform: rotateX(0deg);
  }
  .flip-transition-enter-active {
    transition-delay: 0.3s;
  }
  .flip-transition-enter {
    transform: rotateY(90deg);
  }
  .flip-transition-leave-to {
    transform: rotateY(-90deg);
  }

  // Social player animation
  // START
  .social-player {
    opacity: 1;
    transform: scale(1);
    transition: all 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.25s;
  }
  .social-player-transition-enter-active {
    transition: all 0.5s ease 0s;
  }
  .social-player-transition-leave-active {
    transition: all 0.5s ease 0.6s;
    .social-player {
      transition: all 0.7s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s;
    }
  }
  .social-player-transition-enter,
  .social-player-transition-leave-to {
    min-width: 0 !important;
    max-width: 0 !important;
    margin: 0 !important;
    .social-player {
      opacity: 0;
      transform: scale(0.3);
    }
  }
  // END

  .social-player-transition-wrap {
    display: flex;
    flex-shrink: 1;
    &.top {
      margin-bottom: 30px;
    }
  }
  .buzz-result {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 0.75rem;
  }
  .buzz-result,
  .buzz-asset-mapper {
    @extend .rtb-border, .rtb-border-radius, .rtb-box-shadow;
    background-color: $color-white;
    overflow: hidden;
    padding: 4px 10px;
  }
  .buzz-mission {
    user-select: none;
    position: relative;
    margin-left: 30px;
    margin-right: 30px;
    width: 33.33%;
    max-width: 400px;
    min-width: 220px;
    min-height: 34vmin;
    max-height: 34vmin;
    perspective: 2000px;
    transition: margin-right 0.5s ease 0.5s;
    &.clickable {
      cursor: pointer;
    }
  }
  .buzz-player-col {
    margin-right: 10px;
    width: 34vmin;
    max-width: 300px;
  }
  .compress {
    .buzz-mission {
      min-height: 30vmin;
      max-height: 30vmin;
    }
    .buzz-player-col {
      width: 30vmin;
    }
  }
  .buzz-main-row {
    padding-bottom: 30px;
    padding-right: 20px;
    &.no-padding {
      padding-right: 0 !important;
      margin-right: -10px;
    }
  }
  .mission-container {
    height: 100%;
  }
  .multi-team-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .multi-team-social {
    &.buzz-main-row {
      padding-bottom: 0;
    }
    .buzz-player-col {
      width: 25vmin;
      max-width: 25vmin;
    }
    .buzz-mission {
      min-height: 50vmin;
      min-height: calc(50vmin + 30px);
      max-height: 50vmin;
      max-height: calc(50vmin + 30px);
    }
    .mission {
      max-height: 50vmin;
      max-height: calc(50vmin + 30px);
    }
    .social-player {
      font-size: 0.9em;
      .buzz-player-name {
        font-size: 0.8em;
      }
      .buzz-player-counter {
        padding-top: 1px;
        font-size: 0.6em;
      }
    }
  }

  .visibility-link {
    background-color: $color-white;
    height: 30px;
    // width: 100px;
    padding: 0 7px;
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 10;
    border: 2px solid $primary_accent_color;
    border-radius: 1em;
    user-select: none;
    cursor: pointer;
    i {
      color: $color-secondary-dark;
    }
    .text {
      padding-left: 3px;
      color: $primary_accent_color;
      text-decoration: underline;
    }
    &:hover {
      .text {
        filter: brightness(1.5);
      }
    }
  }
}
</style>
