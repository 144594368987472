var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.tipJarVisible
        ? _c("TipJar", {
            staticClass: "mode-mapper__tip-jar",
            attrs: { data: _vm.gameHost },
            on: {
              venmo: function($event) {
                _vm.venmoModalVisible = true
              }
            }
          })
        : _vm._e(),
      _vm.venmoModalVisible && !!_vm.venmoCode
        ? _c("VenmoModal", {
            attrs: { src: _vm.venmoCode },
            on: { hide: _vm.hideVenmoModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }