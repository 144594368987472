var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-flex", { attrs: { "align-self-end": "", shrink: "" } }, [
    _c(
      "div",
      {
        staticClass: "media-container",
        style: { margin: "0.5em " + _vm.videoSpace },
        on: { dblclick: _vm.onVideoDblClick }
      },
      [
        _c(
          "v-layout",
          { staticClass: "media-responsive-wrap", attrs: { row: "" } },
          [
            _c(
              "v-flex",
              {
                staticClass: "media-responsive",
                class: { "grey-border": _vm.isMuted },
                style: {
                  width: _vm.getVideoWidth + "px",
                  height: _vm.getVideoHeight + "px"
                },
                attrs: { "d-flex": "" }
              },
              [
                _c("UserMedia", {
                  attrs: { identity: _vm.player.id, imageUrl: _vm.player.image }
                }),
                _c("div", { staticClass: "player-name" }, [
                  !!_vm.player
                    ? _c("div", { staticClass: "player-name-bg" }, [
                        _c(
                          "span",
                          [
                            _c("UserName", {
                              attrs: {
                                firstname: _vm.player.firstname,
                                lastname: _vm.player.lastname
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]),
                _c(
                  "v-icon",
                  {
                    staticClass: "meeting-player-mute-icon",
                    on: {
                      click: function($event) {
                        return _vm.toggleAudio(_vm.player)
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isMuted ? "volume_off" : "volume_up") +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }