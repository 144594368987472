import { Role } from "@/helpers"

const SpeechModule = {
  namespaced: true,
  state: {
    recognitionInstance: null,
    isListening: false,
    mediaRecorder: null
  },
  mutations: {
    UPDATE_SPEECH_RECOGNITION(state, value) {
      state.recognitionInstance = value
    },
    UPDATE_IS_LISTENING_STATE(state, value) {
      state.isListening = value
    },
    UPDATE_MEDIA_RECORDER(state, value) {
      state.mediaRecorder = value
    }
  },
  actions: {
    enableRecognitionEngine({ state, commit }, value) {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition

      if (typeof SpeechRecognition === "undefined")
        throw new Error("Speech Recognition is not enabled on this browser")

      const recognition = new SpeechRecognition()
      commit("UPDATE_SPEECH_RECOGNITION", recognition)
    },
    startRecognition({ state, commit }) {
      state.recognitionInstance.continuous = true
      state.recognitionInstance.interimResults = false
      state.recognitionInstance.start()
      commit("UPDATE_IS_LISTENING_STATE", true)
    },
    stopRecognition({ state, commit }) {
      state.recognitionInstance.stop()
      commit("UPDATE_IS_LISTENING_STATE", false)
      commit("UPDATE_SPEECH_RECOGNITION", null)
    },
    startRecording({ getters, state, commit }) {
      const audioData = []
      const mediaRecorder = new MediaRecorder(getters.getComposedAudio)
      mediaRecorder.ondataavailable = event => {
        audioData.push(event.data)
      }
      commit("UPDATE_MEDIA_RECORDER", mediaRecorder)
      state.mediaRecorder.start(1000)
    },
    stopRecording({ state, getters, commit }) {
      state.mediaRecorder.stop()
      commit("UPDATE_MEDIA_RECORDER", null)
    }
  },
  getters: {
    getIsListening: state => state.isListening,
    getRecognitionInstance: state => state.recognitionInstance,
    getPlayersAudios: (_, __, ___, rootGetters) =>
      rootGetters["onlineUsersArray"]
        .filter(({ audioTrack }) => !!audioTrack)
        .filter(({ isMutedGlobally }) => !isMutedGlobally),
    getHostAudio: async (_, getters, ___, rootGetters) => {
      const hosts = getters.getPlayersAudios
        .filter(({ role }) => role === Role.Host)
        .map(({ audioTrack }) => audioTrack.mediaStreamTrack)
      if (rootGetters["auth/role"] === Role.Host) {
        return [getters.getLocalAudioTrack]
      }
      return hosts[0] ? [hosts[0]] : []
    },
    getTeamPlayerAudios: (_, getters, __, rootGetters) => {
      const { teamIDb: playerTeamID } = rootGetters["auth/user"]
      return getters.getPlayersAudios
        .filter(
          ({ role, teamID }) => role === Role.Host || teamID === playerTeamID
        )
        .map(({ audioTrack }) => audioTrack.mediaStreamTrack)
    },
    getSocialPlayerAudios: (_, getters) => {
      return getters.getPlayersAudios
        .filter(({ selected }) => selected)
        .map(({ audioTrack }) => audioTrack.mediaStreamTrack)
    },
    getLocalAudioTrack(_, __, ___, rootGetters) {
      const room = rootGetters["twilio/room"]
      return (
        room && room.localAudioTrack && room.localAudioTrack.mediaStreamTrack
      )
    },
    getSpeechAudios: (_, getters) => {
      return mode => {
        switch (mode) {
          case "individual":
            return getters.getLocalAudioTrack
          case "host":
            return getters.getHostAudio
          case "social":
            return getters.getSocialPlayerAudios
          default:
            return getters.getTeamPlayerAudios
        }
      }
    }
  }
}

export default SpeechModule
