import { state } from "./state"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

/** @type {import('vuex').Module<import('./typedef').State, any>} */
export const Module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export const MODULE_NAME = "theme"
