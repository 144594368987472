/**
 * @readonly
 * @enum {string}
 */
const DEFAULT_STYLES = {
  "--primary-accent-color": "#f36523",
  "--secondary-accent-color": "#4c9390",
  "--logo-color": "#f36523",
  "--correct-color": "#39b449",
  "--wrong-color": "#eb1c22",
  "--disabled-color": "#b1b1b1"
}
/**
 * @readonly
 * @enum {string}
 */
const DEFAULT_THEME_STYLES = {
  "--primary-accent-color": DEFAULT_STYLES["--primary-accent-color"],
  "--secondary-accent-color": DEFAULT_STYLES["--secondary-accent-color"],
  "--logo-color": DEFAULT_STYLES["--logo-color"]
}

/**
 * @readonly
 * @type {Record<DEFAULT_THEME_STYLES, string>}
 */
const Map = {
  "--primary-accent-color": "color-primary-accent",
  "--secondary-accent-color": "color-secondary-accent",
  "--logo-color": "color-logo",
  "--correct-color": "color-correct",
  "--wrong-color": "color-wrong",
  "--disabled-color": "color-disabled"
}

const DEFAULT_IMAGES = {
  logo: "/assets/img/logo-horizontal.svg",
  loginLogo: "/assets/img/logo-horizontal.svg",
  loginCarousel: [
    "/assets/img/login-carousel/1.jpg",
    "/assets/img/login-carousel/2.jpg",
    "/assets/img/login-carousel/3.jpg",
    "/assets/img/login-carousel/4.jpg",
    "/assets/img/login-carousel/5.jpg",
    "/assets/img/login-carousel/6.jpg"
  ]
}

/**
 * @readonly
 * @type {import('./types').ThemeMeta}
 */
const DEFAULT_META = {
  hideGoGame: false,
  hideExpoText: false,
  tagline: "It’s more than Remotely Fun"
}

const ImagesToClientMap = {
  logo: "logoImage",
  expoLogo: "customLogo",
  loginLogo: "loginLogo"
}

exports.DEFAULT_STYLES = DEFAULT_STYLES
exports.DEFAULT_THEME_STYLES = DEFAULT_THEME_STYLES
exports.DEFAULT_IMAGES = DEFAULT_IMAGES
exports.DEFAULT_META = DEFAULT_META
exports.Map = Map
exports.ImagesToClientMap = ImagesToClientMap
