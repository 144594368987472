export const IDLE_TIMEOUT = 7200000 // 20 minutes
export const MAX_CAPTURE_AVATAR_ATTEMPTS = 3
export const EXPO_PLAYERS_CAPACITY = 500
export const MAX_PEOPLE_IN_GAME = 50
export const MIN_PASSWORD_LENGTH = 7
export const DEFAULT_PROFILE_IMAGE = require("@/assets/game-card-profile-icon.png")
export const DEFAULT_PROFILE_IMAGE_BIG = require("@/assets/game-card-profile-icon-big-margins.png")
export const DEBUG_ORIGINS = [
  "https://rtb-dev2000.firebaseapp.com",
  "https://rtb-staging.firebaseapp.com",
  "http://localhost:8999"
]
export const DEFAULT_IMAGE_PROFILE_URL =
  "https://cdn.pixabay.com/photo/2017/02/23/13/05/profile-2092113_960_720.png"
export const CLIENT_GAME_ASSIGNMENT_TYPES = [
  {
    code: 0,
    text: "FREE ROAM"
  },
  {
    code: 1,
    text: "STICKY"
  },
  {
    code: 2,
    text: "PLEDGED"
  },
  {
    code: 4,
    text: "TRAINING - FREE ROAM"
  },
  {
    code: 3,
    text: "TRAINING - STICKY"
  }
]
export const CLIENT_CUSTOM_INPUT_TYPES = [
  {
    value: null,
    label: "none"
  },
  {
    value: "select",
    label: "Select"
  },
  {
    value: "text",
    label: "Text"
  },
  {
    value: "password",
    label: "Text - Password"
  }
]
export const USER_ACCESS_LEVELS = [
  {
    code: 0,
    text: "ADMIN"
  },
  {
    code: 1,
    text: "HOST"
  },
  {
    code: 2,
    text: "AUDIT"
  },
  {
    code: 3,
    text: "SPECTATOR"
  },
  {
    code: 4,
    text: "PLAYER"
  }
]

export const LATECOMER_TIME = 300000 // 5 minutes
