<template>
  <div class="team-tag-wrap" ref="border">
    <div class="team-tag" @click.exact="resetMissionTeam()">
      <div class="team-tag-whisper-cover" ref="whispercover">
        <div class="team-tag-whisper">
          <div class="team-whisper-shape-cover">
            <div class="team-whisper-shape" ref="shape">
              <div class="team-tag-shape-left origin"></div>
              <div class="team-tag-shape-left white"></div>
            </div>
          </div>
          <div
            ref="whisper"
            class="team-whisper"
            :class="whisperClass"
            @click="changeTeam()"
          >
            <span v-if="isGameOver"> Join Team</span>
          </div>
        </div>
      </div>
      <div class="team-tag-team" ref="team">
        <div class="team-tag-shape-left"></div>
        <div class="team-tag-team-name">
          {{ team.name || "null" }}
        </div>
        <div class="team-tag-shape-right"></div>
      </div>
      <div class="team-tag-strikes-cover" ref="cover">
        <div class="team-tag-strikes">
          <div class="team-strikes">
            <StrikeIcon
              v-for="(strike, index) in strikes"
              :color="strike.color"
              :key="`${team.id}-x-${index}`"
              class="strike-icon"
            />
          </div>
          <div class="team-strikes-shape-cover">
            <div class="team-strikes-shape" ref="shape">
              <div class="team-tag-shape-right white"></div>
              <div class="team-tag-shape-right origin"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import { TweenMax, Power2 } from "gsap/TweenMax"
import StrikeIcon from "@/components/GroupTeams/Common/StrikeIcon.vue"

export default {
  name: "TeamTag",
  props: {
    teamId: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  components: {
    StrikeIcon
  },
  data() {
    return {
      strikes: []
    }
  },
  mounted() {
    if (this.isGameOver) {
      // this.showWhisper()
    }
    if (this.isMissionCorrect) {
      TweenMax.to([this.$refs.team, this.$refs.shape], 0.35, {
        backgroundColor: this.$theme.get("--correct-color"),
        ease: Power2.easeInOut
      })
    } else if (this.isOutOfTries) {
      TweenMax.to([this.$refs.team, this.$refs.shape], 0.35, {
        backgroundColor: this.$theme.get("--wrong-color"),
        ease: Power2.easeInOut
      })
    }

    this.updateStrikeBar(this.nOfMissionTries, this.isUnlimited, this.mode)
  },
  computed: {
    ...mapGetters({
      mission: "getCurrentMission",
      missionID: "currentMission",
      teams: "chats",
      playType: "missionPlayType",
      isUnlimited: "isUnlimitedMission"
    }),
    ...mapGetters([
      "nOfMissionTries",
      "missionPlaysArray",
      "onlineUsersGroupedByTeam",
      "user",
      "styles"
    ]),
    whisperClass() {
      return "active"
      // if (this.isGameOver) {
      //   return "active"
      // } else {
      //   return ""
      // }
    },
    isTeamMission() {
      return this.playType === "team"
    },
    isGameOver() {
      if (this.mission.name == "Game Over") {
        // this.showWhisper()
        return true
      } else {
        // this.hideWhisper()
        return false
      }
    },
    behavior() {
      return this.mission.behavior
    },
    users() {
      if (!this.onlineUsersGroupedByTeam) return []
      return this.onlineUsersGroupedByTeam[this.teamId]
    },
    selectedUsers() {
      if (!this.users) return []
      return this.users.filter(({ selected }) => selected)
    },
    nOfSelectedUsers() {
      return this.selectedUsers.length
    },
    team() {
      return this.teams ? this.teams[this.teamId] : null
    },
    answers() {
      // skip the computation for unlimited
      if (this.isUnlimited) return []
      return this.plays.reduce((acc, val) => {
        const { answer } = val
        if (Array.isArray(answer)) {
          return acc.concat(answer)
        } else {
          acc.push(answer)
          return acc
        }
      }, [])
    },
    isCompleted() {
      return this.isOutOfTries || this.isMissionCorrect
    },
    isOutOfTries() {
      return this.answers.length >= this.nOfMissionTries
    },
    plays() {
      return this.missionPlaysArray.filter(play => play.teamID === this.teamId)
    },
    nOfPlays() {
      return this.plays.length
    },
    nOfOfflinePlays() {
      return this.correctPlays.reduce((acc, val) => {
        const { userID } = val
        const offline = !this.selectedUsers.some(({ id }) => id === userID)
        return offline ? acc + 1 : acc
      }, 0)
    },
    nOfExpectedPlays() {
      // return this.nOfSelectedUsers + this.nOfOfflinePlays
      return this.nOfSelectedUsers
    },
    isMissionCorrect() {
      const {
        nOfSelectedUsers,
        nOfCorrectPlays,
        nOfPlays,
        nOfExpectedPlays,
        isFactMatch
      } = this

      if (isFactMatch) {
        return nOfPlays > 0
      } else if (nOfSelectedUsers > 1) {
        return nOfCorrectPlays > 0 && nOfCorrectPlays === nOfExpectedPlays
      } else {
        return nOfCorrectPlays > 0
      }
    },
    correctPlays() {
      return this.plays.filter(({ result }) => result)
    },
    nOfCorrectPlays() {
      return this.correctPlays.length
    },
    isFactMatch() {
      return this.behavior === "Fact Match"
    },
    nOfIncorrectPlays() {
      if (this.isFactMatch) return 0

      return this.plays.reduce((acc, val) => {
        const { result, answer } = val
        if (Array.isArray(answer)) {
          if (result) {
            return acc + answer.length - 1
          } else {
            return acc + answer.length
          }
        } else {
          if (result) {
            return acc
          } else {
            return acc + 1
          }
        }
      }, 0)
    }
  },
  watch: {
    // isGameOver(newVal) {
    //   if (newVal) {
    //     this.showWhisper()
    //   } else {
    //     this.hideWhisper()
    //   }
    // },
    // The mission ID has changed, so reset it all
    missionID() {
      this.updateStrikeBar(this.nOfMissionTries, this.isUnlimited, this.mode)
    },
    isCompleted(newValue, oldValue) {
      if (oldValue === true && newValue === false) {
        // reset the tag color to normal
        TweenMax.to([this.$refs.team, this.$refs.shape], 0.35, {
          backgroundColor: this.$theme.get("--primary-accent-color"),
          ease: Power2.easeInOut,
          overwrite: true
        })
        this.updateStrikeBar(this.nOfMissionTries, this.isUnlimited, this.mode)
      }
    },
    mode(newValue) {
      this.updateStrikeBar(this.nOfMissionTries, this.isUnlimited, newValue)
    },
    nOfIncorrectPlays(newValue) {
      if (newValue === 0) return
      if (newValue > this.nOfMissionTries)
        return console.warn("You've reached the max n of incorrect tries")

      if (newValue === this.nOfMissionTries) {
        TweenMax.to([this.$refs.team, this.$refs.shape], 0.35, {
          backgroundColor: this.$theme.get("--wrong-color"),
          ease: Power2.easeInOut
        })
      } else {
        TweenMax.to([this.$refs.team, this.$refs.shape], 0.35, {
          backgroundColor: this.$theme.get("--wrong-color"),
          ease: Power2.easeInOut,
          onComplete: () => {
            TweenMax.to([this.$refs.team, this.$refs.shape], 0.35, {
              backgroundColor: this.$theme.get("--primary-accent-color"),
              ease: Power2.easeInOut
            })
          }
        })
      }

      if (!Array.isArray(this.strikes)) return
      if (!this.strikes.length) return

      const strikes = this.strikes.slice(0, newValue)

      TweenMax.to(strikes, 0.8, {
        overwrite: true,
        color: this.$theme.get("--wrong-color")
      })
    },
    isMissionCorrect(value) {
      if (value === true) {
        TweenMax.to([this.$refs.team, this.$refs.shape], 0.35, {
          backgroundColor: this.$theme.get("--correct-color"),
          ease: Power2.easeInOut,
          overwrite: true
        })
      }
    }
  },
  methods: {
    ...mapActions(["updateUserToTeamID"]),
    showWhisper() {
      if (this.$refs.whispercover) {
        TweenMax.to(this.$refs.whispercover, 0.8, {
          maxWidth: 320,
          overwrite: false,
          ease: Power2.easeInOut
        })
      }
    },
    hideWhisper() {
      if (this.$refs.whispercover) {
        TweenMax.to(this.$refs.whispercover, 0.8, {
          maxWidth: 27,
          overwrite: true,
          ease: Power2.easeInOut
        })
      }
    },
    // fire show/hide depending on the
    // current mission status
    resetMissionTeam() {
      console.log("RESET")
      if (this.user.role == "facilitator") {
        var arr = this.plays
        for (var play in arr) {
          this.$store.dispatch("deletePlay", arr[play].id)
        }
      }
    },
    changeTeam() {
      if (this.isGameOver) {
        this.updateUserToTeamID({ teamID: this.teamId, userID: this.user.id })
      }
    },
    showStrikeIcons() {
      TweenMax.to(this.$refs.cover, 0.8, {
        maxWidth: 300,
        overwrite: false,
        ease: Power2.easeInOut
      })
    },
    hideStrikeIcons(callback = () => {}) {
      TweenMax.to(this.$refs.cover, 0.8, {
        maxWidth: 27,
        ease: Power2.easeInOut,
        overwrite: true,
        onComplete: callback
      })
    },
    updateStrikeBar(numOfTries, isUnlimited, mode) {
      if (numOfTries < 2 || isUnlimited || mode === "welcome") {
        this.hideStrikeIcons()
      } else {
        // hide the current number of strikes
        this.hideStrikeIcons(() => {
          // add a number of mutable objects
          // which is equal to the number of tries
          this.strikes = []

          let i
          // so if any incorrect set them as red
          for (i = 0; i < this.nOfIncorrectPlays; i++)
            this.strikes.push({ color: this.$theme.get("--wrong-color") })

          // any remaining get the default grey
          for (i = 0; i < numOfTries - this.nOfIncorrectPlays; i++)
            this.strikes.push({ color: this.$theme.get("--disabled-color") })

          // display the icons bar
          this.showStrikeIcons()
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.team-tag-wrap {
  border-top: 2px solid $primary_accent_color;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 10;
}
.team-tag-team-name {
  display: inline-block;
  background-color: inherit;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  z-index: 2;
}
.team-tag-team {
  background-color: $primary_accent_color;
  z-index: 3;
}
.team-tag {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  top: -15px;
  color: $color-white;
  font-size: 18px;
  height: 28px;
  margin-bottom: -28px;
  line-height: 28px;
  // margin-right: -27px;
  z-index: 2;
  vertical-align: top;

  .team-tag-shape-left {
    left: -10px;
  }
  .team-tag-shape-right {
    left: 10px;
  }
  .team-tag-shape-left,
  .team-tag-shape-right {
    vertical-align: top;
    content: "";
    display: inline-block;
    position: relative;
    top: 4px;
    width: 20px;
    height: 20px;
    background-color: inherit;
    transform: rotate(45deg);
  }
  .strike-icon {
    display: inline-block;
    width: auto;
    height: 22px;
    margin-top: 3px;
    margin-right: 4px;
  }

  .team-tag-strikes-cover {
    display: flex;
    overflow: hidden;
    max-width: 27px;
    transition: max-width ease-in-out 0.8s;
    white-space: nowrap;
    justify-content: flex-end;
  }

  .team-tag-strikes {
    display: flex;
    padding-right: 14px;
    flex-wrap: nowrap;
  }

  .team-strikes-shape-cover {
    display: flex;
    padding-right: 13px;
    overflow: hidden;
  }
  .team-strikes-shape {
    background-color: $primary_accent_color;
    margin-left: -20px;
    display: flex;
    flex-wrap: nowrap;
    z-index: 2;
    .team-tag-shape-right.white {
      background-color: $color-white;
      position: relative;
      z-index: 1;
    }
    .team-tag-shape-right.origin {
      margin-left: -12px;
    }
  }
  .team-strikes {
    z-index: 2;
    padding-left: 20px;
    white-space: nowrap;
    background-color: $color-white;
    flex-wrap: nowrap;
  }

  .team-tag-whisper-cover {
    display: flex;
    overflow: hidden;
    max-width: 27px;
    transition: max-width ease-in-out 0.8s;
    white-space: nowrap;
    justify-content: flex-start;
  }
  .team-tag-whisper {
    display: flex;
    padding-left: 14px;
    flex-wrap: nowrap;
  }

  .team-whisper-shape-cover {
    display: flex;
    padding-left: 13px;
    overflow: hidden;
  }
  .team-whisper-shape {
    background-color: $primary_accent_color;
    margin-right: -20px;
    display: flex;
    flex-wrap: nowrap;
    z-index: 2;
    .team-tag-shape-left.white {
      background-color: $color-white;
      position: relative;
      z-index: 1;
    }
    .team-tag-shape-left.origin {
      margin-right: -12px;
    }
  }
  .team-whisper {
    z-index: 2;
    padding: 0 22px 0 5px;
    white-space: nowrap;
    color: $color-black;
    font-size: 13px;
    text-transform: uppercase;
    line-height: 29px;
    font-weight: bold;
    width: 108px;
    background-color: $color-white;
    flex-wrap: nowrap;

    &.active {
      cursor: pointer;
      color: $color-disabled;
      transition: color 0.1s ease-out;

      &.whispering {
        color: $color-black;
      }
      &:hover {
        color: $primary_accent_color;
      }
    }
  }
}
</style>
