import firebase from "firebase/app"
import "firebase/database"

let orgsSubscriptionRef = null

/**
 * @readonly
 * @enum {string}
 */
export const ActionTypes = {
  FETCH_ORGS: "FECTH_ORGS"
}

const OrgModule = {
  state: {
    orgs: []
  },
  mutations: {
    setOrgs(state, payload) {
      state.orgs = payload
    }
  },
  getters: {
    orgs(state) {
      return state.orgs
    }
  },
  actions: {
    updateOrg({ rootState }, payload) {
      var id = payload.theKey
      delete payload.theKey
      var thePayload = JSON.parse(JSON.stringify(payload))
      return firebase
        .database()
        .ref("orgs/" + id)
        .set(thePayload)
      // commit('setMissions', id)
    },
    addOrg({ rootState }, payload) {
      var thePayload = JSON.parse(JSON.stringify(payload))
      return firebase.database().ref("orgs").push(thePayload)
      // commit('setMissions', id)
    },
    removeOrg({ rootState }, payload) {
      var id = payload.theKey
      firebase
        .database()
        .ref("orgs/" + id)
        .remove()
    },
    async unsubscribeFromOrgs() {
      if (orgsSubscriptionRef) orgsSubscriptionRef.off()
      orgsSubscriptionRef = null
    },
    async [ActionTypes.FETCH_ORGS]({ commit }) {
      const ref = firebase.database().ref("orgs")
      const onSnapshot = snapshot => {
        // this sorts by the actual value of pos returned cause value does not do it on it's own
        const newObj = {}
        snapshot.forEach(child => {
          newObj[child.key] = child.val()
        })
        commit("setOrgs", newObj)
      }
      const snapshot = await ref.once("value")
      onSnapshot(snapshot)
    },
    async subscribeToOrgs({ commit }) {
      const ref = firebase.database().ref("orgs")

      const onSnapshot = snapshot => {
        // this sorts by the actual value of pos returned cause value does not do it on it's own
        const newObj = {}
        snapshot.forEach(child => {
          newObj[child.key] = child.val()
        })
        commit("setOrgs", newObj)
      }

      if (orgsSubscriptionRef) {
        if (!ref.isEqual(orgsSubscriptionRef)) {
          orgsSubscriptionRef.off("value")
          ref.on("value", onSnapshot)
          orgsSubscriptionRef = ref
        }
      } else {
        ref.on("value", onSnapshot)
        orgsSubscriptionRef = ref
      }

      const snapshot = await ref.once("value")

      onSnapshot(snapshot)
    }
  }
}

export default OrgModule
