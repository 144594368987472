<template>
  <div ref="root" class="team-icon-tag">
    <v-icon>
      {{ teamIcon }}
    </v-icon>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import { TweenMax, Power2 } from "gsap/TweenMax"
export default {
  name: "TeamTag",
  props: {
    teamID: {
      type: String,
      required: true
    }
  },
  mounted() {
    if (this.isMissionCorrect) {
      TweenMax.to(this.$refs.root, 0.35, {
        backgroundColor: this.$theme.get("--correct-color"),
        ease: Power2.easeInOut
      })
    } else if (this.isOutOfTries) {
      TweenMax.to(this.$refs.root, 0.35, {
        backgroundColor: this.$theme.get("--wrong-color"),
        ease: Power2.easeInOut
      })
    }
  },
  computed: {
    ...mapGetters({
      mission: "getCurrentMission",
      missionID: "currentMission",
      teams: "chats",
      playType: "missionPlayType",
      isUnlimited: "isUnlimitedMission"
    }),
    ...mapGetters([
      "nOfMissionTries",
      "missionPlaysArray",
      "onlineUsersGroupedByTeam"
    ]),
    isTeamMission() {
      return this.playType === "team"
    },
    behavior() {
      return this.mission.behavior
    },
    users() {
      if (!this.onlineUsersGroupedByTeam) return []
      return this.onlineUsersGroupedByTeam[this.teamID]
    },
    selectedUsers() {
      if (!this.users) return []
      return this.users.filter(({ selected }) => selected)
    },
    nOfSelectedUsers() {
      return this.selectedUsers.length
    },
    team() {
      return this.teams ? this.teams[this.teamID] : null
    },
    teamIcon() {
      return this.team ? this.team.icon : null
    },
    answers() {
      // skip the computation for unlimited
      if (this.isUnlimited) return []
      return this.plays.reduce((acc, val) => {
        const { answer } = val
        if (Array.isArray(answer)) {
          return acc.concat(answer)
        } else {
          acc.push(answer)
          return acc
        }
      }, [])
    },
    isCompleted() {
      return this.isOutOfTries || this.isMissionCorrect
    },
    isOutOfTries() {
      return this.answers.length >= this.nOfMissionTries
    },
    plays() {
      return this.missionPlaysArray.filter(play => play.teamID === this.teamID)
    },
    nOfPlays() {
      return this.plays.length
    },
    nOfOfflinePlays() {
      return this.correctPlays.reduce((acc, val) => {
        const { userID } = val
        const offline = !this.selectedUsers.some(({ id }) => id === userID)
        return offline ? acc + 1 : acc
      }, 0)
    },
    nOfExpectedPlays() {
      // return this.nOfSelectedUsers + this.nOfOfflinePlays
      return this.nOfSelectedUsers
    },
    isMissionCorrect() {
      const {
        nOfSelectedUsers,
        nOfCorrectPlays,
        nOfPlays,
        nOfExpectedPlays,
        isFactMatch
      } = this

      if (isFactMatch) {
        return nOfPlays > 0
      } else if (nOfSelectedUsers > 1) {
        return nOfCorrectPlays > 0 && nOfCorrectPlays === nOfExpectedPlays
      } else {
        return nOfCorrectPlays > 0
      }
    },
    correctPlays() {
      return this.plays.filter(({ result }) => result)
    },
    nOfCorrectPlays() {
      return this.correctPlays.length
    },
    isFactMatch() {
      return this.behavior === "Fact Match"
    },
    nOfIncorrectPlays() {
      if (this.isFactMatch) return 0

      return this.plays.reduce((acc, val) => {
        const { result, answer } = val
        if (Array.isArray(answer)) {
          if (result) {
            return acc + answer.length - 1
          } else {
            return acc + answer.length
          }
        } else {
          if (result) {
            return acc
          } else {
            return acc + 1
          }
        }
      }, 0)
    }
  },
  watch: {
    isCompleted(newValue, oldValue) {
      if (oldValue === true && newValue === false) {
        TweenMax.to(this.$refs.root, 0.35, {
          backgroundColor: this.$theme.get("--primary-accent-color"),
          ease: Power2.easeInOut,
          overwrite: true
        })
      }
    },
    nOfIncorrectPlays(newValue) {
      if (newValue === 0) return
      if (newValue > this.nOfMissionTries)
        return console.warn("You've reached the max n of incorrect tries")

      if (newValue === this.nOfMissionTries) {
        TweenMax.to(this.$refs.root, 0.35, {
          backgroundColor: this.$theme.get("--wrong-color"),
          ease: Power2.easeInOut
        })
      } else {
        TweenMax.to(this.$refs.root, 0.35, {
          backgroundColor: this.$theme.get("--wrong-color"),
          ease: Power2.easeInOut,
          onComplete: () => {
            TweenMax.to(this.$refs.root, 0.35, {
              backgroundColor: this.$theme.get("--primary-accent-color"),
              ease: Power2.easeInOut
            })
          }
        })
      }
    },
    isMissionCorrect(value) {
      if (value === true) {
        TweenMax.to(this.$refs.root, 0.35, {
          backgroundColor: this.$theme.get("--correct-color"),
          ease: Power2.easeInOut,
          overwrite: true
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.team-icon-tag {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  background-color: $primary_accent_color;
  z-index: 10;
  i {
    line-height: 31px;
    color: $color-white !important;
  }
}
</style>
