<template>
  <v-layout row fill-height class="play-mode-wrap">
    <v-flex xs3 ml-2 class="asset-column">
      <v-layout column justify-center fill-height>
        <div class="play-asset-wrap">
          <AssetSlide class="play-asset" :mode="currentMode" />
        </div>
      </v-layout>
    </v-flex>
    <v-flex xs6 mr-2 ml-2 class="play-mode">
      <RtbCard class="canvas-rtb-card" v-if="isDrawingMission">
        <transition name="mission-result-transition" mode="out-in">
          <ResultContainer
            v-if="currentMode === 'results'"
            class="result-component"
          />
          <Drawing
            :key="`drawing-component-${missionID}`"
            v-else
            :bgColor="'#efefef'"
            :width="510"
            :height="270"
          />
        </transition>
      </RtbCard>
      <MissionContainer v-else :mode="currentMode">
        <MultipleChoice
          v-if="missionType === 'Multiple Choice'"
          :mode="currentMode"
        />
        <Poll v-else-if="missionType === 'Poll'" :mode="currentMode" />
        <Giphy v-else-if="missionType === 'Giphy'" :mode="currentMode" />
        <Lipdub v-else-if="missionType === 'Lipdub'" :mode="currentMode" />
        <TakePhoto
          v-else-if="missionType === 'Take Photo'"
          :mode="currentMode"
        />
        <Photo v-else-if="missionType === 'Photo'" :mode="currentMode" />
        <URL v-else-if="missionType === 'URL'" :mode="currentMode" />
        <TextInput
          v-else-if="
            missionType === 'Text' ||
            missionType === 'Freeform' ||
            missionType === 'Team Name' ||
            missionType === 'Fact Match'
          "
          :mode="currentMode"
        />
        <Categories
          v-else-if="missionType === 'Categories'"
          :mode="currentMode"
        />
        <TextOnly
          v-else-if="
            missionType === 'Buzz In' ||
            missionType === 'Player Rating' ||
            missionType === 'Info' ||
            missionType === 'Link'
          "
          :mode="currentMode"
        />
      </MissionContainer>
    </v-flex>
    <v-flex d-flex align-center xs3 mr-2>
      <transition name="canvas-tools-slide-transition" mode="out-in">
        <RtbCard class="canvas-tools-rtb-card" v-if="hasPallette">
          <v-layout row>
            <v-flex shrink>
              <v-layout column class="draw-tools-left-col">
                <v-flex d-flex shrink>
                  <v-layout column>
                    <v-flex align-center style="display: flex">
                      <div
                        class="draw-tool-btn"
                        :class="{
                          disabled: false, //!canPlayerDraw(),
                          active: isPen
                        }"
                        @click="setToPen"
                      >
                        <img
                          v-if="isPen"
                          width="28"
                          class="draw-tool-icon"
                          :src="
                            require('../../../../assets/pen-icon-yellow.png')
                          "
                        />
                        <img
                          v-else
                          width="28"
                          class="draw-tool-icon"
                          :src="
                            require('../../../../assets/pen-icon-white.png')
                          "
                        />
                        <div>Draw</div>
                      </div>
                    </v-flex>
                    <v-flex align-center style="display: flex">
                      <div
                        class="draw-tool-btn"
                        :class="{
                          disabled: false, //!canPlayerDraw(),
                          active: !isPen
                        }"
                        @click="setToEraser"
                      >
                        <img
                          v-if="!isPen"
                          width="28"
                          class="draw-tool-icon"
                          :src="
                            require('../../../../assets/eraser-icon-yellow.png')
                          "
                        />
                        <img
                          v-else
                          width="28"
                          class="draw-tool-icon"
                          :src="
                            require('../../../../assets/eraser-icon-white.png')
                          "
                        />
                        <div>Erase</div>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex d-flex shrink v-if="isHost">
                  <v-layout column>
                    <v-flex>
                      <v-btn class="clear-board-btn" @click="onClearOneTeam">
                        CLEAR<br />THIS
                      </v-btn>
                    </v-flex>
                    <v-flex>
                      <v-btn class="clear-all-btn" @click="onClearTeams">
                        CLEAR<br />ALL
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex style="overflow: hidden; background-color: #fff">
              <v-layout column fill-height>
                <v-flex>
                  <div class="pen-size-container">
                    <div
                      class="pen-size"
                      v-for="idx in 7"
                      :key="'pen-size-' + idx"
                      @click="onSizeClick(idx + 1)"
                      :class="{ active: isCurrentSize(idx + 1) }"
                    >
                      <svg
                        class="pen-size-svg"
                        :height="brushSizeBase(idx + 1)"
                        :width="brushSizeBase(idx + 1)"
                      >
                        <circle
                          class="filled-circle"
                          :cx="brushSizeBase(idx + 1) / 2"
                          :cy="brushSizeBase(idx + 1) / 2"
                          :r="brushSizeBase(idx + 1) / 2 - 3"
                          fill="#68625f"
                        />
                        <circle
                          class="outline-circle"
                          :cx="brushSizeBase(idx + 1) / 2"
                          :cy="brushSizeBase(idx + 1) / 2"
                          :r="brushSizeBase(idx + 1) / 2 - 2"
                          stroke-width="1"
                          fill="none"
                        />
                        Sorry, your browser does not support inline SVG.
                      </svg>
                    </div>
                  </div>
                </v-flex>
                <v-flex shrink class="select-color-text"> Select Color </v-flex>
                <v-flex d-flex>
                  <chrome-picker
                    class="color-picker"
                    v-model="colors"
                    :disableFields="true"
                    @input="onColorPickerUpdate"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </RtbCard>
      </transition>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"

import ResultContainer from "@/components/GroupTeams/Common/Games/ResultContainer.vue"
import AssetSlide from "@/components/GroupTeams/Common/AssetSlide.vue"
const MultipleChoice = () =>
  import("@/components/GroupTeams/Common/Games/MultipleChoice.vue")
const Poll = () => import("@/components/GroupTeams/Common/Games/Poll.vue")
const Giphy = () => import("@/components/GroupTeams/Common/Games/Giphy.vue")
const Lipdub = () => import("@/components/GroupTeams/Common/Games/Lipdub.vue")
const TextInput = () =>
  import("@/components/GroupTeams/Common/Games/TextInput.vue")
const TextOnly = () =>
  import("@/components/GroupTeams/Common/Games/TextOnly.vue")
const Categories = () =>
  import("@/components/GroupTeams/Common/Games/Categories.vue")
const TakePhoto = () =>
  import("@/components/GroupTeams/Common/Games/TakePhoto.vue")
const Photo = () => import("@/components/GroupTeams/Common/Games/Photo.vue")
const URL = () => import("@/components/GroupTeams/Common/Games/Url.vue")
const MissionContainer = () =>
  import("@/components/GroupTeams/Common/Games/MissionContainer.vue")
const RtbCard = () => import("@/components/GroupTeams/Common/Games/RtbCard.vue")
const Drawing = () => import("@/components/GroupTeams/Common/Games/Drawing.vue")

import { GameMission } from "@/entities/GameMission"
import { Chrome } from "vue-color"

export default {
  name: "PlayMode",
  components: {
    AssetSlide,
    Poll,
    MultipleChoice,
    TextInput,
    TextOnly,
    Giphy,
    MissionContainer,
    Categories,
    Lipdub,
    TakePhoto,
    Photo,
    URL,
    Drawing,
    RtbCard,
    ResultContainer,
    "chrome-picker": Chrome
  },
  data() {
    return {
      colors: {
        rgba: "(0, 0, 0, 1)"
      }
    }
  },
  mounted() {
    this.setLineWidth({ lineWidth: this.brushSize(3) })
  },
  methods: {
    ...mapActions("drawing", [
      "setPenState",
      "setPenColor",
      "setLineWidth",
      "clearTeams",
      "clearOneTeam"
    ]),
    onClearOneTeam() {
      this.clearOneTeam({ teamID: this.assignedTeamID })
    },
    onClearTeams() {
      this.clearTeams()
    },
    onColorPickerUpdate({ rgba }) {
      this.setPenColor({ color: { rgba } })
    },
    brushSize(value) {
      return Math.round((1 / 8) * Math.pow(value, 2.7))
    },
    brushSizeBase(idx) {
      return this.brushSize(idx) + 8
    },
    onSizeClick(idx) {
      this.setLineWidth({ lineWidth: this.brushSize(idx) })
    },
    isCurrentSize(idx) {
      return this.lineWidth === this.brushSize(idx)
    },
    setToPen() {
      this.setPenState({ isPen: true })
    },
    setToEraser() {
      this.setPenState({ isPen: false })
    }
  },
  computed: {
    ...mapState("group", ["currentGlobalTeam"]),
    ...mapGetters("auth", ["user", "isHost"]),
    ...mapGetters(["getCurrentMode", "gameStatus"]),
    ...mapGetters({ missionID: "currentMission" }),
    ...mapState("drawing", ["lineWidth", "isPen"]),
    ...mapState("drawing", { assignedTeamID: "teamID" }),
    isDrawingMission() {
      return (
        this.missionType === GameMission.DrawingEachTeam ||
        this.missionType === GameMission.DrawingPictionary ||
        this.missionType === GameMission.DrawingEveryone
      )
    },
    isPictionary() {
      return this.missionType == GameMission.DrawingPictionary
    },
    hasPallette() {
      if ((this.currentMode !== "results") & (this.currentMode !== "welcome")) {
        if (this.isPictionary) {
          if (this.assignedTeamID == this.team.id || this.isHost) return true
        } else if (this.isDrawingMission) return true
      }
      return false
    },
    currentMode() {
      let mode = this.getCurrentMode
      if (this.gameStatus.media == "social") mode = "social"
      return mode
    },
    missionType() {
      return this.currentMission.behavior
    },
    useTrivia() {
      return (
        this.missionType == "Number" ||
        this.missionType == "Multiple Choice" ||
        this.missionType == "Text" ||
        this.missionType == "Freeform" ||
        this.missionType == "Team Name"
      )
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    teams() {
      return this.$store.getters.chats
    },
    team() {
      if (this.isHost) {
        return {
          ...this.teams[this.currentGlobalTeam],
          id: this.currentGlobalTeam
        }
      } else {
        return { ...this.teams[this.user.teamID], id: this.user.teamID }
      }
    }
  }
}
</script>

<style lang="scss">
.play-mode-wrap {
  .mission-result-transition-enter-active,
  .mission-result-transition-leave-active {
    transition: all ease 0.3s;
    opacity: 1;
  }
  .mission-result-transition-enter-active {
    transition-delay: 0.3s;
  }
  .mission-result-transition-enter,
  .mission-result-transition-leave-to {
    opacity: 0;
  }

  .canvas-tools-slide-transition-enter-active,
  .canvas-tools-slide-transition-leave-active {
    transition: all ease 0.35s;
    transform: translateX(0%);
  }
  .canvas-tools-slide-transition-enter,
  .canvas-tools-slide-transition-leave-to {
    transform: translateX(-105%);
  }
  .pen-size-container {
    max-width: 170px;
    margin: auto;
    padding-top: 3px;
    display: flex;
    justify-content: space-between;
  }
  .draw-tool-btn {
    margin: auto;
    font-weight: bold;
    color: $color-white;
    padding-top: 15px;
    &:not(.disabled):not(.active) {
      cursor: pointer;
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.2;
    }
    &.active {
      pointer-events: none;
      color: #efe73c;
    }
  }
  .pen-size {
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 3px;
    }
    display: flex;
    .pen-size-svg {
      align-self: center;
      margin: auto;
    }
    .outline-circle {
      opacity: 0;
      stroke: $primary_accent_color;
    }
    &.active {
      .outline-circle {
        opacity: 1;
      }
    }
  }
  .color-picker {
    width: 100%;
    max-width: 170px;
    box-shadow: none !important;
    align-self: center;
    margin: auto;
  }
  .select-color-text {
    color: $primary_accent_color;
  }
  .clear-board-btn,
  .clear-all-btn {
    line-height: 1;
    color: $primary_accent_color !important;
    font-size: 10px;
    min-width: 60px !important;
    padding: 0 8px !important;
    height: 28px !important;
  }
  .canvas-tools-rtb-card {
    position: relative;
    height: 100%;
    max-height: 240px;
    padding: 0 !important;
    background-color: $primary_accent_color !important;
  }
  .play-mode {
    position: relative;
    z-index: 2;
  }
  .canvas-rtb-card {
    position: relative;
    z-index: 2;
    max-height: 300px;
    .disabled {
      pointer-events: none;
    }
  }
  .play-asset-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 240px;
  }
  .asset-column {
    position: relative;
  }
  .draw-tools-left-col {
    height: 100%;
    min-width: 70px;
    background-color: $primary_accent_color;
    padding-right: 5px;
    justify-content: space-between;
  }
  .play-asset {
    text-align: right;
    .asset {
      @extend .rtb-border, .rtb-border-radius, .rtb-box-shadow;
    }
  }
  .play-mode {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .current-mode-debug {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    text-align: left;
    color: $color-red2;
    z-index: 99;
    font-size: 8px;
    padding: 10px;
  }
}
</style>
