var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fullscreen-toggle" },
    [
      _vm._t(
        "default",
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "a",
                        _vm._g(
                          {
                            staticClass: "fullscreen-toggle__activator",
                            attrs: {
                              href: "#",
                              role: "button",
                              "aria-label": "Toggle Fullscreen"
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.toggle($event)
                              }
                            }
                          },
                          on
                        ),
                        [
                          _c("svg-icon", {
                            attrs: { name: "expand-regular", size: "small" }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Fullscreen")])]
          )
        ],
        { toggle: _vm.toggle }
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }