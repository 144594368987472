<template>
  <v-flex d-flex class="fact-match-result">
    <transition-group
      name="fact-match-result-transition"
      tag="div"
      class="flex d-flex justify-center"
    >
      <v-flex
        d-flex
        class="fact-match-col-wrap"
        v-for="obj in factData"
        :key="`fact-match-player-${obj.userID}`"
      >
        <v-layout column justify-center class="fact-match-col">
          <v-flex shrink>
            <FactMatchPlayer
              :userInfo="getUserInfo(obj.userID)"
              :imageURL="getUserImage(obj.userID)"
              :teamIcon="getTeamIcon(getTeamIdByUserId(obj.userID))"
              :userID="obj.userID"
            />
          </v-flex>
          <v-flex
            @click.exact="changeScore(obj, 'up')"
            @click.shift="changeScore(obj, 'down')"
            :class="{
              correct: isCorrectFactMatch(obj),
              fact__image: !!obj.correct.image
            }"
            :style="{
              backgroundImage: obj.correct.image
                ? `url(${obj.correct.image})`
                : null
            }"
            shrink
            d-flex
            justify-center
            align-center
            class="fact"
          >
            <template v-if="!obj.correct.image">
              {{ obj.correct }}
            </template>
            <template v-else>
              {{ isCorrectFactMatch(obj) ? "YES" : "NO" }}
            </template>
          </v-flex>
        </v-layout>
      </v-flex>
    </transition-group>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex"
import FactMatchPlayer from "@/components/GroupTeams/Common/Player/FactMatchPlayer.vue"
export default {
  name: "FactMatchResult",
  components: {
    FactMatchPlayer
  },
  props: {
    teamID: String
  },
  computed: {
    ...mapGetters([
      "getCurrentMission",
      "getCurrentMode",
      "missionPlaysArray",
      "onlineUsersArray",
      "user"
    ]),
    ...mapGetters("auth", ["isHost"]),
    ...mapGetters("GameUsers", ["users"]),
    teams() {
      return this.$store.getters.chats
    },
    // gives plays associated with the current mission
    factMissionSourcePlays() {
      if (!this.teamID) return []
      const team = this.getTeamByID(this.teamID)
      if (!team) return []
      if (!team.otherTeamFacts) return []
      // console.log("OTHER TEAM FACTS", team.otherTeamFacts)
      return team.otherTeamFacts
        .map(obj => obj.fact)
        .filter(
          ({ missionID }) =>
            missionID === this.getCurrentMission.freeFormMissionID
        )
    },
    factData() {
      // unless we have a flipped card
      if (!this.teamID) return []
      // unless there are some related plays
      if (!this.factMissionSourcePlays.length) return []
      // otherwise, do the following
      const allButFlippedTeamPlays = this.factMissionSourcePlays.filter(
        ({ teamID }) => teamID !== this.teamID
      )
      // plus video feed
      const allButFlippedAndMedia = allButFlippedTeamPlays.map(fact => {
        const found = this.onlineUsersArray.find(({ id }) => id === fact.userID)

        if (found) {
          return {
            ...fact,
            videoTrack: found.videoTrack
          }
        } else {
          return {
            ...fact,
            videoTrack: null
          }
        }
      })
      // groping by player
      const groupedByPlayer = allButFlippedAndMedia.reduce((acc, val) => {
        if (val.userID in acc) {
          acc[val.userID].push(val)
        } else {
          acc[val.userID] = [val]
        }
        return acc
      }, {})
      // only the first occurence
      return Object.values(groupedByPlayer).map(obj => obj[0])
    }
  },
  methods: {
    photoFactCheck(t) {
      if (t.image) return true
      return false
    },
    changeScore(thePlay, direction) {
      if (this.isHost) {
        var num = this.factData.length
        const teamPlay = this.missionPlaysArray.filter(
          play => play.teamID === this.teamID
        )
        if (teamPlay[0]) {
          var play = {}
          console.log("thePlay", teamPlay[0].points)
          play.id = teamPlay[0].id
          var newScore = 0
          if (direction == "up")
            newScore = teamPlay[0].score + teamPlay[0].points / num
          if (direction == "down")
            newScore = teamPlay[0].score - teamPlay[0].points / num
          play.score = newScore
          console.log("FACT DATA", play)
          const team = this.getTeamByID(this.teamID)
          this.$store.dispatch("addPlay", play)
          var obj = {}
          // console.log("TEAM SCORE", team)
          // console.log("TH NEW SCOER", team.totalScore + play.score)
          obj.teamID = this.teamID
          if (direction == "up") obj.teamScore = team.totalScore + newScore
          if (direction == "down") obj.teamScore = team.totalScore - newScore
          this.$store.dispatch("setTeamScore", obj)
        }
      }
    },
    isCorrectFactMatch(obj) {
      const teamPlay = this.missionPlaysArray.find(
        ({ teamID }) => teamID === this.teamID
      )
      if (!teamPlay) return false
      const { answer } = teamPlay
      if (!answer) return false
      const found = answer.find(({ factUserID }) => factUserID === obj.userID)
      // console.log("FOUND = ", found)
      if (!found) return false
      const { result } = found
      return result
    },
    getUserInfo(userID) {
      const { firstname, lastname } = this.users[userID] || {}
      return { firstname, lastname }
    },
    getUserImage(userID) {
      return this.users && this.users[userID] && this.users[userID].image
        ? this.users[userID].image
        : undefined
    },
    getTeamIcon(teamID) {
      const team = this.getTeamByID(teamID)
      return team ? team.icon : null
    },
    getTeamIdByUserId(userID) {
      return this.users[userID] ? this.users[userID].teamID : null
    },
    getTeamByID(teamID) {
      if (!teamID) return null
      if (!this.teams) return null
      return this.teams[teamID]
    }
  }
}
</script>

<style lang="scss">
.fact-photo {
  max-width: 210px;
  max-height: 100px;
  border-radius: 5px;
  margin-left: -3px;
}
.fact-match-result {
  width: 100%;
  .fact-audio-elem {
    pointer-events: none;
    position: fixed;
    bottom: 0;
    right: 0;
  }
  .fact-match-result-transition-enter-active {
    transition: all 0.5s ease 0s;
  }
  .fact-match-result-transition-leave-active {
    transition: all 0.5s ease 0.6s;
    .fact-match-col {
      transition: all 0.7s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s;
    }
  }
  .fact-match-result-transition-enter,
  .fact-match-result-transition-leave-to {
    min-width: 0 !important;
    max-width: 0 !important;
    margin: 0 !important;
    .fact-match-col {
      opacity: 0;
      transform: scale(0.3);
    }
  }
  .fact-match-col {
    opacity: 1;
    transform: scale(1);
    transition: all 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.25s;
  }
  .fact-match-result-transition-enter-active {
    transition: all 0.5s ease 0s;
  }
  .fact-match-result-transition-leave-active {
    transition: all 0.5s ease 0.6s;
    .fact-match-col {
      transition: all 0.7s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s;
    }
  }
  .fact-match-result-transition-enter,
  .fact-match-result-transition-leave-to {
    min-width: 0 !important;
    max-width: 0 !important;
    margin: 0 !important;
    .fact-match-col {
      opacity: 0;
      transform: scale(0.3);
    }
  }
  .fact {
    @extend .rtb-border, .rtb-border-radius, .rtb-box-shadow;
    user-select: none;
    font-size: 16px;
    font-weight: bold;
    color: $color-wrong;
    background-color: $color-white;
    margin-top: 10px;
    text-align: center;
    padding: 0 3px;
    min-height: 9vh !important;
    align-content: center;
    overflow: hidden;
    &__image {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      min-height: 12vh !important;
      color: #fff;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    }
    &:not(:last-child) {
      margin-right: 10px;
    }
    &.correct {
      color: $color-correct;
    }
  }
  .fact-match-col-wrap {
    width: (100% / 6);
    max-width: 25vh;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
</style>
