<template>
  <div class="swapper" ref="root">
    <div ref="target">
      <!-- <keep-alive> -->
      <component v-bind:is="current" />
      <!-- </keep-alive> -->
    </div>
  </div>
</template>

<script>
import { Back, Power2, TweenMax } from "gsap/TweenMax"

// We should probably gets these via params
import PlayMode from "@/components/GroupTeams/Common/GameModes/PlayMode"
import SocialMode from "@/components/GroupTeams/Common/GameModes/Social"
import VotingMode from "@/components/GroupTeams/Common/GameModes/VotingMode"
import MeetingMode from "@/components/GroupTeams/Common/GameModes/MeetingMode"

export default {
  name: "Swapper",
  data() {
    return {
      current: null,
      type: null
    }
  },
  components: {
    PlayMode,
    SocialMode,
    VotingMode,
    MeetingMode
  },
  watch: {
    // the current comonent changed so animate
    component() {
      this.play()
    }
  },
  props: {
    component: String
  },
  mounted() {
    this.play()
  },
  beforeDestroy() {
    if (this.$refs.target) TweenMax.killTweensOf(this.$refs.target)
  },
  methods: {
    play() {
      if (!this.current) {
        this.current = this.component
        this.in("fade")
      } else if (!this.component) {
        this.out("fade", () => {
          this.current = this.component
        })
      } else {
        this.out("slide", () => {
          this.current = this.component
          this.in("slide")
        })
      }
    },
    out(type, callback) {
      if (type === "slide") {
        TweenMax.to(this.$refs.target, 0.8, {
          delay: 0.05,
          alpha: 0,
          x: 100,
          overwrite: true,
          ease: Power2.easeIn,
          onComplete: callback
        })
      } else if (type === "fade") {
        TweenMax.to(this.$refs.target, 0.6, {
          delay: 0.05,
          scale: 0.3,
          alpha: 0,
          overwrite: true,
          ease: Power2.easeIn,
          onComplete: callback
        })
      }
    },
    in(type) {
      if (type === "slide") {
        TweenMax.fromTo(
          this.$refs.target,
          0.8,
          { alpha: 0, x: -100, scale: 1 },
          {
            delay: 0.25,
            alpha: 1,
            x: 0,
            scale: 1,
            overwrite: false,
            ease: Power2.easeOut
          }
        )
      } else if (type === "fade") {
        TweenMax.fromTo(
          this.$refs.target,
          0.8,
          { scale: 0.3, alpha: 0 },
          {
            delay: 0.5,
            scale: 1,
            alpha: 1,
            overwrite: false,
            ease: Back.easeOut.config(1.5)
          }
        )
      }
    }
  }
}
</script>

<style scoped>
.swapper {
  flex: 1 1 auto;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}
.swapper > div {
  transform: translateZ(0);
  position: absolute;
  height: 100%;
  width: 100%;
}
</style>
