var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "UserImage",
    { attrs: { imageUrl: _vm.imageUrl } },
    [
      _vm.track && !_vm.disable
        ? _c("UserVideo", {
            ref: "video",
            attrs: { track: _vm.track },
            on: {
              ready: function($event) {
                return _vm.$emit("ready")
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }