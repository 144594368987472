var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "root", staticClass: "swapper" }, [
    _c("div", { ref: "target" }, [_c(_vm.current, { tag: "component" })], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }