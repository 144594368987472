<template>
  <audio preload="auto" class="game-start-sound" ref="startsound">
    <source
      :src="require('@/assets/game-start-countdown.mp3')"
      type="audio/mpeg"
    />
  </audio>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "GameStartSound",
  data() {
    return {
      soundPlayed: false,
      activationSecs: 6
    }
  },
  computed: {
    ...mapGetters({
      globalTime: "time",
      actualGame: "actualGame"
    })
  },
  watch: {
    globalTime(value) {
      if (this.actualGame && this.actualGame.startTimestamp) {
        var timeLeft = this.actualGame.startTimestamp - value

        if (
          timeLeft > 0 &&
          timeLeft / 1000 < this.activationSecs &&
          !this.soundPlayed
        ) {
          this.$refs.startsound.volume = 0.5
          this.$refs.startsound.play()
          this.soundPlayed = true
        }
      }
    }
  }
}
</script>
