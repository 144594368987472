import firebase from "firebase/app"
import "firebase/database"
import _ from "lodash"

export const MODULE_NAME = "soundeffect"

export const GetterTypes = {
  VOLUME: "volume"
}

// Sound Effects constants
const BUZZ_IN = "BUZZ_IN"
const GOT_IT_RIGHT = "GOT_IT_RIGHT"
const GOT_IT_WRONG = "GOT_IT_WRONG"
const SWOOSH = "SWOOSH"

const SOUND_EFFECTS = [
  {
    name: BUZZ_IN,
    source:
      "https://assets.remoteteambuilding.com/audio%2F-LroXpzuSTyvLT2mA87K%2FBendTarragon.mp3Fri%20Oct%2025%202019%2013%3A19%3A23%20GMT-0400%20(Eastern%20Daylight%20Time)?alt=media&token=4cf20a8c-7f47-4744-a4f7-03a33ed7b6e9"
  },
  {
    name: GOT_IT_RIGHT,
    source:
      "https://assets.remoteteambuilding.com/audio%2F-Ltal8ocQGSYt988ovij%2Fright%20bell.mp3Thu%20Nov%2014%202019%2020%3A11%3A56%20GMT-0800%20(Pacific%20Standard%20Time)?alt=media&token=b9f57181-014a-4acb-a9f5-69824cd4e769"
  },
  {
    name: GOT_IT_WRONG,
    source:
      "https://assets.remoteteambuilding.com/audio%2F-Ltal8ocQGSYt988ovij%2Ferror-buzz2.mp3Thu%20Nov%2014%202019%2020%3A22%3A12%20GMT-0800%20(Pacific%20Standard%20Time)?alt=media&token=aedbd4e0-aab8-47a0-aab8-9bfe477b582d"
  },
  {
    name: SWOOSH,
    source:
      "https://assets.remoteteambuilding.com/audio%2F-LroXpzuSTyvLT2mA87K%2FBendTarragon.mp3Fri%20Oct%2025%202019%2013%3A19%3A23%20GMT-0400%20(Eastern%20Daylight%20Time)?alt=media&token=4cf20a8c-7f47-4744-a4f7-03a33ed7b6e9"
  }
]

const AUDIO_SEPARATOR = { name: "--------------" }

const SoundEffectModule = {
  namespaced: true,
  state: {
    gameAudios: [],
    userAudios: [],
    volume: null,
    soundEffects: SOUND_EFFECTS
  },
  mutations: {
    SET_LOCAL_VOLUME(state, payload) {
      state.volume = payload
    },
    SET_GAME_AUDIOS(state, payload) {
      state.gameAudios = payload
    },
    SET_USER_AUDIOS(state, payload) {
      state.userAudios = payload
    }
  },
  actions: {
    updateLocalVolume({ commit }, payload) {
      commit("SET_LOCAL_VOLUME", payload)
    },
    updateTeamSoundEffect({ rootGetters, commit }, payload) {
      return firebase
        .database()
        .ref("org/" + rootGetters.orgID + "/game/" + rootGetters.gameID)
        .child("teams/" + rootGetters.teamID)
        .update({
          soundEffect: {
            status: payload.status || "stop",
            key: payload.key,
            timestamp: Date.now()
          }
        })
    },
    updateGameSoundEffect({ rootGetters, commit }, payload) {
      return firebase
        .database()
        .ref("org/" + rootGetters.orgID + "/games/" + rootGetters.gameID)
        .update({
          soundEffect: {
            status: payload.status || "stop",
            key: payload.key,
            timestamp: Date.now()
          }
        })
    },
    async subscribeToGameAudio({ rootGetters, commit }) {
      const snapshot = await firebase
        .database()
        .ref(`org/${rootGetters.orgID}/game/${rootGetters.gameID}/audio`)
        .once("value")

      commit("SET_GAME_AUDIOS", Object.values(snapshot.val() || {}))
    },
    async subscribeToUserAudio({ rootGetters, commit }) {
      const path = `org/${rootGetters.orgID}/`
      const game = rootGetters["game"]
      const actualHost = rootGetters["gameHost"]

      if (!game) return console.error("Game error")

      const actualHostID = actualHost ? actualHost.id : null
      const assignedHostID = game.hostUserID
      const hostID = actualHostID || assignedHostID

      if (!hostID) return console.error("Undefined user ID")

      const ref = firebase
        .database()
        .ref(`${path}/audio`)
        .orderByChild("userID")
        .equalTo(hostID)

      const snapshot = await ref.once("value")
      const value = snapshot.val()

      commit("SET_USER_AUDIOS", Object.values(value || {}))
    },
    updateUserVolume({ rootState }, { userID, volume }) {
      return firebase
        .database()
        .ref("org/1/users/" + userID)
        .update({ volume })
    },
    toggleAudioLoop({ rootState }, audioLoop) {
      return firebase
        .database()
        .ref(`org/${rootState.orgID}/games/${rootState.gameID}`)
        .update({ audioLoop: !audioLoop })
    },
    updateGameAudioTrack({ rootGetters }, payload) {
      return firebase
        .database()
        .ref("org/" + rootGetters.orgID + "/games/" + rootGetters.gameID)
        .update({
          audioTrack: {
            status: payload.status || "stop",
            key: payload.key,
            timestamp: Date.now()
          }
        })
    }
  },
  getters: {
    [GetterTypes.VOLUME](state, getters, rootState, rootGetters) {
      return state.volume !== null ? state.volume : rootGetters["auth/volume"]
    },
    getGameEffects(state) {
      return state.soundEffects
    },
    getAllAudios(_, getters) {
      return getters.getUserAudios
        .concat([AUDIO_SEPARATOR])
        .concat(getters.getGameAudios)
    },
    getGameAudios(state) {
      return state.gameAudios
        .map(sound => ({
          ...sound,
          key: sound.key || sound.source
        }))
        .sort((a, b) => {
          const nameA = a.name.toLowerCase()
          const nameB = b.name.toLowerCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
    },
    getUserAudios(state) {
      return state.userAudios
        .map(sound => ({
          ...sound,
          key: sound.key || sound.source
        }))
        .sort((a, b) => {
          const nameA = a.name.toLowerCase()
          const nameB = b.name.toLowerCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
    },
    getAudioLoopStatus(_, __, ___, rootGetters) {
      const game = rootGetters["game"]
      return !!game && !!game.audioLoop
    }
  }
}

export default SoundEffectModule
