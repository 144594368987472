<template>
  <v-layout class="audio-player">
    <v-select
      ref="selectaudio"
      solo
      class="select-audio"
      :items="gameAudios"
      item-text="name"
      item-value="key"
      v-model="current"
      @focus="onSelectFocus"
      @change="onSelectChange"
      @blur="onSelectBlur"
      label="Audio"
    />
    <v-btn
      v-if="!!current && isPlaying"
      class="stop-btn"
      icon
      @click="play(null)"
    >
      <v-icon>stop</v-icon>
    </v-btn>
    <v-btn
      v-if="!!current && !isPlaying"
      class="stop-btn"
      icon
      @click="play(current)"
    >
      <v-icon>play_arrow</v-icon>
    </v-btn>
    <v-checkbox
      v-model="loop"
      @change="updateLoopStatus"
      class="check"
    ></v-checkbox>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { EventBus } from "@/Eventbus"
import { GameEvent } from "@/event"

export default {
  name: "AudioPlayer",
  data() {
    return {
      current: null,
      isPlaying: false,
      loop: false
    }
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.onKeyUp)
    EventBus.$off("soundEffect-music-ended", this.onMusicEnded)
  },
  async created() {
    EventBus.$on("soundEffect-music-ended", this.onMusicEnded)
    window.addEventListener("keyup", this.onKeyUp)
    this.loop = this.getAudioLoopStatus
    await this.play(null)
  },
  computed: {
    ...mapGetters("soundeffect", ["getAllAudios"]),
    ...mapGetters(["isToolBoxOpen"]),
    ...mapGetters("soundeffect", ["getAudioLoopStatus"]),
    gameAudios() {
      return this.getAllAudios.map(audio => {
        if (audio.keypress) {
          return { ...audio, name: `${audio.name} (${audio.keypress})` }
        } else {
          return { ...audio }
        }
      })
    }
  },
  methods: {
    ...mapActions("soundeffect", ["updateGameAudioTrack", "toggleAudioLoop"]),
    onMusicEnded() {
      this.isPlaying = false
    },
    onKeyUp(e) {
      if (this.$route.name !== "game" || this.isToolBoxOpen) {
        return
      }
      const keypress = String.fromCharCode(e.keyCode)
      const serialize = string => String(string).toLowerCase()
      const found = this.getAllAudios.find(
        audio => serialize(audio.keypress) === serialize(keypress)
      )
      if (!this.editGameSettings && found) {
        this.current = found.key
        this.play(found.key)
      }
    },
    async play(key) {
      if (!key || key === "none") {
        await this.updateGameAudioTrack({ key: null, status: "stop" })
        this.isPlaying = false
      } else {
        await this.updateGameAudioTrack({ key, status: "play" })
        this.isPlaying = true
      }
      if (this.$route.name !== "game" && this.$refs.selectaudio) {
        this.$refs.selectaudio.blur()
      }
    },
    updateLoopStatus(loopStatus) {
      return this.toggleAudioLoop(!loopStatus)
    },
    onSelectFocus() {
      this.$bus.$emit(GameEvent.DisableKeyboardShortcuts)
    },
    /** @param {string} key */
    onSelectChange(key) {
      this.play(key)
      this.$refs.selectaudio.blur()
    },
    onSelectBlur() {
      this.$bus.$emit(GameEvent.EnableKeyboardShortcuts)
    }
  }
}
</script>

<style lang="scss">
.audio-player {
  padding: 0;
  min-width: 100px;
  max-width: 250px;
  margin: 0 10px;
  .select-audio.v-select {
    margin-top: 12px;
    input {
      padding: 0 0 0 8px;
    }
    .v-label {
      padding: 0 20px 0 8px;
      max-width: 100%;
      box-sizing: border-box;
    }
    .v-input__control {
      min-height: unset;
    }
    .v-input__slot {
      min-height: unset;
      box-sizing: border-box;
      padding: 0;
      .v-select__slot {
        height: 25px;
        .v-select__selection {
          margin-top: 0;
        }
      }
    }
  }
  .v-btn--icon.stop-btn,
  .v-btn--icon.prev-btn,
  .v-btn--icon.next-btn {
    margin: 7px -6px 0 0 !important;
  }
  .v-btn--icon.prev-btn,
  .v-btn--icon.next-btn {
    width: 20px;
    &.inactive {
      opacity: 0.5;
    }
  }
  .check {
    height: 30px;
    margin: auto auto auto 5px;
    align-items: end;
    label {
      left: -7px !important;
      font-weight: bolder;
    }
    .accent--text {
      color: $primary_accent_color !important;
      caret-color: $primary_accent_color !important;
    }
    .theme--dark.v-icon {
      color: rgba(255, 255, 255, 0.7);
    }
  }
}
</style>
