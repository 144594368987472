var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Result", {
    attrs: { data: _vm.data, isHost: _vm.isHost },
    on: {
      onIncreaseTotalScore: function(ref) {
        var team = ref.team
        var score = ref.score

        return _vm.increaseTotalScore(team, score)
      },
      onDecreaseTotalScore: function(ref) {
        var team = ref.team
        var score = ref.score

        return _vm.decreaseTotalScore(team, score)
      },
      onIncreaseMissionScore: function(ref) {
        var team = ref.team
        var score = ref.score

        return _vm.increaseMissionScore(team, score)
      },
      onDecreaseMissionScore: function(ref) {
        var team = ref.team
        var score = ref.score

        return _vm.decreaseMissionScore(team, score)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }