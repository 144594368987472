var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "audio-player" },
    [
      _c("v-select", {
        ref: "selectaudio",
        staticClass: "select-audio",
        attrs: {
          solo: "",
          items: _vm.gameAudios,
          "item-text": "name",
          "item-value": "key",
          label: "Audio"
        },
        on: {
          focus: _vm.onSelectFocus,
          change: _vm.onSelectChange,
          blur: _vm.onSelectBlur
        },
        model: {
          value: _vm.current,
          callback: function($$v) {
            _vm.current = $$v
          },
          expression: "current"
        }
      }),
      !!_vm.current && _vm.isPlaying
        ? _c(
            "v-btn",
            {
              staticClass: "stop-btn",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.play(null)
                }
              }
            },
            [_c("v-icon", [_vm._v("stop")])],
            1
          )
        : _vm._e(),
      !!_vm.current && !_vm.isPlaying
        ? _c(
            "v-btn",
            {
              staticClass: "stop-btn",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.play(_vm.current)
                }
              }
            },
            [_c("v-icon", [_vm._v("play_arrow")])],
            1
          )
        : _vm._e(),
      _c("v-checkbox", {
        staticClass: "check",
        on: { change: _vm.updateLoopStatus },
        model: {
          value: _vm.loop,
          callback: function($$v) {
            _vm.loop = $$v
          },
          expression: "loop"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }