var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "social-team-users",
      attrs: { column: "", "justify-center": "", "fill-height": "" }
    },
    [
      _vm.isMissionCardHidden && _vm.isHost
        ? _c(
            "v-flex",
            {
              staticClass: "visibility-link",
              attrs: { "d-flex": "", "align-center": "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.onMissionCardToggle($event)
                }
              }
            },
            [
              _c("v-icon", { staticClass: "visibility-icon" }, [
                _vm._v(" visibility ")
              ]),
              _c("span", { staticClass: "text" }, [
                _vm._v(" " + _vm._s(_vm.currentMission.behavior) + " ")
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-layout",
        {
          staticClass: "buzz-main-row",
          class: {
            compress: _vm.isHost,
            "multi-team-social": _vm.isMultiTeamLayout,
            "no-padding": _vm.isMissionCardHidden
          },
          attrs: { shrink: "", row: "", "justify-center": "" }
        },
        [
          !_vm.isMissionCardHidden
            ? _c(
                "v-layout",
                {
                  staticClass: "buzz-mission",
                  class: { clickable: _vm.isHost },
                  attrs: { column: "", "justify-center": "" },
                  on: {
                    dblclick: function($event) {
                      $event.stopPropagation()
                      return _vm.onMissionCardToggle($event)
                    }
                  }
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "flip-transition", mode: "out-in" } },
                    [
                      _vm.cardStatus === "asset"
                        ? _c("AssetMapper", {
                            staticClass: "buzz-asset-mapper"
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "transition",
                    { attrs: { name: "flip-transition", mode: "out-in" } },
                    [
                      _vm.cardStatus === "game"
                        ? _c(
                            "v-layout",
                            {
                              attrs: {
                                column: "",
                                "justify-center": "",
                                "mission-container": ""
                              }
                            },
                            [
                              _c(
                                "MissionContainer",
                                [
                                  _vm.missionType === "Buzz In"
                                    ? _c("TextOnly", {
                                        attrs: { mode: _vm.mode }
                                      })
                                    : _vm.missionType === "Lipdub"
                                    ? _c("Lipdub", {
                                        attrs: { mode: _vm.mode }
                                      })
                                    : _vm.missionType === "Text" ||
                                      _vm.missionType === "Freeform" ||
                                      _vm.missionType === "Team Name"
                                    ? _c("TextInput", {
                                        attrs: { mode: _vm.mode }
                                      })
                                    : _c("TextOnly", {
                                        attrs: { mode: _vm.mode }
                                      })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isMultiTeamLayout
            ? _c(
                "div",
                { staticClass: "multi-team-grid" },
                [
                  _c(
                    "transition-group",
                    {
                      staticClass: "social-player-transition-wrap top",
                      attrs: { name: "social-player-transition", tag: "div" }
                    },
                    _vm._l(
                      _vm.selectedPlayers.slice(
                        0,
                        _vm.selectedPlayersMiddleIndex
                      ),
                      function(player) {
                        return _c("SocialPlayer", {
                          key:
                            "buzz-in-player-" + player.id + "-" + player.teamID,
                          staticClass: "buzz-player-col",
                          attrs: {
                            buzzCount: _vm.getUserBuzzCount(player.id),
                            firstname: player.firstname,
                            lastname: player.lastname,
                            teamIcon: player.icon,
                            score: _vm.getRatingScore(player.id, player.teamID),
                            readOnly: !_vm.showRating(player.id, player.teamID),
                            allowKeys: _vm.nOfSelectedPlayers === 1,
                            userID: player.id,
                            isWinner: _vm.isWinner(player.id),
                            imageURL: player.image
                          },
                          on: {
                            onStarred: function(num) {
                              return _vm.onStarred(num, player)
                            }
                          }
                        })
                      }
                    ),
                    1
                  ),
                  _c(
                    "transition-group",
                    {
                      staticClass: "social-player-transition-wrap bottom",
                      attrs: { name: "social-player-transition", tag: "div" }
                    },
                    _vm._l(
                      _vm.selectedPlayers.slice(_vm.selectedPlayersMiddleIndex),
                      function(player) {
                        return _c("SocialPlayer", {
                          key:
                            "buzz-in-player-" + player.id + "-" + player.teamID,
                          staticClass: "buzz-player-col",
                          attrs: {
                            buzzCount: _vm.getUserBuzzCount(player.id),
                            firstname: player.firstname,
                            lastname: player.lastname,
                            teamIcon: player.icon,
                            score: _vm.getRatingScore(player.id, player.teamID),
                            readOnly: !_vm.showRating(player.id, player.teamID),
                            allowKeys: _vm.nOfSelectedPlayers === 1,
                            userID: player.id,
                            isWinner: _vm.isWinner(player.id),
                            imageURL: player.image
                          },
                          on: {
                            onStarred: function(num) {
                              return _vm.onStarred(num, player)
                            }
                          }
                        })
                      }
                    ),
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "d-flex rtb-flex-wrap" },
                [
                  _c(
                    "transition-group",
                    {
                      staticClass: "social-player-transition-wrap",
                      attrs: { name: "social-player-transition", tag: "div" }
                    },
                    _vm._l(_vm.selectedPlayers, function(player) {
                      return _c("SocialPlayer", {
                        key:
                          "buzz-in-player-" + player.id + "-" + player.teamID,
                        staticClass: "buzz-player-col",
                        attrs: {
                          buzzCount: _vm.getUserBuzzCount(player.id),
                          firstname: player.firstname,
                          lastname: player.lastname,
                          teamIcon: player.icon,
                          score: _vm.getRatingScore(player.id, player.teamID),
                          readOnly: !_vm.showRating(player.id, player.teamID),
                          allowKeys: _vm.nOfSelectedPlayers === 1,
                          userID: player.id,
                          isWinner: _vm.isWinner(player.id),
                          imageURL: player.image
                        },
                        on: {
                          onStarred: function(num) {
                            return _vm.onStarred(num, player)
                          }
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }