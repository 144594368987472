import firebase from "firebase/app"
import "firebase/database"

import { Role, Navigation } from "../helpers"
import { EmailVerificationError } from "../helpers/exceptions"
import { userEmailInList } from "../services/auth.service"
import _ from "lodash"

let clientsSubscription = null
let clientsSubscriptionRef = null

import {
  getToken,
  signInWithEmailAndPassword,
  signUpWithEmailAndPassword,
  sendEmailVerification,
  sendResetPassword,
  googleSingIn,
  reauth,
  signInAnonymously,
  signUpSomeoneElse
} from "../services/auth.service"

import {
  fetchUser,
  updateUser,
  getUserRef,
  getOnlineUsersCountByClientID
} from "../services/user.service"
import {
  canUserJoinGame,
  getGameIDByEmailMapping,
  fetchGameObjects
} from "../services/game.service"
import {
  fetchClient,
  fetchUsersInPresense,
  ClientService
} from "../services/client.service"
import { authorizeToClient } from "../services/api.service"
import { User } from "@/helpers/user"

export const MODULE_NAME = "auth"

export const GetterTypes = {
  IS_ANON: "IS_ANON"
}

export const ACTION_TYPES = {
  CHECK_CLIENT_CAPACITY: "CHECK_CLIENT_CAPACITY",
  CHECK_EMAIL_MATCHING: "CHECK_EMAIL_MATCHING"
}
const ERROR_CLIENT_MATCH =
  "Your email is not registered for this event." + " Please contact your host"
const REAUTH_INTERVAL = 1000 * 60 * 10
let connectionRef = null
let userRef = null
let interval = null

const getUserObject = ({ user, audit, online }) => {
  let { role, teamID, selected } = user
  const isHost = role === Role.Host

  if (isHost) {
    teamID = 0
    selected = false
  } else if (audit === false && user.role === Role.Audit) {
    role = Role.Player
  } else if (audit === true || user.role === Role.Audit) {
    teamID = 0
    selected = false
    role = Role.Audit
  } else if (user.role === Role.Spectator) {
    role = Role.Spectator
    teamID = 0
    selected = false
  } else {
    role = Role.Player
  }

  return {
    id: user.id,
    name: user.name || "",
    username: user.name || "",
    image: user.image || null,
    firstname: user.firstname,
    lastname: user.lastname,
    orgID: user.orgID,
    originOrgID: user.originOrgID || user.orgID,
    role: role,
    muted: false,
    expotour: !!user.expotour,
    onboarding: !!user.onboarding,
    super: !!user.super,
    gameID: user.gameID || null,
    clientID: user.clientID,
    teamID: teamID || 0,
    selected: !!selected,
    volume: user.volume || 0.25,
    speaker: !!user.speaker,
    status: online ? "online" : "offline",
    loginTimestamp: firebase.database.ServerValue.TIMESTAMP,
    noGameTimestamp: user.noGameTimestamp || 0,
    verificationRequired: !!user.verificationRequired,
    identifier: user.identifier || null,
    isAnonymous: !!user.isAnonymous,
    speechToText: !!user.speechToText,
    potato: !!user.potato
  }
}

const parseObject = string => {
  try {
    return JSON.parse(string)
  } catch (e) {
    console.warn(e)
    return {}
  }
}

export const MutationTypes = {
  UPDATE_CLIENT: "UPDATE_CLIENT"
}

const AuthModule = {
  namespaced: true,
  state: {
    lastKnownUrl: localStorage.lastKnownUrl || "",
    lastKnownCheck: parseObject(localStorage.lastKnownCheck),
    user: {},
    token: null,
    status: null,
    error: null,
    initialized: false,
    initializing: false,
    gameIDs: [],
    clientID: null,
    client: null
  },
  mutations: {
    UPDATE_LAST_KNOWN_URL(state, payload) {
      state.lastKnownUrl = payload
      localStorage.setItem("lastKnownUrl", payload)
    },
    UPDATE_LAST_KNOWN_CHECK(state, payload) {
      let lastKnownCheck
      if (!payload) {
        lastKnownCheck = {}
      } else {
        lastKnownCheck = { ...state.lastKnownCheck }
        const timestampID = state.user && state.user.id ? state.user.id : "url"
        lastKnownCheck[timestampID] = payload
      }
      localStorage.setItem("lastKnownCheck", JSON.stringify(lastKnownCheck))
      state.lastKnownCheck = lastKnownCheck
    },
    UPDATE_USER(state, payload) {
      state.user = { ...payload }
    },
    UPDATE_CLIENT_ID(state, { clientID }) {
      state.clientID = clientID
    },
    UPDATE_GAME_IDs(state, { gameIDs }) {
      state.gameIDs = [...gameIDs]
    },
    UPDATE_USER_FIREBASE_TOKEN(state, payload) {
      state.token = payload
    },
    UPDATE_ERROR(state, { message }) {
      state.error = message
    },
    UPDATE_INITIALIZED_STATUS(state, { status }) {
      state.initialized = status
    },
    UPDATE_INITIALIZING_STATUS(state, { status }) {
      state.initializing = status
    },
    UPDATE_STATUS(state, { status }) {
      state.status = status
    },
    [MutationTypes.UPDATE_CLIENT](state, { client }) {
      state.client = client
    }
  },
  actions: {
    updateLocalClientSettingLoginTimestamp({ dispatch }, timestamp) {
      const clientID = "0"
      const update = { regular: { timestamp } }
      return dispatch("updateLocalClientSetting", { clientID, update })
    },
    async updateLocalClientSetting({ dispatch }, { clientID, update }) {
      const settings = await dispatch("getLocalClientSetting", { clientID })
      const key = `client-${clientID}-settings`
      const newSettings = _.assign(settings, update)
      localStorage.setItem(key, JSON.stringify(newSettings))
    },
    getLocalClientSetting({ commit }, { clientID }) {
      const key = `client-${clientID}-settings`
      const string = localStorage.getItem(key)
      return JSON.parse(string) || {}
    },
    async unsubscribeFromClient({ commit }) {
      if (clientsSubscriptionRef)
        clientsSubscriptionRef.off("value", clientsSubscription)
      commit(MutationTypes.UPDATE_CLIENT, { client: null })
    },
    async subscribeToClient({ commit, dispatch }, { clientID }) {
      if (!clientID) throw new Error("No client ID")
      const ref = firebase.database().ref(`clients/${clientID}`)
      if (clientsSubscriptionRef && clientsSubscriptionRef.isEqual(ref)) {
        return null
      } else {
        commit("UPDATE_CLIENT_ID", { clientID })
        if (clientsSubscriptionRef)
          clientsSubscriptionRef.off("value", clientsSubscription)
        commit(MutationTypes.UPDATE_CLIENT, { client: null })
        clientsSubscriptionRef = ref
        return new Promise(resolve => {
          clientsSubscription = clientsSubscriptionRef.on("value", snapshot => {
            commit(MutationTypes.UPDATE_CLIENT, { client: snapshot.val() })
            resolve()
          })
        })
      }
    },
    async updateError({ commit }, { message }) {
      commit("UPDATE_ERROR", { message })
      commit("UPDATE_STATUS", { status: null })
    },
    async signUserUp({ commit, dispatch }, payload) {
      try {
        const {
          firstname,
          lastname,
          username,
          image,
          email,
          password,
          gameID,
          clientID,
          audit,
          trusted,
          orgID,
          identifier
        } = payload

        if (!trusted) {
          commit("UPDATE_ERROR", { message: null })
          commit("UPDATE_STATUS", { status: "loading" })
        }

        if (!clientID && !trusted)
          throw new Error("Cannot create a user without a client ID")

        let user

        if (trusted) {
          const { user: firebaseUser } = await signUpSomeoneElse({
            email,
            password
          })
          user = firebaseUser
        } else {
          const { user: firebaseUser } = await signUpWithEmailAndPassword({
            email,
            password
          })
          user = firebaseUser
        }

        // We don't need to wait a response of it
        if (!trusted) sendEmailVerification(window.location.href)

        const { uid: userID } = user
        const emailVerified = trusted ? true : !!user.emailVerified

        if (!userID) throw new Error("Cannot create a user without an ID")

        await dispatch("createUser", {
          firstname,
          lastname,
          userID,
          image,
          email,
          gameID,
          clientID,
          username,
          verificationRequired: !emailVerified,
          orgID
        })

        if (!trusted) {
          await dispatch("initializeUser", {
            clientID,
            gameID,
            userID,
            audit,
            identifier
          })
          commit("UPDATE_STATUS", { status: "authorized" })
        }

        return userID
      } catch (e) {
        console.error(e)
        const status =
          e instanceof EmailVerificationError ? "email_not_verified" : "error"
        commit("UPDATE_LAST_KNOWN_URL", null)
        commit("UPDATE_ERROR", e)
        commit("UPDATE_STATUS", { status })
      }
    },
    async resendEmailVerification({ commit }) {
      sendEmailVerification(window.location.href)
      commit("UPDATE_LAST_KNOWN_URL", null)
      commit("UPDATE_ERROR", {
        message: "Verification link was sent to your email"
      })
      commit("UPDATE_STATUS", { status: "error" })
    },
    async forgotPass({ commit, rootState, dispatch }, { email }) {
      commit("UPDATE_ERROR", { message: null })
      commit("UPDATE_STATUS", { status: "loading" })
      try {
        await sendResetPassword({ email, url: window.location.href })
        commit("UPDATE_STATUS", { status: "complete" })
      } catch (e) {
        console.error(e)
        commit("UPDATE_LAST_KNOWN_URL", null)
        commit("UPDATE_ERROR", e)
        commit("UPDATE_STATUS", { status: "error" })
      }
    },
    async createUser({ dispatch, getters }, payload) {
      const {
        email,
        username,
        image,
        firstname,
        lastname,
        gameID,
        clientID,
        userID,
        verificationRequired,
        orgID: argOrgID
      } = payload

      if (!argOrgID) await dispatch("subscribeToClient", { clientID })
      const { client } = getters
      const { gameIDs } = client || {}
      const orgID = argOrgID || client.orgID

      const user = {
        username,
        image,
        firstname,
        lastname,
        gameID,
        clientID,
        verificationRequired
      }

      const newUser = getUserObject({
        user: { ...user, gameID, orgID, id: userID }
      })

      const promises = [getUserRef({ userID }).update(newUser)]

      if (email) {
        const promise = firebase
          .database()
          .ref(`users/private/${userID}`)
          .update({ email })
        promises.push(promise)
      }

      return Promise.all(promises)
    },
    async initializeUser(
      { commit, dispatch, state, getters, rootGetters },
      payload
    ) {
      const {
        userID,
        audit,
        clientID: urlClientID,
        gameID: urlGameID,
        emailVerified,
        identifier,
        isAnonymous
      } = payload
      // find user by ID in firebase
      const user = await fetchUser({ userID })
      // get out of here
      if (!user) throw new Error(`Cannot find user ${userID}`)

      if (user.verificationRequired && !emailVerified) {
        throw new EmailVerificationError("Email is not verified.")
      }
      // map
      const {
        clientID: currentClientID,
        gameID: currentGameID,
        orgID: currentOrgID
      } = user

      if (!currentClientID && !urlClientID)
        throw new Error("You need a cleint ID to login")

      if (user.role !== Role.Host) {
        const clientID = urlClientID || currentClientID
        if (clientID) {
          const client = await fetchClient({ clientID })
          if (client) {
            // Check Max Capacity and Redirect Client to the URL
            if (client.redirectURL) {
              const usersInPresense = await fetchUsersInPresense({ clientID })
              const loggedInBefore = (usersInPresense || {})[userID]
              if (!loggedInBefore) {
                const numUsersInPresense = Object.keys(usersInPresense || {})
                  .length
                // Initialize game from redirect url
                if (
                  !numUsersInPresense ||
                  numUsersInPresense >= client.maxCapacity
                ) {
                  // Get only id part from the url
                  const urlPartGameID = client.redirectURL
                    .replace(/^[:/\w]*\//, "")
                    .replace(/\?[\w=\d]+$/, "")
                  console.log(`Redirect to ${client.redirectURL}`)
                  return await dispatch("initializeUser", {
                    userID,
                    audit,
                    emailVerified,
                    identifier,
                    isAnonymous,
                    ...Navigation.parseUrlID(urlPartGameID)
                  })
                }
              }
            }
            // Check Capacity
            await dispatch(ACTION_TYPES.CHECK_CLIENT_CAPACITY, {
              client,
              clientID
            })
          }
        }
        // If client doesn't contains any game - show error
        const games = await fetchGameObjects({ orgID: currentOrgID, clientID })
        if (!games) {
          throw new Error(
            "We are almost done putting the finishing touches " +
              "on your game - it will be ready soon! " +
              "In the meantime, we recommend you start your " +
              "pre-game warm-up routine and begin composing a victory speech..."
          )
        }
      }

      let orgID = currentOrgID
      let clientID = currentClientID

      // if login is happening without any ID and the user is a host
      // refresh his login mode timestamp
      if (user.role === Role.Host && !urlClientID) {
        await dispatch("updateLocalClientSettingLoginTimestamp", Date.now())
      }

      if (urlClientID && user.role !== Role.Host && user.role !== Role.Audit) {
        const snapshot = await firebase
          .database()
          .ref(`auth/client/${urlClientID}/auth`)
          .once("value")
        const auth = snapshot.val()

        if (auth) {
          if (!userID) throw new Error("No user ID is given")
          const snapshot = await firebase
            .database()
            .ref(`auth/client/${urlClientID}/user/${userID}/authorized`)
            .once("value")
          const authorized = snapshot.val()
          if (!authorized)
            throw new Error(
              `User ${userID} is not authorized for client ${urlClientID}`
            )
        }
      }

      // null by default
      let gameID = null
      let proposedGameID = null
      let gameIDs = []
      let isEmailMatchingCase = false

      if (urlClientID) {
        // && urlGameID
        await dispatch("subscribeToClient", { clientID: urlClientID })
        const { client } = getters
        const {
          gameIDs: clientGamgeIDs,
          orgID: clientOrgID,
          tournament
        } = client
        gameIDs = [...clientGamgeIDs]
        // override defaults
        clientID = urlClientID
        orgID = clientOrgID

        if (tournament && !getters.isHost) {
          isEmailMatchingCase = true
        }
        if (urlGameID) {
          if (clientGamgeIDs.indexOf(urlGameID) < 0) {
            throw new Error("Incorrect game ID")
          }
          proposedGameID = urlGameID
        } else if (tournament && gameIDs.indexOf(currentGameID) >= 0) {
          proposedGameID = currentGameID
        } else if (!tournament || gameIDs.length === 1) {
          proposedGameID = gameIDs[0]
        }
        // If email matching is not expected - assign game ID immediately
        if (!isEmailMatchingCase && proposedGameID) {
          gameID = proposedGameID
        }
      } else {
        await dispatch("subscribeToClient", { clientID })
        const { client } = getters
        const { gameIDs: clientGamgeIDs, orgID: clientOrgID } = client
        gameIDs = [...clientGamgeIDs]
        orgID = clientOrgID
        // if user's gameID is not found in his current client
        // reset it to whatever we get from client
        if (currentGameID && gameIDs.indexOf(currentGameID) < 0) {
          console.error(`Game ${currentGameID} not found in ${clientID}`)
          if (gameIDs.length === 1) {
            gameID = gameIDs[0]
          }
        } else if (currentGameID) {
          gameID = currentGameID
        }
      }

      // get an object with some default values and team ID/role conditions
      const newUser = getUserObject({
        user: {
          ...user,
          id: userID,
          gameID,
          orgID,
          clientID,
          identifier,
          isAnonymous
        },
        audit,
        online: true
      })

      // got a new role here
      const { role } = newUser

      if (gameID) {
        // yes or no
        const message = await canUserJoinGame({ role, userID, gameID, orgID })
        // get out of here
        if (message) throw new Error(message)
      }

      const updateToken = async () => {
        const token = await getToken()
        commit("UPDATE_USER_FIREBASE_TOKEN", token)
      }

      const userRef = getUserRef({ userID })
      userRef.on("value", snapshot => commit("UPDATE_USER", snapshot.val()))

      const onConnected = snapshot => {
        const value = snapshot.val()
        console.log(`connection status of ${userID} changed to`, value)
        if (value) userRef.update({ status: "online" })
        userRef.onDisconnect().update({ status: "offline", onboarding: false })
      }

      connectionRef = firebase.database().ref(".info/connected")
      connectionRef.on("value", onConnected)

      // user is getting new client ID assigned here
      await Promise.all([updateToken(), updateUser({ userID, obj: newUser })])

      commit("UPDATE_USER", newUser)

      // Create presence only for Players
      await ClientService.createUserPresence({
        userID,
        clientID,
        firstname: user.firstname,
        lastname: user.lastname,
        identifier
      })
      ClientService.getPresenceRef({ userID, clientID })
        .onDisconnect()
        .update({ lastSeen: Date.now() })

      if (isEmailMatchingCase && !gameID) {
        // Check game played only if the current client the same as requested
        if (currentClientID === clientID) {
          await dispatch(
            "pregame/fetchUserPlayedGames",
            {
              clientID,
              userID
            },
            { root: true }
          )
        }
        const userGames = rootGetters["pregame/userPlayedGames"]
        gameID = await getGameIDByEmailMapping({
          orgID,
          clientID,
          userPlayedGames: userGames
        })
        console.info(`Game ${gameID} set by email matching`)
      }
      if (!gameID) {
        gameID = proposedGameID
        // TODO: do we need to call canUserJoinGame?
      }

      interval = setInterval(updateToken, REAUTH_INTERVAL)

      const { hasPreGame } = getters

      if (gameID) {
        try {
          // "force" means no need to check game capacity
          await dispatch("initializeToGame", { gameID, clientID, force: true })
        } catch (e) {
          if (hasPreGame) {
            console.warn(e)
            if (role === Role.Player && hasPreGame) {
              const clientOnlineUserRef = firebase
                .database()
                .ref(`client/${clientID}/usersPlayingGames/${userID}`)
              clientOnlineUserRef.onDisconnect().set(null)
              await clientOnlineUserRef.set(0)
            }
            await dispatch("setSession", { orgID, clientID, gameIDs })
          } else {
            throw e
          }
        }
      } else {
        if (role === Role.Player && hasPreGame) {
          const clientOnlineUserRef = firebase
            .database()
            .ref(`client/${clientID}/usersPlayingGames/${userID}`)
          clientOnlineUserRef.onDisconnect().set(null)
          await clientOnlineUserRef.set(0)
        }
        await dispatch("setSession", { orgID, clientID, gameIDs })
      }
    },
    async deinitializeGame({ state, commit, dispatch }) {
      const { user, clientID, gameIDs } = state
      const { id: userID, orgID, gameID } = user
      if (!gameID) throw new Error("No current game found")
      commit("UPDATE_INITIALIZED_STATUS", { status: false })

      const clientOnlineUserRef = firebase
        .database()
        .ref(`client/${clientID}/usersPlayingGames/${userID}`)
      await clientOnlineUserRef.set(0)

      await Promise.all([
        updateUser({ userID, obj: { gameID: null, orgID } }),
        dispatch("setSession", { orgID, gameID: null, clientID, gameIDs })
      ])
    },
    async initializeToGame(
      { state, commit, dispatch, getters },
      { gameID, clientID, force }
    ) {
      if (!gameID) throw new Error(`Game ID error`)
      if (!clientID) throw new Error(`Client ID error`)
      const { user } = state
      const { id: userID, role, clientID: userClientID } = user
      await dispatch("subscribeToClient", { clientID })

      const { client, hasPreGame } = getters
      const { gameIDs, orgID } = client

      if (!orgID) throw new Error("Invalid data")
      if (!Array.isArray(gameIDs)) throw new Error("Invalid data")

      if (role !== Role.Host && role !== Role.Audit && !force) {
        const snapshot = await firebase
          .database()
          .ref(`org/${orgID}/games/${gameID}`)
          .once("value")
        const game = snapshot.val()
        if (!game) throw new Error(`Game ${gameID} settings are unavailable`)
        const { ondeck, deactivate, endTimestamp, deletedTimestamp } = game
        if (ondeck || deactivate || endTimestamp || deletedTimestamp) {
          console.log(`forbidden game`, game)
          throw new Error(`Game ${gameID} initilization is forbidden`)
        }
      }

      if (!force) {
        const message = await canUserJoinGame({ role, userID, gameID, orgID })
        if (message) throw new Error(message)
      }

      if (role === Role.Player && hasPreGame) {
        const currentClientOnlineUserRef = firebase
          .database()
          .ref(`client/${userClientID}/usersPlayingGames/${userID}`)

        await currentClientOnlineUserRef.set(null)
        currentClientOnlineUserRef.onDisconnect().cancel()

        const newClientOnlineUserRef = firebase
          .database()
          .ref(`client/${clientID}/usersPlayingGames/${userID}`)

        newClientOnlineUserRef.onDisconnect().set(null)
        await newClientOnlineUserRef.set(gameID)
      }

      commit("UPDATE_INITIALIZED_STATUS", { status: false })
      commit("UPDATE_INITIALIZING_STATUS", { status: true })

      try {
        await updateUser({
          userID,
          obj: { gameID, orgID, clientID, noGameTimestamp: 0 }
        })

        await firebase
          .database()
          .ref(`client/${clientID}/presense/${userID}/games/${gameID}`)
          .set(true)

        await dispatch("setSession", { orgID, clientID, gameIDs })
        await dispatch("subscribeToGame", { orgID, gameID })
      } catch (e) {
        commit("UPDATE_INITIALIZED_STATUS", { status: true })
        commit("UPDATE_INITIALIZING_STATUS", { status: false })
        throw e
      }

      commit("UPDATE_INITIALIZED_STATUS", { status: true })
      commit("UPDATE_INITIALIZING_STATUS", { status: false })
    },
    async setSession({ commit, dispatch }, { orgID, clientID, gameIDs }) {
      commit("UPDATE_CLIENT_ID", { clientID })
      commit("UPDATE_GAME_IDs", { gameIDs })
      await dispatch("setOrgID", orgID, { root: true })
    },
    async subscribeToGame({ state, commit, dispatch }, { orgID, gameID }) {
      await Promise.all([
        dispatch("subscribeToTheGameID", { gameID, orgID }, { root: true }),
        dispatch("subscribeToChats", { gameID, orgID }, { root: true }),
        dispatch("GameUsers/subscribeToGameUsers", { gameID }, { root: true })
      ])
      await Promise.all([
        dispatch("subscribeToMissions", null, { root: true }),
        dispatch("subscribeToGameStatus", null, { root: true }),
        dispatch("subscribeToPlays", null, { root: true }),
        dispatch("corretTeamID")
      ])
    },
    async corretTeamID({ rootGetters, dispatch, state }) {
      const { chats } = rootGetters
      const { user } = state
      const { teamID, id: userID } = user
      // reset team ID to 0 if not found in this game
      if (teamID !== 0 && !chats[teamID]) {
        await dispatch(
          "updateUserToTeamID",
          { userID, teamID: 0 },
          { root: true }
        )
      }
    },
    async signUserIn({ commit, dispatch }, payload) {
      commit("UPDATE_ERROR", { message: null })
      commit("UPDATE_STATUS", { status: "loading" })
      commit("UPDATE_USER_FIREBASE_TOKEN", null)
      commit("UPDATE_LAST_KNOWN_URL", null)
      commit("UPDATE_USER", {})
      try {
        const { email, password, gameID, clientID, audit, identifier } = payload
        const { user } = await signInWithEmailAndPassword({ email, password })
        const { uid: userID, emailVerified } = user
        await dispatch("initializeUser", {
          clientID,
          gameID,
          userID,
          audit,
          emailVerified,
          identifier
        })
        commit("UPDATE_STATUS", { status: "authorized" })
      } catch (e) {
        console.error(e)
        const status =
          e instanceof EmailVerificationError ? "email_not_verified" : "error"
        commit("UPDATE_LAST_KNOWN_URL", null)
        commit("UPDATE_STATUS", { status })
        commit("UPDATE_ERROR", e)
      }
    },
    async signGoogleUserIn({ commit, dispatch }, payload) {
      commit("UPDATE_ERROR", { message: null })
      commit("UPDATE_STATUS", { status: "loading" })
      try {
        const { gameID, clientID, audit, identifier } = payload
        const { user } = await googleSingIn()
        const {
          displayName: username,
          uid: userID,
          photoURL: image,
          email
        } = user
        const [firstname, lastname] = username.split(" ")
        try {
          await dispatch("initializeUser", {
            gameID,
            clientID,
            userID,
            audit,
            identifier
          })
          commit("UPDATE_STATUS", { status: "authorized" })
        } catch (e) {
          console.log(e)
          console.log(`Cannot find Google user ${userID}. Creating a new one`)
          if (!clientID)
            throw new Error("Cannot create a user without a client ID")
          const user = {
            firstname,
            lastname,
            userID,
            image,
            email,
            gameID,
            clientID,
            username
          }
          await dispatch("createUser", user)
          await dispatch("initializeUser", {
            gameID,
            clientID,
            userID,
            audit,
            identifier
          })
          commit("UPDATE_STATUS", { status: "authorized" })
        }
      } catch (e) {
        console.error(e)
        commit("UPDATE_LAST_KNOWN_URL", null)
        commit("UPDATE_ERROR", e)
        commit("UPDATE_STATUS", { status: "error" })
      }
    },
    async signUserInAnonymouslyWithPassword({ commit, dispatch }, payload) {
      commit("UPDATE_ERROR", { message: null })
      commit("UPDATE_STATUS", { status: "loading" })
      const {
        gameID,
        clientID,
        audit,
        firstname,
        lastname,
        password,
        identifier
      } = payload
      try {
        if (!password) throw new Error("No password is given")
        const { user } = await signInAnonymously()
        const { uid: userID } = user
        await authorizeToClient({ userID, clientID, password })
        try {
          await dispatch("initializeUser", {
            clientID,
            gameID,
            userID,
            audit,
            identifier,
            isAnonymous: true
          })
          commit("UPDATE_STATUS", { status: "authorized" })
        } catch (e) {
          console.log(e)
          console.log(`Cannot find Google user ${userID}. Creating a new one`)
          if (!clientID)
            throw new Error("Cannot create a user without a client ID")
          const user = {
            firstname,
            lastname,
            userID,
            image: "",
            gameID,
            clientID,
            username: `${firstname} ${lastname}`
          }
          await dispatch("createUser", user)
          await dispatch("initializeUser", {
            gameID,
            clientID,
            userID,
            audit,
            identifier,
            isAnonymous: true
          })
          commit("UPDATE_STATUS", { status: "authorized" })
        }
      } catch (e) {
        commit("UPDATE_ERROR", e)
        commit("UPDATE_STATUS", { status: "error" })
      }
    },
    async signUserInAnonymously({ commit, dispatch }, payload) {
      commit("UPDATE_ERROR", { message: null })
      commit("UPDATE_STATUS", { status: "loading" })
      const {
        gameID,
        clientID,
        audit,
        firstname,
        lastname,
        identifier
      } = payload
      try {
        const { user } = await signInAnonymously()
        const { uid: userID } = user
        try {
          await dispatch("initializeUser", {
            clientID,
            gameID,
            userID,
            audit,
            identifier,
            isAnonymous: true
          })
          commit("UPDATE_STATUS", { status: "authorized" })
        } catch (e) {
          console.log(e)
          console.log(`Cannot find Google user ${userID}. Creating a new one`)
          if (!clientID)
            throw new Error("Cannot create a user without a client ID")
          const user = {
            firstname,
            lastname,
            userID,
            image: "",
            gameID,
            clientID,
            username: `${firstname} ${lastname}`
          }
          await dispatch("createUser", user)
          await dispatch("initializeUser", {
            gameID,
            clientID,
            userID,
            audit,
            identifier,
            isAnonymous: true
          })
          commit("UPDATE_STATUS", { status: "authorized" })
        }
      } catch (e) {
        commit("UPDATE_ERROR", e)
        commit("UPDATE_STATUS", { status: "error" })
      }
    },
    /**
     * Sign In users with email first name and last name
     * These users are stored in format {ClientID}:{email},
     * and it works only for specific ClientID
     *
     * @param commit
     * @param dispatch
     * @param payload
     * @return {Promise<void>}
     */
    async signUserInWithEmail({ commit, dispatch }, payload) {
      try {
        commit("UPDATE_ERROR", { message: null })
        commit("UPDATE_STATUS", { status: "loading" })
        commit("UPDATE_USER_FIREBASE_TOKEN", null)
        commit("UPDATE_LAST_KNOWN_URL", null)
        commit("UPDATE_USER", {})
        const {
          firstname,
          lastname,
          email,
          identifier,
          clientID,
          gameID,
          orgID
        } = payload
        const password = email
        // Sign In Or Sign Up user
        let user
        try {
          // Try to login
          const { user: firebaseUser } = await signInWithEmailAndPassword({
            email,
            password
          })
          user = firebaseUser
        } catch (e) {
          const { user: firebaseUser } = await signUpWithEmailAndPassword({
            email,
            password
          })
          user = firebaseUser
        }
        const { uid: userID } = user
        //  Get or create user
        const rtbUser = await fetchUser({ userID })
        if (!rtbUser) {
          await dispatch("createUser", {
            firstname,
            lastname,
            userID,
            email,
            gameID,
            clientID,
            verificationRequired: true,
            orgID
          })
        }

        await dispatch("initializeUser", {
          clientID,
          gameID,
          userID,
          identifier,
          emailVerified: true // Don't check verification
        })
        commit("UPDATE_STATUS", { status: "authorized" })
      } catch (e) {
        commit("UPDATE_LAST_KNOWN_URL", null)
        commit("UPDATE_STATUS", { status: "error" })
        commit("UPDATE_ERROR", e)
      }
    },
    /**
     * Check Email Matching for the client.
     *
     * @param commit
     * @param dispatch
     * @param email
     * @param client
     * @return {Promise<void>}
     */
    async checkEmailMatching({ commit, dispatch }, { email, client }) {
      try {
        email = email ? email.toLowerCase() : null
        await dispatch(ACTION_TYPES.CHECK_EMAIL_MATCHING, { email, client })
      } catch (e) {
        commit("UPDATE_ERROR", e)
        commit("UPDATE_STATUS", { status: "error" })
        throw e
      }
    },
    async signUserOut({ commit, state, getters, rootState }) {
      commit("UPDATE_STATUS", { status: "loading" })
      const { clientID, user } = state
      const { hasPreGame } = getters
      const { gameID, orgID } = rootState
      const { id: userID, role } = user

      if (connectionRef) connectionRef.off()

      if (userID) {
        const ref = getUserRef({ userID })
        await Promise.all([
          ref.child("status").set("offline"),
          ref.child("onboarding").set(false)
        ])
        ref.onDisconnect().cancel()
      }

      if (role === Role.Player && hasPreGame) {
        const ref = firebase
          .database()
          .ref(`client/${clientID}/usersPlayingGames/${userID}`)
        await ref.set(null)
        ref.onDisconnect().cancel()
      }

      clearInterval(interval)

      commit("UPDATE_INITIALIZED_STATUS", { status: false })
      commit("UPDATE_STATUS", { status: null })
      commit("UPDATE_USER_FIREBASE_TOKEN", null)
      commit("UPDATE_LAST_KNOWN_URL", null)
      commit("UPDATE_USER", {})
    },
    updateLastKnownUrl({ commit }, payload) {
      commit("UPDATE_LAST_KNOWN_URL", payload)
    },
    updateLastKnownCheck({ commit }, payload) {
      commit("UPDATE_LAST_KNOWN_CHECK", payload)
    },
    async reauthenticate({ commit, dispatch, state }, payload) {
      commit("UPDATE_STATUS", { status: "loading" })
      try {
        const { uid: userID, isAnonymous } = await reauth()
        if (!userID) throw new Error("Cannot re authorize without a user ID")
        try {
          await dispatch("initializeUser", { userID, isAnonymous })
          commit("UPDATE_STATUS", { status: "authorized" })
        } catch (e) {
          const status =
            e instanceof EmailVerificationError ? "email_not_verified" : "error"
          commit("UPDATE_STATUS", { status })
          commit("UPDATE_LAST_KNOWN_URL", null)
          console.error(e)
        }
      } catch (e) {
        commit("UPDATE_STATUS", { status: null })
        console.warn(e)
      }
    },
    /**
     * @param {} _
     * @param { { clientID: string } } payload
     * @throws
     * @returns {Promise<boolean>}
     */
    async [ACTION_TYPES.CHECK_CLIENT_CAPACITY](_, { client, clientID }) {
      if (client !== null) {
        const count = await getOnlineUsersCountByClientID(clientID)
        const maxCapacity = parseInt(client.maxCapacity) || 999999

        console.log("count", count)
        console.log("maxCapacity", maxCapacity)

        if (count >= maxCapacity) {
          throw new Error("The game is full. Try again later")
        } else {
          return true
        }
      } else {
        return false
      }
    },
    async [ACTION_TYPES.CHECK_EMAIL_MATCHING](_, { email, client }) {
      const matchEmails = client.matchEmails || []
      if (matchEmails.length && !userEmailInList(matchEmails, email)) {
        throw new Error(ERROR_CLIENT_MATCH)
      }
    },
    updateFirebaseToken({ commit }, token) {
      commit("UPDATE_USER_FIREBASE_TOKEN", token)
    }
  },
  getters: {
    lastKnownCheck({ user, lastKnownCheck }) {
      if (user && lastKnownCheck) {
        const urlTimestamp = parseInt(lastKnownCheck["url"]) || 0
        const userTimestamp = user ? parseInt(lastKnownCheck[user.id]) || 0 : 0
        return Math.max(urlTimestamp, userTimestamp)
      } else {
        return 0
      }
    },
    isSuper(state, { isHost }) {
      return state.user && isHost ? !!state.user.super : false
    },
    isHost(state, { role }) {
      return role === Role.Host
    },
    isPlayer(state, { role }, { livechat: { roomID } }) {
      return roomID && role === Role.Spectator ? true : role === Role.Player
    },
    isAudit(state, { role }) {
      return role === Role.Audit
    },
    isSpectator(state, { role }, { livechat: { roomID } }) {
      return roomID ? false : role === Role.Spectator
    },
    client(state) {
      if (!state.client) return null
      const gameIDs = state.client.games ? Object.keys(state.client.games) : []
      const gameID = state.client.gameID
      if (gameIDs.indexOf(gameID) < 0) gameIDs.unshift(gameID)
      const client = {
        ...state.client,
        gameIDs,
        imageUrl: state.client.logoImage
      }
      return client
    },
    restriction(state, { client }) {
      return client && client.restriction ? client.restriction : 0
    },
    accessOverride(state, getters, rootState, { game }) {
      return !!game && !!(game.deactivate || game.ondeck)
    },
    access(
      state,
      { isHost, isAudit, restriction, accessOverride },
      rootState,
      rootGetters
    ) {
      const code = parseInt(restriction)
      const userGames = rootGetters["pregame/userPlayedGames"]
      // n of games played
      const n = Object.keys(userGames || {}).length
      // set FREE ROAM if a host or their assigned game is ended, deleted
      // or disabled
      if (isHost || isAudit || accessOverride) {
        return 0
      } else if (code === 3) {
        // enabled TRAINING CAMP mode to enforce the first game as PLEDGED
        // and let the following games to be played as STICKY
        return n > 0 ? 1 : 2
      } else if (code === 4) {
        return n > 0 ? 0 : 2
      } else {
        return restriction
      }
    },
    volume(state, { user }) {
      return user && user.volume ? Math.min(1, user.volume) : 1
    },
    lastKnownUrl(state) {
      return state.lastKnownUrl
    },
    status(state) {
      return state.status
    },
    error(state) {
      return state.error
    },
    clientID(state) {
      return state.clientID
    },
    user(state) {
      return state.user
    },
    hasPreGame(state, { client }) {
      return client ? !!client.tournament : false
    },
    initialized(state, { authorized }) {
      return authorized && state.initialized
    },
    initializing(state) {
      return state.initializing
    },
    authorized(state) {
      return state.token && state.user && state.status === "authorized"
    },
    token(state) {
      return state.token
    },
    role(state) {
      return state.user ? state.user.role : null
    },
    [GetterTypes.IS_ANON]({ user }) {
      return User.isAnon(user)
    }
  }
}

export default AuthModule
