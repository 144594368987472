import firebase from "firebase/app"
import "firebase/database"
import { Role } from "@/helpers"

const MAX_N_OF_TEAMS_PER_GROUP = 9

let globalTeamSubscription = null
let missionAudioStateSubscription = null
let missionVideoStateSubscription = null

const modes = [
  "welcome",
  "explain",
  "play",
  "huddle",
  "social",
  "voting",
  "results",
  "over",
  "meeting"
]

const MULTI_TEAM_THRESHOLD = 3

const GroupModule = {
  namespaced: true,
  state: {
    modes,
    isLoadingTeam: false,
    isWispering: false,
    currentGlobalTeam: null,
    missionAudioState: {},
    missionVideoState: {},
    votingOverride: false,
    isUsersLocallyMuted: false
  },
  mutations: {
    UPDATE_IS_LOADING_TEAM(state, payload) {
      state.isLoadingTeam = payload
    },
    UPDATE_IS_WISPERING(state, payload) {
      state.isWispering = payload
    },
    UPDATE_CURRENT_GLOBAL_TEAM(state, teamID) {
      state.currentGlobalTeam = teamID
    },
    UPDATE_MISSION_AUDIO_STATE(state, payload) {
      state.missionAudioState = payload
    },
    UPDATE_MISSION_VIDEO_STATE(state, payload) {
      state.missionVideoState = payload
    },
    UPDATE_LOCAL_MODE(state, payload) {
      state.votingOverride = payload
    },
    /**
     *
     * @param {*} state
     * @param {boolean} value
     */
    UPDATE_IS_USERS_LOCALLY_MUTED(state, value) {
      state.isUsersLocallyMuted = value
    }
  },
  actions: {
    async updateVotingOverride({ commit }, payload) {
      commit("UPDATE_LOCAL_MODE", payload)
    },
    async addTeamToGroup({ rootState, commit }, team_id) {
      if (!team_id)
        throw new Error("Cannot add a team without an ID to a group")

      const orgID = rootState.orgID
      const gameID = rootState.gameID

      const groupsPromise = await firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/groups`)
        .once("value")

      const groupsObj = groupsPromise.val() || {}
      const groupsEntries = Object.entries(groupsObj)
      const groupsArray = groupsEntries.map(arr => ({
        ...arr[1],
        groupID: arr[0]
      }))

      const group = groupsArray.find(({ teams = {} }) => {
        const array = Object.keys(teams)
        return MAX_N_OF_TEAMS_PER_GROUP > array.length
      })

      let groupID = null

      if (group) {
        await firebase
          .database()
          .ref(`org/${orgID}/game/${gameID}/groups/${group.groupID}/teams`)
          .update({ [team_id]: 1 })

        await firebase
          .database()
          .ref(`org/${orgID}/game/${gameID}/teams/${team_id}`)
          .update({ groupID: group.groupID })

        groupID = group.groupID
      } else {
        const newGroupPromise = await firebase
          .database()
          .ref(`org/${orgID}/game/${gameID}/groups`)
          .push()

        const newGroupID = newGroupPromise.key

        await firebase
          .database()
          .ref(`org/${orgID}/game/${gameID}/groups/${newGroupID}/teams`)
          .update({ [team_id]: 1 })

        const promise = await firebase
          .database()
          .ref(`org/${orgID}/game/${gameID}/teams/${team_id}`)
          .update({ groupID: newGroupID })

        groupID = newGroupID
      }

      return groupID
    },
    async autoAssignTeam({ rootGetters, dispatch }, { userID }) {
      const { chats, onlineUsersGroupedByTeam } = rootGetters
      if (!chats) throw new Error("Got no teams to assign")
      const teamIDs = Object.keys(chats).reverse()
      const groupedByTeam = {}

      const isValid = team => {
        return Array.isArray(team) || team === null || team === undefined
      }

      for (let i = 0; i < teamIDs.length; i++) {
        const teamID = teamIDs[i]
        const array = onlineUsersGroupedByTeam[teamID]
        if (!isValid(array)) throw new Error("Got corrupt data")
        groupedByTeam[teamID] = Array.isArray(array) ? array : []
      }

      let newTeamID = teamIDs[0]
      let minPlayers = groupedByTeam[teamIDs[0]].length

      for (let i = 0; i < teamIDs.length; i++) {
        const teamID = teamIDs[i]
        const array = groupedByTeam[teamID]
        if (array.length <= minPlayers) {
          minPlayers = array.length
          newTeamID = teamID
        }
      }

      if (!newTeamID) throw new Error("Cannot auto assign to an undefined team")

      await dispatch(
        "updateUserToTeamID",
        { userID, teamID: newTeamID },
        { root: true }
      )
    },
    async removeTeamFromGroup({ rootState }, payload) {
      const teamID = payload.id
      const { orgID, gameID } = rootState

      const promise = await firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/teams/${teamID}`)
        .once("value")

      const team = promise.val()
      const { groupID } = team

      if (groupID) {
        await firebase
          .database()
          .ref(
            `org/${orgID}/game/${gameID}/groups/${team.groupID}/teams/${teamID}`
          )
          .remove()
      }
    },
    toggleEveryoneAudio({ rootState: { orgID, gameID } }, payload) {
      return firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/gameStatus`)
        .update({ everyoneAudioOn: !payload.mutedStatus })
    },
    toggleEveryoneMuted({ rootState: { orgID, gameID } }, payload) {
      return firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/gameStatus`)
        .update({ everyoneMuted: !payload.mutedStatus })
    },
    subscribeToCurrentGlobalTeam({ rootState, commit }) {
      const { orgID, gameID } = rootState
      if (globalTeamSubscription) globalTeamSubscription.off("value")

      globalTeamSubscription = firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/gameStatus/getCurrentGlobalTeam`)

      return new Promise(resolve => {
        globalTeamSubscription.on("value", snapshot => {
          commit("UPDATE_CURRENT_GLOBAL_TEAM", snapshot.val())
          resolve()
        })
      })
    },
    async updateCurrentGlobalTeam({ rootState }, teamID) {
      const { orgID, gameID } = rootState

      await firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/gameStatus`)
        .update({ getCurrentGlobalTeam: teamID })
    },
    updateCurrentLocalTeam({ commit }, teamID) {
      commit("UPDATE_CURRENT_GLOBAL_TEAM", teamID)
    },
    subscribeToMissionAudioState({ rootState, commit }) {
      const { orgID, gameID } = rootState
      if (missionAudioStateSubscription)
        missionAudioStateSubscription.off("value")
      missionAudioStateSubscription = firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/missionAudio`)
      missionAudioStateSubscription.on("value", snapshot => {
        commit("UPDATE_MISSION_AUDIO_STATE", snapshot.val())
      })
    },
    toggleMissionAudioState({ rootState }, payload) {
      const { orgID, gameID } = rootState
      const refPath = `org/${orgID}/game/${gameID}/missionAudio`
      firebase
        .database()
        .ref(refPath)
        .update({ playing: payload.playing, seekTime: payload.seekTime })
    },
    subscribeToMissionVideoState({ rootState, commit }) {
      const { orgID, gameID } = rootState
      if (missionVideoStateSubscription)
        missionVideoStateSubscription.off("value")
      missionVideoStateSubscription = firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/missionVideo`)
      missionVideoStateSubscription.on("value", snapshot => {
        commit("UPDATE_MISSION_VIDEO_STATE", snapshot.val())
      })
    },
    toggleMissionVideoState({ rootState }, payload) {
      const { orgID, gameID } = rootState
      const refPath = `org/${orgID}/game/${gameID}/missionVideo`
      firebase
        .database()
        .ref(refPath)
        .update({ playing: payload.playing, seekTime: payload.seekTime })
    }
  },
  getters: {
    votingOverride(state) {
      return state.votingOverride
    },
    isMultiTeam(_, __, ___, { chats }) {
      return Object.keys(chats || {}).length > MULTI_TEAM_THRESHOLD
    },
    isUserMutedGlobally(state, getters, rootState, rootGetters) {
      return payload => {
        const mode = rootGetters.getCurrentMode
        if (rootGetters.getEveryoneAudioStatus) return false
        if (rootGetters.getEveryoneMuted && payload.user.role != Role.Host)
          return true
        if (payload.user.muted) return true

        switch (mode) {
          case "welcome":
            return false
          case "explain":
            return getters.explain_mode(payload)
          case "explain_done":
            return false
          case "huddle":
            return getters.huddle_mode(payload)
          case "play":
            return getters.play_mode(payload)
          case "social":
            return rootGetters.getCurrentMission.behavior === "Lipdub"
              ? getters.lipdub_mode(payload)
              : getters.social_mode(payload)
          case "voting":
            return getters.play_mode(payload)
          case "results":
            return false
          case "meeting_mode":
            return getters.meeting_mode(payload)
          default:
            return false
        }
      }
    },
    explain_mode(state, getters, rootState) {
      return payload => {
        if (
          payload.user.role === Role.Host ||
          payload.user.role === Role.Audit ||
          payload.user.role === Role.Spectator
        ) {
          return false
        } else if (rootState.auth.user.role === Role.Player) {
          return true
        } else {
          return true
        }
      }
    },
    huddle_mode(state, getters, rootState, rootGetters) {
      return payload => {
        const { user } = payload
        if (
          [Role.Host, Role.Spectator, Role.Audit].includes(
            rootState.auth.user.role
          )
        ) {
          if (
            user.role === Role.Host ||
            user.role === Role.Audit ||
            user.role === Role.Spectator
          ) {
            return false
          } else if (state.currentGlobalTeam === user.teamID) {
            return false
          } else {
            return true
          }
        }

        if (
          user.role === Role.Host ||
          user.role === Role.Audit ||
          user.role === Role.Spectator
        ) {
          if (state.currentGlobalTeam !== rootGetters.teamID) {
            return true
          } else {
            return false
          }
        }

        if (user.teamID === rootGetters.teamID) {
          return false
        } else {
          return true
        }
      }
    },
    social_mode(state, getters, rootState, rootGetters) {
      return payload => {
        const { user } = payload

        if (rootState.auth.user.role === Role.Host) {
          if (user.selected) {
            return false
          } else {
            return true
          }
        } else {
          if (user.teamID === rootGetters.teamID) {
            return false
          } else if (rootState.auth.user.selected) {
            if (
              user.role === Role.Host ||
              user.role === Role.Audit ||
              user.role === Role.Spectator ||
              user.selected ||
              user.teamID === rootGetters.teamID
            ) {
              return false
            } else {
              return true
            }
          } else {
            if (
              user.role === Role.Host ||
              user.role === Role.Audit ||
              user.role === Role.Spectator ||
              user.selected
            ) {
              return false
            } else {
              return true
            }
          }
        }
      }
    },
    play_mode(state, getters, rootState, rootGetters) {
      return payload => {
        const { user } = payload

        if (
          [Role.Host, Role.Audit, Role.Spectator].includes(
            rootState.auth.user.role
          )
        ) {
          if (
            user.role === Role.Host ||
            user.role === Role.Audit ||
            user.role === Role.Spectator
          ) {
            return false
          } else if (user.selected) {
            return false
          } else {
            return true
          }
        } else {
          if (
            user.role === Role.Host ||
            user.role === Role.Audit ||
            user.role === Role.Spectator
          )
            return false
          // if  user is not in  your team, you should not hear them
          if (payload.user.teamID === rootGetters.teamID) {
            return false
          }
          // no condition satisfied, then the player should be muted
          return true
        }
      }
    },
    lipdub_mode(state, getters) {
      return payload => {
        // All players are muted, only
        // social users, and facilitator should
        // have volume turned on
        if (
          payload.user.role === Role.Host ||
          payload.user.role === Role.Audit ||
          payload.user.role === Role.Spectator
        ) {
          return false
        } else {
          return payload.user.selected ? false : true
        }
      }
    },
    meeting_mode(state, getters) {
      return () => false
    }
  }
}

export default GroupModule
