import firebase from "firebase/app"
import "firebase/database"
import uniqid from "uniqid"
import { uploadMedia } from "@/services/storage.service"
import io from "socket.io-client"

const rtmpServer = "afternoon-sands-78596.herokuapp.com"
const twitchRTMP = `rtmp://live-sfo.twitch.tv/app/`

const canvasToJpg = canvas =>
  new Promise(resolve =>
    canvas.toBlob(blob => resolve(blob), "image/jpeg", 0.8)
  )

let mediaRecorder

let twitchSubscription = null
let twitchSubscriptionRef = null

const videoDefaultConstraintString = { frameRate: 30 }
const audioDefaultConstraintString = {
  sampleSize: 16,
  // channelCount: 2,
  echoCancellation: false
}

const ScreenshotModule = {
  namespaced: true,
  state: {
    stream: null,
    userStream: null,
    twitchAccounts: [],
    inuseTwitchAccount: {},
    isProcessingScreenshot: null,
    twitchAccountRef: null,
    currentGameRef: null,
    isBroadcastingDataToTwitch: null,
    isProcessingBroadcastingDataToTwitch: null,
    localScreenShotStatus: false
  },
  mutations: {
    UPDATE_SCREEN_STREAM(state, stream) {
      state.stream = stream
    },
    UPDATE_USER_STREAM(state, stream) {
      state.userStream = stream
    },
    UPDATE_TWITCH_ACCOUNTS(state, accounts) {
      state.twitchAccounts = accounts
    },
    UPDATE_TWITCH_INUSE_ACCOUNT(state, account) {
      state.inuseTwitchAccount = account
    },
    UPDATE_ISPROCCESSING_STATUS(state, status) {
      state.isProcessingScreenshot = status
    },
    UPDATE_BROADCASTING_DATA_TO_TWITCH_STATUS(state, status) {
      state.isBroadcastingDataToTwitch = status
    },
    PROCESSING_STREAM_TO_TWITCH(state, status) {
      state.isProcessingBroadcastingDataToTwitch = status
    },
    UPDATE_REF(state, { stateName, ref }) {
      if (state[stateName]) state[stateName] = ref
    },
    LOCAL_TOGGLE_SCREENSHOT(state, status = false) {
      state.localScreenShotStatus = status
    }
  },
  actions: {
    async toggleScreenshot({ rootGetters }, status) {
      const { orgID, gameID } = rootGetters
      const ref = firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/gameStatus`)

      await ref.update({ screenshot: status })
    },
    async localToggleScreenShot({ commit }, status) {
      console.log(status)
      commit("LOCAL_TOGGLE_SCREENSHOT", status)
    },
    async startScreenshotStream({ state, commit, dispatch, getters }) {
      try {
        if (state.stream) return
        const constrains = {
          audio: audioDefaultConstraintString,
          video: videoDefaultConstraintString
        }
        const mediaStream = await navigator.mediaDevices.getDisplayMedia(
          constrains
        )
        commit("UPDATE_SCREEN_STREAM", mediaStream)
      } catch (e) {
        await dispatch("toggleScreenshot", false)
        console.warn(e.message)
      }
    },
    async stopScreenshotStream({ state, commit }) {
      const stream = state.stream
      if (stream) await stream.getTracks().forEach(track => track.stop())
      commit("UPDATE_SCREEN_STREAM", null)
    },
    async addScreenshot({ rootGetters }, payload) {
      const { url, timestamp, action } = payload
      const { gameHost, orgID, gameID, game } = rootGetters
      const hostID = gameHost.id
      const gameName = game.name || null

      if (!hostID) throw new Error("Can't save Screenshot, Undefined user ID")
      if (!orgID) throw new Error(`Can't save screenshot, orgID is missing`)
      if (!gameID) throw new Error(`Can't save screenshot, gameID is missing`)
      if (!url) throw new Error(`Can't save screenshot, url is missing`)

      const ref = firebase
        .database()
        .ref(`org/${orgID}/games/${gameID}/screenshot`)

      await ref.push({
        url,
        hostID,
        action,
        gameName,
        timestamp
      })
    },
    async captureScreenshot({ dispatch, commit, state, rootGetters }, action) {
      if (!state.stream)
        throw new Error("Can't take screenshot - No stream available")

      commit("UPDATE_ISPROCCESSING_STATUS", true)

      const timestamp = Date.now()
      const video = document.getElementById("screenshotRootVidEl")

      return new Promise((resolve, reject) => {
        const capture = async () => {
          const canvas = document.createElement("canvas")
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight

          const ctx = canvas.getContext("2d")
          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)

          const file = await canvasToJpg(canvas)
          const fileName = `screenshots/${action}-${uniqid()}.jpg`

          video.removeEventListener("loadedmetadata", capture)

          try {
            const url = await uploadMedia({
              fileName,
              blob: file,
              token: rootGetters["auth/token"]
            })

            await dispatch("addScreenshot", {
              url,
              timestamp,
              action
            })
            console.log("Saved screenshot: ", url)
            resolve(url)
          } catch (e) {
            console.log("Error uploading screenshot", e.message)
            reject(e.message)
          }

          commit("UPDATE_ISPROCCESSING_STATUS", false)
        }

        if (!video.readyState) {
          video.addEventListener("loadedmetadata", capture)
        } else {
          capture()
        }
      })
    },
    /**
     *
     * @param {*} twitchAccount - Object {apiKey, url, name, key}
     * The `key` is the firebase ID of the twitch account. The key is used as a reference to
     * update the twitch account if needs arise. If the key is -1,
     * then no firebase references should be carried out.
     * @returns void
     */
    async streamToTwitch(
      { state, getters, commit, dispatch, rootState, rootGetters },
      twitchAccount
    ) {
      const { orgID, gameID } = rootState
      if (!state.stream)
        throw new Error("Can't broadcast stream: stream not enabled")

      commit("PROCESSING_STREAM_TO_TWITCH", true)

      state.stream.oninactive = () => {
        try {
          if (getters.getInuseTwitchAccount.key) {
            dispatch("stopTwitchStreaming", getters.getInuseTwitchAccount.key)
          }
        } catch (e) {}
      }

      // check if the user have a twitch account
      // use it and ignore the passed account...
      const user = rootGetters["auth/user"]
      if (
        user &&
        user.twitchAccount &&
        Object.keys(user.twitchAccount || {}).length > 2 && // [name, apiKey, url]
        user.twitchAccount.name &&
        user.twitchAccount.url &&
        user.twitchAccount.apiKey
      ) {
        console.log("using user twitch account...", user.twitchAccount)
        twitchAccount = { ...user.twitchAccount, key: -1 }
      }

      let { apiKey, key } = twitchAccount
      if (!apiKey) {
        const freeTwitchAccounts = getters.getFreeTwitchAccounts

        if (!freeTwitchAccounts.length)
          throw new Error("No free twitch account found")

        twitchAccount = freeTwitchAccounts[0]
        apiKey = twitchAccount.apiKey
        key = twitchAccount.key
      }

      dispatch("updateTwitchAccount", {
        key,
        obj: {
          inuse: true,
          gameDetail: { orgID, gameID }
        }
      })
      commit("UPDATE_TWITCH_INUSE_ACCOUNT", twitchAccount)
      dispatch(
        "Games/updateGameAny",
        {
          theKey: gameID,
          twitchChannel: twitchAccount.name,
          gameType: "Broadcast"
        },
        { root: true }
      )
      dispatch("onDisconnectActions")

      const screenStream = state.stream

      console.log("screenStream", screenStream)

      const micStream = await navigator.mediaDevices.getUserMedia({
        audio: audioDefaultConstraintString
      })

      commit("UPDATE_USER_STREAM", micStream)

      const composedStream = new MediaStream()

      const audioContext = new AudioContext()
      const dest = audioContext.createMediaStreamDestination()

      // add the video stream from the screen
      const [screenVideoTrack] = screenStream.getVideoTracks()
      const [screenAudioTrack] = screenStream.getAudioTracks()

      if (micStream) {
        const audioSource1 = audioContext.createMediaStreamSource(micStream)
        audioSource1.connect(dest)
      }

      // if user does not check the audio box, `screenAudioTrack`
      // would be undefined
      if (screenAudioTrack) {
        const screenAudioStream = new MediaStream()
        screenAudioStream.addTrack(screenAudioTrack)

        const audioSource2 = audioContext.createMediaStreamSource(
          screenAudioStream
        )
        audioSource2.connect(dest)
      }

      const [destAudioTrack] = dest.stream.getAudioTracks()
      if (destAudioTrack) {
        composedStream.addTrack(destAudioTrack)
      }

      console.log("destAudioTrack", destAudioTrack)

      composedStream.addTrack(screenVideoTrack)

      const socket = io(rtmpServer, { query: `rtmp=${twitchRTMP}${apiKey}` })
      socket.on("connect", () => {
        commit("PROCESSING_STREAM_TO_TWITCH", false)
        console.log("WebSocket Open", composedStream)
        commit("UPDATE_BROADCASTING_DATA_TO_TWITCH_STATUS", true)

        if (!mediaRecorder) {
          const recordConfig = {
            mimeType: "video/webm;codecs=h264",
            videoBitsPerSecond: 2 * 1024 * 1024 // 2MB/sec
          }
          mediaRecorder = new MediaRecorder(composedStream, recordConfig)
          mediaRecorder.ondataavailable = event => {
            socket.binary(true).emit("data", event.data)
          }
          mediaRecorder.onstop = () => {
            socket.close()
            commit("UPDATE_BROADCASTING_DATA_TO_TWITCH_STATUS", false)
          }
          mediaRecorder.onerror = () => {
            socket.close()
            commit("UPDATE_BROADCASTING_DATA_TO_TWITCH_STATUS", false)
          }
          mediaRecorder.start(2000) // start recording and dump data every 2 sec.
        }
      })

      socket.on("disconnect", e => {
        console.log("WebSocket Close", e)
        if (["ping timeout", "transport close"].includes(e)) return
        socket.off()
        commit("PROCESSING_STREAM_TO_TWITCH", false)
        commit("UPDATE_BROADCASTING_DATA_TO_TWITCH_STATUS", false)
        try {
          dispatch("stopTwitchStreaming", key)
        } catch (e) {
          console.warn(e.message)
        }
      })
    },
    stopTwitchStreaming(
      { commit, state, dispatch, rootState },
      firebaseTwitchKey
    ) {
      const { gameID } = rootState
      if (!firebaseTwitchKey) {
        try {
          firebaseTwitchKey = state.inuseTwitchAccount.key
        } catch (e) {}
      }

      commit("PROCESSING_STREAM_TO_TWITCH", true)
      try {
        state.userStream.getTracks().forEach(track => track.stop())
        mediaRecorder && mediaRecorder.stop()
        mediaRecorder = null
      } catch (e) {
        console.warn(e.message)
      }
      commit("UPDATE_BROADCASTING_DATA_TO_TWITCH_STATUS", false)
      commit("UPDATE_USER_STREAM", null)
      commit("PROCESSING_STREAM_TO_TWITCH", false)

      if (firebaseTwitchKey && firebaseTwitchKey !== -1) {
        dispatch("updateTwitchAccount", {
          key: firebaseTwitchKey,
          obj: { inuse: false, gameDetail: {}, timestamp: null }
        })
      }
      dispatch(
        "Games/updateGameAny",
        {
          theKey: gameID,
          gameType: "Standard"
        },
        { root: true }
      )
      dispatch("cancelOnDisconnectActions")
    },
    async subscribeToTwitchAccounts({ commit, dispatch, rootState }) {
      const orgID = rootState.orgID
      await dispatch("unsubscribeToTwitchAccounts")
      twitchSubscriptionRef = firebase
        .database()
        .ref(`orgs/${orgID}/twitchAccounts`)
      return new Promise(resolve => {
        twitchSubscription = twitchSubscriptionRef.on("value", snapshot => {
          commit("UPDATE_TWITCH_ACCOUNTS", snapshot.val())
          resolve()
        })
      })
    },
    unsubscribeToTwitchAccounts({ commit }) {
      if (twitchSubscriptionRef)
        twitchSubscriptionRef.off("value", twitchSubscription)
      commit("UPDATE_TWITCH_ACCOUNTS", {})
    },
    addTwitchAccount({ rootState }, twitch) {
      if (!twitch) throw new Error("Twitch data is empty")
      const orgID = rootState.orgID
      firebase
        .database()
        .ref(`orgs/${orgID}/twitchAccounts`)
        .push(twitch)
    },
    async updateTwitchAccount({ rootState }, payload) {
      const { key, obj } = payload
      if (key === -1) return
      if (!key || !obj) throw new Error("Twitch data is empty")
      await firebase
        .database()
        .ref(`orgs/${rootState.orgID}/twitchAccounts/${payload.key}`)
        .update(payload.obj)
    },
    async removeTwitchAccount({ rootState }, key) {
      if (!key) throw new Error("Firebase twitch key is required")
      const orgID = rootState.orgID
      await firebase
        .database()
        .ref(`orgs/${orgID}/twitchAccounts/${key}`)
        .remove()
    },
    onDisconnectActions({ state, rootState, commit }) {
      const { orgID, gameID } = rootState
      const { key } = state.inuseTwitchAccount

      if (key && key !== -1) {
        const twitchAccountRef = firebase
          .database()
          .ref(`orgs/${orgID}/twitchAccounts/${key}`)
        twitchAccountRef.onDisconnect().update({ inuse: false, gameDetail: {} })
        commit("UPDATE_REF", {
          stateName: "twitchAccountRef",
          ref: twitchAccountRef
        })
      }

      const currentGameRef = firebase
        .database()
        .ref(`org/${orgID}/games/${gameID}`)
      commit("UPDATE_REF", {
        stateName: "currentGameRef",
        ref: currentGameRef
      })
      currentGameRef.onDisconnect().update({ gameType: "Standard" })
    },
    cancelOnDisconnectActions({ state }) {
      state.twitchAccountRef && state.twitchAccountRef.onDisconnect().cancel()
      state.currentGameRef && state.currentGameRef.onDisconnect().cancel()
    }
  },
  getters: {
    getScreenshotStream: state => state.stream,
    getScreenshotStatus: (_, __, ___, rootGetters) =>
      rootGetters["gameStatus"]["screenshot"] || false,
    getIsProcessingScreenshot: state => state.isProcessingScreenshot,
    getIsBroadcastingDataToTwitch: state => state.isBroadcastingDataToTwitch,
    getTwitchAccounts: state => {
      try {
        return Object.keys(state.twitchAccounts || {}).map(key => {
          return {
            key,
            ...state.twitchAccounts[key]
          }
        })
      } catch (e) {
        console.warn(e.message)
        return []
      }
    },
    getFreeTwitchAccounts: (_, getters) =>
      getters.getTwitchAccounts.filter(account => !account.inuse),
    getIsProcessingBroadcastingDataToTwitch: state =>
      state.isProcessingBroadcastingDataToTwitch,
    getInuseTwitchAccount: state => state.inuseTwitchAccount,
    getBroadcastTimestampTimeIntervalID: state =>
      state.broadcastTimestampTimeIntervalID,
    getLocalScreenshotStatus: state => state.localScreenShotStatus
  }
}

export default ScreenshotModule
