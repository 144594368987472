var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "main-grid",
      class: { "multi-team": _vm.isMultiTeam },
      attrs: { "pa-0": "", fluid: "", "fill-height": "" }
    },
    [
      _c(
        "v-layout",
        {
          class: { "game-over": _vm.isGameOver || _vm.isFactMatchResult },
          attrs: { column: "" }
        },
        [
          _c(
            "v-layout",
            {
              attrs: {
                "align-center": "",
                shrink: "",
                "justify-center": "",
                row: ""
              }
            },
            [
              _c(
                "Header",
                {
                  attrs: { headline: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "potato",
                        fn: function() {
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-switch",
                                          _vm._g(
                                            {
                                              staticClass: "potato-toggle",
                                              model: {
                                                value: _vm.userPotato,
                                                callback: function($$v) {
                                                  _vm.userPotato = $$v
                                                },
                                                expression: "userPotato"
                                              }
                                            },
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ])
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.potatoToggleLabel))
                                ])
                              ]
                            )
                          ]
                        },
                        proxy: true
                      },
                      _vm.isSpeechToTextEnabled
                        ? {
                            key: "speechToText",
                            fn: function() {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "speech-toggle"
                                                },
                                                [
                                                  _c(
                                                    "v-switch",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "potato-toggle",
                                                        model: {
                                                          value:
                                                            _vm.toggleUserSpeechToText,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.toggleUserSpeechToText = $$v
                                                          },
                                                          expression:
                                                            "toggleUserSpeechToText"
                                                        }
                                                      },
                                                      on
                                                    )
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "speech-cc"
                                                    },
                                                    [_vm._v("CC")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      4119718461
                                    )
                                  },
                                  [_c("span", [_vm._v(" Speech to text ")])]
                                )
                              ]
                            },
                            proxy: true
                          }
                        : null,
                      {
                        key: "wrap",
                        fn: function() {
                          return [
                            _c("GameCountdown", {
                              ref: "countdown",
                              staticClass: "game-countdown",
                              attrs: { endTime: _vm.endTime }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  )
                },
                [
                  _c(
                    "v-layout",
                    [
                      _c("v-layout", {
                        attrs: { column: "", "header-column": "" }
                      }),
                      _c(
                        "v-layout",
                        {
                          staticClass: "text-xs-center",
                          attrs: { grow: "", column: "", "header-column": "" }
                        },
                        [
                          _c(
                            "span",
                            [
                              _c("ScoreTable", {
                                key: "top-team-score-" + _vm.allTeamIDsHash,
                                attrs: { teamIDs: _vm.topScoreTeamIDs },
                                on: { reoderTeam: _vm.handleReoderTeam }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "v-layout",
                        { attrs: { column: "", "header-column": "" } },
                        [
                          _c(
                            "span",
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "" } },
                                [
                                  _c("QuickVolumeTester", {
                                    staticClass: "volume-slider"
                                  }),
                                  _vm.canShowGamesButton
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "logout-btn-wrap",
                                                        on: {
                                                          click:
                                                            _vm.pushToPreGame
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "logout-btn"
                                                            },
                                                            on
                                                          ),
                                                          [_vm._v("GAMES")]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2818033230
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Return to the EXPO")
                                          ])
                                        ]
                                      )
                                    : !_vm.isHost
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "logout-btn-wrap",
                                          on: {
                                            click: function($event) {
                                              return _vm.$router.push("/logout")
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "logout-btn" },
                                            [_vm._v("LOG OUT")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("FullscreenToggle", {
                                    staticClass: "ml-2 self-center",
                                    staticStyle: {
                                      position: "relative",
                                      "z-index": "3"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.shouldShowAnnouncement
            ? _c(
                "v-flex",
                { attrs: { shrink: "", column: "" } },
                [_c("MeetingAnnouncement")],
                1
              )
            : _vm._e(),
          !_vm.isMeetingMode
            ? _c(
                "v-flex",
                {
                  staticClass: "current-team-row",
                  attrs: { shrink: "", "d-flex": "" }
                },
                [
                  [
                    !_vm.isToolBoxOpen && _vm.getIsBroadcastingDataToTwitch
                      ? _c("RecordingIndicator", {
                          staticClass: "game-recording-indicator"
                        })
                      : _vm._e()
                  ],
                  _c(
                    "transition",
                    {
                      attrs: {
                        name: "game-over-current-team-transition",
                        mode: "out-in"
                      }
                    },
                    [
                      _vm.isFactMatchResult && !_vm.gameOverSelectedTeam
                        ? _c(
                            "v-flex",
                            {
                              key: "fact-match-result-text",
                              staticClass: "the-winners-text"
                            },
                            [_vm._v(" FACT MATCH RESULTS ")]
                          )
                        : _vm.isFactMatchResult
                        ? _c("FactMatchResult", {
                            key: "fact-match-results",
                            attrs: { teamID: _vm.gameOverSelectedTeam }
                          })
                        : !_vm.isGameOver && !_vm.isMeetingMode
                        ? _c("Team", {
                            key: "top-team-" + _vm.playerTeamID,
                            ref: "topTeam",
                            attrs: {
                              teamID: _vm.playerTeamID,
                              mode: _vm.currentModeExtended,
                              isTop: true,
                              isPotato: _vm.isPotato
                            }
                          })
                        : _vm.isGameOver && !_vm.gameOverSelectedTeam
                        ? _c(
                            "v-flex",
                            {
                              key: "game-over-text",
                              staticClass: "the-winners-text"
                            },
                            [_vm._v(" AND THE WINNERS ARE... ")]
                          )
                        : _vm.isGameOver && _vm.gameOverSelectedTeam
                        ? _c("Team", {
                            key: "game-over-team-" + _vm.gameOverSelectedTeam,
                            staticClass: "game-over-current-team",
                            attrs: {
                              teamID: _vm.gameOverSelectedTeam,
                              isPotato: _vm.isPotato,
                              mode: null,
                              teamPosition:
                                _vm.getTeamIndexByID(_vm.gameOverSelectedTeam) +
                                1,
                              starPlayerID: null
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("TeamTag", {
                    attrs: { mode: _vm.currentMode, teamId: _vm.playerTeamID }
                  }),
                  _c("GameOverTeamTag", {
                    staticClass: "game-over-team-tag",
                    attrs: {
                      canShow: _vm.isGameOver && !!_vm.gameOverSelectedTeam,
                      total: _vm.getTeamTotalByID(_vm.gameOverSelectedTeam),
                      name: _vm.getTeamNameByID(_vm.gameOverSelectedTeam)
                    }
                  })
                ],
                2
              )
            : _vm._e(),
          _c(
            "v-flex",
            { staticClass: "game-row", attrs: { grow: "" } },
            [
              _c("ModeMapper", { attrs: { mode: _vm.currentMode } }),
              _c(
                "transition",
                {
                  attrs: {
                    name: "game-over-middle-row-transition",
                    mode: "out-in"
                  }
                },
                [
                  _vm.isGameOver || _vm.isFactMatchResult
                    ? _c(
                        "v-flex",
                        { staticClass: "game-over-mode-wrap" },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "game-over-mode",
                              attrs: {
                                "fill-height": "",
                                row: "",
                                "justify-center": ""
                              }
                            },
                            _vm._l(_vm.teamsSortedByTotalScore, function(
                              team,
                              index
                            ) {
                              return _c(
                                "v-flex",
                                {
                                  key: "game-over-result-" + index,
                                  staticClass: "game-over-team-card-wrap",
                                  attrs: { xs4: "", "d-flex": "" }
                                },
                                [
                                  _vm.isFactMatchResult
                                    ? _c("TeamFactMatchCard", {
                                        staticClass: "game-over-team-card",
                                        class: { active: _vm.isHost },
                                        attrs: {
                                          pts: _vm.getFactMatchScore(team.id),
                                          teamName: team.name,
                                          state: team.flippedState,
                                          teamIcon: team.icon
                                        },
                                        nativeOn: {
                                          click: [
                                            function($event) {
                                              if (
                                                $event.ctrlKey ||
                                                $event.shiftKey ||
                                                $event.altKey ||
                                                $event.metaKey
                                              ) {
                                                return null
                                              }
                                              return _vm.onTeamCardClick(team)
                                            },
                                            function($event) {
                                              if (!$event.altKey) {
                                                return null
                                              }
                                              return _vm.onTeamCardClickAlt()
                                            }
                                          ]
                                        }
                                      })
                                    : _c("TeamSpecificsCard", {
                                        staticClass: "game-over-team-card",
                                        class: { active: _vm.isHost },
                                        attrs: {
                                          info: _vm.getTeamSpecificsCardData(
                                            team.id
                                          ),
                                          teamTotal: _vm.getTeamTotalByID(
                                            team.id
                                          ),
                                          teamName: team.name,
                                          position: index + 1,
                                          state: team.flippedState,
                                          teamIcon: team.icon,
                                          numeric: true
                                        },
                                        nativeOn: {
                                          click: [
                                            function($event) {
                                              if (
                                                $event.ctrlKey ||
                                                $event.shiftKey ||
                                                $event.altKey ||
                                                $event.metaKey
                                              ) {
                                                return null
                                              }
                                              return _vm.onTeamCardClick(team)
                                            },
                                            function($event) {
                                              if (!$event.altKey) {
                                                return null
                                              }
                                              return _vm.onTeamCardClickAlt()
                                            }
                                          ]
                                        }
                                      })
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.isSpeechToTextEnabled
            ? _c("Speech", { staticStyle: { position: "inherit" } })
            : _vm._e(),
          _c(
            "transition",
            {
              attrs: { name: "game-over-bottom-row-transition", mode: "out-in" }
            },
            [
              _vm.isMultiTeam
                ? [
                    !_vm.collapseBottomRow
                      ? _c(
                          "v-flex",
                          {
                            staticClass: "two-teams-row",
                            attrs: { shrink: "" }
                          },
                          [
                            _c(
                              "transition",
                              {
                                attrs: {
                                  name: "game-over-bottom-row-transition",
                                  mode: "out-in"
                                }
                              },
                              [
                                !_vm.collapseBottomTeams
                                  ? _c(
                                      "v-layout",
                                      {
                                        staticClass: "bottom-teams-layout",
                                        attrs: { row: "" }
                                      },
                                      _vm._l(_vm.otherTeamIDs, function(
                                        teamID
                                      ) {
                                        return _c(
                                          "v-flex",
                                          {
                                            key: "{multi-team-" + teamID + "}",
                                            staticClass: "multi-team-col",
                                            style: {
                                              width:
                                                100 / _vm.otherTeamIDs.length +
                                                "%"
                                            }
                                          },
                                          [
                                            _c("Team", {
                                              attrs: {
                                                mode: _vm.currentModeExtended,
                                                teamID: teamID,
                                                showOnlyScribe: true,
                                                isPotato: _vm.isPotato
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c("ScoreTable", {
                              key: _vm.allTeamIDsHash,
                              staticClass: "score-table-bottom",
                              attrs: {
                                teamIDs: _vm.otherTeamIDs,
                                showIcon: false
                              },
                              on: { reoderTeam: _vm.handleReoderTeam }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                : [
                    !_vm.collapseBottomRow
                      ? _c(
                          "v-flex",
                          {
                            staticClass: "two-teams-row",
                            attrs: { shrink: "" }
                          },
                          [
                            _vm.getTeamCounts === 3
                              ? _c(
                                  "v-layout",
                                  {
                                    staticStyle: { height: "16px" },
                                    attrs: { row: "" }
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticStyle: { position: "relative" },
                                        attrs: { xs6: "" }
                                      },
                                      [
                                        _c("TeamTag", {
                                          attrs: {
                                            mode: _vm.currentMode,
                                            teamId: _vm.teamSorted[1]
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticStyle: { position: "relative" },
                                        attrs: { xs6: "" }
                                      },
                                      [
                                        _c("TeamTag", {
                                          attrs: {
                                            mode: _vm.currentMode,
                                            teamId: _vm.teamSorted[2]
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.getTeamCounts === 2
                              ? _c(
                                  "v-layout",
                                  {
                                    staticStyle: { height: "16px" },
                                    attrs: { row: "" }
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticStyle: { position: "relative" },
                                        attrs: { xs12: "" }
                                      },
                                      [
                                        _c("TeamTag", {
                                          attrs: {
                                            mode: _vm.currentMode,
                                            teamId: _vm.teamSorted[1]
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "transition",
                              {
                                attrs: {
                                  name: "game-over-bottom-row-transition",
                                  mode: "out-in"
                                }
                              },
                              [
                                !_vm.collapseBottomTeams &&
                                _vm.getTeamCounts === 3
                                  ? _c(
                                      "v-layout",
                                      {
                                        key: "three-teams",
                                        attrs: { row: "" }
                                      },
                                      [
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "team-2-col",
                                            attrs: { xs6: "" }
                                          },
                                          [
                                            _c("Team", {
                                              attrs: {
                                                mode: _vm.currentModeExtended,
                                                teamID: _vm.teamSorted[1],
                                                isPotato: _vm.isPotato
                                              }
                                            }),
                                            _c("div", {
                                              staticClass:
                                                "vertical-line hidden-sm-and-down"
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "team-3-col",
                                            attrs: { xs6: "" }
                                          },
                                          [
                                            _c("Team", {
                                              attrs: {
                                                mode: _vm.currentModeExtended,
                                                teamID: _vm.teamSorted[2],
                                                isPotato: _vm.isPotato
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !_vm.collapseBottomTeams &&
                                _vm.getTeamCounts === 2
                                  ? _c(
                                      "v-layout",
                                      { key: "two-teams", attrs: { row: "" } },
                                      [
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "team-2-col",
                                            attrs: { xs12: "" }
                                          },
                                          [
                                            _c("Team", {
                                              attrs: {
                                                mode: _vm.currentModeExtended,
                                                teamID: _vm.teamSorted[1],
                                                isPotato: _vm.isPotato
                                              }
                                            }),
                                            _c("div", {
                                              staticClass:
                                                "vertical-line hidden-sm-and-down"
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }