var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        "xmlns:dc": "http://purl.org/dc/elements/1.1/",
        "xmlns:cc": "http://creativecommons.org/ns#",
        "xmlns:rdf": "http://www.w3.org/1999/02/22-rdf-syntax-ns#",
        "xmlns:svg": "http://www.w3.org/2000/svg",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd",
        "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape",
        width: "67.961479mm",
        height: "67.961479mm",
        viewBox: "-0.2 0 67.929117 67.961479",
        version: "1.1",
        id: "svg8",
        "inkscape:version": "0.92.4 (5da689c313, 2019-01-14)",
        "sodipodi:docname": "icon-strike.svg"
      }
    },
    [
      _c("defs", { attrs: { id: "defs2" } }),
      _c("sodipodi:namedview", {
        attrs: {
          id: "base",
          pagecolor: "#ffffff",
          bordercolor: "#666666",
          borderopacity: "1.0",
          "inkscape:pageopacity": "0.0",
          "inkscape:pageshadow": "2",
          "inkscape:zoom": "1.979899",
          "inkscape:cx": "89.88123",
          "inkscape:cy": "162.89785",
          "inkscape:document-units": "mm",
          "inkscape:current-layer": "layer1",
          showgrid: "false",
          "viewbox-x": "-0.2",
          "fit-margin-top": "0",
          "fit-margin-left": "0",
          "fit-margin-right": "0",
          "fit-margin-bottom": "0",
          "inkscape:window-width": "1920",
          "inkscape:window-height": "1001",
          "inkscape:window-x": "-9",
          "inkscape:window-y": "-9",
          "inkscape:window-maximized": "1"
        }
      }),
      _c(
        "metadata",
        { attrs: { id: "metadata5" } },
        [
          _c(
            "rdf:RDF",
            [
              _c(
                "cc:Work",
                { attrs: { "rdf:about": "" } },
                [
                  _c("dc:format", [_vm._v("image/svg+xml")]),
                  _c("dc:type", {
                    attrs: {
                      "rdf:resource": "http://purl.org/dc/dcmitype/StillImage"
                    }
                  }),
                  _c("dc:title")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "g",
        {
          attrs: {
            "inkscape:label": "Layer 1",
            "inkscape:groupmode": "layer",
            id: "layer1",
            transform: "translate(-8.2868439,-10.139323)"
          }
        },
        [
          _c("path", {
            staticStyle: { "stroke-width": "0.26458332" },
            style: { fill: _vm.color },
            attrs: {
              d:
                "m 63.221086,17.571377 a 34.017858,34.017858 0 0 0 -42.424184,-0.01718 l 21.22068,21.22068 z",
              id: "path3783",
              "inkscape:connector-curvature": "0"
            }
          }),
          _c("path", {
            staticStyle: { "stroke-width": "0.26458332" },
            style: { fill: _vm.color },
            attrs: {
              d:
                "m 68.566267,65.323566 a 34.017858,34.017858 0 0 0 0.01718,-42.424182 L 47.362764,44.120062 Z",
              id: "path3781",
              "inkscape:connector-curvature": "0"
            }
          }),
          _c("path", {
            staticStyle: { "stroke-width": "0.26458332" },
            style: { fill: _vm.color },
            attrs: {
              d:
                "m 20.814077,70.668749 a 34.017858,34.017858 0 0 0 42.424184,0.01717 L 42.017582,49.465244 Z",
              id: "path3779",
              "inkscape:connector-curvature": "0"
            }
          }),
          _c("path", {
            staticStyle: { "stroke-width": "0.26458332" },
            style: { fill: _vm.color },
            attrs: {
              d:
                "m 15.468896,22.916557 a 34.017858,34.017858 0 0 0 -0.01718,42.424185 l 21.22068,-21.22068 z",
              id: "path3777",
              "inkscape:connector-curvature": "0"
            }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }