var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "social-submode-transition", mode: "out-in" } },
    [
      _vm.isFactMatch && _vm.isOtherTeamFactsOkay
        ? _c("FactMatch")
        : _vm.isFactMatch && !_vm.isOtherTeamFactsOkay
        ? _c(
            "v-flex",
            {
              staticStyle: { color: "#fff" },
              attrs: {
                "d-flex": "",
                "fill-height": "",
                "justify-center": "",
                "align-center": ""
              }
            },
            [
              _vm._v(
                " Sorry, there is not enough data to play a Fact Match mission. "
              )
            ]
          )
        : _c("SocialTeamUsers")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }