<template>
  <v-container
    pa-0
    fluid
    fill-height
    class="main-grid"
    :class="{ 'multi-team': isMultiTeam }"
  >
    <v-layout column :class="{ 'game-over': isGameOver || isFactMatchResult }">
      <v-layout align-center shrink justify-center row>
        <Header headline="">
          <v-layout>
            <v-layout column header-column> </v-layout>
            <v-layout grow column header-column class="text-xs-center">
              <span>
                <ScoreTable
                  :key="`top-team-score-${allTeamIDsHash}`"
                  :teamIDs="topScoreTeamIDs"
                  @reoderTeam="handleReoderTeam"
                />
              </span>
            </v-layout>
            <v-layout column header-column>
              <span>
                <v-layout row>
                  <!-- {{ Date.now() }} -->
                  <QuickVolumeTester class="volume-slider" />
                  <v-tooltip v-if="canShowGamesButton" bottom>
                    <template #activator="{ on }">
                      <div class="logout-btn-wrap" @click="pushToPreGame">
                        <div class="logout-btn" v-on="on">GAMES</div>
                      </div>
                    </template>
                    <span>Return to the EXPO</span>
                  </v-tooltip>
                  <div
                    v-else-if="!isHost"
                    class="logout-btn-wrap"
                    @click="$router.push('/logout')"
                  >
                    <div class="logout-btn">LOG OUT</div>
                  </div>
                  <!-- TODO(Andrew): remove inline style -->
                  <FullscreenToggle
                    class="ml-2 self-center"
                    style="position: relative; z-index: 3"
                  />
                </v-layout>
              </span>
            </v-layout>
          </v-layout>
          <template v-slot:potato>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-switch
                  v-on="on"
                  v-model="userPotato"
                  class="potato-toggle"
                />
              </template>
              <span>{{ potatoToggleLabel }}</span>
            </v-tooltip>
          </template>
          <template v-slot:speechToText v-if="isSpeechToTextEnabled">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div class="speech-toggle">
                  <v-switch
                    v-on="on"
                    v-model="toggleUserSpeechToText"
                    class="potato-toggle"
                  />
                  <div class="speech-cc">CC</div>
                </div>
              </template>
              <span> Speech to text </span>
            </v-tooltip>
          </template>
          <template v-slot:wrap>
            <GameCountdown
              :endTime="endTime"
              ref="countdown"
              class="game-countdown"
            />
          </template>
        </Header>
      </v-layout>

      <!-- Meeting Announcement -->
      <v-flex shrink column v-if="shouldShowAnnouncement">
        <MeetingAnnouncement />
      </v-flex>

      <!-- Current team -->
      <v-flex shrink d-flex class="current-team-row" v-if="!isMeetingMode">
        <template>
          <RecordingIndicator
            class="game-recording-indicator"
            v-if="!isToolBoxOpen && getIsBroadcastingDataToTwitch"
          />
        </template>
        <transition name="game-over-current-team-transition" mode="out-in">
          <v-flex
            v-if="isFactMatchResult && !gameOverSelectedTeam"
            :key="'fact-match-result-text'"
            class="the-winners-text"
          >
            FACT MATCH RESULTS
          </v-flex>

          <FactMatchResult
            v-else-if="isFactMatchResult"
            :key="'fact-match-results'"
            :teamID="gameOverSelectedTeam"
          />

          <Team
            v-else-if="!isGameOver && !isMeetingMode"
            :teamID="playerTeamID"
            :mode="currentModeExtended"
            :isTop="true"
            :key="`top-team-${playerTeamID}`"
            :isPotato="isPotato"
            ref="topTeam"
          />

          <v-flex
            v-else-if="isGameOver && !gameOverSelectedTeam"
            :key="`game-over-text`"
            class="the-winners-text"
          >
            AND THE WINNERS ARE...
          </v-flex>

          <Team
            v-else-if="isGameOver && gameOverSelectedTeam"
            :key="`game-over-team-${gameOverSelectedTeam}`"
            :teamID="gameOverSelectedTeam"
            :isPotato="isPotato"
            :mode="null"
            :teamPosition="getTeamIndexByID(gameOverSelectedTeam) + 1"
            :starPlayerID="null"
            class="game-over-current-team"
          />
        </transition>

        <TeamTag :mode="currentMode" :teamId="playerTeamID" />

        <GameOverTeamTag
          class="game-over-team-tag"
          :canShow="isGameOver && !!gameOverSelectedTeam"
          :total="getTeamTotalByID(gameOverSelectedTeam)"
          :name="getTeamNameByID(gameOverSelectedTeam)"
        />
      </v-flex>

      <!-- The middle row -->
      <v-flex grow class="game-row">
        <ModeMapper :mode="currentMode" />
        <transition name="game-over-middle-row-transition" mode="out-in">
          <!-- Team cards -->
          <v-flex
            class="game-over-mode-wrap"
            v-if="isGameOver || isFactMatchResult"
          >
            <v-layout fill-height row justify-center class="game-over-mode">
              <v-flex
                xs4
                d-flex
                class="game-over-team-card-wrap"
                v-for="(team, index) in teamsSortedByTotalScore"
                :key="'game-over-result-' + index"
              >
                <TeamFactMatchCard
                  v-if="isFactMatchResult"
                  :pts="getFactMatchScore(team.id)"
                  :teamName="team.name"
                  :state="team.flippedState"
                  :teamIcon="team.icon"
                  :class="{ active: isHost }"
                  class="game-over-team-card"
                  @click.exact.native="onTeamCardClick(team)"
                  @click.alt.native="onTeamCardClickAlt()"
                />
                <TeamSpecificsCard
                  v-else
                  :info="getTeamSpecificsCardData(team.id)"
                  :teamTotal="getTeamTotalByID(team.id)"
                  :teamName="team.name"
                  :position="index + 1"
                  :state="team.flippedState"
                  :teamIcon="team.icon"
                  :numeric="true"
                  :class="{ active: isHost }"
                  class="game-over-team-card"
                  @click.exact.native="onTeamCardClick(team)"
                  @click.alt.native="onTeamCardClickAlt()"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </transition>
      </v-flex>
      <Speech style="position: inherit" v-if="isSpeechToTextEnabled" />
      <transition name="game-over-bottom-row-transition" mode="out-in">
        <!-- Multi Team Start -->
        <template v-if="isMultiTeam">
          <v-flex shrink class="two-teams-row" v-if="!collapseBottomRow">
            <transition name="game-over-bottom-row-transition" mode="out-in">
              <v-layout
                row
                class="bottom-teams-layout"
                v-if="!collapseBottomTeams"
              >
                <v-flex
                  v-for="teamID in otherTeamIDs"
                  :key="`{multi-team-${teamID}}`"
                  class="multi-team-col"
                  :style="{ width: 100 / otherTeamIDs.length + '%' }"
                >
                  <Team
                    :mode="currentModeExtended"
                    :teamID="teamID"
                    :showOnlyScribe="true"
                    :isPotato="isPotato"
                  />
                </v-flex>
              </v-layout>
            </transition>
            <ScoreTable
              :key="allTeamIDsHash"
              :teamIDs="otherTeamIDs"
              :showIcon="false"
              class="score-table-bottom"
              @reoderTeam="handleReoderTeam"
            />
          </v-flex>
        </template>
        <!--  -->
        <template v-else>
          <v-flex shrink class="two-teams-row" v-if="!collapseBottomRow">
            <!-- Three teams -->
            <v-layout row style="height: 16px" v-if="getTeamCounts === 3">
              <v-flex xs6 style="position: relative">
                <TeamTag :mode="currentMode" :teamId="teamSorted[1]" />
              </v-flex>
              <v-flex xs6 style="position: relative">
                <TeamTag :mode="currentMode" :teamId="teamSorted[2]" />
              </v-flex>
            </v-layout>
            <!--  -->
            <!-- Two teams -->
            <v-layout row style="height: 16px" v-if="getTeamCounts === 2">
              <v-flex xs12 style="position: relative">
                <TeamTag :mode="currentMode" :teamId="teamSorted[1]" />
              </v-flex>
            </v-layout>
            <!--  -->
            <transition name="game-over-bottom-row-transition" mode="out-in">
              <!-- Three teams -->
              <v-layout
                row
                :key="'three-teams'"
                v-if="!collapseBottomTeams && getTeamCounts === 3"
              >
                <v-flex xs6 class="team-2-col">
                  <Team
                    :mode="currentModeExtended"
                    :teamID="teamSorted[1]"
                    :isPotato="isPotato"
                  />
                  <div class="vertical-line hidden-sm-and-down"></div>
                </v-flex>
                <v-flex xs6 class="team-3-col">
                  <Team
                    :mode="currentModeExtended"
                    :teamID="teamSorted[2]"
                    :isPotato="isPotato"
                  />
                </v-flex>
              </v-layout>
              <!--  -->
              <!-- Two teams -->
              <v-layout
                row
                :key="'two-teams'"
                v-if="!collapseBottomTeams && getTeamCounts === 2"
              >
                <v-flex xs12 class="team-2-col">
                  <Team
                    :mode="currentModeExtended"
                    :teamID="teamSorted[1]"
                    :isPotato="isPotato"
                  />
                  <div class="vertical-line hidden-sm-and-down"></div>
                </v-flex>
              </v-layout>
              <!--  -->
            </transition>
          </v-flex>
        </template>
      </transition>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import { GameEvent } from "@/event"
// import { GameMission } from "@/entities/GameMission"
import { GameMode } from "@/entities/GameMode"

import { Team as TeamEntity } from "@/entities/Team"
import { TeamFlippedState } from "@/entities/TeamFlippedState"

import GameOverTeamTag from "@/components/GroupTeams/Common/GameOverTeamTag"
import GameCountdown from "@/components/GroupTeams/Common/GameCountdown"
import Team from "@/components/GroupTeams/Common/Team"
import Header from "@/components/GroupTeams/Common/Header"
import ModeMapper from "@/components/GroupTeams/Common/ModeMapper"
import ScoreTable from "@/components/GroupTeams/Common/ScoreTable"
import TeamTag from "@/components/GroupTeams/Common/TeamTag"
import TeamSpecificsCard from "@/components/GroupTeams/Common/TeamSpecificsCard"
import TeamFactMatchCard from "@/components/GroupTeams/Common/TeamFactMatchCard"
import QuickVolumeTester from "@/components/GroupTeams/Misc/QuickVolumeTester"
import FactMatchResult from "@/components/GroupTeams/Common/FactMatchResult"
import RecordingIndicator from "@/components/Recording/RecordingIndicator"
import MeetingAnnouncement from "@/components/GroupTeams/Common/MeetingAnnouncement"
import FullscreenToggle from "@/components/FullscreenToggle.vue"
import Speech from "@/components/GroupTeams/Common/Speech"

const LOCAL_MODE_RESET_MODES = ["results", "welcome", "explain"]
const MAX_N_OF_PLAYERS = 9

export default {
  name: "Main",
  data() {
    return {
      globalTeam: null,
      allTeamFlippedTimeoutID: null,
      numOfUsersThreshold: null,
      isPotato: false,
      potatoToggleLabel: ""
    }
  },
  components: {
    Team,
    ModeMapper,
    ScoreTable,
    TeamTag,
    Header,
    TeamSpecificsCard,
    FactMatchResult,
    TeamFactMatchCard,
    QuickVolumeTester,
    GameOverTeamTag,
    GameCountdown,
    RecordingIndicator,
    MeetingAnnouncement,
    FullscreenToggle,
    Speech
  },
  created() {
    this.potatoMode = this.user.potato ? true : false
    this.$bus.$on(GameEvent.FlipFactMatchCard, this.onFlipFactMatchCard)
    this.numOfUsersThreshold =
      this.game && parseInt(this.game.potato)
        ? parseInt(this.game.potato)
        : MAX_N_OF_PLAYERS
    this.potatoToggleLabel = this.user.potato
      ? "You are in slow mode which offers fewer videos"
      : "Slow mode"
  },
  beforeDestroy() {
    this.$bus.$off(GameEvent.FlipFactMatchCard, this.onFlipFactMatchCard)
  },
  watch: {
    forceSlowMode: {
      handler(value) {
        if (value !== this.isPotato) this.isPotato = value
      },
      immediate: true
    },
    canResetLocalMode(value) {
      if (value && this.votingOverride) this.updateVotingOverride(false)
    },
    currentMissionID() {
      if (this.votingOverride) this.updateVotingOverride(false)
    },
    currentModeExtended(newValue) {
      if (this.$refs.topTeam) {
        if (newValue === "huddle") {
          this.$refs.topTeam.huddle()
        } else {
          this.$refs.topTeam.unhuddle()
        }
      }
    },
    currentMode(newValue) {
      if (newValue == "play" || newValue == "huddle" || newValue == "social") {
        this.$refs.countdown.start()
      }
      if (newValue == "over" || newValue == "results") {
        this.onTeamCardClickAlt()
        this.resetTeamsFlipState()
      } else if (newValue == "welcome" || newValue == "explain") {
        this.$refs.countdown.reset()
      }
    },
    allTeamFlipped(newValue) {
      clearTimeout(this.allTeamFlippedTimeoutID)
      if (!this.getLocalScreenshotStatus) return
      this.allTeamFlippedTimeoutID = setTimeout(() => {
        if (newValue) this.captureScreenshot("GAMEOVER_ALL_TEAM_FLIPPED")
      }, 1000)
    }
  },
  computed: {
    ...mapState("group", ["currentGlobalTeam"]),
    ...mapGetters("group", ["votingOverride", "isMultiTeam"]),
    ...mapGetters(["missionPlayType", "getCurrentMode"]),
    ...mapGetters("auth", [
      "hasPreGame",
      "clientID",
      "client",
      "isHost",
      "isAudit",
      "isSpectator"
    ]),
    ...mapGetters([
      "game",
      "missionPlaysArray",
      "plays",
      "user",
      "missions",
      "gameStatus",
      "isToolBoxOpen"
    ]),
    ...mapGetters({
      currentMission: "getCurrentMission",
      teams: "chats",
      currentTeamID: "teamID",
      currentMissionID: "currentMission",
      onlineUsers: "onlineUsersArray"
    }),
    ...mapGetters("screenshot", [
      "getLocalScreenshotStatus",
      "getIsBroadcastingDataToTwitch"
    ]),
    userPotato: {
      get() {
        return !!this.user.potato
      },
      async set() {
        await this.updateUser({
          userID: this.user.id,
          obj: { potato: !this.user.potato }
        })
        this.$router.go()
      }
    },
    // force slow mode if not game over and if the use is in the slow mode or
    // the max number of player for this game is reached
    forceSlowMode() {
      return (
        this.currentMode !== GameMode.Over &&
        (!!this.user.potato ||
          this.onlineUsers.length > this.numOfUsersThreshold)
      )
    },
    canShowGamesButton() {
      return (
        this.hasPreGame &&
        (this.isHost ||
          this.isAudit ||
          this.isSpectator ||
          (this.isFreeRoam && !this.game.tournamentID))
      )
    },
    gameEndTime() {
      return this.game.endTimestamp || 0
    },
    isFreeRoam() {
      return this.client.restriction == 0 || this.client.restriction == 4
    },
    canResetLocalMode() {
      return (
        LOCAL_MODE_RESET_MODES.includes(this.getCurrentMode) &&
        this.votingOverride
      )
    },
    playerTeamID() {
      return this.teamSorted[0]
    },
    otherTeamIDs() {
      return this.teamSorted.slice(1)
    },
    topScoreTeamIDs() {
      return this.isMultiTeam ? [this.playerTeamID] : this.teamIDs
    },
    allTeamIDsHash() {
      return this.teamSorted.join("-")
    },
    teamIDs() {
      // We need to always preserve an order
      return Object.keys(this.teams || {}).sort()
    },
    isFactMatchResult() {
      return this.missionType === "Fact Match" && this.currentMode === "results"
    },
    isGameOver() {
      return this.currentMode === "over"
    },
    isMeetingMode() {
      return this.currentMode === "meeting"
    },
    collapseBottomRow() {
      return this.isGameOver || this.isFactMatchResult || this.isMeetingMode
    },
    collapseBottomTeams() {
      const { currentModeExtended: mode } = this
      if (this.isMultiTeam) {
        return mode === "huddle" || mode === "social"
      } else {
        return mode === "huddle"
      }
    },
    currentModeExtended() {
      const { currentMode: mode, missionType: type } = this
      if (mode === "results" && type === "Fact Match") {
        return "huddle"
      } else {
        return mode
      }
    },
    teamsSortedByTotalScore() {
      const entries = Object.entries(this.teams)
      const values = entries.map(array => {
        const team = array[1]
        const { totalScore } = team
        const int = parseInt(totalScore)
        const totalScoreInt = isNaN(int) ? 0 : int
        return {
          id: array[0],
          ...team,
          totalScore: totalScoreInt
        }
      })
      // sort by total score (descending)
      values.sort((a, b) => b.totalScore - a.totalScore)
      return values
    },
    gameOverSelectedTeam() {
      return this.gameStatus.flippedTeamID
    },
    missionType() {
      return this.currentMission.behavior
    },
    getTeamCounts() {
      return this.teamIDs.length
    },
    endTime() {
      const int = parseInt(this.gameStatus.endTime) || 0
      const endTime = int - Date.now()
      return endTime > 0 ? endTime : 0
    },
    currentMode() {
      if (this.votingOverride) {
        return "voting"
      } else if (this.gameStatus.media === "social") {
        return "social"
      } else {
        return this.getCurrentMode
      }
    },
    teamSorted() {
      if (this.isHost || this.isAudit || this.isSpectator) {
        return this.teamIDs.sort(a => {
          if (a === this.currentGlobalTeam || a === this.currentTeamID) {
            return -1
          } else {
            return 1
          }
        })
      } else {
        return this.teamIDs.sort(a => {
          if (a === this.currentTeamID) {
            return -1
          } else {
            return 1
          }
        })
      }
    },
    allTeamFlipped() {
      return Object.values(this.teams).every(
        team => team.flippedState === "results"
      )
    },
    shouldShowAnnouncement() {
      return this.isMeetingMode && this.game.meetingAnnouncement
    },
    isSpeechToTextEnabled() {
      return this.game.speechToText || false
    },
    toggleUserSpeechToText: {
      get() {
        return this.user.speechToText || false
      },
      set(value) {
        this.updateUser({ userID: this.user.id, obj: { speechToText: value } })
      }
    }
  },
  methods: {
    onFlipFactMatchCard() {
      // We get the last one team with `flippedState` 'hidden'
      const [team] = this.teamsSortedByTotalScore
        .filter(TeamEntity.createFlippedStatePredicate(TeamFlippedState.Hidden))
        .reverse()
      if (team) {
        this.onTeamCardClick(team)
      } else {
        // If there is no team we just emit the "next" event
        this.$bus.$emit(GameEvent.MoveNext)
      }
    },
    ...mapActions("group", [
      "updateCurrentGlobalTeam",
      "updateCurrentLocalTeam",
      "updateVotingOverride"
    ]),
    ...mapActions([
      "setLowPerformance",
      "updateGameStatusAny",
      "updateTeam",
      "updateUser"
    ]),
    ...mapActions("screenshot", ["captureScreenshot"]),
    async pushToPreGame() {
      await this.$navigator.navigateTo(`/reception/${this.clientID}`)
    },
    isCorrectFactMatch(obj) {
      const teamPlay = this.missionPlaysArray.find(
        ({ teamID }) => teamID === this.gameOverSelectedTeam
      )
      if (!teamPlay) return false
      const { answer } = teamPlay
      if (!answer) return false
      const found = answer.find(({ factUserID }) => factUserID === obj.userID)
      if (!found) return false
      const { result } = found
      return result
    },
    getFactMatchScore(teamID) {
      const teamPlay = this.missionPlaysArray
        .filter(({ missionID }) => missionID === this.currentMissionID)
        .find(play => play.teamID === teamID)
      if (!teamPlay) return 0
      return parseInt(teamPlay.score)
    },
    getTeamSpecificsCardData(teamID) {
      const totalSocial =
        parseInt(this.getTeamTotalByID(teamID)) -
        this.totalTrivia(teamID) -
        this.totalCreatives(teamID)
      console.log("TEEAMS", Object.values(this.teams).length)
      if (Object.values(this.teams).length > 6) {
        return [
          {
            title: "",
            details: 0,
            points: 1210 + " pts" //this.getTeamTotalByID(teamID)
          }
        ]
      } else {
        return [
          {
            title: "Total Pts",
            details: 0,
            points: this.getTeamTotalByID(teamID)
          },
          {
            title: "Creatives",
            details: "",
            points: `<span>` + this.totalCreatives(teamID)
          },
          {
            title: "Trivia",
            details: "",
            points: `<span>` + this.totalTrivia(teamID)
          },
          {
            title: "Social",
            details: "",
            points: `<span>` + totalSocial
          }
        ]
      }
    },
    getTeamByID(teamID) {
      if (!teamID) return null
      if (!Array.isArray(this.teamsSortedByTotalScore)) return null
      return this.teamsSortedByTotalScore.filter(team => team.id === teamID)[0]
    },
    getTeamIndexByID(teamID) {
      if (!teamID) return -1
      if (!Array.isArray(this.teamsSortedByTotalScore)) return -1
      return this.teamsSortedByTotalScore.findIndex(team => team.id === teamID)
    },
    getTeamTotalByID(teamID) {
      const team = this.getTeamByID(teamID)
      if (!team) return 0
      return team.totalScore
    },
    getTeamNameByID(teamID) {
      const team = this.getTeamByID(teamID)
      if (!team) return ""
      if (!team.name) return ""
      return team.name
    },
    teamGamesWon(teamID) {
      if (!this.plays) return 0
      var missions = Object.entries(this.missions)
      var teams = Object.values(this.teams)
      var gamesWon = {}
      gamesWon.total = 0
      gamesWon.score = 0
      for (var m in missions) {
        var missionPlays = this.plays.filter(
          item => item.missionID == missions[m][0]
        )
        if (missionPlays.length > 0) {
          var teamScores = []
          for (var t in teams) {
            var teamPlays = missionPlays.filter(
              item => item.teamID == teams[t].id
            )
            var score = teamPlays.reduce((a, b) => a + parseInt(b.score), 0)
            var obj = {}
            obj.team = teams[t]
            obj.score = score
            teamScores.push(obj)
          }
          this.sortedArray(teamScores, "score")
          if (teamID == teamScores[0].team.id) {
            gamesWon.total++
            gamesWon.score += teamScores[0].score
          }
        }
      }
      return gamesWon.total
    },
    teamGamesWon2(teamID) {
      if (!this.missions) return 0
      if (!this.plays) return 0
      const entries = Object.entries(this.missions)
      console.log("Missions", entries)
      const missions = entries.map(array => ({ ...array[1], id: array[0] }))
      console.log("MAPPED ", missions)

      return missions.reduce((acc, val) => {
        const { id } = val
        const found = this.plays.find(
          play =>
            play.missionID === id &&
            play.teamID === teamID &&
            (play.score > 0 || play.result)
        )
        if (found) {
          return acc + 1
        } else {
          return acc
        }
      }, 0)
    },
    totalCreatives(teamID) {
      var t = 0
      t = t + this.totalScoreType(teamID, "Giphy")
      t = t + this.totalScoreType(teamID, "Photo")
      t = t + this.totalScoreType(teamID, "Take Photo")
      t = t + this.totalScoreType(teamID, "Team Name")
      t = t + this.totalScoreType(teamID, "URL")
      t = t + this.totalScoreType(teamID, "Draw: Each Team")
      t = t + this.totalScoreType(teamID, "Draw: Pictionary")
      return t
    },
    totalTrivia(teamID) {
      var t = 0
      t = t + this.totalScoreType(teamID, "Text")
      t = t + this.totalScoreType(teamID, "Multiple Choice")
      t = t + this.totalScoreType(teamID, "Buzz In")
      t = t + this.totalScoreType(teamID, "Categories")
      return t
    },
    totalSocial(teamID) {
      var t = 0
      t = t + this.totalScoreType(teamID, "Lipdub")
      t = t + this.totalScoreType(teamID, "Player Rating")
      t = t + this.totalScoreType(teamID, "Fact Match")
      t = t + this.totalScoreType(teamID, "Freeform")
      return t
    },
    totalScoreType(teamID, type) {
      if (!this.missions) return 0
      if (!this.plays) return 0
      const entries = Object.entries(this.missions)
      const missions = entries.filter(item => item[1].behavior == type)
      let typeScore = 0
      for (var m in missions) {
        const missionPlays = this.plays.filter(
          item => item.missionID === missions[m][0] && item.teamID === teamID
        )
        if (missionPlays.length > 0) {
          const missionScore = missionPlays.reduce((acc, val) => {
            return acc + (parseInt(val.score) || 0)
          }, 0)
          typeScore = typeScore + missionScore
        }
      }
      return typeScore
    },
    sortedArray(arr, field) {
      return arr.sort((a, b) => b[field] - a[field])
    },
    getBestPlayerPerTeam(teamID) {
      if (!teamID) return null
      if (!this.plays) return null
      const allUsers = this.onlineUsers
      if (!allUsers) return null
      const plays = this.missionPlaysArray
      const users = allUsers.filter(user => user.teamID === teamID)
      const usersPlaysTotal = users.map(user => {
        const usersPlays = plays.filter(
          play => play.userID === user.id && user.show
        )
        const usersTotal = usersPlays.reduce((a, b) => a + parseInt(b.score), 0)
        return {
          ...user,
          usersTotal
        }
      })
      usersPlaysTotal.sort((a, b) => b.usersTotal - a.usersTotal)
      // probably makes sense to enable more than one starred player?
      return usersPlaysTotal[0]
    },
    async onTeamCardClickAlt() {
      if (!this.isHost) return
      await this.updateGameStatusAny({ flippedTeamID: null })
    },
    async onTeamCardClick(team) {
      if (!this.isHost) return
      if (team.flippedState === "results") {
        setTimeout(async () => {
          try {
            await this.captureScreenshot("FACT_MATCH_FLIPPED")
          } catch (e) {
            console.warn("Could not take screenshot: ", e.message)
          }
        }, 1000)
      }
      const id = team.id
      await this.updateGameStatusAny({ flippedTeamID: id })
      const flippedState = team.flippedState === "hidden" ? "results" : "hidden"
      await this.updateTeam({ id, flippedState })
    },
    async resetTeamsFlipState() {
      if (!this.isHost) return
      const teamIDs = Object.keys(this.teams)
      const promises = teamIDs.map(teamID =>
        this.updateTeam({ id: teamID, flippedState: "hidden" })
      )
      await Promise.all(promises)
    },
    handleReoderTeam(team) {
      if (!(this.isHost || this.isAudit || this.isSpectator)) return
      this.globalTeam = team.id
      if (this.isAudit || this.isSpectator) {
        this.updateCurrentLocalTeam(team.id)
      } else {
        console.log("updating current global team")
        this.updateCurrentGlobalTeam(team.id)
      }
    }
  }
}
</script>

<style lang="scss">
.main-grid {
  background-color: $color-tertiary-dark;
  overflow: hidden;
  background-image: url("../../../assets/honeycomb-texture.png");
  background-repeat: repeat;
  background-size: 35px;

  .game-recording-indicator {
    position: fixed;
    top: 30px;
    left: 8px;
    z-index: 999;
  }

  .audio-patch {
    position: fixed;
    top: 0;
    left: 0;
  }

  .v-input__icon--prepend .v-icon {
    color: $color-white;
  }
  .v-input__icon--append .v-icon {
    color: $color-white;
  }
  .v-input__slot {
    color: $color-white;
  }

  .header-column {
    flex: 1;
  }
  .header-column:first-child {
    padding-left: 130px;
    min-width: 250px;
  }
  .header-column:nth-child(2) {
    flex: initial;
  }
  .header-column:last-child {
    padding-right: 96px;
    min-width: 250px;
    align-items: flex-end;
  }

  .score-table-bottom {
    background-color: $primary_accent_color;
    display: flex;
    // margin-top: 4px;
    padding-bottom: 6px;
    padding-top: 2px;
    & > span {
      display: block;
      flex: 1 1 auto;
      margin: 0 !important;
      text-align: center;
      flex-basis: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .orange-text {
      color: $color-white;
    }
    .team-points {
      color: $color-yellow;
    }
  }

  .potato-toggle {
    padding: 0;
    margin: 3px 0 0 10px;
    font-size: 11px;
    .v-input__slot {
      margin: 0;
    }
    .v-messages {
      display: none;
    }
    .v-label {
      color: $color-white;
      font-size: 12px;
    }
    .v-input--switch__track {
      height: 9px;
      top: calc(50% - 5px);
      background: $color-grey-dim;
    }
    .v-input--switch__thumb {
      width: 15px;
      height: 15px;
      top: calc(50% - 8px);
    }
    .v-input--selection-controls__input {
      width: 28px;
    }
    &.v-input--is-dirty {
      .v-input--switch__thumb {
        transform: translate(13px, 0);
      }
      .v-input--selection-controls__ripple {
        transform: translate(13px, 0);
      }
    }
    .v-input--selection-controls__ripple {
      width: 24px;
      height: 25px;
      left: -11px;
      top: calc(50% - 20px);
    }
    .accent--text {
      color: $primary_accent_color !important;
      caret-color: $primary_accent_color !important;
    }
  }

  // current game over team transition
  .game-over-current-team-transition-enter-active,
  .game-over-current-team-transition-leave-active {
    opacity: 1;
    transition: all ease 0.3s;
  }
  .game-over-current-team-transition-enter,
  .game-over-current-team-transition-leave-to {
    opacity: 0;
    transform: translateY(20px);
  }
  // current game over team transition ends

  // middle row transitions
  .game-over-middle-row-transition-enter-active {
    transition: opacity ease 0.5s 0.5s;
    opacity: 1;
  }
  .game-over-middle-row-transition-leave-active {
    transition: opacity ease 0.5s;
    opacity: 1;
  }

  .game-over-middle-row-transition-enter,
  .game-over-middle-row-transition-leave-to {
    opacity: 0;
  }
  // middle row transitions ends

  // the carrot transition
  .team-carrot-specifics-transition-enter-active {
    transition: all ease 0.4s;
  }
  .team-carrot-specifics-transition-leave-active {
    transition: all ease 0.4s;
  }
  .team-carrot-specifics-transition-enter,
  .team-carrot-specifics-transition-leave-to {
    transform: scale(0);
  }
  // the carrot transition ends

  // top row transition
  .game-over-top-team-transition-enter-active,
  .game-over-top-team-transition-leave-active {
    transition: opacity ease 0.5s;
    opacity: 1;
  }

  .game-over-top-team-transition-enter,
  .game-over-top-team-transition-leave-to {
    opacity: 0;
  }
  // top row transition ends

  .multi-team-col {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  // bottom row transitions
  &.multi-team {
    .game-over-bottom-row-transition-enter-active,
    .game-over-bottom-row-transition-leave-active {
      transition: all ease 0.8s;
      max-height: 400px;
      min-height: 18vmin;
      .multi-team-col {
        transform: scale(1);
        opacity: 1;
      }
    }

    .game-over-bottom-row-transition-leave-active {
      .multi-team-col {
        transition: all ease-in 0.3s;
      }
    }

    .game-over-bottom-row-transition-enter-active {
      .multi-team-col {
        transition: all ease-out 0.3s;
      }
    }

    .game-over-bottom-row-transition-enter,
    .game-over-bottom-row-transition-leave-to {
      max-height: 0;
      min-height: 0vmin !important;
      .multi-team-col {
        transform: scale(0.75);
        opacity: 0;
      }
    }
  }

  &:not(.multi-team) {
    .game-over-bottom-row-transition-enter-active,
    .game-over-bottom-row-transition-leave-active {
      transition: max-height ease 0.8s;
      max-height: 400px;
      .team-tag {
        transform: scale(1);
      }
    }

    .game-over-bottom-row-transition-leave-active {
      .team-tag {
        transition: transform ease-in 0.3s;
      }
    }

    .game-over-bottom-row-transition-enter-active {
      .team-tag {
        transition: transform ease-out 0.3s;
      }
    }

    .game-over-bottom-row-transition-enter,
    .game-over-bottom-row-transition-leave-to {
      max-height: 0;
      .team-tag {
        transform: scale(0);
      }
    }
  }

  // bottom row transitions end

  .vertical-line {
    position: absolute;
    right: 0;
    width: 1px;
    height: 80%;
    top: 10%;
    background-color: $color-tertiary-dark;
  }

  .game-row {
    position: relative;
    display: flex;
    padding: {
      top: 20px;
      bottom: 20px;
    }
  }

  .current-team-row {
    flex-direction: column;
    position: relative;
    box-shadow: 0px 3px 5px rgba($color-black, 0.5);
    background-color: $color-secondary-dark;
    padding-bottom: 14px;
    padding-left: 3px;
    padding-right: 3px;
    min-height: 0px;
    transform: translateZ(0);
    transition: min-height ease 0.8s;
    align-items: center;
    .team-tag-wrap {
      top: 100%;
      .team-tag-team,
      .team-strikes,
      .team-strikes-shape,
      .team-tag-team > .team-tag-shape-left {
        box-shadow: 0px 1px 3px rgba($color-black, 0.25);
      }
    }
    .team-tag {
      transition: 0.4s ease;
    }
    .rtb-game-team {
      position: relative;
      min-height: 56px;
      z-index: 3;
    }
    .player-icon {
      margin: 4px 0.75%;
      min-width: 120px;
      max-width: 16vmin;
    }
    .gear-icon {
      bottom: auto;
      top: 100%;
      margin-top: -27px;
      left: 4px;
    }
  }

  .team-2-col,
  .team-3-col {
    // padding-top: 16px;
    padding-bottom: 1px;
    position: relative;
    .disabled .player-name {
      font-size: 16px !important;
    }
    .rtb-game-team {
      & > .layout {
        justify-content: end;
      }
    }
  }

  .game-over {
    .current-team-row {
      min-height: calc(50% - 28px);
      min-height: 45%;
      .team-tag {
        transform: scale(0);
      }
    }
  }

  .two-teams-row {
    transform: translateZ(0);
    box-shadow: 0px -2px 5px rgba($color-black, 0.33);
    background-color: $color-secondary-dark;
    .team-tag-wrap {
      top: 0;
    }
    .team-tag-team,
    .team-strikes,
    .team-strikes-shape,
    .team-tag-team > .team-tag-shape-left {
      box-shadow: 0px 0px 3px rgba($color-black, 0.25);
    }
  }

  &.multi-team {
    .two-teams-row {
      border-top: 2px solid $primary_accent_color;
    }
  }

  .team-tag-wrap {
    position: absolute;
    left: 0;
  }

  .orange-text {
    color: $primary_accent_color;
  }

  .two-teams-row {
    .player-icon {
      margin: 2px 2px;
      min-width: 72px;
      max-width: 16vmin;
    }
  }

  .logout-btn-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    white-space: nowrap;
    .logout-btn {
      $color-white-space: nowrap;
      margin-top: 1px;
      line-height: 12px;
      position: relative;
      color: $color-white;
      font-size: 10px;
      border-radius: 0.75rem;
      padding: 1px 12px 0;
      border: 1px solid $primary_accent_color;
      z-index: 99;
      user-select: none;
      cursor: pointer;
      &:hover {
        border: 1px solid $secondary_accent_color;
      }
    }
  }
  .volume-slider {
    position: relative;
    z-index: 2;
    top: 2px;
    color: $color-white;
    width: 150px;
    box-sizing: content-box;
  }

  .game-main {
    background-color: $color-tertiary-dark;
  }

  .audio {
    color: $color-white;
    background-color: $color-red;
    padding: 2px;
    opacity: 0.9;
    margin-right: 3px;
  }

  .the-winners-text {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 3.8rem;
    line-height: 3.6rem;
    padding: 10px 0;
    color: $primary_accent_color;
  }

  .game-over-current-team {
    margin-bottom: 30px;
    .player-icon {
      max-width: 30vh !important;
    }
    .winner-outline {
      border-radius: 10px !important;
    }
    .player-outline {
      border-radius: 10px !important;
    }
  }

  .game-over-mode-wrap {
    z-index: 1;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .bottom-teams-layout {
    min-height: 18vmin;
  }

  .game-over-mode {
    line-height: 1;
    font-size: 1rem;
    padding-top: 18px;
    max-width: 1200px;
    margin: auto;
    .game-over-team-card-wrap {
      align-items: center;
      position: relative;
      margin: 0 10px;
    }
    .game-over-team-card {
      height: 80%;
      &.active {
        cursor: pointer;
      }
    }
  }

  .game-over-team-tag {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: -1px;
    z-index: 99;
  }

  .game-countdown {
    z-index: 2;
    bottom: 0;
    right: 8px;
    position: absolute;
  }

  .speech-toggle {
    display: flex;
    flex-direction: row;
    .speech-cc {
      font-weight: bolder;
      background-color: black;
      color: white;
      padding: 0px 5px;
      margin: 4px auto auto -6px;
      display: flex;
      border-radius: 3px;
      letter-spacing: 2px;
    }
  }
}
</style>
