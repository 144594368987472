<template>
  <v-flex
    d-flex
    fill-height
    class="social-fact-match"
    ref="root"
    :class="{ compress: isHost }"
  >
    <v-layout column justify-center fill-height>
      <v-flex shrink class="fact-match-player-row">
        <transition-group
          name="social-player-transition"
          tag="div"
          class="social-player-transition-wrap"
        >
          <FactMatchPlayer
            class="fact-match-player-col"
            v-for="player in players"
            :key="`fact-match-player-${player.id}`"
            :userInfo="getUserInfo(player.id)"
            :teamIcon="getTeam(player.id).icon"
            :userID="player.id"
            :imageOnly="photoFacts"
            :imageURL="imageFact(player)"
          />
        </transition-group>
      </v-flex>
      <!-- {{ factData }} -->
      <v-flex shrink :class="{ 'no-transition': target }">
        <draggable
          tag="div"
          class="fact-match-fact-row"
          v-model="facts"
          :disabled="isDragAndDropDisabled"
          v-bind="{ ghostClass: 'ghost-class' }"
          @change="onUpdate()"
        >
          <transition-group
            tag="div"
            name="fact-transition"
            class="flex d-flex justify-center"
          >
            <v-flex
              d-flex
              class="fact-match-fact"
              :class="{
                selected: item.id === target,
                disabled: isDragAndDropDisabled
              }"
              v-for="item in facts"
              :key="item.id"
              @mousedown="onMouseDown(item.id)"
              @mouseup="onMouseDown(null)"
              @dragend="onMouseDown(null)"
            >
              <v-flex v-if="!photoFacts" style="align-self: center">
                {{ item.fact.correct }}
              </v-flex>
              <v-flex v-else style="align-self: center">
                {{ item.fact.firstName }}
              </v-flex>
            </v-flex>
          </transition-group>
        </draggable>
      </v-flex>
      <transition name="collapse-transition" mode="out-in">
        <v-flex shrink v-if="showSubmit">
          <v-btn class="fact-match-submit child" @click="onSubmit()">
            Submit
          </v-btn>
        </v-flex>
      </transition>
    </v-layout>
    <img
      v-show="showCursor"
      ref="cursor"
      :src="require('../../../../../assets/hand-cursor-image.svg')"
      class="cursor-image"
    />
  </v-flex>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"
import draggable from "vuedraggable"
import { TimelineLite, TweenMax, Power2 } from "gsap/TweenMax"
import * as moment from "moment"
const FactMatchPlayer = () =>
  import("@/components/GroupTeams/Common/Player/FactMatchPlayer.vue")

export default {
  name: "FactMatch",
  components: {
    FactMatchPlayer,
    draggable
  },
  data() {
    return {
      root: null,
      cursor: null,
      cursorOffest: {
        x: 0,
        y: 0
      },
      cursorTarget: null,
      showCursor: false,
      facts: [],
      target: null,
      photoFacts: false
    }
  },
  mounted() {
    // initialize local copy
    if (this.otherTeamFacts) {
      this.facts = this.otherTeamFacts.map(obj => ({
        ...obj,
        index: parseInt(obj.index)
      }))
      console.log("FACT", this.facts[0])
      if (this.facts[0].fact.correct.image) this.photoFacts = true
    } else {
      console.log("this.otherTeamFacts is not yet avilable")
    }

    if (this.canDragAndDrpo) {
      this.$nextTick(() => {
        // cursor animation
        this.root = this.$refs.root
        this.cursor = this.$refs.cursor
        const facts = this.root.querySelectorAll(".fact-match-fact")
        if (!facts.length) return null
        this.cursorTarget = facts[0]

        TweenMax.ticker.addEventListener("tick", this.onTick)

        const OFFSET_X = 300
        const OFFSET_Y = 300

        const tl = new TimelineLite({
          paused: true,
          onComplete: () => {
            this.showCursor = false
            TweenMax.ticker.removeEventListener("tick", this.onTick)
          }
        })

        tl.from(
          this.cursorOffest,
          0.8,
          { x: OFFSET_X, y: OFFSET_Y, ease: Power2.easeOut },
          "+=0.5"
        )
          .to(this.cursor, 0.25, { scale: 0.95 }, "+=0.5")
          .set(this.cursorTarget, { className: "+=selected" }, "-=0.15")
          .to(this.cursorOffest, 0.5, { x: 15 }, "+=0.0")
          .to(this.cursorTarget, 0.5, { x: 15 }, "-=0.5")
          .to(this.cursor, 0.25, { scale: 1 }, "+=0.25")
          .set(this.cursorTarget, { className: "-=selected" }, "-=0.15")
          .to(this.cursorTarget, 0.5, { x: 0 }, "-=0.15")
          .to(this.cursorOffest, 0.5, { x: 30 }, "-=0.5")
          .to(
            this.cursorOffest,
            0.75,
            { y: OFFSET_Y, ease: Power2.easeIn },
            "+=0.1"
          )

        this.showCursor = true
        tl.play()
      })
    }
  },
  watch: {
    // watch for the remote and updte the local copy
    otherTeamFacts: {
      handler: function (newValue) {
        //console.log("otherTeamFacts changed")
        // if (oldValue)
        //   console.log(
        //     oldValue.reduce((acc, val) => acc + String(val.index), "")
        //   )
        // if (newValue)
        //   console.log(
        //     newValue.reduce((acc, val) => acc + String(val.index), "")
        //   )
        let array = []
        if (newValue) {
          if (newValue.length) {
            array = newValue.map(obj => ({
              ...obj,
              index: parseInt(obj.index)
            }))
          }
        }
        this.facts = array
        // console.log("FACTS IN FACT MATCH VUE in WATCHER")
        console.log(this.facts)
      },
      deep: true
    },
    currentGlobalTeam: {
      handler() {
        // Take screenshot whenever the globalTeam changes
        if (!this.getLocalScreenshotStatus) return
        setTimeout(async () => {
          try {
            await this.captureScreenshot("FACT_MATCH_GLOBAL_TEAM_CHANGED")
          } catch (e) {
            console.warn("Could not take screenshot: ", e.message)
          }
        }, 2000)
      },
      immediate: true
    }
  },
  computed: {
    ...mapState("group", ["currentGlobalTeam"]),
    ...mapGetters(["onlineUsersArray", "orgID", "gameID", "isScribe"]),
    ...mapGetters("auth", ["isAudit", "isSpectator"]),
    ...mapGetters("GameUsers", ["users"]),
    ...mapGetters("screenshot", ["getLocalScreenshotStatus"]),
    showSubmit() {
      return this.isScribe && !this.isHost && !this.missionCompleted
    },
    canDragAndDrpo() {
      return this.isScribe || this.isHost
    },
    missionCompleted() {
      return this.missionPlaysArray.some(
        ({ score, completed, teamID }) =>
          (parseInt(score) > 0 || completed) && teamID === this.user.teamID
      )
    },
    isDragAndDropDisabled() {
      return this.missionCompleted ? true : !this.canDragAndDrpo
    },
    otherTeamFacts() {
      if (!this.team) return []
      if (!Array.isArray(this.team.otherTeamFacts)) return []
      return this.team.otherTeamFacts
    },
    missionPlaysArray() {
      return this.$store.getters.missionPlaysArray
    },
    players() {
      const facts = this.facts
      const onlineParticipants = this.onlineUsersArray
      if (!facts) return []

      const array = this.facts.map(obj => {
        const { fact } = obj
        const player = onlineParticipants.find(({ id }) => fact.userID === id)
        if (player) {
          return {
            ...obj,
            ...player
          }
        } else {
          return {
            ...obj,
            id: fact.userID,
            index: obj.index,
            image: this.getUserImage(fact.userID)
          }
        }
      })

      // the only purpose of this index is to
      // keep the video/image elements stable
      // in the array
      array.sort((a, b) => a.index - b.index)
      return array
    },
    isHost() {
      return this.user.role === "facilitator"
    },
    teams() {
      return this.$store.getters.chats
    },
    team() {
      if (this.isHost || this.isAudit || this.isSpectator) {
        return this.teams[this.currentGlobalTeam]
      } else {
        return this.teams[this.user.teamID]
      }
    },
    user() {
      return this.$store.getters.user
    },
    currentMissionID() {
      return this.$store.getters.currentMission
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    teamMission() {
      if (
        this.currentMission.playType == "Team: Speed Matters" ||
        this.currentMission.playType == "Team: Speed Does Not Matter" ||
        this.currentMission.playType == undefined
      ) {
        return true
      }
      return false
    },
    speedMatters() {
      if (
        this.currentMission.playType == "Team: Speed Matters" ||
        this.currentMission.playType == "Individual: Speed Matters" ||
        this.currentMission.playType == undefined
      ) {
        return true
      }
      return false
    }
  },
  beforeDestroy() {
    try {
      TweenMax.ticker.removeEventListener("tick", this.onTick)
    } catch (e) {
      console.log(e.message)
    }
  },
  methods: {
    ...mapActions("screenshot", ["captureScreenshot"]),
    imageFact(player) {
      if (this.photoFacts) {
        return player.fact.answer[0].image
      } else {
        return player.image
      }
    },
    onTick() {
      if (this.cursorTarget && this.root) {
        const rootClientRect = this.root.getBoundingClientRect()
        const cursorTargetClientRect = this.cursorTarget.getBoundingClientRect()

        const x =
          cursorTargetClientRect.left +
          this.cursorOffest.x -
          rootClientRect.left
        const y =
          cursorTargetClientRect.top + this.cursorOffest.y - rootClientRect.top

        this.cursor.style.left = x + "px"
        this.cursor.style.top = y + "px"
      }
    },
    getUserImage(userID) {
      if (!this.users) return null
      const { image } = this.users[userID] || {}
      return image
    },
    onMouseDown(factID) {
      this.target = factID
    },
    onTeamUpdate(teamID, facts) {
      const obj = {}
      obj.otherTeamFacts = facts
      obj.id = teamID
      this.$store.dispatch("updateTeam", obj)
    },
    // update current (local) team
    onUpdate() {
      this.onTeamUpdate(this.team.id, this.facts)
    },
    onSubmit() {
      console.log("onSubmit")
      if (this.missionCompleted)
        return console.log("Cannot submit one more time")

      if (this.facts.length !== this.players.length)
        throw new Error("Somethign bad happend")

      const correct = this.facts.filter(
        (obj, idx) => this.players[idx].id === obj.fact.userID
      )
      const incorrect = this.facts.filter(
        (obj, idx) => this.players[idx].id !== obj.fact.userID
      )

      const numOfFacts = this.facts.length
      const num = parseInt(this.currentMission.points)
      var points = isNaN(num) ? 0 : num
      points = points / numOfFacts
      const score = correct.length * points
      const answer = []

      correct.forEach(obj => {
        answer.push({
          name: obj.name,
          factUserID: obj.fact.userID,
          fact: obj.fact,
          result: true
        })
      })

      incorrect.forEach(obj => {
        answer.push({
          name: obj.name,
          factUserID: obj.fact.userID,
          fact: obj.fact,
          result: false
        })
      })

      this.addFactMatchPlay(score, answer)
    },
    getUserInfo(userID) {
      const { firstname, lastname } = this.users[userID] || {}
      return { firstname, lastname }
    },
    getTeam(userID) {
      var teamID = this.getTeamID(userID)
      return (this.teams && this.teams[teamID]) || {}
    },
    addFactMatchPlay(score, answer) {
      var obj = {}
      let teamScore
      const results = answer.filter(obj => obj.result)
      // console.log("USER")
      // console.log(this.user)
      var teamID = this.getTeamID(this.user.id)
      if (this.isHost) teamID = this.currentGlobalTeam
      // console.log("TEAM ID")
      // console.log(teamID)
      var sTime = new Date()
      teamScore = this.teams[teamID].totalScore
      var c = this.currentMission
      obj.behavior = c.behavior
      obj.correct = "Fact Match"
      obj.mission = c.name
      obj.answer = answer
      obj.missionID = this.currentMissionID
      obj.points = c.points
      obj.score = score
      obj.readTime = moment(sTime).format("LLLL")
      obj.result = results.length > 0
      obj.show = true
      obj.completed = true
      obj.teamID = teamID
      obj.time = moment(sTime).unix()
      obj.userID = this.user.id
      obj.username = this.user.name
      obj.firstName = this.user.firstname

      console.log("HERE's THE PLAY WE ARE SETTING")
      console.log(obj)

      this.$store.dispatch("addPlay", obj)
      obj = {}
      obj.userID = this.user.id
      obj.teamID = teamID
      obj.teamScore = teamScore + score
      obj.totalScore = score
      this.$store.dispatch("setScore", obj)
      this.$store.dispatch("setTeamScore", obj)
    },
    getTeamID(userID) {
      if (!userID) return null
      if (!this.users) return null
      const user = this.users[userID]
      if (!user) return null
      return user.teamID
    }
  }
}
</script>

<style lang="scss">
.social-fact-match {
  .collapse-transition-enter-active,
  .collapse-transition-leave-active {
    transition: max-height ease 0.8s;
    max-height: 400px;
    .child {
      opacity: 1;
    }
  }

  .collapse-transition-leave-active {
    .child {
      transition: opacity ease-in 0.3s;
    }
  }

  .collapse-transition-enter-active {
    .child {
      transition: opacity ease-out 0.3s;
    }
  }

  .collapse-transition-enter,
  .collapse-transition-leave-to {
    max-height: 0;
    .child {
      opacity: 0;
    }
  }

  .social-player {
    opacity: 1;
    transform: scale(1);
    transition: all 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.25s;
  }
  .social-player-transition-enter-active {
    transition: all 0.5s ease 0s;
  }
  .social-player-transition-leave-active {
    transition: all 0.5s ease 0.6s;
    .social-player {
      transition: all 0.7s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s;
    }
  }
  .social-player-transition-enter,
  .social-player-transition-leave-to {
    min-width: 0 !important;
    max-width: 0 !important;
    margin: 0 !important;
    .social-player {
      opacity: 0;
      transform: scale(0.3);
    }
  }
  .social-player-transition-wrap {
    display: flex;
    flex-shrink: 1;
    justify-content: center;
  }
  .fact-match-submit {
    background-color: $primary_accent_color !important;
    color: $color-white;
    border-radius: 6px;
    padding: 0px 30px;
    margin: 8px 8px;
    letter-spacing: 0.05em;
    font-size: 1.2em;
    height: 28px;
  }
  .bottom-info-text {
    color: $color-white;
    margin-top: 15px;
    margin-bottom: 8px;
  }
  .fact-match-fact {
    @extend .rtb-border, .rtb-border-radius, .rtb-box-shadow;
    cursor: pointer;
    user-select: none;
    font-size: 16px;
    color: $color-white;
    font-weight: normal;
    width: 27vh;
    max-width: 27vh;
    min-height: 6vh;
    align-content: center;
    &.selected:not(.disabled) {
      background-color: $primary_accent_color;
      border-color: $color-white;
    }
    &:not(:first-child) {
      margin-left: 10px;
    }
    &.disabled {
      pointer-events: none;
    }
  }
  .fact-match-player-col {
    width: 27vh;
    max-width: 27vh;
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
  .compress {
    .fact,
    .fact-match-player-col {
      width: 24vh;
      max-width: 24vh;
    }
  }
  .fact-match-fact-row {
    padding-right: 20px;
    padding-left: 20px;
    display: flex;
    justify-content: center;
  }
  .fact-match-player-row {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 8px;
  }
  .ghost-class {
    opacity: 0;
  }
  .fact-transition-move {
    transition: transform 0.5s;
  }
  .no-transition {
    .fact-transition-move {
      transition: transform 0s;
    }
  }
  .cursor-image {
    width: 80px;
    height: auto;
    position: absolute;
    transform: translateY(12px) translateX(10px) translateZ(0);
    top: 0;
    left: 0;
  }
}
</style>
