import firebase from "firebase/app"
import "firebase/database"

const MutationTypes = {
  SET_LIPDUB_MISSION_LYRICS_INDEX: "SET_LIPDUB_MISSION_LYRICS_INDEX"
}

export const ActionTypes = {
  RESET_LIPDUB_MISSION_LYRICS_INDEX: "RESET_LIPDUB_MISSION_LYRICS_INDEX"
}

const serialize = string => String(string).toLowerCase().trim()

// it's an on demand piece of data
// since only categories need it we can call it as a function
const isMultipleChoiceCompleted = ({
  missionAllAnswers,
  missionCorrectAnswer
}) => {
  if (!Array.isArray(missionCorrectAnswer))
    throw new Error("Categories answer must be an array")
  // return true if all correct asnwers can be found in the user
  // given answers
  return missionCorrectAnswer.every(correct =>
    missionAllAnswers.some(answer => serialize(answer) === correct)
  )
}

let giphySubscription = null
let giphySubscriptionRef = null

let buzzSubscriptionRef = null
let votingSubscriptionRef = null

let playsSubscription = null
let playsSubscriptionRef = null

let lipdubSubscriptionRef = null

const MAX_NUM_OF_MISSION_TRIES = 999999
const TEAM_MISSION_MODE = "team"
const INDIVIDUAL_MISSION_MODE = "individual"
const CATEGORIES_MISSION_TYPE = "Categories"

const PlayModule = {
  state: {
    score: 1,
    totalComplete: 0,
    play: {},
    plays: [],
    votes: {},
    giphies: {},
    buzz: {},
    /**
     * @description Store "Lipdub" missions lyrics index
     * @type {Record<string, number>}
     */
    lipdub: {}
  },
  mutations: {
    SET_PLAYS(state, { plays }) {
      state.plays = Object.entries(plays || {}).map(([id, play]) => ({
        ...play,
        id
      }))
    },
    ADD_MANY_PLAYS(state, { plays }) {
      state.plays = [
        ...state.plays,
        ...Object.entries(plays || {}).map(([id, play]) => ({ ...play, id }))
      ]
    },
    ADD_ONE_PLAY(state, { play, playID }) {
      if (play && playID) {
        const obj = play
        obj.id = playID
        state.plays = [...state.plays, obj]
      }
    },
    UPDATE_ONE_PLAY(state, { playID, play }) {
      if (play && playID) {
        const obj = play
        obj.id = playID
        state.plays = [...state.plays.filter(play => play.id !== playID), obj]
      }
    },
    REMOVE_ONE_PLAY(state, { playID }) {
      if (playID) {
        state.plays = [...state.plays.filter(play => play.id !== playID)]
      }
    },
    setBuzz(state, payload) {
      state.buzz = payload
    },
    setVotes(state, payload) {
      state.votes = payload
    },
    setGiphy(state, payload) {
      state.giphies = payload
    },
    setTeamScore(state, payload) {
      state.team.totalScore = payload
    },
    /**
     * @description Use spread to trigger VDOM recalculation
     * @param {any} state
     * @param {{ missionID: string, index: number }} payload
     */
    [MutationTypes.SET_LIPDUB_MISSION_LYRICS_INDEX](state, payload) {
      state.lipdub = { ...state.lipdub, [payload.missionID]: payload.index }
    }
  },
  getters: {
    score(state) {
      return state.score
    },
    plays(state) {
      return state.plays
    },
    missionPlays(state, { currentMission: missionID }) {
      if (!missionID) return []
      return state.plays.filter(play => play.missionID === missionID)
    },
    missionPlaysArray(state, getters) {
      return getters.missionPlays
    },
    allCorrectMissionPlays(state, { missionPlaysArray }) {
      return missionPlaysArray.filter(({ result }) => result)
    },
    nOfAllCorrectMissionPlays(state, { allCorrectMissionPlays }) {
      return allCorrectMissionPlays.length
    },
    missionTeamPlaysArray(state, getters, rootState) {
      const user = getters.user
      const plays = getters.missionPlaysArray
      const { teamID } = user
      if (rootState.livechat.roomID) {
        return plays.filter(obj => obj.roomID === rootState.livechat.roomID)
      } else {
        return plays.filter(obj => obj.teamID === teamID)
      }
    },
    isAuditor(state, getters, rootState) {
      if (rootState.livechat.roomID) return false
      if (getters.user.rule === "auditor") return true
    },
    missionUserPlaysArray(state, getters) {
      const user = getters.user
      const plays = getters.missionTeamPlaysArray
      const { id: userID } = user
      // make sure the userID match the current user
      return plays.filter(obj => obj.userID === userID)
    },
    missionPlayType(state, getters) {
      const mission = getters.getCurrentMission
      if (!mission) return TEAM_MISSION_MODE
      if (!mission.playType) return TEAM_MISSION_MODE
      const { playType } = mission
      const string = serialize(playType)
      if (string.indexOf(INDIVIDUAL_MISSION_MODE) > -1) {
        return INDIVIDUAL_MISSION_MODE
      } else {
        return TEAM_MISSION_MODE
      }
    },
    isUnlimitedMission(state, { nOfMissionTries }) {
      return nOfMissionTries >= MAX_NUM_OF_MISSION_TRIES
    },
    nOfMissionTries(state, getters) {
      const { getCurrentMission } = getters
      const { numOfTries } = getCurrentMission
      // let's make it always an int
      const max = MAX_NUM_OF_MISSION_TRIES
      const num = numOfTries === "Unlimited" ? max : parseInt(numOfTries)
      return isNaN(num) ? 1 : num
    },
    missionFail(state, getters) {
      const { missionSuccess, missionAnswers, nOfMissionTries } = getters
      // doesn't matter what comes next
      if (missionSuccess) return false
      // if n of answers hits the max n of tries
      return nOfMissionTries <= missionAnswers.length
    },
    missionSuccess(state, { missionSuccessfulPlays }) {
      return missionSuccessfulPlays.length > 0
    },
    missionSuccessfulPlays(state, getters) {
      const {
        missionPlayType,
        missionTeamPlaysArray,
        missionUserPlaysArray
      } = getters
      if (missionPlayType === TEAM_MISSION_MODE) {
        return missionTeamPlaysArray.filter(({ result }) => result)
      } else if (missionPlayType === INDIVIDUAL_MISSION_MODE) {
        return missionUserPlaysArray.filter(({ result }) => result)
      } else {
        return []
      }
    },
    missionCompletedPlays(state, getters) {
      const {
        missionPlayType,
        missionTeamPlaysArray,
        missionUserPlaysArray
      } = getters
      if (missionPlayType === TEAM_MISSION_MODE) {
        return missionTeamPlaysArray.filter(({ completed }) => completed)
      } else if (missionPlayType === INDIVIDUAL_MISSION_MODE) {
        return missionUserPlaysArray.filter(({ completed }) => completed)
      } else {
        return []
      }
    },
    anyTeamMissionCompletedPlays(state, { missionPlaysArray }) {
      return missionPlaysArray.filter(({ completed }) => completed)
    },
    missionCorrectAnswer(state, { getCurrentMission }) {
      const { answer } = getCurrentMission
      if (typeof answer === "string") {
        if (answer.indexOf(";") > -1) {
          const array = answer.split(";")
          return array.map(string => serialize(string))
        } else if (answer.indexOf(",") > -1) {
          const array = answer.split(",")
          return array.map(string => serialize(string))
        } else {
          return serialize(answer)
        }
      } else {
        return answer
      }
    },
    missionAnswers(state, getters) {
      const {
        missionPlayType,
        missionTeamPlaysArray,
        missionUserPlaysArray
      } = getters
      const reducer = (acc, val) => {
        const { answer } = val
        // just in case handle array AND any other type
        if (Array.isArray(answer)) {
          return acc.concat(answer)
        } else {
          acc.push(answer)
          return acc
        }
      }
      // fork it here just for the sake of
      // optimization
      if (missionPlayType === INDIVIDUAL_MISSION_MODE) {
        return missionUserPlaysArray.reduce(reducer, [])
      } else {
        return missionTeamPlaysArray.reduce(reducer, [])
      }
    },
    // join all user given answers into one array
    missionAllAnswers(state, { missionPlaysArray }) {
      const reducer = (acc, val) => {
        const { answer } = val
        // just in case handle array AND any other type
        if (Array.isArray(answer)) {
          return acc.concat(answer)
        } else {
          acc.push(answer)
          return acc
        }
      }
      return missionPlaysArray.reduce(reducer, [])
    },
    nOfIncorrectMissionAnswers(state, getters) {
      const {
        getCurrentMission,
        missionSuccess,
        missionAnswers,
        missionSuccessfulPlays
      } = getters
      const { behavior } = getCurrentMission
      // subtract a correct answer
      if (missionSuccess) {
        if (behavior === CATEGORIES_MISSION_TYPE) {
          missionAnswers.length - missionSuccessfulPlays.length
        } else {
          return missionAnswers.length - 1
        }
      } else {
        return missionAnswers.length
      }
    },
    missionCompleted(state, getters) {
      const {
        getCurrentMission,
        missionFail,
        missionSuccess,
        missionCompletedPlays,
        missionAllAnswers,
        missionCorrectAnswer
      } = getters
      const { behavior } = getCurrentMission
      // exclusively for categories
      if (behavior === CATEGORIES_MISSION_TYPE) {
        // let's don't compute isMultipleChoiceCompleted
        // but rather call it only for "Categories"
        const obj = { missionCorrectAnswer, missionAllAnswers }
        return missionFail || isMultipleChoiceCompleted(obj)
      } else if (missionCompletedPlays.length) {
        // completed prop is enforing the mission status
        return true
      } else {
        return missionFail || missionSuccess
      }
    },
    play(state) {
      return state.play
    },
    votes(state) {
      return state.votes
    },
    giphies(state) {
      return state.giphies
    },
    buzz(state) {
      return state.buzz
    },
    getLipdubMissionLyricsIndex: state => missionID => {
      const value = state.lipdub[missionID]

      if (value !== undefined) {
        return value
      } else {
        console.warn(
          `There is no lyrics index for "Lipdub" mission with id ${missionID}`
        )
      }
    }
  },
  actions: {
    addPlay({ commit, rootState }, payload) {
      const { orgID, gameID } = rootState
      const play = { ...payload, gameID }
      if (play.id) {
        return firebase
          .database()
          .ref(`org/${orgID}/game/${gameID}/play/${play.id}`)
          .update(play)
      } else {
        return firebase
          .database()
          .ref(`org/${orgID}/game/${gameID}/play`)
          .push(play)
      }
    },
    addGiphy({ commit, rootState: { orgID, gameID } }, payload) {
      if (payload.id) {
        return firebase
          .database()
          .ref(`org/${orgID}/game/${gameID}/giphies/${payload.id}`)
          .update(payload)
      } else {
        return firebase
          .database()
          .ref(`org/${orgID}/game/${gameID}/giphies`)
          .push(payload)
      }
    },
    addVote({ commit, rootState: { orgID, gameID } }, payload) {
      if (payload.id) {
        return firebase
          .database()
          .ref(`org/${orgID}/game/${gameID}/votes/${payload.id}`)
          .update(payload)
      } else {
        return firebase
          .database()
          .ref(`org/${orgID}/game/${gameID}/votes`)
          .push(payload)
      }
    },
    removeBuzz({ commit, rootState: { orgID, gameID } }) {
      return firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/buzz`)
        .remove()
    },
    addBuzz({ rootState: { orgID, gameID } }, payload) {
      return firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/buzz/`)
        .push({
          ...payload,
          timestamp: firebase.database.ServerValue.TIMESTAMP
        })
    },
    setScore({ rootState }, { userID, totalScore }) {
      // return firebase
      //   .database()
      //   .ref(`org/1/users/${userID}`)
      //   .update({ totalScore })
      return null
    },
    purgeClientGameData({ rootState, rootGetters }) {
      const { gameID } = rootState
      const { game } = rootGetters
      const { clientID } = game
      return firebase
        .database()
        .ref(`client/${clientID}/game/${gameID}`)
        .set(null)
    },
    setTeamScore({ rootState: { orgID, gameID } }, payload) {
      const { teamID, teamScore } = payload
      const newScore = parseInt(teamScore)
      if (isNaN(newScore)) return console.error("Score error")
      // update team score
      return firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/teams/${teamID}`)
        .update({ totalScore: newScore })
    },
    deletePlays({ commit, rootState: { orgID, gameID } }, payload) {
      return firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/play`)
        .remove()
    },
    deletePlay({ rootState: { orgID, gameID } }, playID) {
      return firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/play/${playID}`)
        .remove()
    },
    deleteMissionPlays({ rootState: { orgID, gameID }, state }, { missionID }) {
      const update = state.plays
        .filter(play => play.missionID === missionID)
        .reduce((acc, val) => {
          acc[val.id] = null
          return acc
        }, {})
      return firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/play`)
        .update(update)
    },
    async subscribeToPlays({ state, commit, rootState: { orgID, gameID } }) {
      commit("SET_PLAYS", { plays: {} })

      if (playsSubscription) {
        playsSubscription.off("child_added")
        playsSubscription.off("child_changed")
        playsSubscription.off("child_removed")
      }

      playsSubscriptionRef = firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/play`)

      const snapshot = await playsSubscriptionRef.once("value")
      const plays = snapshot.val()

      commit("ADD_MANY_PLAYS", { plays })

      const keys = Object.keys(snapshot.val() || {})
      const firstID = keys[keys.length - 1]

      if (firstID) {
        playsSubscriptionRef
          .orderByKey()
          .startAt(firstID)
          .on("child_added", snapshot => {
            if (snapshot.key === firstID) return
            commit("ADD_ONE_PLAY", {
              play: snapshot.val(),
              playID: snapshot.key
            })
          })
      } else {
        playsSubscriptionRef.on("child_added", snapshot => {
          commit("ADD_ONE_PLAY", {
            play: snapshot.val(),
            playID: snapshot.key
          })
        })
      }

      playsSubscriptionRef.on("child_changed", snapshot => {
        commit("UPDATE_ONE_PLAY", {
          play: snapshot.val(),
          playID: snapshot.key
        })
      })

      playsSubscriptionRef.on("child_removed", snapshot => {
        commit("REMOVE_ONE_PLAY", {
          play: snapshot.val(),
          playID: snapshot.key
        })
      })
    },
    deleteVotes({ commit, rootState }) {
      return firebase
        .database()
        .ref("org/" + rootState.orgID + "/game/" + rootState.gameID + "/votes")
        .remove()
    },
    subscribeToVoting({ commit, rootState: { orgID, gameID } }) {
      if (votingSubscriptionRef) votingSubscriptionRef.off("value")

      votingSubscriptionRef = firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/votes`)

      return new Promise(resolve => {
        votingSubscriptionRef.on("value", snapshot => {
          commit("setVotes", snapshot.val())
          resolve()
        })
      })
    },
    unsubscribeFromPlays() {
      console.log("Unsubscribing from voting collection")
      if (playsSubscriptionRef) playsSubscriptionRef.off()
      playsSubscriptionRef = null
    },
    unsubscribeFromVoting() {
      console.log("Unsubscribing from plays collection")
      if (votingSubscriptionRef) votingSubscriptionRef.off()
      votingSubscriptionRef = null
    },
    deleteGiphy({ commit, rootState }) {
      firebase
        .database()
        .ref(
          "org/" + rootState.orgID + "/game/" + rootState.gameID + "/giphies/"
        )
        .remove()
      commit("setGiphy", null)
    },
    subscribeToGiphy({ commit, rootState: { orgID, gameID } }) {
      if (giphySubscriptionRef)
        giphySubscriptionRef.off("value", giphySubscription)

      giphySubscriptionRef = firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/giphies`)

      return new Promise(resolve => {
        giphySubscription = giphySubscriptionRef.on("value", snapshot => {
          commit("setGiphy", snapshot.val())
          resolve()
        })
      })
    },
    async subscribeToBuzz({ commit, rootState: { orgID, gameID } }) {
      if (buzzSubscriptionRef) buzzSubscriptionRef.off("value")
      buzzSubscriptionRef = firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/buzz`)
      buzzSubscriptionRef.on("value", snapshot =>
        commit("setBuzz", snapshot.val())
      )
    },
    unsubscribeFromBuzz() {
      if (buzzSubscriptionRef) buzzSubscriptionRef.off()
      buzzSubscriptionRef = null
    },
    async subscribeToLipdub({ commit, rootState }, { missionID }) {
      console.log(
        `Subscribing to Lipdub lyrics index for missionID: ${missionID}`
      )

      const ref = firebase
        .database()
        .ref(
          `org/${rootState.orgID}/game/${rootState.gameID}/lipdub/${missionID}/index`
        )

      if (lipdubSubscriptionRef) {
        if (ref.isEqual(lipdubSubscriptionRef) === false) {
          lipdubSubscriptionRef.off()
          ref.on("value", snapshot =>
            commit(MutationTypes.SET_LIPDUB_MISSION_LYRICS_INDEX, {
              missionID,
              index: snapshot.val()
            })
          )
          lipdubSubscriptionRef = ref
        }
      } else {
        ref.on("value", snapshot =>
          commit(MutationTypes.SET_LIPDUB_MISSION_LYRICS_INDEX, {
            missionID,
            index: snapshot.val()
          })
        )
      }

      const snapshot = await ref.once("value")
      commit(MutationTypes.SET_LIPDUB_MISSION_LYRICS_INDEX, {
        missionID,
        index: snapshot.val()
      })
    },
    unsubscribeFromLipdub() {
      console.log("Unsubscribing from Lipdub lyrics index")
      if (lipdubSubscriptionRef) lipdubSubscriptionRef.off()
      lipdubSubscriptionRef = null
    },
    async incrementLipdubIndex({ rootState, getters }) {
      const {
        getCurrentMission: currentMission,
        currentMission: currentMissionID
      } = getters

      const ref = firebase
        .database()
        .ref(
          `org/${rootState.orgID}/game/${rootState.gameID}/lipdub/${currentMissionID}/index`
        )

      const snapshot = await ref.once("value")

      let currentIndex = snapshot.val()

      let value = 0

      if (currentIndex !== null && isNaN(parseInt(currentIndex)) === false) {
        const parsedIndex = parseInt(currentIndex)
        const instructions = currentMission.instructions.split(";")

        // prevent overflow
        if (parsedIndex < instructions.length - 1) {
          value = parsedIndex + 1
        }
      }

      await ref.set(value)
    },
    [ActionTypes.RESET_LIPDUB_MISSION_LYRICS_INDEX]({ rootState, getters }) {
      const { orgID, gameID } = rootState
      const { currentMission: currentMissionID } = getters

      return firebase
        .database()
        .ref(`org/${orgID}/game/${gameID}/lipdub/${currentMissionID}/index`)
        .set(0)
    },
    async purgeLipdub({ rootState, getters }) {
      const ref = firebase
        .database()
        .ref(`org/${rootState.orgID}/game/${rootState.gameID}/lipdub`)

      await ref.set({})
    }
  }
}

export default PlayModule
