var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "main",
      attrs: {
        bg: "",
        "fill-height": "",
        "grid-list-md": "",
        "text-xs-center": "",
        fluid: ""
      }
    },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "align-center": "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c("v-flex", [
                    _c("h3", { staticClass: "waiting" }, [
                      _vm._v("Loading...")
                    ]),
                    _c(
                      "div",
                      [
                        _c("v-progress-circular", {
                          attrs: { indeterminate: "", color: "purple" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        false
                          ? _c("v-btn", { on: { click: _vm.pickteams } }, [
                              _vm._v("Pick Teams")
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }