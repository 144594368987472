import firebase from "firebase/app"
import "firebase/database"
import "firebase/storage"
import _ from "lodash"
import { uploadMedia } from "@/services/storage.service"

let teamIDSubscriptionRef = null
let teamPointsSubscriptionRef = null

const DrawingModule = {
  namespaced: true,
  state: {
    teamID: null,
    points: [],
    lineWidth: 1,
    lineColorRgb: "rgb(0, 0, 0)",
    lineColorRgba: "rgba(0, 0, 0, 1)",
    alpha: 1,
    isPen: true
  },
  mutations: {
    UPDATE_ASSIGNED_TEAM(state, { teamID }) {
      state.teamID = teamID
    },
    UPDATE_TEAM_POINTS(state, { points }) {
      state.points = points
    },
    SET_PEN_STATE(state, { isPen }) {
      state.isPen = isPen
    },
    SET_PEN_RGB_COLOR(state, { rgb }) {
      state.lineColorRgb = rgb
    },
    SET_PEN_RGBA_COLOR(state, { rgba }) {
      state.lineColorRgba = rgba
    },
    SET_PEN_ALPHA(state, { alpha }) {
      state.alpha = alpha
    },
    SET_LINE_WIDTH(state, { lineWidth }) {
      state.lineWidth = lineWidth
    }
  },
  actions: {
    purgeGameDrawings({ rootState: { gameID } }) {
      return firebase
        .database()
        .ref(`drawing/${gameID}`)
        .set(null)
    },
    async uploadImage({ commit, rootState }, { filename, file }) {
      const fileName = `drawing-images/${filename}`
      return await uploadMedia({
        fileName,
        blob: file,
        token: rootState.auth.token
      })
    },
    setLineWidth({ commit }, payload) {
      commit("SET_LINE_WIDTH", payload)
    },
    setPenColor({ commit }, payload) {
      const { r, g, b, a } = payload.color.rgba
      const rgb = `rgb(${r},${g},${b})`
      const rgba = `rgba(${r},${g},${b},${a})`
      const alpha = a
      commit("SET_PEN_RGB_COLOR", { rgb })
      commit("SET_PEN_RGBA_COLOR", { rgba })
      commit("SET_PEN_ALPHA", { alpha })
    },
    setPenState({ commit }, payload) {
      commit("SET_PEN_STATE", payload)
    },
    updateDrawingTeam({ rootState, rootGetters }, { teamID, missionID }) {
      const gameID = rootState.gameID
      const actualMissionID = missionID || rootGetters.currentMission
      return firebase
        .database()
        .ref(`drawing/${gameID}/${actualMissionID}/assigned_team`)
        .set(teamID)
    },
    async pushTeamPath(
      { rootState, rootGetters },
      { teamID, points, lineColor, lineWidth }
    ) {
      const missionID = rootGetters.currentMission
      const gameID = rootState.gameID
      const ref = firebase
        .database()
        .ref(`drawing/${gameID}/${missionID}/team_points/${teamID}`)
      const obj = { points, lineColor, lineWidth }
      await ref.push({ data: JSON.stringify(obj) })
    },
    clearOneTeam({ rootState, rootGetters }, { teamID }) {
      const missionID = rootGetters.currentMission
      const gameID = rootState.gameID
      return firebase
        .database()
        .ref(`drawing/${gameID}/${missionID}/team_points/${teamID}`)
        .set(null)
    },
    clearTeams({ rootState, rootGetters }) {
      const missionID = rootGetters.currentMission
      const gameID = rootState.gameID
      return firebase
        .database()
        .ref(`drawing/${gameID}/${missionID}`)
        .set(null)
    },
    unsubscribeFromAssignedTeam({ commit }) {
      if (teamIDSubscriptionRef) {
        teamIDSubscriptionRef.off("value")
        commit("UPDATE_ASSIGNED_TEAM", { teamID: null })
        teamIDSubscriptionRef = null
      }
    },
    async subscribeToAssignedTeam({ commit, rootState, rootGetters }) {
      if (teamIDSubscriptionRef) {
        teamIDSubscriptionRef.off("value")
        commit("UPDATE_ASSIGNED_TEAM", { teamID: null })
      }

      const missionID = rootGetters.currentMission
      const gameID = rootState.gameID

      teamIDSubscriptionRef = firebase
        .database()
        .ref(`drawing/${gameID}/${missionID}/assigned_team`)

      return new Promise(resolve => {
        teamIDSubscriptionRef.on("value", snapshot => {
          commit("UPDATE_ASSIGNED_TEAM", { teamID: snapshot.val() })
          resolve()
        })
      })
    },
    unsubscribeFromTeamPaths({ commit }) {
      if (teamPointsSubscriptionRef) {
        teamPointsSubscriptionRef.off("value")
        commit("UPDATE_TEAM_POINTS", { points: null })
        teamPointsSubscriptionRef = null
      }
    },
    updateTeamPoints: _.throttle(({ commit }, snapshot) => {
      commit("UPDATE_TEAM_POINTS", { points: snapshot.val() })
    }, 250),
    async subscribeToTeamPaths(
      { dispatch, commit, rootState, rootGetters },
      { teamID }
    ) {
      if (teamPointsSubscriptionRef) {
        teamPointsSubscriptionRef.off("value")
        commit("UPDATE_TEAM_POINTS", { points: null })
      }

      const missionID = rootGetters.currentMission
      const gameID = rootState.gameID
      teamPointsSubscriptionRef = firebase
        .database()
        .ref(`drawing/${gameID}/${missionID}/team_points/${teamID}`)

      return new Promise(resolve => {
        teamPointsSubscriptionRef.on("value", snapshot => {
          dispatch("updateTeamPoints", snapshot)
          resolve()
        })
      })
    }
  }
}

export default DrawingModule
