// @ts-nocheck

import Vue from "vue"
import "./plugins/axios"
import "./plugins/vuetify"
import App from "./App.vue"

import firebase from "firebase/app"
import router from "./router"
import { store } from "./store"
import VueCookies from "vue-cookies"
import AlertComponent from "./components/Shared/Alert.vue"
import VueCountdown from "@chenfengyuan/vue-countdown"
import ImageUploader from "vue-image-upload-resize"
import VuePlyr from "vue-plyr"
import "vue-plyr/dist/vue-plyr.css"
import IdleVue from "idle-vue"
import Media from "@/components/Media"
import { IDLE_TIMEOUT } from "@/config"

import { BaseComponentsPlugin } from "./plugins/base"
import { DevicesPlugin } from "./plugins/devices"
import { VersionPlugin } from "./plugins/version"
import { NavigatorPlugin } from "./plugins/navigator"
import { BusPlugin } from "./plugins/bus"
import { ServicePlugin } from "./plugins/services"
import AddToCalendar from "vue-add-to-calendar"
import "./scss/main.scss"

import { ThemePlugin } from "./modules/theme/plugin"

// enable idle logout only during production
console.log("ENV", process.env.VUE_APP_MODE)
if (process.env.VUE_APP_MODE === "production") {
  Vue.use(IdleVue, {
    eventEmitter: new Vue(),
    idleTime: IDLE_TIMEOUT
  })
}

Vue.use(NavigatorPlugin, { store, router })
Vue.use(AddToCalendar)
Vue.use(BaseComponentsPlugin)
Vue.use(BusPlugin)
Vue.use(DevicesPlugin, { module: "twilio", store })
Vue.use(VersionPlugin)
Vue.use(VueCookies)
Vue.use(ImageUploader)
Vue.use(VuePlyr)
Vue.use(ServicePlugin)
Vue.use(ThemePlugin, { store })

Vue.component(VueCountdown.name, VueCountdown)
Vue.component("app-alert", AlertComponent)
Vue.component("Media", Media)

Vue.config.productionTip = false

new Vue({
  created() {
    firebase.initializeApp({
      apiKey:
        process.env.VUE_APP_FIREBASE_apiKey ||
        "AIzaSyC1B6ZKqESQ_Y0jzZjH44u-xGEwC4n5h4s",
      authDomain:
        process.env.VUE_APP_FIREBASE_authDomain ||
        "chat-remote.firebaseapp.com",
      databaseURL:
        process.env.VUE_APP_FIREBASE_databaseURL ||
        "https://chat-remote.firebaseio.com",
      projectId: process.env.VUE_APP_FIREBASE_projectId || "chat-remote",
      storageBucket:
        process.env.VUE_APP_FIREBASE_storageBucket || "chat-remote.appspot.com"
    })
  },
  /**
   * @see idle-vue
   */
  onIdle() {
    this.$router.push("/logout")
  },
  render: h => h(App),
  router,
  store
}).$mount("#app")
