<template>
  <v-flex d-flex class="signin-carousel">
    <v-flex
      :style="`width: ${100 / slidesPerRow}%`"
      class="carousel-slide"
      v-for="(url, i) in display"
      :key="`${i}-slide`"
    >
      <transition name="image-transition">
        <img class="carousel-image" :key="url" :src="url" alt="" />
      </transition>
    </v-flex>
  </v-flex>
</template>

<script>
export default {
  name: "SigninCarousel",
  data() {
    return {
      available: [],
      display: [],
      interval: null,
      counter: 0
    }
  },
  props: {
    images: {
      type: Array,
      required: true
    },
    slidesPerRow: {
      type: Number,
      default: 3
    },
    delay: {
      type: Number,
      default: 5000
    }
  },
  async created() {
    clearInterval(this.interval)
    if (this.slidesPerRow > this.images.length) {
      throw new Error(
        "The given numer of images sould be greater or equal to the number of slides"
      )
    }
    this._updateAvailable()
  },
  computed: {
    canStart() {
      return this.available.length > this.display.length
    }
  },
  watch: {
    canStart: {
      handler(newValue) {
        if (newValue) {
          clearInterval(this.interval)
          this.interval = setInterval(this.updateDisplay, this.delay)
        } else {
          clearInterval(this.interval)
        }
      },
      immediate: true
    },
    async images() {
      await this._updateAvailable()
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    getImageToDisplay(index) {
      return this.display[index - 1]
    },
    updateDisplay() {
      const url = this.getRandomImage()
      const randIndex = this.getRandomInt(0, this.slidesPerRow - 1)
      const array = [...this.display]
      array[randIndex] = url
      this.display = [...array]
    },
    getRandomImage() {
      const pool = this.available.filter(url => !this.display.includes(url))
      const randomImage = pool[this.getRandomInt(0, pool.length - 1)]
      return randomImage
    },
    getTimeout(delay) {
      return new Promise(resolve => setTimeout(resolve, delay))
    },
    getRandomInt(min, max) {
      const _min = Math.ceil(min)
      const _max = Math.floor(max)
      return Math.floor(Math.random() * (_max - _min + 1)) + _min
    },
    getImageByUrl(url) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        const onLoad = () => {
          img.removeEventListener("load", onLoad)
          img.removeEventListener("error", onError)
          resolve({ img, url })
        }
        const onError = e => {
          img.removeEventListener("load", onLoad)
          img.removeEventListener("error", onError)
          reject(e)
        }
        img.src = url
        img.addEventListener("load", onLoad)
        img.addEventListener("error", onError)
      })
    },
    async _updateAvailable() {
      const promises = this.images.map(url => this.getImageByUrl(url))
      const images = await Promise.all(promises)
      this.available = images.map(({ url }) => url)
      this.display = this.available.slice(0, this.slidesPerRow)
    }
  }
}
</script>

<style lang="scss">
.signin-carousel {
  .image-transition-enter-active,
  .image-transition-leave-active {
    transition: all ease 2s;
    opacity: 1;
  }
  .image-transition-enter,
  .image-transition-leave-to {
    opacity: 0;
  }
  .carousel-slide {
    position: relative;
    border: solid 2px $color-primary-dark;
    margin: 24px 14px;
    margin-left: 0;
    &:first-child {
      margin-left: 10px;
    }
  }
  .carousel-image {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

@media (max-height: 1050px) {
  .signin-carousel .carousel-slide {
    margin: 20px 14px 20px 0;
  }
}

@media (max-height: 1000px) {
  .signin-carousel .carousel-slide {
    margin: 14px 14px 14px 0;
  }
}
</style>
