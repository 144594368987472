import { getApiUrl } from "@/api/function"
import firebase from "firebase/app"
import axios from "axios"

const API_URL = getApiUrl()

const fetchTwilioToken = async ({ token, userID }) => {
  try {
    const url = `${API_URL}token/twilio`
    console.log("API", url)
    const params = { token, identity: userID }
    const config = {
      responseType: "json",
      headers: {
        "content-type": "application/json"
      }
    }
    const { data } = await axios.post(url, params, config)
    const { token: twilioToken } = data
    return twilioToken
  } catch (e) {
    if (e.response) {
      console.log(e.response.data)
      console.log(e.response.status)
      console.log(e.response.headers)
      throw new Error(e.response.data.message)
    } else if (e.request) {
      console.log(e.request)
      throw new Error(e.request)
    } else {
      throw new Error(e.message)
    }
  }
}

const runUserSpreadWorker = clientID => {
  return firebase
    .database()
    .ref(`/client/${clientID}/usersSpreadQueue`)
    .push(true)
}

const getSignedURL = async ({ fileName, token }) => {
  const res = await axios.post(`${API_URL}media/getSignedURL?token=${token}`, {
    fileName
  })
  return res.data.data
}

const upload = async ({ fileName, token, blob, axiosConfig }) => {
  try {
    const result = await axios.post(
      `${API_URL}media/upload/${fileName}?token=${token}`,
      blob,
      {
        ...axiosConfig,
        headers: { "Content-Type": blob.type }
      }
    )
    return result.data.data
  } catch (e) {
    if (e.response) {
      console.log(e.response.data)
      console.log(e.response.status)
      console.log(e.response.headers)
      throw new Error(e.response.data.message)
    } else if (e.request) {
      console.log(e.request)
      throw new Error(e.request)
    } else {
      throw new Error(e.message)
    }
  }
}

const authorizeToClient = async ({ userID, clientID, password }) => {
  const params = {
    userID,
    clientID,
    password
  }
  const { data } = await axios.post(`${API_URL}client/authorize`, params)
  const { message, status } = data
  if (status === "error") {
    throw new Error(message)
  } else {
    return console.log(message)
  }
}

const downloadScreenshot = async ({ orgID, gameID, token }) => {
  const res = await axios.post(
    `${API_URL}media/downloadScreenshot?token=${token}`,
    {
      orgID,
      gameID
    }
  )
  return res.data
}

export {
  fetchTwilioToken,
  getSignedURL,
  authorizeToClient,
  downloadScreenshot,
  upload,
  runUserSpreadWorker
}
