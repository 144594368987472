<template>
  <span>
    <!-- Sound Effects -->
    <audio
      v-for="effect in getGameEffects"
      :key="`effect-${effect.name}`"
      :src="effect.source"
      :id="effect.name"
    />
    <!-- Audios -->
    <audio ref="audio_player" :loop="getAudioLoopStatus" @ended="onEnded" />
  </span>
</template>

<script>
import { EventBus } from "@/Eventbus"
import { mapActions, mapGetters } from "vuex"

const MAX_TIMESTAMP_EFFECT_DIFF = 3000
const MAX_TIMESTAMP_AUDIO_DIFF = 1000 * 60 * 5
export default {
  name: "SoundEffects",
  watch: {
    volume: {
      handler: function(volume = 0) {
        this.$nextTick(() => {
          this.getGameEffects.forEach(({ name }) => {
            const node = document.getElementById(name)
            if (node) {
              node.volume = volume
            } else {
              console.log(`The effect tag ${name} is missing`)
            }
          })
          if (!this.$refs.audio_player)
            return console.log("The audio tag is missing")
          if (!this.audio) return console.log("No audio")
          const { key } = this.audio
          if (!key) return console.log("Got no audio. No key")
          const audio = this.getAllAudios.find(obj => obj.key === key)
          if (volume > 0 && audio) {
            this.$refs.audio_player.volume = this.computeAverageVolume({
              fileVolume: audio.volume,
              volume
            })
          } else {
            this.$refs.audio_player.volume = 0
          }
        })
      },
      immediate: true
    },
    effect(newValue, oldValue) {
      if (newValue && JSON.stringify(newValue) != JSON.stringify(oldValue)) {
        this.playSoundEffect({ key: newValue.key })
      }
    }
  },
  async created() {
    try {
      await this.subscribeToGameAudio()
    } catch (e) {
      console.error(e)
      console.error("Cannot subscribe to the game audio")
    }
    try {
      await this.subscribeToUserAudio()
    } catch (e) {
      console.error(e)
      console.error("Cannot subscribe to the user audio")
    }
    this.$watch(
      "audio",
      (newValue, oldValue) => {
        if (newValue && JSON.stringify(newValue) != JSON.stringify(oldValue)) {
          if (newValue.key && newValue.status === "play") {
            this.playAudio({ key: newValue.key })
          } else {
            this.stopAudio()
          }
        }
      },
      { immediate: this.$route.name === "lobby" ? true : false }
    )
  },
  computed: {
    ...mapGetters("soundeffect", [
      "getAllAudios",
      "getGameEffects",
      "getAudioLoopStatus",
      "volume"
    ]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters(["game"]),
    ...mapGetters({ teams: "chats" }),
    teamID() {
      return this.user.teamID
    },
    audio() {
      return this.game ? this.game.audioTrack : null
    },
    teamEffect() {
      if (!this.teamID) return null
      if (!this.teams) return null
      if (!this.teams[this.teamID]) return null
      return { ...this.teams[this.teamID].soundEffect }
    },
    gameEffect() {
      return this.game ? this.game.soundEffect : null
    },
    effect() {
      if (!this.teamEffect) return this.gameEffect
      if (!this.gameEffect) return this.teamEffect
      if (this.gameEffect.timestamp > this.teamEffect.timestamp) {
        return this.gameEffect
      } else {
        return this.teamEffect
      }
    }
  },
  methods: {
    ...mapActions("soundeffect", [
      "subscribeToGameAudio",
      "subscribeToUserAudio"
    ]),
    stopSoundEffect() {
      this.getGameEffects.forEach(({ name }) => {
        const node = document.getElementById(name)
        if (node) {
          node.pause()
          node.currentTime = 0
        } else {
          console.log(`The effect tag ${name} is missing`)
        }
      })
    },
    playSoundEffect({ key }) {
      const name = key
      if (!name) return console.log("Got no effect to play. No name")
      console.log(`New effect name ${name}`)
      const timestamp = this.effect.timestamp
      const now = Date.now()
      const diff = now - timestamp
      if (diff > MAX_TIMESTAMP_EFFECT_DIFF)
        return console.warn(
          `Cannot play an audio because it started ${diff}ms ago`
        )
      console.log(`Started ${timestamp}`)
      console.log(`Now ${now}`)
      console.log(`Diff ${diff}`)
      // stop any playing effects
      this.getGameEffects.forEach(({ name }) => {
        const node = document.getElementById(name)
        if (!node) return console.log(`The effect tag ${name} is missing`)
        node.pause()
        node.currentTime = 0
      })
      // play the actual requested sound effect
      const node = document.getElementById(name)
      if (!node) return console.log(`The effect tag ${name} is missing`)
      try {
        const promise = node.play()
        if (promise !== undefined) {
          promise
            .then(() => {
              console.log(`Playing effect "${name}"`)
            })
            .catch(error => {
              console.log(error.message)
            })
        }
      } catch (e) {
        console.log(e.message)
      }
    },
    computeAverageVolume({ fileVolume, volume }) {
      const isInRange = (n, min, max) => !isNaN(n) && n <= max && n >= min
      // 0-100
      const int = parseInt(fileVolume)
      const fileVolumeInt = isInRange(int, 0, 100) ? int : 100
      // 0-1
      const float = Number.parseFloat(volume)
      const userVolumeFloat = isInRange(float, 0, 1) ? float : 1
      // turn into 0-100
      const userVolumeInt = userVolumeFloat * 100
      console.log(`User volume int is ${userVolumeInt}`)
      console.log(`File volume int is ${fileVolumeInt}`)
      // let's give the user volume more power over the file volume
      const newVolume = Math.round((fileVolumeInt / 100) * userVolumeInt) / 100
      console.log(`Computed volume is ${newVolume}`)
      return newVolume
    },
    playAudio({ key }) {
      if (!key) return console.log("Got no audio to play. No key")
      console.log(`New audio key ${key}`)
      if (!Array.isArray(this.getAllAudios))
        return console.log("Got no audios to search through")
      const { source, volume } =
        this.getAllAudios.find(obj => obj.key === key) || {}
      if (!source) return console.log("Got no audio to play. No source")
      console.log(`New audio src ${source}`)
      if (!this.$refs.audio_player)
        return console.log("The audio tag is missing")
      const timestamp = this.audio.timestamp
      const now = Date.now()
      const diff = now - timestamp
      if (diff > MAX_TIMESTAMP_AUDIO_DIFF)
        return console.warn(
          `Cannot play an audio because it started ${diff}ms ago`
        )
      console.log(`Started ${timestamp}`)
      console.log(`Now ${now}`)
      console.log(`Diff ${diff}`)
      // new source
      this.$refs.audio_player.src = source
      if (this.volume > 0) {
        // compute new volume with respect to both initail and user volume
        this.$refs.audio_player.volume = this.computeAverageVolume({
          fileVolume: volume,
          volume: this.volume
        })
      } else {
        // mute
        this.$refs.audio_player.volume = 0
      }
      // refresh
      this.$refs.audio_player.load()
      // a try catch just in case for those
      // that don't support promises for play()
      try {
        const promise = this.$refs.audio_player.play()
        if (promise !== undefined) {
          promise
            .then(() => {
              console.log(`Playing audio "${source}"`)
            })
            .catch(error => {
              console.log(error.message)
            })
        }
      } catch (e) {
        console.log(e.message)
      }
    },
    stopAudio() {
      const { audio_player } = this.$refs
      if (!audio_player) return console.log("The `<audio>` tag is missing")
      audio_player.pause()
      audio_player.currentTime = 0
    },
    onEnded() {
      EventBus.$emit("soundEffect-music-ended")
    }
  }
}
</script>

<style scoped>
span {
  position: fixed;
  pointer-events: none;
  bottom: 0;
  left: 0;
}
</style>
