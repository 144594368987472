import { getSignedURL } from "./api.service"
import axios from "axios"

export const RUNNING = "running"
export const SUCCESS = "success"
export const CANCELLED = "cancelled"

/**
 *
 * @param fileName
 * @param blob
 * @param token
 * @param axiosConfig - additional axios config
 * @return {Promise<any>} Return modified promise. It contains such fields and methods:
 *   - state - it's uploading state
 *   - cancel() - cancel uploading
 */
export function uploadMedia({ fileName, blob, token, axiosConfig }) {
  axiosConfig = axiosConfig || {}
  let resolvePromise, rejectPromise
  // create new promise
  const uploadMediaPromise = new Promise((resolve, reject) => {
    resolvePromise = resolve
    rejectPromise = reject
  })
  //set state for promise
  uploadMediaPromise.state = RUNNING
  // await uploadToStorage(urls.signedURL, blob)
  // create axios request with cancel token
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  getSignedURL({ fileName, token }).then(urls => {
    // Request to upload media, using  axios
    axios
      .put(urls.signedURL, blob, {
        headers: { "Content-Type": blob.type },
        cancelToken: source.token,
        ...axiosConfig
      })
      .then(() => {
        // change state of promise to success and return data
        uploadMediaPromise.state = SUCCESS
        resolvePromise(urls.downloadURL)
      })
      .catch(() => {
        uploadMediaPromise.state = CANCELLED
        rejectPromise()
      })
  })
  uploadMediaPromise.cancel = source.cancel
  return uploadMediaPromise
}

/**
 * Get Blob from data url
 * @param dataURL
 * @return {Promise<Blob>}
 */
export async function getBlobFromDataURL(dataURL) {
  const res = await fetch(dataURL)
  return await res.blob()
}
