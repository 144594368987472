<template>
  <v-layout column justify-center fill-height class="meeting-container">
    <div style="overflow-y: auto;">
      <v-flex shrink class="meeting-container-wrap">
        <v-layout
          row
          wrap
          justify-center
          style="margin: 4px;"
          v-for="(row, i) in videoGridWraped"
          :key="`meeting-row-${i}`"
        >
          <MeetingPlayer
            v-for="(player, j) in row"
            :key="`meeting-user-${player.id}-${i}-${j}`"
            :player="player"
            :videoHeight="size"
            :videoWidth="size"
            :colCount="row.length"
          />
        </v-layout>
      </v-flex>
    </div>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import _ from "lodash"
import { Role } from "@/helpers"

import MeetingPlayer from "@/components/GroupTeams/Common/Player/MeetingPlayer"

const DEFAULT_VIDEO_SIZE = 300

export default {
  name: "Meeting",
  components: {
    MeetingPlayer
  },
  computed: {
    ...mapGetters(["onlineUsersArray"]),
    videoGridWraped() {
      const max = this.getMaxColNumber(this.getAllPlayers.length)
      return this.getAllPlayers.reduce(
        (rows, key, index) =>
          (index % max == 0
            ? rows.push([key])
            : rows[rows.length - 1].push(key)) && rows,
        []
      )
    },
    size() {
      // as the dept increases, let's
      // reduce the size by (0.2% * videosDept)%
      if (this.getAllPlayers.length == 1) return 500
      if (this.getAllPlayers.length == 2) return 400
      if (this.getAllPlayers.length == 3) return 300
      const videosDept = this.videoGridWraped[0].length
      return videosDept > 1
        ? DEFAULT_VIDEO_SIZE - 0.2 * 100 * videosDept
        : DEFAULT_VIDEO_SIZE
    },
    getAllPlayers() {
      return _.chain(this.onlineUsersArray)
        .filter(
          user => user.role !== Role.Audit && user.role !== Role.Spectator
        )
        .sortBy("teamID")
        .sortBy(user => user.role !== Role.Host)
        .value()
    }
  },
  methods: {
    getMaxColNumber(count) {
      if (count <= 3) {
        return count
      } else if (count <= 4) {
        return 2
      } else if (count <= 6) {
        return 3
      } else if (count <= 8) {
        return 4
      } else if (count <= 10) {
        return 5
      } else if (count <= 11) {
        return 6
      } else if (count <= 12) {
        return 4
      } else {
        return 6
      }
    }
  }
}
</script>

<style lang="scss">
.meeting-container {
  position: relative;
  .meeting-container-wrap {
    flex: 1;
  }
}
</style>
