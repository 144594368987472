var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    {
      ref: "root",
      staticClass: "generic-result",
      staticStyle: { "align-items": "flex-start" },
      attrs: { "d-flex": "" }
    },
    [
      _c(
        "v-layout",
        {
          staticStyle: { "min-height": "100%" },
          attrs: { column: "", "justify-center": "" }
        },
        _vm._l(_vm.dataMutable, function(team, index) {
          return _c(
            "v-flex",
            {
              key: "team-result-" + team.id,
              staticClass: "team-result",
              class: { "team-result-top": team.top },
              attrs: { shrink: "" }
            },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "left-col" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c("div", { staticClass: "team-rank" }, [
                            _vm._v(" " + _vm._s(index + 1) + " ")
                          ]),
                          _c("v-icon", { staticClass: "result-team-icon" }, [
                            _vm._v(
                              " " +
                                _vm._s(team.icon || "radio_button_unchecked") +
                                " "
                            )
                          ]),
                          _c(
                            "v-flex",
                            { staticClass: "v-align team-name" },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    row: "",
                                    "justify-space-between": ""
                                  }
                                },
                                [
                                  _c("div", { staticClass: "team-name-span" }, [
                                    _vm._v(" " + _vm._s(team.name) + " ")
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "team-score-now",
                                      class: { pointer: _vm.isHost },
                                      on: {
                                        click: [
                                          function($event) {
                                            if (
                                              $event.ctrlKey ||
                                              $event.shiftKey ||
                                              $event.altKey ||
                                              $event.metaKey
                                            ) {
                                              return null
                                            }
                                            return _vm.onIncreaseMissionScore(
                                              team
                                            )
                                          },
                                          function($event) {
                                            if (!$event.shiftKey) {
                                              return null
                                            }
                                            return _vm.onDecreaseMissionScore(
                                              team
                                            )
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            team.score > 0
                                              ? "+" + team.score
                                              : 0
                                          )
                                      ),
                                      _c("span", { staticClass: "score-pts" }, [
                                        _vm._v("pts")
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "v-align team-total",
                      class: { pointer: _vm.isHost },
                      on: {
                        click: [
                          function($event) {
                            if (
                              $event.ctrlKey ||
                              $event.shiftKey ||
                              $event.altKey ||
                              $event.metaKey
                            ) {
                              return null
                            }
                            return _vm.onIncreaseTotalScore(team)
                          },
                          function($event) {
                            if (!$event.altKey) {
                              return null
                            }
                            return _vm.onDecreaseTotalScore(team)
                          },
                          function($event) {
                            if (!$event.shiftKey) {
                              return null
                            }
                            return _vm.onDecreaseTotalScore(team)
                          }
                        ]
                      }
                    },
                    [
                      _c("div", [
                        _vm._v(_vm._s(team.totalScore)),
                        _c("span", { staticClass: "total-pts" }, [
                          _vm._v("pts")
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }